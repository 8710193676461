import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import PSPDFKit, {
  HighlightAnnotation,
  Instance,
  Rect,
  TextLine,
  type Annotation as PSPDFKitAnnotation,
} from 'pspdfkit'

import { UploadedFile } from 'openapi/models/UploadedFile'
import Services from 'services'
import { Maybe } from 'types'

import { PromiseHelperAllSettled } from 'utils/promise'
import { extractMarkedTextSimple } from 'utils/source'
import { Source } from 'utils/task'

import {
  getMergedAnnotations,
  getOrCreateAnnotations,
  createPdfTextAnnotationObjects,
  getTextLinesFromTextAndStartingPage,
} from './pspdfkit-annotation-creation'

export const applyPageOnlyAnnotations = async (
  activeDocument: UploadedFile,
  sources: Source[],
  pdfkitInstance: Instance,
  selectedSource: Maybe<Source>
  // eslint-disable-next-line max-params
): Promise<any> => {
  await removeAnnotations(pdfkitInstance)
  // highlight selectedSource first
  if (!_.isNil(selectedSource)) {
    // scroll to page
    const newViewState = pdfkitInstance.viewState.set(
      'currentPageIndex',
      selectedSource.page
    )
    pdfkitInstance.setViewState(newViewState)

    // remove mark from text
    const extracted = selectedSource.text
      .replace('<mark>', '')
      .replace('</mark>', '')
    const { textLines } = await getTextLinesFromTextAndStartingPage(
      extracted,
      selectedSource.page,
      pdfkitInstance
    )

    // create annotation objects to submit to PSPDFKit
    for (const textLine of textLines) {
      const highlight = createPdfTextAnnotationObjects(textLine, true)
      void pdfkitInstance.create(highlight)
    }
  }

  // apply all other highlights for other sources in doc
  const annotations = await Promise.all(
    sources
      .filter(
        (source) =>
          source.documentName === activeDocument.name && !_.isNil(source.page)
      )
      .map(async ({ text, page }) => {
        const extracted = extractMarkedTextSimple(text)
        const { textLines } = await getTextLinesFromTextAndStartingPage(
          extracted,
          page,
          pdfkitInstance
        )
        return textLines
      })
  )

  await Promise.all(
    annotations
      .flat()
      .map((textLine) => createPdfTextAnnotationObjects(textLine, false))
      .map((annotation) => pdfkitInstance.create(annotation))
  )
}

export const applyAnnotations = async (
  activeDocument: Maybe<UploadedFile>,
  sources: Source[],
  pdfkitInstance: Maybe<Instance>,
  selectedSource: Maybe<Source>
  // eslint-disable-next-line max-params
): Promise<any> => {
  if (_.isNil(activeDocument)) {
    return
  }
  const annotations = AllPSPDFKitAnnotations(sources, activeDocument.name)

  if (annotations.length === 0) {
    return
  }

  if (_.isNil(pdfkitInstance)) {
    console.warn('No pdfkit instance to apply annotations')
    return
  }

  await Promise.all(
    Array.from({
      length: pdfkitInstance.totalPageCount ?? 0,
    }).map(async (_, pageIndex) => {
      const annotations = await pdfkitInstance.getAnnotations(pageIndex)
      return await Promise.all(
        (annotations ?? []).map(
          async (annotation) => await pdfkitInstance.delete(annotation)
        )
      )
    })
  )

  // create annotation in pspdfkit document
  await Promise.all(
    // eslint-disable-next-line
    annotations.map((annotation) => {
      return pdfkitInstance.create(annotation)
    })
  )

  // if source is selected, scroll to that annotation
  if (!_.isNil(selectedSource)) {
    await scrollToSourceHighlight(selectedSource, pdfkitInstance)
  }
}

export const AllPSPDFKitAnnotations = (
  sources: Source[],
  docName?: string
): PSPDFKitAnnotation[] => {
  let docSources: Source[] = []
  if (docName !== undefined && docName !== '' && !_.isEmpty(sources)) {
    docSources = sources.filter((source) => source.documentName === docName)
  }

  return docSources
    .flatMap((source) => source.annotations as any[])
    .filter((annotation) => annotation)
    .map(
      (raw) =>
        PSPDFKit.Annotations.fromSerializableObject({
          ...raw,
          color: '#BFDBFE',
        }) as PSPDFKitAnnotation
    )
}

export const scrollToSourceHighlight = async (
  source: Source,
  pspdfInstance: Maybe<Instance>
): Promise<void> => {
  const group = source.id
  if (_.isNil(group) || _.isNil(pspdfInstance)) {
    console.warn('No pdfkit instance to apply annotations')
    return
  }

  if (source.annotations.length === 0) {
    // if no annotations, jump to page
    const sourceText = source.text.replace(/…$/, '').substring(0, 30) // remove trailing ellipsis and truncate before searching

    try {
      const searchResults = await pspdfInstance.search(sourceText)

      // @ts-expect-error todo
      let pageIndex = searchResults.first()?.pageIndex ?? source.page

      // XXX: Guarding against over-indexing, not clear how this happens
      if (pageIndex >= pspdfInstance.totalPageCount) {
        const newPage =
          source.page < pspdfInstance.totalPageCount ? source.page : 0

        console.warn(
          `Page index out of bounds: ${pageIndex} of ${pspdfInstance.totalPageCount}, ` +
            `setting to ${newPage}`,
          source
        )
        pageIndex = newPage
      }

      const newViewState = pspdfInstance?.viewState.set(
        'currentPageIndex',
        pageIndex
      )
      pspdfInstance.setViewState(newViewState)
    } catch (e) {
      console.error('cant scroll')
      Sentry.captureException(e)
      Services.HoneyComb.RecordError(e)
    }
    return
  }

  // get current document annotations
  const pagesAnnotations = await Promise.all(
    Array.from({
      length: pspdfInstance.totalPageCount ?? 0,
    }).map(
      // eslint-disable-next-line
      (_, pageIndex) => pspdfInstance.getAnnotations(pageIndex)
    )
  )

  // highlight selected annotation
  pagesAnnotations.forEach((annotations) => {
    if (_.isNil(annotations)) return

    annotations.forEach(async (annotation) => {
      let newOpacity = 0.4
      if (annotation.get('name') === group) {
        newOpacity = 1
      }

      if (annotation.get('opacity') === newOpacity || _.isNil(pspdfInstance)) {
        return
      }

      try {
        await pspdfInstance.update(annotation.set('opacity', newOpacity))
      } catch (e) {
        console.warn('Failed to update highlighted opacity', e)
      }
    })

    const matchingAnnotations = annotations.filter(
      (annotation) => annotation.get('name') === group
    )
    if (matchingAnnotations.size > 0) {
      const lastMatchingAnnotation =
        matchingAnnotations.last() as PSPDFKitAnnotation
      const pageIndex = lastMatchingAnnotation.get('pageIndex') ?? -1
      pspdfInstance.jumpToRect(pageIndex, lastMatchingAnnotation.boundingBox)
    }
  })
}

// documentation from pspdfkit to remove annotations
// https://pspdfkit.com/guides/web/knowledge-base/delete-all-annotations/
export const removeAnnotations = async (instance: Instance) => {
  const pagesAnnotations = (
    await PromiseHelperAllSettled(
      Array.from({
        length: instance.totalPageCount,
      }).map((_, pageIndex) => instance.getAnnotations(pageIndex))
    )
  )
    .filter((result) => result.status === 'fulfilled')
    .map((result) => result.value)

  const annotationIds = pagesAnnotations.flatMap((pageAnnotations) =>
    pageAnnotations
      .map((annotation: PSPDFKitAnnotation) => annotation.id)
      .toArray()
  )
  await instance.delete(annotationIds)
}

export const navigateToPage = (pageIndex: number, pdfkitInstance: Instance) => {
  const newViewState = pdfkitInstance.viewState.set(
    'currentPageIndex',
    pageIndex
  )
  pdfkitInstance.setViewState(newViewState)
}

const renderAnnotations = async (
  annotations: HighlightAnnotation[],
  pdfkitInstance: Instance
) => {
  await Promise.all(
    annotations.map((annotation) => pdfkitInstance.create(annotation))
  )
}

export const applyFrontendAnnotations = async (params: {
  sources: Source[]
  pdfkitInstance: Instance
  selectedSource: Maybe<Source>
  cachedSourceAnnotations: {
    [key: string]: {
      startingPage: number
      textLines: [TextLine[], Maybe<Rect>, Maybe<Rect>]
    }
  }
  updateSourceAnnotationStore: (
    sourceId: string,
    annotation: {
      startingPage: number
      textLines: [TextLine[], Maybe<Rect>, Maybe<Rect>]
    }
  ) => void
  cachedDocumentAnnotations: {
    [key: number]: [TextLine[], Maybe<Rect>, Maybe<Rect>][]
  }
  updateDocumentAnnotationStore: (
    page: number,
    annotations: [TextLine[], Maybe<Rect>, Maybe<Rect>][]
  ) => void
}) => {
  const {
    sources,
    pdfkitInstance,
    selectedSource,
    cachedSourceAnnotations,
    updateSourceAnnotationStore,
    cachedDocumentAnnotations,
    updateDocumentAnnotationStore,
  } = params
  // delete all annotations
  await removeAnnotations(pdfkitInstance)

  const allAnnotations = []
  // highlight selectedSource
  if (!_.isNil(selectedSource)) {
    // scroll to page
    navigateToPage(selectedSource.page ?? 0, pdfkitInstance)

    const { startingPage, annotations } = await getOrCreateAnnotations({
      isSelectedSource: true,
      selectedSource,
      cachedAnnotations: cachedSourceAnnotations,
      pdfkitInstance,
      updateAnnotationStore: updateSourceAnnotationStore,
    })
    if (selectedSource.page !== startingPage) {
      navigateToPage(startingPage, pdfkitInstance)
    }
    allAnnotations.push(...annotations)
  }

  // apply all other highlights for other sources in doc
  const annotations = await getMergedAnnotations(
    sources,
    pdfkitInstance,
    cachedDocumentAnnotations,
    updateDocumentAnnotationStore
  )
  allAnnotations.push(...annotations)
  await renderAnnotations(allAnnotations, pdfkitInstance)
}

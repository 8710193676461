import React from 'react'

interface IManageIconProps {
  className?: string
}

const IManageIcon: React.FC<IManageIconProps> = ({ className = '' }) => {
  return (
    <img className={className} src="/img/logos/imanage.svg" alt="iManage" />
  )
}

export default IManageIcon

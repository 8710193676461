import React, { useState } from 'react'

import Services from 'services'

import { site } from 'utils/server-data'
import { getSiteName } from 'utils/site'
import { displayErrorMessage } from 'utils/toast'

import { useLoadWorkflowsQuery } from 'components/assistant/workflows/workflow-loader'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import Combobox from 'components/ui/combobox/combobox'
import { Spinner } from 'components/ui/spinner'

const SettingsWorkflowPermissions = () => {
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false)
  const [roles, setRoles] = useState<string[]>([])
  const [selectedWorkflow, setSelectedWorkflow] = useState('')
  const [users, setUsers] = useState<string[]>([])

  const { data: workflows, isLoading: isLoadingWorkflows } =
    useLoadWorkflowsQuery()

  const onDropdownChange = async (value: string): Promise<void> => {
    setSelectedWorkflow(value)
    if (!value) {
      setRoles([])
      setUsers([])
      return
    }

    setIsLoadingPermissions(true)

    try {
      const results = await Services.Backend.Get<{
        roles: string[]
        users: string[]
      }>(`internal_admin/workflows/${value}/permissions`)

      setRoles(results.roles)
      setUsers(results.users)
    } catch (error) {
      displayErrorMessage('Failed to fetch permissions')
    } finally {
      setIsLoadingPermissions(false)
    }
  }

  return (
    <>
      <SettingsAppHeader
        isInternalAdmin
        title={`Workflow Permissions (${getSiteName(site)})`}
      />
      <SettingsLayout>
        <Combobox
          defaultText="Select workflow"
          isLoading={isLoadingWorkflows}
          options={
            workflows?.map((w) => ({
              value: w.id,
              label: w.name,
            })) ?? []
          }
          setValue={onDropdownChange}
          value={selectedWorkflow}
        />
        <div className="mb-2 mt-4 text-lg">Users</div>
        {!selectedWorkflow && (
          <div className="text-sm">Select a workflow to view users</div>
        )}
        {isLoadingPermissions && (
          <div className="flex items-center gap-2 text-sm">
            <Spinner size="xxs" className="shrink-0 fill-primary" /> Loading...
          </div>
        )}
        {selectedWorkflow && !isLoadingPermissions && (
          <>
            {users.length === 0 && (
              <div className="text-sm italic">No users</div>
            )}
            {users.map((user) => (
              <div key={user} className="text-sm">
                {user}
              </div>
            ))}
          </>
        )}
        <div className="mb-2 mt-4 text-lg">Roles</div>
        {!selectedWorkflow && (
          <div className="text-sm">Select a workflow to view roles</div>
        )}
        {isLoadingPermissions && (
          <div className="flex items-center gap-2 text-sm">
            <Spinner size="xxs" className="shrink-0 fill-primary" /> Loading...
          </div>
        )}
        {selectedWorkflow && !isLoadingPermissions && (
          <>
            {roles.length === 0 && (
              <div className="text-sm italic">No roles</div>
            )}
            {roles.map((role) => (
              <div key={role} className="text-sm">
                {role}
              </div>
            ))}
          </>
        )}
      </SettingsLayout>
    </>
  )
}

export default SettingsWorkflowPermissions

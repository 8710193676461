import { GridApi } from 'ag-grid-community'

import { EM_DASH } from 'utils/utils'

import { ReviewAnswer } from 'components/vault/utils/vault'
import { FilterType } from 'components/vault/utils/vault-data-grid-filters-store'
import { getDisplayAnswer } from 'components/vault/utils/vault-helpers'

import { ColumnFilter } from './column-filter-types'

export class TextColumnFilter extends ColumnFilter {
  getUniqueColumnValues(
    gridApi: GridApi,
    currentFilterQuestionId: string
  ): string[] {
    const uniqueValuesSet = new Set<string>()
    gridApi.forEachNode((node) => {
      // when grouping by folder, we want to ignore the rows that are the group rows
      // because they do not have any unique value
      if (node.group) return
      const answer = node.data.answers?.find(
        (answer: ReviewAnswer) => answer.columnId === currentFilterQuestionId
      )
      let value = getDisplayAnswer(answer)

      // if the value is empty or not part of the answers (ie the name column)
      if (!value || value === EM_DASH) {
        value = node.data[currentFilterQuestionId]
      }

      if (value != null && value.length > 0) {
        uniqueValuesSet.add(value.toString())
      }
    })
    return Array.from(uniqueValuesSet).sort((a, b) => a.localeCompare(b))
  }

  getFilterType(): FilterType {
    return FilterType.TEXT
  }
}

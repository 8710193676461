import * as React from 'react'

import { cn } from 'utils/utils'

import { FLOWS } from 'components/common/flows'
import { Dialog, DialogContent } from 'components/ui/dialog'

export type StackValue = {
  flow: string
  step: string
  stepInput: Record<string, unknown>
  stepIndex: number
}

// TODO(@jhoong): exported as I couldn't figure out how get nested context working. I know, I'm a bad boy.
export type ModalFlowStepContextType = {
  step: string
  stepInput: Record<string, unknown>
  stepIndex: number
}

type ModalFlowNavigatorContextType = {
  stack: StackValue[]
  setStack: React.Dispatch<React.SetStateAction<StackValue[]>>
}

// The context for each step in the flow. This holds everything the current step needs to know
export const ModalFlowStepContext = React.createContext<
  ModalFlowStepContextType | undefined
>(undefined)

// This just holds a stack for downstream controls
export const ModalFlowNavigatorContext = React.createContext<
  ModalFlowNavigatorContextType | undefined
>(undefined)

export const useModalFlowNavigatorContext = () => {
  const value = React.useContext(ModalFlowNavigatorContext)

  if (!value) {
    throw new Error(
      'Tried to consume ModalFlowNavigatorContext without a provider'
    )
  }

  return value
}

export const useModalFlowStepContext = () => {
  const value = React.useContext(ModalFlowStepContext)

  if (!value) {
    throw new Error('Tried to consume ModalFlowStepContext without a provider')
  }

  return value
}

interface ModalFlowProps<T extends typeof FLOWS> {
  flows: T
  children: React.ReactNode
}

export const MODAL_HEIGHT = 500
export const MODAL_WIDTH = 700

export const ModalFlowNavigator = <T extends typeof FLOWS>({
  flows,
  children,
}: ModalFlowProps<T>) => {
  const [stack, setStack] = React.useState<StackValue[]>([])
  const contextValue = React.useMemo(
    () => ({ stack, setStack }),
    [stack, setStack]
  )

  return (
    <ModalFlowNavigatorContext.Provider value={contextValue}>
      <Dialog modal open={stack.length > 0}>
        <DialogContent
          preventClose
          showCloseIcon={false}
          className="h-[80vh] max-h-[500px] w-[90vw] max-w-[700px]"
          innerClassName="p-0 flex flex-col space-y-0"
        >
          {stack.map((stackValue, index) => {
            const currentStep = index === stack.length - 1
            const FlowComponent = flows[stackValue.flow]

            if (!FlowComponent) {
              throw new Error(
                `Tried to navigate to a non-existent flow (${stackValue.flow}). Please add your flow to the Flows component at the top level of the app (src/components/common/flows/index.tsx)`
              )
            }

            return (
              <div
                className={cn('relative flex h-full flex-col', {
                  hidden: !currentStep,
                })}
                key={index}
                data-testid="modal-content"
              >
                <ModalFlowStepContext.Provider value={stackValue}>
                  <FlowComponent />
                </ModalFlowStepContext.Provider>
              </div>
            )
          })}
        </DialogContent>
      </Dialog>
      {children}
    </ModalFlowNavigatorContext.Provider>
  )
}

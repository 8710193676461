import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { formatDistanceToNow } from 'date-fns'
import _ from 'lodash'
import { Plus, Trash2 } from 'lucide-react'

import {
  BulkRevokePermBundlesFromUsers,
  FetchWorkspacePermBundles,
  Perm,
  WorkspacePermBundle,
  PermSource,
} from 'models/perms'
import { Permission } from 'models/user-info'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'

import { displayErrorMessage } from 'utils/toast'
import { parseIsoString } from 'utils/utils'

import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import WorkspacePermissionsDialog from 'components/settings/workspace/workspace-details/workspace-permissions-dialog'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import { Dialog } from 'components/ui/dialog'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

const READABLE_SOURCE_NAME: Record<PermSource, string> = {
  [PermSource.USER]: 'User',
}

type PermBundleRowProps = {
  type: 'permBundle'
  permBundle: WorkspacePermBundle
  children: PermRowProps[]
}

type PermRowProps = {
  type: 'perm'
  perm: Perm
  isLast?: boolean
  children?: never
}

type RowProps = PermBundleRowProps | PermRowProps

interface UserPermBundlesTableProps {
  workspaceId: number
  userEmail: string
  perms: Perm[]
  refetchPerms: () => Promise<void>
}

const UserPermBundlesTable: React.FC<UserPermBundlesTableProps> = ({
  workspaceId,
  userEmail,
  perms,
  refetchPerms,
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'id', desc: false },
  ])
  const [permBundles, setPermBundles] = useState<WorkspacePermBundle[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [permDialogOpen, setPermDialogOpen] = useState(false)
  const [confirmingDelete, setConfirmingDelete] = useState<{
    bundleId: PermissionBundleId
  } | null>(null)

  const fetchPermBundles = useCallback(async () => {
    try {
      setIsLoading(true)
      const bundles = await FetchWorkspacePermBundles(workspaceId)
      setPermBundles(bundles)
    } catch (error) {
      displayErrorMessage('Failed to fetch permission bundles')
    } finally {
      setIsLoading(false)
    }
  }, [workspaceId])

  useEffect(() => {
    void fetchPermBundles()
  }, [fetchPermBundles])

  const handleDeletePermBundle = async (bundleId: PermissionBundleId) => {
    try {
      await BulkRevokePermBundlesFromUsers([userEmail], [bundleId])
      await refetchPerms()
    } catch (error) {
      displayErrorMessage('Failed to remove permission bundle')
    }
  }

  const columns = useMemo<ColumnDef<RowProps>[]>(
    () => [
      {
        accessorKey: 'name',
        header: ({ column }) => (
          <DataTableSortHeader column={column} header="Name" />
        ),
        cell: ({ row }) => {
          if (row.original.type === 'permBundle') {
            return (
              <div>
                <span className="text-sm font-semibold">
                  {row.original.permBundle.name}
                </span>
              </div>
            )
          }
          return <div className="pl-4 text-sm">{row.original.perm.name}</div>
        },
      },
      {
        accessorKey: 'id',
        header: ({ column }) => (
          <DataTableSortHeader column={column} header="ID" />
        ),
        cell: ({ row }) => {
          if (row.original.type === 'permBundle') {
            return (
              <pre className="text-xs font-semibold">
                {row.original.permBundle.id}
              </pre>
            )
          }
          return <pre className="text-xs">{row.original.perm.permId}</pre>
        },
      },
      {
        accessorKey: 'desc',
        header: 'Description',
        size: 300,
        cell: ({ row }) => {
          if (row.original.type === 'permBundle') {
            return (
              <span className="text-sm">
                {row.original.permBundle.description}
              </span>
            )
          }
          return <span className="text-sm">{row.original.perm.desc}</span>
        },
      },
      {
        accessorKey: 'source',
        header: 'Source',
        cell: ({ row }) => {
          if (row.original.type === 'permBundle') {
            return null
          }
          const sources = row.original.perm.permSources ?? []
          return (
            <div className="flex flex-wrap">
              {sources.map((source, idx) => {
                const readableSource =
                  READABLE_SOURCE_NAME[source as PermSource] || source
                return (
                  <div key={idx} className="m-0.5">
                    <Badge variant="secondary" className="text-xs">
                      {readableSource}
                    </Badge>
                  </div>
                )
              })}
            </div>
          )
        },
      },
      {
        accessorKey: 'expiresAt',
        header: ({ column }) => (
          <DataTableSortHeader column={column} header="Expires At" />
        ),
        cell: ({ row }) => {
          if (row.original.type === 'permBundle') {
            return null
          }
          const expiresAt = row.original.perm.expiresAt
          if (!expiresAt) {
            return <span className="text-sm">Never</span>
          }
          const expiresAtDate = parseIsoString(expiresAt)
          const timeDifference = _.startCase(
            formatDistanceToNow(expiresAtDate, { addSuffix: true })
          )
          return <span className="text-sm">{timeDifference}</span>
        },
      },
      {
        id: 'actions',
        header: 'Actions',
        cell: ({ row }) => {
          if (row.original.type === 'perm') {
            return null
          }

          const userPermsInBundle = perms.filter(
            (perm) =>
              row.original.type === 'permBundle' &&
              row.original.permBundle.permissions.includes(
                perm.permId as Permission
              ) &&
              (perm.permSources ?? []).includes(PermSource.USER)
          )

          const hasUserPerms = userPermsInBundle.length > 0
          if (!hasUserPerms) {
            return (
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="ghost"
                    size="icon"
                    disabled
                    className="cursor-not-allowed"
                  >
                    <Trash2 className="size-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="left">
                  All permissions in this bundle originate from role(s)
                </TooltipContent>
              </Tooltip>
            )
          }

          return (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                if (row.original.type === 'permBundle') {
                  setConfirmingDelete({
                    bundleId: row.original.permBundle.id as PermissionBundleId,
                  })
                }
              }}
            >
              <Trash2 className="size-4" />
            </Button>
          )
        },
      },
    ],
    [perms]
  )

  const rows = useMemo<PermBundleRowProps[]>(
    () =>
      permBundles
        .map((bundle) => {
          const bundlePerms = perms.filter((perm) =>
            bundle.permissions.includes(perm.permId as Permission)
          )
          if (bundlePerms.length === 0) {
            return null
          }

          return {
            type: 'permBundle',
            permBundle: bundle,
            children: bundlePerms.map<PermRowProps>((perm, index) => ({
              type: 'perm',
              perm,
              isLast: index === bundlePerms.length - 1,
            })),
          }
        })
        .filter((row): row is PermBundleRowProps => row !== null),
    [permBundles, perms]
  )

  const existingPermBundleIds = rows.map((row) => row.permBundle.id)

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getRowId: (row) => {
      return row.type === 'permBundle' ? row.permBundle.id : row.perm.permId
    },
    getSubRows: (row) => row.children,
    state: {
      sorting,
      expanded: true,
    },
  })

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-lg font-semibold">Current Permissions</h1>
        <Button onClick={() => setPermDialogOpen(true)}>
          <Plus className="mr-1 size-4" />
          Add permissions
        </Button>
      </div>
      <DataTable
        table={table}
        isLoading={isLoading}
        tableCellClassName="p-3"
        tableRowClassName={(row: Row<RowProps>) =>
          row.original.type === 'permBundle'
            ? 'bg-secondary'
            : !row.original.isLast
            ? 'border-b-0'
            : ''
        }
      />
      <div className="mt-2 flex justify-end text-sm text-muted">
        {perms.length} permissions
      </div>

      {confirmingDelete && (
        <Dialog open onOpenChange={() => setConfirmingDelete(null)}>
          <ConfirmationDialog
            title="Remove Permission Bundle"
            description={`Are you sure you want to remove this permission bundle? This will remove all the underlying user permissions for ${userEmail}.`}
            variant="destructive"
            cta={{
              label: 'Remove',
              onClick: () => handleDeletePermBundle(confirmingDelete.bundleId),
            }}
            secondaryCta={{
              label: 'Cancel',
              onClick: () => setConfirmingDelete(null),
            }}
          />
        </Dialog>
      )}

      {permDialogOpen && (
        <WorkspacePermissionsDialog
          isOpen
          addOnly
          workspaceId={workspaceId}
          isInternalAdmin={false}
          onClose={() => setPermDialogOpen(false)}
          onComplete={() => {
            setPermDialogOpen(false)
            void refetchPerms()
          }}
          selectedUsers={[userEmail]}
          excludedBundles={existingPermBundleIds}
        />
      )}
    </div>
  )
}

export default UserPermBundlesTable

/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SocketMessageResponseTranscriptsProcessedResult } from './SocketMessageResponseTranscriptsProcessedResult';
import {
    SocketMessageResponseTranscriptsProcessedResultFromJSON,
    SocketMessageResponseTranscriptsProcessedResultFromJSONTyped,
    SocketMessageResponseTranscriptsProcessedResultToJSON,
    SocketMessageResponseTranscriptsProcessedResultToJSONTyped,
} from './SocketMessageResponseTranscriptsProcessedResult';

/**
 * 
 * @export
 * @interface WorkflowTranscriptSummarizationRendererBlockParams
 */
export interface WorkflowTranscriptSummarizationRendererBlockParams {
    /**
     * 
     * @type {SocketMessageResponseTranscriptsProcessedResult}
     * @memberof WorkflowTranscriptSummarizationRendererBlockParams
     */
    answer?: SocketMessageResponseTranscriptsProcessedResult;
}

/**
 * Check if a given object implements the WorkflowTranscriptSummarizationRendererBlockParams interface.
 */
export function instanceOfWorkflowTranscriptSummarizationRendererBlockParams(value: object): value is WorkflowTranscriptSummarizationRendererBlockParams {
    return true;
}

export function WorkflowTranscriptSummarizationRendererBlockParamsFromJSON(json: any): WorkflowTranscriptSummarizationRendererBlockParams {
    return WorkflowTranscriptSummarizationRendererBlockParamsFromJSONTyped(json, false);
}

export function WorkflowTranscriptSummarizationRendererBlockParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowTranscriptSummarizationRendererBlockParams {
    if (json == null) {
        return json;
    }
    return {
        
        'answer': json['answer'] == null ? undefined : SocketMessageResponseTranscriptsProcessedResultFromJSON(json['answer']),
    };
}

export function WorkflowTranscriptSummarizationRendererBlockParamsToJSON(json: any): WorkflowTranscriptSummarizationRendererBlockParams {
    return WorkflowTranscriptSummarizationRendererBlockParamsToJSONTyped(json, false);
}

export function WorkflowTranscriptSummarizationRendererBlockParamsToJSONTyped(value?: WorkflowTranscriptSummarizationRendererBlockParams | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'answer': SocketMessageResponseTranscriptsProcessedResultToJSON(value['answer']),
    };
}


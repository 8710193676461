import * as React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { useResearchTaxonomyQuery } from 'models/queries/use-research-taxonomy-query'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import SourceRendererItem from 'components/assistant/features/composer/components/source-renderer-item'
import SourcesToolbar from 'components/assistant/features/composer/components/sources-toolbar'
import { NEW_KNOWLEDGE_SOURCE_CONFIG } from 'components/assistant/features/composer/config'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { ResearchKnowledgeSource } from 'components/assistant/utils/assistant-knowledge-sources'
import { getAllChildrenDeep } from 'components/research/research-helpers'
import { ScrollArea } from 'components/ui/scroll-area'

interface ResearchRendererProps {
  handleCancel: () => void
}

const getParentFilter = (
  filterId: string,
  allFiltersFlattened: ResearchFilter[]
): ResearchFilter | undefined => {
  const filter = allFiltersFlattened.find((f) => f.id === filterId)
  if (!filter) return
  const parentFilter = allFiltersFlattened.find((f) => f.id === filter.parentId)
  if (parentFilter) {
    return getParentFilter(parentFilter.id, allFiltersFlattened)
  }
  return filter
}

const formatFilterText = (
  filterId: string,
  allFiltersFlattened: ResearchFilter[]
) => {
  const parentFilter = getParentFilter(filterId, allFiltersFlattened)
  return parentFilter?.name || ''
}

const ResearchRenderer: React.FC<ResearchRendererProps> = ({
  handleCancel,
}) => {
  const { taxonomy } = useResearchTaxonomyQuery(null)

  const allFiltersFlattened = React.useMemo(() => {
    const researchFilters = taxonomy
    return researchFilters.concat(
      researchFilters.flatMap((researchFilter) =>
        getAllChildrenDeep(researchFilter)
      )
    )
  }, [taxonomy])

  const [
    knowledgeSource,
    removeFilterIds,
    setShowResearchDialog,
    setResearchDialogArea,
  ] = useAssistantStore(
    useShallow((s) => [
      s.knowledgeSource,
      s.removeFilterIds,
      s.setShowResearchDialog,
      s.setResearchDialogArea,
    ])
  )
  const knowledgeSourceItem = knowledgeSource as ResearchKnowledgeSource
  const config = NEW_KNOWLEDGE_SOURCE_CONFIG[knowledgeSourceItem.type]
  const groupedFilters = React.useMemo(
    () =>
      knowledgeSourceItem.filterIds.reduce(
        (acc, filterId) => {
          const text = formatFilterText(filterId, allFiltersFlattened)
          acc[text] = (acc[text] || []).concat(filterId)
          return acc
        },
        {} as Record<string, string[]>
      ),
    [knowledgeSourceItem.filterIds, allFiltersFlattened]
  )

  const handleClick = React.useCallback(() => {
    setShowResearchDialog(true)
    setResearchDialogArea(knowledgeSourceItem.type)
  }, [knowledgeSourceItem.type, setShowResearchDialog, setResearchDialogArea])

  return (
    <>
      <SourcesToolbar
        handleCancel={handleCancel}
        text={config.title}
        icon={config.icon}
      />
      <div className="flex flex-col border-t-[0.5px]">
        {/* 214 = 36 (file item height) * 5.5 + 16 (padding) */}
        <ScrollArea className="h-full" maxHeight="max-h-[214px]">
          <div className="p-2">
            {Object.entries(groupedFilters).map(([text, filterIds]) => (
              <SourceRendererItem
                key={text}
                text={`${text} / ${filterIds.length} selected`}
                handleRemove={() => removeFilterIds(filterIds)}
                handleClick={handleClick}
              />
            ))}
          </div>
        </ScrollArea>
      </div>
    </>
  )
}

export default ResearchRenderer

import React, { useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { Edit2, Trash } from 'lucide-react'
import pluralize from 'pluralize'

import Services from 'services'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { getTableDateString, isoTimestampIsPast } from 'utils/date-utils'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'

import { ApiKey } from './workspace-details-api'
import ApiEditDialog from './workspace-details-api-edit-dialog'

interface WorkspaceDetailsApiTableProps {
  workspaceId: number
  apiKeys: ApiKey[]
  onDeleteApiKey: (apiKeyId: string) => void
  onSaveApiKey: (apiKeyId: string) => void
}

interface ApiKeySetExpiry {
  id: string
  expiresInSeconds: number
}

const WorkspaceDetailsApiTable = ({
  workspaceId,
  apiKeys,
  onDeleteApiKey,
  onSaveApiKey,
}: WorkspaceDetailsApiTableProps) => {
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const [currentlyEditing, setCurrentlyEditing] = useState<ApiKey | undefined>(
    undefined
  )

  const onApiCellClick = (email: string) => {
    navigate(`/settings/internal_admin/user-inspector`, {
      state: { email },
    })
  }
  const onEditClick = (id: string) => {
    setCurrentlyEditing(apiKeys.find((e) => e.id == id))
  }
  const onSetExpiry = (id: string, hours: number) => {
    return Services.Backend.Patch<ApiKeySetExpiry>('settings/api/token', {
      workspaceId: workspaceId,
      id: id,
      expiresInSeconds: 60 * 60 * hours,
    }).then(() => onSaveApiKey(id))
  }

  const columns: ColumnDef<ApiKey>[] = [
    {
      accessorKey: 'id',
    },
    {
      accessorKey: 'userEmail',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Service User" />
      },
      cell: ({ getValue, row }) => (
        <div
          className="ml-3 text-sm"
          onClick={() => onApiCellClick(row.getValue('userEmail'))}
          onKeyDown={() => row.getValue('userEmail')}
          tabIndex={0}
          role="button"
        >
          {String(getValue())}
        </div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'token',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Token" />
      },
      cell: ({ row }) => (
        <div
          className="ml-3 text-sm"
          onClick={() => onApiCellClick(row.getValue('userEmail'))}
          onKeyDown={() => row.getValue('userEmail')}
          tabIndex={0}
          role="button"
        >
          {String(row.getValue('id'))}
        </div>
      ),
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Created" />
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'expiresAt',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Expiry" />
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 text-sm">
            {value ? getTableDateString(value) : 'Never'}
          </div>
        )
      },
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'actions',
      header: '',
      size: 1,
      cell: ({ row }) => {
        if (!userInfo?.IsInternalAdminWriter) return null
        return (
          <div className="float-right flex justify-end">
            <Button
              className="mx-1 flex items-center justify-center rounded transition hover:bg-button-secondary-hover"
              variant="ghost"
              size="xsIcon"
              tooltip={`Edit ${row.getValue('userEmail')}`}
              tooltipSide="left"
              disabled={isoTimestampIsPast(row.getValue('expiresAt'))}
              onClick={() => onEditClick(row.getValue('id'))}
            >
              <Edit2 className="h-4 w-4" />
            </Button>
            <Button
              className="mx-1 flex items-center justify-center rounded transition hover:bg-button-secondary-hover"
              variant="ghost"
              size="xsIcon"
              tooltip={`Remove ${row.getValue('userEmail')}`}
              tooltipSide="left"
              onClick={() => onDeleteApiKey(row.getValue('id'))}
              aria-label={`Remove ${row.getValue('userEmail')}`}
            >
              <Trash className="h-4 w-4 stroke-destructive" />
            </Button>
          </div>
        )
      },
      enableGlobalFilter: false,
    },
  ]

  const [filter, setFilter] = useState<string>('')

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: apiKeys,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
      columnVisibility: {
        id: false,
      },
    },
    onPaginationChange: setTablePaginationState,
    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'key',
    table.getFilteredRowModel().rows.length
  )}`

  return (
    <div>
      <DataTableHeader
        searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
      />
      <div className="mt-2">
        <DataTable
          table={table}
          className="min-h-[420px]"
          emptyStateText="No API keys found"
        />
      </div>
      <DataTableFooter table={table}>
        <p>{rowCountCopy}</p>
      </DataTableFooter>
      <ApiEditDialog
        apiKey={currentlyEditing}
        close={() => setCurrentlyEditing(undefined)}
        setExpiryFromNow={onSetExpiry}
      />
    </div>
  )
}

export default WorkspaceDetailsApiTable

import React, { useMemo } from 'react'

import { ColumnDef } from '@tanstack/react-table'

import { TableSelect } from 'openapi/models/TableSelect'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'
import { Maybe } from 'types'

import { Source } from 'utils/task'

import AssistantSources from 'components/assistant/components/assistant-sources'
import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import TableSelectTable, {
  generateColumns,
} from 'components/assistant/workflows/components/table-select/table-select'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
} from './assistant-workflow-block-layout'

type RowData = TableSelect['rows'][number]

export const AssistantWorkflowTableRendererThread: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.TABLE
> = ({ blockParams, blockStatus }) => {
  const { headerText, table } = blockParams
  const { handleSourceClick, getHrvyInfoMetadata } = useSourceUtils({
    sources: (blockParams.sources || []) as Source[],
  })

  const columns: ColumnDef<RowData>[] = table
    ? generateColumns(table, getHrvyInfoMetadata)
    : []

  const Sidebar = useMemo(
    () =>
      (blockParams.sources?.length || 0) > 0 ? (
        <AssistantSources
          allDocuments={[]}
          sources={blockParams.sources as Maybe<Source[]>}
          onSetActiveFileId={handleSourceClick}
          // 68px makes it align with the content of the block
          className="absolute left-0 top-[68px] ml-4 sm:ml-4"
        />
      ) : undefined,
    [blockParams.sources, handleSourceClick]
  )

  return (
    <AssistantWorkflowThreadBlock sidebar={Sidebar}>
      <AssistantWorkflowHarveyComponent>
        <AssistantWorkflowThreadText
          blockStatus={blockStatus}
          text={headerText}
        />
        <div className="mt-5">
          {table && <TableSelectTable data={table.rows} columns={columns} />}
        </div>
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowTableRendererInput: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.TABLE
> = ({ blockParams }) => {
  const { headerText } = blockParams
  return (
    <WorkflowInput>
      <WorkflowInputFooter footer={headerText} />
    </WorkflowInput>
  )
}

import * as React from 'react'
import { useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  useFlowControls,
  useFlowStepInput,
} from 'providers/modal-flow-provider/modal-flow-controls'
import { Step } from 'providers/modal-flow-provider/modal-flow-types'
import { z } from 'zod'

import { Title } from 'openapi/models/Title'

import { shouldShowPracticeAreas } from 'utils/user-profile-helpers'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import Modal from 'components/common/flows/profile-setup/components/modal'
import ModalClose from 'components/common/flows/profile-setup/components/modal-close'
import { useSkipOnboarding } from 'components/common/flows/profile-setup/hooks/use-skip-onboarding'
import { ProfileSetupFlowType } from 'components/common/flows/profile-setup/profile-setup'
import { prepareEventProperties } from 'components/common/flows/profile-setup/utils/prepare-event-properties'
import ProfileTitleForm, {
  FORM_DESCRIPTION,
  FORM_HEADING,
} from 'components/common/profile/profile-title-form'

import { ProfessionStepType } from './profession-step'

export type JobTitleStepType = Step<
  ProfessionStepType['stepOutput'],
  {
    title: Title
  }
>

const jobTitleSchema = z.object({
  title: z.nativeEnum(Title),
})

export type JobTitleSchema = z.infer<typeof jobTitleSchema>

const JobTitleStep: React.FC = () => {
  const { goBack, navigateToStep, exit } =
    useFlowControls<ProfileSetupFlowType>()
  const stepInput = useFlowStepInput<JobTitleStepType>()
  const form = useForm<JobTitleSchema>({
    resolver: zodResolver(jobTitleSchema),
  })
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const handleSkipOnboarding = useSkipOnboarding({ stepInput, exit })

  React.useEffect(() => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent('profile_setup_job_title_step_viewed', preparedEventProperties)
  }, [stepInput, trackEvent])

  const handleContinue = form.handleSubmit((data) => {
    if (shouldShowPracticeAreas(stepInput.primaryProfession, data.title)) {
      navigateToStep('practice-area-step', {
        ...stepInput,
        title: data.title,
        stepCounter: stepInput.stepCounter + 1,
      })
    } else {
      navigateToStep('primary-language-step', {
        ...stepInput,
        title: data.title,
        stepCounter: stepInput.stepCounter + 1,
        totalSteps: stepInput.totalSteps - 1,
      })
    }
  })

  return (
    <Modal
      goBack={goBack}
      handleContinue={handleContinue}
      currentStep={stepInput.stepCounter}
      totalSteps={stepInput.totalSteps}
      nextDisabled={!form.formState.isValid}
      heading={FORM_HEADING}
      description={FORM_DESCRIPTION}
    >
      {userInfo.isCreateUserProfilesOptionalUser ? (
        <ModalClose onClick={handleSkipOnboarding} />
      ) : null}
      <ProfileTitleForm control={form.control} />
    </Modal>
  )
}

export default JobTitleStep

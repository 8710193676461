import React, { useMemo } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { EventStatus } from 'openapi/models/EventStatus'

import { RenderWorkflowComponent } from './assistant-block-renderer'
import { useAssistantWorkflowStore } from './stores/assistant-workflow'
import { isFrontendBlock, stepBlockIdToWorkflowBlockDefinition } from './utils'

export const useAssistantWorkflowComponents = (
  handleCurrentStepCompletion: (stepId: string, result: any) => void
) => {
  const [workflowDefinition, currentEvent] = useAssistantWorkflowStore(
    useShallow((state) => [state.workflowDefinition, state.currentEvent])
  )

  // TODO: We probably want to separate completed steps and current step
  // So we don't have to re-render the completed steps
  const componentsToRender = useMemo(() => {
    const ThreadComponents: React.ReactNode[] = []
    let InputComponent: React.ReactNode | null = null

    if (!currentEvent) {
      return { InputComponent, ThreadComponents }
    }

    let stepIdx = 0
    for (; stepIdx < (currentEvent.steps?.length || 0); stepIdx++) {
      const step = currentEvent.steps[stepIdx]
      const isCurrentStep = stepIdx === currentEvent.steps.length - 1

      const blockDefinition = stepBlockIdToWorkflowBlockDefinition(
        workflowDefinition,
        step.blockId
      )

      if (!isFrontendBlock(blockDefinition)) {
        console.warn('Unexpected block definition', blockDefinition)
        continue
      }

      const blockParams = step.blockParams

      ThreadComponents.push(
        <RenderWorkflowComponent
          key={stepIdx}
          stepIdx={stepIdx}
          blockType={blockDefinition.blockId as any}
          blockParams={blockParams as any}
          outputData={step.outputData as any}
          renderType="thread"
          onCompleted={(result: unknown) =>
            handleCurrentStepCompletion(step.blockId, result)
          }
          status={step.status as EventStatus}
        />
      )

      // If this is the last step, we want to render this input component
      if (isCurrentStep) {
        InputComponent = (
          <RenderWorkflowComponent
            key={stepIdx}
            stepIdx={stepIdx}
            blockType={blockDefinition.blockId as any}
            blockParams={blockParams as any}
            outputData={step.outputData as any}
            renderType="input"
            onCompleted={(result: unknown) =>
              handleCurrentStepCompletion(step.blockId, result)
            }
            status={step.status as EventStatus}
          />
        )
      }
    }

    return { InputComponent, ThreadComponents }
  }, [currentEvent, workflowDefinition, handleCurrentStepCompletion])

  return componentsToRender
}

import React from 'react'

type RequestForInformationComponentProps = {
  countryName: string
  requests: string[]
}

export const RequestForInformationComponent: React.FC<
  RequestForInformationComponentProps
> = ({ countryName, requests }) => (
  <div>
    <h3 className="text-base font-semibold">{countryName}</h3>
    <ul className="ml-8">
      {requests.map((rfi, idx) => (
        <li key={`${countryName}-${idx + 1}`}>
          <p className="text-sm">{rfi[0].toLocaleUpperCase() + rfi.slice(1)}</p>
        </li>
      ))}
    </ul>
  </div>
)

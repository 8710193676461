/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PermissionBundleId = {
    ACCESS_CLIENT_MATTERS: 'access_client_matters',
    ACCESS_SHARED_LINKS: 'access_shared_links',
    ADD_USERS: 'add_users',
    ALLOW_LARGE_FILES: 'allow_large_files',
    ALR_ADD_ONS: 'alr_add_ons',
    ASK_QUERIES: 'ask_queries',
    ASSISTANT_CREATE_SHARING_LINKS: 'assistant_create_sharing_links',
    ASSISTANT_BETA_PREVIEW: 'assistant_beta_preview',
    ASSISTANT_ACCESS_SHARED_LINKS: 'assistant_access_shared_links',
    ASSISTANT_WORKFLOWS: 'assistant_workflows',
    ASSIST_MODE: 'assist_mode',
    AUSTRALIA_BREACH_REPORTING: 'australia_breach_reporting',
    AUSTRALIA_TAX: 'australia_tax',
    BASE_USER_DEFAULTS: 'base_user_defaults',
    CALL_SUMMARIZATION: 'call_summarization',
    CLIENT_MATTERS_ADMIN: 'client_matters_admin',
    COMPANY_PROFILES: 'company_profiles',
    COMPETITIVE_ANALYSIS: 'competitive_analysis',
    CONTRACTS: 'contracts',
    COPILOT_ADD_IN: 'copilot_add_in',
    CREATE_FRANCE_CASE_LAW: 'create_france_case_law',
    CREATE_GLOBAL_EXAMPLES: 'create_global_examples',
    CREATE_GLOBAL_VAULT_SANDBOXES: 'create_global_vault_sandboxes',
    CREATE_PRIVATE_LIBRARY_PROMPTS: 'create_private_library_prompts',
    CREATE_SXS: 'create_sxs',
    CREATE_WORKSPACE_LIBRARY_ITEMS: 'create_workspace_library_items',
    CUATRECASAS: 'cuatrecasas',
    CUSTOM_ENGINE_COPILOT_ADD_IN: 'custom_engine_copilot_add_in',
    DEALS_DISCOVERY: 'deals_discovery',
    DELETE_HISTORY: 'delete_history',
    DELETE_WORKSPACE_HISTORY: 'delete_workspace_history',
    DILIGENCE_TRANSCRIPTS: 'diligence_transcripts',
    DOCUMENT_COMPARISON: 'document_comparison',
    DOCUMENT_UPLOAD: 'document_upload',
    DRAFTING: 'drafting',
    DRAFT_MODE: 'draft_mode',
    EDGAR_RESEARCH_ALL_FILINGS: 'edgar_research_all_filings',
    ENGINEERING_ADD_ONS: 'engineering_add_ons',
    ENG_INTERNAL_READ: 'eng_internal_read',
    ENG_INTERNAL_WRITE: 'eng_internal_write',
    EUR_LEX_RESEARCH: 'eur_lex_research',
    FROM_COUNSEL: 'from_counsel',
    GLOBAL_SUPER_DUPER_ADMIN: 'global_super_duper_admin',
    GTM_ADD_ONS: 'gtm_add_ons',
    HMRC_QA: 'hmrc_qa',
    ICERTIS_FIELD_EXTRACTION_API: 'icertis_field_extraction_api',
    ICERTIS_REVIEW_API: 'icertis_review_api',
    INDIA_TAX: 'india_tax',
    INTEGRATION_SHAREPOINT_ONEDRIVE: 'integration_sharepoint_onedrive',
    INTEGRATION_GOOGLE_DRIVE: 'integration_google_drive',
    INTEGRATION_IMANAGE: 'integration_imanage',
    INTEGRATION_NETDOCS_EXTENSION: 'integration_netdocs_extension',
    INTEGRATIONS_ADMIN: 'integrations_admin',
    INTERNET_BROWSING: 'internet_browsing',
    IRELAND_TAX: 'ireland_tax',
    ITALY_TAX: 'italy_tax',
    JAPAN_TAX_QA: 'japan_tax_qa',
    KOREA_TAX: 'korea_tax',
    KSA_TAX: 'ksa_tax',
    LEFEBVRE: 'lefebvre',
    LEGAL_MEMOS_RESEARCH: 'legal_memos_research',
    LIBRARY_ADMIN: 'library_admin',
    LIBRARY_ACCESS: 'library_access',
    LIBRARY_INTERNAL_PERMS: 'library_internal_perms',
    MACFARLANES_KNOWLEDGE_SOURCE: 'macfarlanes_knowledge_source',
    MANAGE_ALL_USERS: 'manage_all_users',
    MANAGE_API_TOKENS: 'manage_api_tokens',
    MANAGE_ASSISTANT_WORKFLOWS: 'manage_assistant_workflows',
    MESSAGE_LEVEL_TIMESTAMPS: 'message_level_timestamps',
    NETHERLANDS_TAX: 'netherlands_tax',
    OGC_REVIEW: 'ogc_review',
    OPEN_VAULT_QUERIES_FROM_HISTORY: 'open_vault_queries_from_history',
    ORGANIZATION_ADMIN: 'organization_admin',
    PILLAR_TWO_TAX_QA: 'pillar_two_tax_qa',
    PWC_FEEDBACK_EXPORT: 'pwc_feedback_export',
    PWC_HR_WORKFORCE_API: 'pwc_hr_workforce_api',
    READ_SENSITIVE_DATA: 'read_sensitive_data',
    READ_SOME_SENSITIVE_DATA: 'read_some_sensitive_data',
    REDLINES_WORKFLOW: 'redlines_workflow',
    REMOVE_USERS: 'remove_users',
    REPS_AND_WARRANTIES: 'reps_and_warranties',
    REVIEW_QUERIES: 'review_queries',
    SEC_ASSISTANT: 'sec_assistant',
    SHARING_ADMIN: 'sharing_admin',
    SHOW_STATUS_BAR: 'show_status_bar',
    SINGAPORE_CASE_LAW: 'singapore_case_law',
    SKIP_RATE_LIMIT: 'skip_rate_limit',
    SUBMIT_FEEDBACK: 'submit_feedback',
    SUPER_ADMIN: 'super_admin',
    SWEDEN_CASE_LAW: 'sweden_case_law',
    SWEDEN_TAX: 'sweden_tax',
    SWITZERLAND_TAX: 'switzerland_tax',
    SXS_USER: 'sxs_user',
    TERRITORY_ADMIN: 'territory_admin',
    TRANSCRIPTS_WORKFLOW: 'transcripts_workflow',
    TRANSLATION_API: 'translation_api',
    TRANSLATION_WORKFLOW: 'translation_workflow',
    UAE_TAX: 'uae_tax',
    UK_TAX_DEALS_ADD_ON: 'uk_tax_deals_add_on',
    USA_CASE_LAW_RESEARCH: 'usa_case_law_research',
    USE_VAULT_AS_A_KNOWLEDGE_SOURCE: 'use_vault_as_a_knowledge_source',
    VALS_API: 'vals_api',
    VAULT_ACCESS: 'vault_access',
    VAULT_ACCESS_SHARED_LINKS: 'vault_access_shared_links',
    VAULT_BETA_PREVIEW: 'vault_beta_preview',
    VAULT_CREATE_SHARING_LINKS: 'vault_create_sharing_links',
    VAULT_INTERNAL_PERMS: 'vault_internal_perms',
    VAULT_VIEW_ALL_WORKSPACE_PROJECTS: 'vault_view_all_workspace_projects',
    VAULT_WORKFLOW_ADMIN: 'vault_workflow_admin',
    VIEW_ALL_WORKSPACES: 'view_all_workspaces',
    VIEW_HELP_CENTER: 'view_help_center',
    VIEW_HISTORY: 'view_history',
    VIEW_USAGE_DASHBOARD: 'view_usage_dashboard',
    VIEW_USER_INSPECTOR: 'view_user_inspector',
    VIEW_USERS: 'view_users',
    VIEW_WORKSPACE_HISTORY: 'view_workspace_history',
    WORD_ADD_IN: 'word_add_in',
    WWTS_RESEARCH: 'wwts_research',
    DEFAULT: 'default',
    VAULT_API: 'vault_api',
    MODIFY_WORKSPACE_PROJECTS: 'modify_workspace_projects'
} as const;
export type PermissionBundleId = typeof PermissionBundleId[keyof typeof PermissionBundleId];


export function instanceOfPermissionBundleId(value: any): boolean {
    for (const key in PermissionBundleId) {
        if (Object.prototype.hasOwnProperty.call(PermissionBundleId, key)) {
            if (PermissionBundleId[key as keyof typeof PermissionBundleId] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PermissionBundleIdFromJSON(json: any): PermissionBundleId {
    return PermissionBundleIdFromJSONTyped(json, false);
}

export function PermissionBundleIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionBundleId {
    return json as PermissionBundleId;
}

export function PermissionBundleIdToJSON(value?: PermissionBundleId | null): any {
    return value as any;
}

export function PermissionBundleIdToJSONTyped(value: any, ignoreDiscriminator: boolean): PermissionBundleId {
    return value as PermissionBundleId;
}


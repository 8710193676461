import * as React from 'react'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import { ScrollArea } from 'components/ui/scroll-area'
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from 'components/ui/tooltip'

import { DropdownItem, DropdownItemProps } from './dropdown-item'

interface KsInputDropdownProps {
  dropdownOpen: boolean
  setDropdownOpen: (open: boolean) => void
  triggerComponent: React.ReactNode
  disabled?: boolean
  tooltip?: string
  children?: React.ReactNode
  dropdownItems: (DropdownItemProps | 'separator')[]
  positionSide?: 'top' | 'bottom'
  positionAlign?: 'start' | 'center' | 'end'
}

const KsInputDropdown: React.FC<KsInputDropdownProps> = ({
  dropdownOpen,
  setDropdownOpen,
  triggerComponent,
  disabled,
  tooltip,
  children,
  dropdownItems,
  positionSide = 'bottom',
  positionAlign = 'start',
}) => {
  return (
    <Tooltip open={tooltip ? undefined : false}>
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <TooltipTrigger asChild>
          <DropdownMenuTrigger asChild disabled={disabled}>
            {triggerComponent}
          </DropdownMenuTrigger>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>{tooltip}</TooltipContent>
        </TooltipPortal>
        <DropdownMenuContent
          avoidCollisions={false}
          className="px-0 py-2"
          side={positionSide}
          align={positionAlign}
        >
          {/* 304 = 68 (dropdown item height) * 4 + 32 (half of the dropdown menu padding)*/}
          <ScrollArea
            className="h-full"
            maxHeight="max-h-[304px]"
            // eslint-disable-next-line
            style={{ padding: '0 8px' }}
          >
            {dropdownItems.map((item, index) => {
              if (item === 'separator') {
                return (
                  <DropdownMenuSeparator key={index} className="-mx-2 my-2" />
                )
              }
              return <DropdownItem key={index} {...item} />
            })}
            {children}
          </ScrollArea>
        </DropdownMenuContent>
      </DropdownMenu>
    </Tooltip>
  )
}

export default KsInputDropdown

import React, { useCallback, useEffect, useState } from 'react'

import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

import { IntegrationType } from 'openapi/models/IntegrationType'
import { useIntegrationsStore } from 'stores/integrations-store'
import { Maybe } from 'types'

import { displayErrorMessage } from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import { authenticateSynclyAuthClient } from 'components/settings/integrations/utils'

// this will expand to more syncly integrations in the future
const integrationType = IntegrationType.IMANAGE
const synclyInstanceURL =
  'https://harvey-dev.syncly.au/integration-plugin/workflow_creation?'

const SynclyPicker = () => {
  const userInfo = useAuthUser()
  const [requestId, setRequestId] = useState<string>('')
  const [iframeHeight, setIframeHeight] = useState<number>(500)

  const [synclyAuth0Token, setSynclyAuth0Token] = useState<Maybe<string>>(null)

  const [integrationFilePickerOpenState, setIntegrationFilePickerOpenState] =
    useIntegrationsStore(
      useShallow((state) => [
        state.integrationFilePickerOpenState,
        state.setIntegrationFilePickerOpenState,
      ])
    )

  const shouldShowPicker =
    !_.isNil(integrationFilePickerOpenState) &&
    integrationFilePickerOpenState.integrationType === integrationType

  const cleanup = useCallback(() => {
    setIntegrationFilePickerOpenState(null)
    setSynclyAuth0Token(null)
    setRequestId('')
  }, [setIntegrationFilePickerOpenState])

  const handleIframeLoad = useCallback(
    async (instanceURL: string) => {
      const token = await authenticateSynclyAuthClient(userInfo.id)
      setSynclyAuth0Token(token)
      const newRequestId = uuidv4()
      setRequestId(newRequestId)

      const iframe = document.getElementById('SynclyEmbed') as HTMLIFrameElement
      if (iframe) {
        iframe.src = `${instanceURL}requestId=${newRequestId}&sourceType=iManage`
      }
    },
    [userInfo.id]
  )

  useEffect(() => {
    const loadIframe = async () => {
      if (shouldShowPicker) {
        await handleIframeLoad(synclyInstanceURL)
      }
    }
    void loadIframe()
  }, [handleIframeLoad, shouldShowPicker])

  const getLoginUserTokenObject = useCallback(
    (reqId: string, tokenType: string) => {
      if (_.isNil(synclyAuth0Token)) {
        displayErrorMessage(`Unable to authenticate with ${integrationType}`)
        cleanup()
        return null
      }
      return {
        type: `response:${tokenType}:${reqId}`,
        jwtToken: synclyAuth0Token,
      }
    },
    [cleanup, synclyAuth0Token]
  )

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (!event.data.type?.includes(requestId)) return

      if (
        event.data.type === `request:cancel:${requestId}` ||
        event.data.type === `request:close:${requestId}`
      ) {
        cleanup()
        return
      }

      if (
        event.data.type === `request:logintoken:${requestId}` ||
        event.data.type === `request:renewlogintoken:${requestId}`
      ) {
        const iframe = document.getElementById(
          'SynclyEmbed'
        ) as HTMLIFrameElement
        const tokenType = event.data.type.split(':')[1]
        iframe?.contentWindow?.postMessage(
          getLoginUserTokenObject(requestId, tokenType),
          '*'
        )
      } else if (event.data.type === `request:iframesize:${requestId}`) {
        setIframeHeight(Math.max(event.data.data.iFrameHeight, 500))
      }
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [
    cleanup,
    getLoginUserTokenObject,
    requestId,
    setIntegrationFilePickerOpenState,
  ])

  if (!shouldShowPicker) {
    return null
  }

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center">
      <div className="fixed inset-0 bg-primary/80 backdrop-blur-sm" />
      <div className="relative z-[101] w-4/5 max-w-5xl">
        <div className="flex justify-center">
          <iframe
            title="SynclyEmbed"
            id="SynclyEmbed"
            src="about:blank"
            width="100%"
            height={iframeHeight}
            style={{ border: 0 }}
            className="bg-white rounded-b-lg border border-primary shadow-lg"
          />
        </div>
      </div>
    </div>
  )
}

export default SynclyPicker

/* tslint:disable */
/* eslint-disable */
/**
 * User Profiles
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * ONLY USED FOR BUSINESS LOGIC. Practice areas options for each profession. Make sure to update this when practice areas above are updated.
 * @export
 */
export const LegalPracticeAreaOptions = {
    ANTITRUST_AND_COMPETITION: 'ANTITRUST_AND_COMPETITION',
    BANKING_AND_FINANCE: 'BANKING_AND_FINANCE',
    BANKRUPTCY_AND_RESTRUCTURING: 'BANKRUPTCY_AND_RESTRUCTURING',
    CAPITAL_MARKETS: 'CAPITAL_MARKETS',
    COMPLIANCE_AND_REGULATORY: 'COMPLIANCE_AND_REGULATORY',
    COMMERCIAL: 'COMMERCIAL',
    CORPORATE: 'CORPORATE',
    CORPORATE_GOVERNANCE: 'CORPORATE_GOVERNANCE',
    CRIMINAL_LAW: 'CRIMINAL_LAW',
    ENERGY_AND_INFRASCTRUCTURE: 'ENERGY_AND_INFRASCTRUCTURE',
    ENVIRONMENTAL_LAW: 'ENVIRONMENTAL_LAW',
    EMERGING_COMPANIES_VENTURE_CAPITAL: 'EMERGING_COMPANIES/VENTURE_CAPITAL',
    EXECUTIVE_COMPENSATION_AND_EMPLOYEE_BENEFITS: 'EXECUTIVE_COMPENSATION_AND_EMPLOYEE_BENEFITS',
    FAMILY_LAW: 'FAMILY_LAW',
    GOVERNMENT_INVESTIGATIONS_AND_WHITE_COLLAR: 'GOVERNMENT_INVESTIGATIONS_AND_WHITE_COLLAR',
    GOVERNMENT_AND_PUBLIC_AFFAIRS: 'GOVERNMENT_AND_PUBLIC_AFFAIRS',
    INTELLECTUAL_PROPERTY: 'INTELLECTUAL_PROPERTY',
    INTERNATIONAL_ARBITRATION: 'INTERNATIONAL_ARBITRATION',
    INVESTMENT_FUNDS_AND_MANAGEMENT: 'INVESTMENT_FUNDS_AND_MANAGEMENT',
    LABOR_AND_EMPLOYMENT: 'LABOR_AND_EMPLOYMENT',
    LITIGATION__GENERAL: 'LITIGATION_(GENERAL)',
    MERGERS_AND_ACQUISITION: 'MERGERS_AND_ACQUISITION',
    PRIVACY: 'PRIVACY',
    PRIVATE_EQUITY: 'PRIVATE_EQUITY',
    PRIVATE_WEALTH: 'PRIVATE_WEALTH',
    PUBLIC_LAW: 'PUBLIC_LAW',
    REAL_ESTATE_AND_REITS: 'REAL_ESTATE_AND_REITS',
    TAX: 'TAX'
} as const;
export type LegalPracticeAreaOptions = typeof LegalPracticeAreaOptions[keyof typeof LegalPracticeAreaOptions];


export function instanceOfLegalPracticeAreaOptions(value: any): boolean {
    for (const key in LegalPracticeAreaOptions) {
        if (Object.prototype.hasOwnProperty.call(LegalPracticeAreaOptions, key)) {
            if (LegalPracticeAreaOptions[key as keyof typeof LegalPracticeAreaOptions] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LegalPracticeAreaOptionsFromJSON(json: any): LegalPracticeAreaOptions {
    return LegalPracticeAreaOptionsFromJSONTyped(json, false);
}

export function LegalPracticeAreaOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalPracticeAreaOptions {
    return json as LegalPracticeAreaOptions;
}

export function LegalPracticeAreaOptionsToJSON(value?: LegalPracticeAreaOptions | null): any {
    return value as any;
}

export function LegalPracticeAreaOptionsToJSONTyped(value: any, ignoreDiscriminator: boolean): LegalPracticeAreaOptions {
    return value as LegalPracticeAreaOptions;
}


import { useState, useCallback, useEffect, useRef } from 'react'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { IntegrationType } from 'openapi/models/IntegrationType'
import { useIntegrationsStore } from 'stores/integrations-store'

import { displaySuccessMessage } from 'utils/toast'

import { IntegrationDefinitions } from './integration-definitions'
import { connectOauthIntegration, fetchIntegrationToken } from './utils'

interface UseOauthConnectProps {
  integrationType: IntegrationType
  onConnectCallback?: () => void
}

const POLL_INTERVAL = 5000 // 5 seconds
const MAX_ATTEMPTS = 24 // 2 minutes

export function useOauthConnect({
  integrationType,
  onConnectCallback,
}: UseOauthConnectProps) {
  const [isConnecting, setIsConnecting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const isMountedRef = useRef(true)
  const [tokens, setAccessToken] = useIntegrationsStore(
    useShallow((state) => [state.tokens, state.setIntegrationToken])
  )

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  const pollForToken = useCallback(async () => {
    let attempts = 0

    return new Promise<void>((resolve, reject) => {
      const intervalId = setInterval(async () => {
        attempts += 1
        try {
          const token = await fetchIntegrationToken(integrationType)
          if (!_.isNil(token)) {
            setAccessToken(integrationType, token)
            setIsConnecting(false)
            clearInterval(intervalId)
            displaySuccessMessage(
              `Connected to ${_.startCase(integrationType)}`
            )
            onConnectCallback?.()
            resolve()
          } else if (attempts >= MAX_ATTEMPTS) {
            clearInterval(intervalId)
            reject(new Error('Timed out waiting for token'))
            setError(
              `Failed to connect to ${IntegrationDefinitions[integrationType].name}`
            )
          }
        } catch (err) {
          setError(
            `Failed to connect to ${IntegrationDefinitions[integrationType].name}`
          )
          clearInterval(intervalId)
          reject(new Error('Timed out waiting for token'))
          console.warn('Error fetching access token', err)
        }
      }, POLL_INTERVAL)
    })
  }, [integrationType, setAccessToken, onConnectCallback])

  const handleConnect = useCallback(async () => {
    setIsConnecting(true)
    setError(null)
    try {
      await connectOauthIntegration(integrationType)
      await pollForToken()
      if (isMountedRef.current) {
        setIsConnecting(false)
      }
    } catch (e) {
      console.error(e)
      if (isMountedRef.current) {
        setError(
          `Failed to connect to ${IntegrationDefinitions[integrationType].name}`
        )
        setIsConnecting(false)
      }
    }
  }, [integrationType, pollForToken])

  const isConnected = !!tokens[integrationType]

  return {
    isConnecting,
    isConnected,
    error,
    handleConnect,
  }
}

import React, { useState } from 'react'

import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'

import { cn } from 'utils/utils'

import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import WorkflowInput, {
  WorkflowInputHeader,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { Button } from 'components/ui/button'
import {
  Command,
  CommandInput,
  CommandItem,
  CommandList,
  CommandEmpty,
  CommandGroup,
} from 'components/ui/command'
import { ScrollArea } from 'components/ui/scroll-area'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
  AssistantWorkflowYouComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowSelectThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.SELECT
> = ({ blockParams, outputData, blockStatus }) => {
  const { headerText } = blockParams
  const isCompleted = !!outputData

  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowHarveyComponent>
        <AssistantWorkflowThreadText
          blockStatus={blockStatus}
          text={headerText}
        />
      </AssistantWorkflowHarveyComponent>

      {isCompleted && (
        <AssistantWorkflowYouComponent>
          <AssistantWorkflowThreadText
            blockStatus={blockStatus}
            text={outputData.selected}
          />
        </AssistantWorkflowYouComponent>
      )}
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowSelectInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.SELECT
> = ({ blockParams, onCompleted, outputData }) => {
  const { emptyText, inputLabel, options } = blockParams
  const isCompleted = !!outputData
  const [isLoading, setIsLoading] = useState(false)
  const [selected, setSelected] = useState<string>(outputData?.selected || '')
  const [search, setSearch] = useState('')

  const handleSelect = (value: string) => {
    if (value === selected) {
      setSelected('')
      setSearch('')
    } else {
      setSelected(value)
      setSearch(value)
    }
  }

  const onSubmit = () => {
    if (!selected) return
    setIsLoading(true)
    onCompleted({ selected })
  }

  return (
    <WorkflowInput>
      <Command className="items-stretch bg-accent">
        <WorkflowInputHeader>{inputLabel}</WorkflowInputHeader>
        <CommandList className="rounded-md bg-primary p-2">
          <ScrollArea className="h-60">
            <CommandEmpty>{emptyText ?? 'No options found.'}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  data-testid="select-option"
                  className={cn(
                    'w-full px-[10px] py-0 last:border-0 hover:bg-accent',
                    selected === option && 'bg-accent'
                  )}
                  key={option}
                  value={option}
                  onSelect={handleSelect}
                  disabled={isCompleted || isLoading}
                >
                  <div className="size-full border-b py-3 text-xs text-primary">
                    {option}
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </ScrollArea>
        </CommandList>
        <div className="flex items-center gap-2 px-2 pt-2">
          <CommandInput
            placeholder={blockParams.placeholder}
            containerClassName="w-full"
            className="w-full"
            showSearchStyling={false}
            disabled={isCompleted || isLoading}
            value={search}
            onValueChange={setSearch}
          />
          <Button
            isLoading={isLoading || !!outputData}
            disabled={!selected}
            onClick={onSubmit}
          >
            Send
          </Button>
        </div>
      </Command>
    </WorkflowInput>
  )
}

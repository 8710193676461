import { WorkflowType } from 'types/workflows'

export const getWorkflowDescriptions = (): Record<
  WorkflowType,
  { header: string; body: string }
> => {
  return {
    [WorkflowType.COMPANY_PROFILE]: {
      header: 'Generate a report on a public company',
      body: 'Company Profile will generate a report on any NYSE or NASDAQ listed company that files 10-Ks with the SEC by searching and summarizing publicly available data such as EDGAR.',
    },
    [WorkflowType.LEGACY_DRAFTING]: {
      header: 'Generate a precedent comparison chart',
      body: 'Drafting will review your document against underlying precedent documents and display relevant provisions in a comparison chart. Drafting  supports nondisclosure and lease agreements.',
    },
    [WorkflowType.OGC]: {
      header: 'Generate a contract review',
      body: 'OGC will review your commercial contract using PwC OGC’s contract review style and principles. When exported to Word, the output will appear in PwC OGC’s contract review format.',
    },
    [WorkflowType.REDLINE_ANALYSIS]: {
      header: 'Create an issues list or query a redline document',
      body: 'Redline Analysis generates a chart comparing the original and revised version of a redline document based on topics you identify or generates a response to your query based on the redline document.',
    },
    [WorkflowType.TRANSLATION]: {
      header: 'Translate a document',
      body: 'Translation will translate PDF, Word, and Email documents from one language to another. Translation supports Danish, Dutch, English, French, German, Italian, Portuguese, Simplified Chinese, and Spanish.',
    },
    [WorkflowType.DOCUMENT_COMPARISON]: {
      header: 'Compare two documents',
      body: 'Document Comparison will describe the difference between two documents with vastly different formats but with overlapping substance.',
    },
    [WorkflowType.CONTRACTS]: {
      header: 'Analyze contracts',
      body: 'Contracts will review uploaded contracts, classify them, and extract key terms.',
    },
    [WorkflowType.TRANSCRIPTS]: {
      header: 'Analyze your deposition and trial transcripts',
      body: 'Transcripts surfaces and summarizes key themes from your trial and deposition transcripts. It also allows users to query those transcripts to further develop key themes and additional insights.',
    },
    [WorkflowType.DILIGENCE]: {
      header: 'Initial due diligence discovery',
      body: 'Discovery will generate an initial diligence report touching on the financial, operational, commercial, and tax aspects of a deal opportunity. Built in collaboration with PwC.',
    },
    [WorkflowType.COMPETITIVE_ANALYSIS]: {
      header: 'Analyze your competitive analysis documents',
      body: 'Competitive Analysis will analyze your competitive analysis documents and generate a report comparing your company to competitors',
    },
    [WorkflowType.DILIGENCE_TRANSCRIPTS]: {
      header: 'Analyze and summarize interviews and other calls',
      body: 'Diligence Transcripts will analyze diligence transcripts and generate a summary of the key themes and insights',
    },
  }
}

export const getWorkflowSubtitle = (workflowType: WorkflowType) => {
  const subtitleMap: Record<WorkflowType, string> = {
    [WorkflowType.COMPANY_PROFILE]:
      'Create detailed reports on public companies',
    [WorkflowType.LEGACY_DRAFTING]: 'Generate a precedent comparison chart',
    [WorkflowType.OGC]: 'Generate a contract review',
    [WorkflowType.REDLINE_ANALYSIS]:
      'Review and generate issues lists for redlined documents',
    [WorkflowType.TRANSLATION]: 'Translate a document',
    [WorkflowType.DOCUMENT_COMPARISON]: 'Compare two documents',
    [WorkflowType.CONTRACTS]: 'Convert documents into structured data',
    [WorkflowType.TRANSCRIPTS]: 'Analyze deposition and trial transcripts',
    [WorkflowType.DILIGENCE]: 'Initial due diligence discovery',
    [WorkflowType.COMPETITIVE_ANALYSIS]:
      'Analyze competitive analysis documents',
    [WorkflowType.DILIGENCE_TRANSCRIPTS]:
      'Analyze and summarize interviews and other calls',
  }

  return subtitleMap[workflowType]
}

export const REDLINES_HELP = `<h2 style="font-size: 14px;">About</h2>
<p class="ql-align-justify">Harvey can recognize additions and deletions in a PDF redline or Word Document (.docx) with tracked changes, and produce issues lists or other work product based on user instructions and context.</p>
<h2 style="font-size: 14px;">Tips for using Redline Q&amp;A</h2>
<ul>
  <li>For best results, provide Harvey with context such as which party you represent, which party suggested the changes, and the issues your client cares most about.</li>
  <li>You can ask for an overview of all the changes or ask Harvey about changes to a particular section or relating to a particular issue.</li>
  <li>Consider asking for a table, email, memo, bullet points, or other format.</li>
  <li>Queries should be explicit, descriptive, and specific. Providing some extra direction in a query can go a long way.</li>
  <li>Break up complex queries into simpler components, especially when there is an analytical or subjective task contained in your query. We recommend one task per sentence in your query.</li>
</ul>
<h2 style="font-size: 14px;">Important reminders</h2>
<ul>
  <li>Limited to redline PDF documents that represent insertions with blue text and deletions with red text and to Microsoft Word (.docx) documents with tracked changes.</li>
  <li>Better at summarizing changes and their potential impacts as opposed to providing recommendations as to whether to accept or reject the changes. Users who want to experiment with this tool’s ability to make recommendations should provide plenty of context in their queries.</li>
  <li>This tool does not generate redlines, but rather looks at a redline PDF you upload.</li>
  <li>Better at understanding language than performing numerical operations or recognizing pictures or data tables.</li>
  <li>Improving at interpreting heavily cross-referenced redline documents.</li>
  <li>Improving at understanding which changes should be considered “material” or “substantive.”</li>
  <li>Harvey’s answers may vary based on subtle changes to query language and even across multiple submissions of the same query. Consider refining your query to explore different answers.</li>
  <li>You do not need to ask Harvey for references or citations — they will be generated automatically.</li>
</ul>
<h2 style="font-size: 14px;">Example queries</h2>
<p><em>NDA</em></p>
<ul>
  <li>Attached are the buyer’s changes to my client’s confidentiality agreement. Please prepare a table showing the target company’s proposal, the buyer’s proposed change, and the significance of the change to the target company. Rank in descending order of importance.</li>
</ul>
<p><em>Merger Agreement</em></p>
<ul>
  <li>My client is the potential buyer of a public company and received the attached company markup of the merger agreement. Please summarize the changes that relate to material adverse effect, antitrust risk, termination fees and financing, and note any other changes of importance to buyer.</li>
</ul>
<p><em>Leases</em></p>
<ul>
  <li>Review the attached redlined lease agreement. Can you identify the changes the landlord has proposed, especially those that could impact my client’s ability to sublease or assign the lease in case of an acquisition?</li>
</ul>
<p><em>IP Documents</em></p>
<ul>
  <li>I’d like you to review the attached redlined trademark licensing agreement. Summarize the proposed changes and how they could affect my client’s ability to use these trademarks post-merger.</li>
</ul>
<p><em>Employment Agreement</em></p>
<ul>
  <li>The target company has proposed changes to the key personnel’s employment agreements. Please generate a summary of these changes and how they might affect my client’s obligations post-acquisition.</li>
</ul>
<p><em>Disclosure Schedules</em></p>
<ul>
  <li>The target company has made updates to the disclosure schedules attached to the purchase agreement. Please review these changes and prepare a summary outlining their potential implications for my client.</li>
</ul>
<p><em>NDA</em></p>
<ul>
  <li>My client is the buyer and I just sent the attached proposed edits to the target company. Draft an email to the general counsel of the buyer (Mr. Smith) explaining the edits that I made that are most important to the buyer in descending order, including shortening standstill, adding a fallaway, shortening the non-solicit and removing co-bidding restrictions, with a short explanation as to their significance.</li>
</ul>`

export const TRANSLATION_HELP = `<h2 style="font-size: 14px;">About</h2>
<p class="ql-align-justify">Harvey’s translation tool is designed to help you seamlessly translate documents from one language to another. Whether you’re working on legal contracts, business communications, or any other written content, our tool can assist you in achieving accurate translations. Currently, the tool supports a wide range of languages and translation options, with more features in development.</p>
<h2 style="font-size: 14px;">Tips for using Translation</h2>
<ul>
  <li>Formatting and layout may be affected during translation, so be prepared to review and adjust the translated document if needed. <b>Uploaded .docx files are most likely to retain formatting upon export, while .pdf files will not.</b></li>
  <li>Review the translated document for clarity, coherence, and correctness. Adjust as necessary to ensure the final output is polished.</li>
  <li>Keep in mind that while the tool aims to provide high-quality translations, human review may be necessary for sensitive or critical documents.</li>
</ul>`

export const COMPANY_PROFILE_HELP = `<h2 style="font-size: 14px;">About</h2>
<p class="ql-align-justify">Harvey will generate a profile of any NYSE- or NASDAQ-listed company that files 10-Ks with the SEC by searching certain EDGAR filings, including exhibits, of that company. The output includes linked references to highlighted sections of source filings so that you can check Harvey’s work.</p>
<h2 style="font-size: 14px;">Tips for using Company Profile</h2>
<ul>
  <li>Specify the exact stock ticker of the company you would like profiled, without including any additional information in the stock ticker field.</li>
  <li>Choose a company that is listed on NYSE or NASDAQ and files 10-Ks with the SEC.</li>
</ul>
<h2 style="font-size: 14px;">Important reminders</h2>
<ul>
  <li>Underlying search is limited to annual reports (Form 10-K), proxy statements (Schedule 14A), current reports (Form 8-K), and the exhibits attached thereto. Other filings, such as registration statements, are not reflected in the results.</li>
  <li>Better at understanding language than performing numerical operations or recognizing pictures or data tables.</li>
  <li>Harvey’s answers may vary across multiple requests for the same company profile, so consider submitting multiple requests and comparing results.</li>
</ul>`

export const LEGACY_DRAFTING_HELP = `<h2 style="font-size: 14px;">About</h2>
<p class="ql-align-justify">Harvey will generate a precedent comparison chart comparing the provisions in the agreement you upload to provisions found in your firm’s precedent documents.</p>
<h4>Important reminders</h4>

<ul>
  <li>Limited to PDF (.pdf) and Word (.docx) uploads.</li>
  <li>Underlying search is limited to precedent documents selected by your firm.</li>
  <li>Harvey’s responses may vary across multiple requests using the same uploaded document, so consider submitting multiple requests and comparing results.</li>
</ul>`

export const OGC_REVIEW_HELP = `<h2 style="font-size: 14px;">Tips for using OGC Contract Review</h2>
<ul>
  <li>Limited to PDF and Word uploads at this time.</li>
  <li>Click the export button in the bottom right corner to view the output in Word.</li>
  <li><strong>Limited to documents under 300 pages at this time. For documents over 300 pages, consider removing signature pages and appendices.</strong></li>
  <li>Harvey reads left to right, all the way across the page, so outputs based on documents with two or more columns are likely to contain errors.</li>
  <li>Harvey’s answers may vary across multiple submissions of the same document.</li>
</ul>`

export const ISSUES_LIST_HELP = `<h2 style="font-size: 14px;">About</h2>
<p class="ql-align-justify">Harvey can recognize additions and deletions in a PDF redline or Word Document (.docx) with tracked changes, and produce issues lists in a table format based on the changes in the uploaded redline document. Either the user can input their own topics or Harvey can choose the topics that are most relevant based on the changes in the document.</p>
<h2 style="font-size: 14px;">Tips for using Redline Analysis Issues List</h2>
<ul>
<li>When Harvey automatically generates topics and creates an issues list, Harvey will populate the topics found in the query box. If you would like to add or remove topics, you can do so and re-run the query.</li>
<li>Identify those topics that are most important to you to create a tailored issues list. Harvey will include a summary list of the changes that may be relevant and were not identified by the user but provides the best answers when you identify the key topics.</li>
<li>Split larger topics into smaller, more specific topics (e.g., instead of "Compensation" try inputting ("Company Equity Awards" and "Executive Pay").</li>
<li>Use language found in the document to identify issues (e.g., instead of "Force the Vote" try inputting "Vote of the Company Shareholders" if that matches the language found in the document).</li>
</ul>
<h2 style="font-size: 14px;">Important reminders</h2>
<ul>
<li>Redline Analysis Issues List works best with documents under approximately 200 pages.</li>
<li>Limited to redline PDF documents that represent insertions with blue text and deletions with red text and to Microsoft Word (.docx) documents with tracked changes.</li>
<li>Redline Analysis Issues List reads the changes in the document and does not have the same context as the user, so the tool is best  at summarizing changes and their potential impacts but is not designed to provide recommendations as to whether to accept or reject the changes.</li>
<li>This tool does not generate redlines, but rather looks at a redlined document you upload.</li>
<li>Better at understanding language than performing numerical operations or recognizing pictures or data tables.</li>
<li>Improving at interpreting heavily cross-referenced redline documents.</li>
<li>You do not need to ask Harvey for references or citations — they will be generated automatically.</li>`

export const DILIGENCE_TRANSCRIPTS_HELP = `<h2 style="font-size: 14px;">About</h2>
<p class="ql-align-justify">Harvey can analyze transcripts and audio recordings from client and witness interviews and prepare a summary of the key points discussed.</p>
<h2 style="font-size: 14px;">Tips for using Interview Summaries</h2>
<ul>
<li>Upload a single transcript file (multiple transcript upload coming soon!)</li>
<li>Input key themes, which will inform the key topics of the transcripts summary. Harvey can also autogenerate themes.</li>
<li>For audio files, input keywords that appear in the transcript, particularly any company names.</li>
<li>Optionally, add any additional context or style to further shape the output of the transcript summary.</li>
<li>This workflow is designed to summarize the perspective of a single individual. For optimal results, please upload either a transcript or an audio file that features only one interviewee.</li>
</ul>`

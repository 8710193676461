import React, { useState } from 'react'

import { CutoverWorkspaceToPermBundles } from 'models/perms'
import { Workspace } from 'models/workspace'
import { RequestError } from 'services/backend/backend'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import { Dialog, DialogTrigger } from 'components/ui/dialog'

export const isWorkspaceCutoverToPermBundles = (workspace: Workspace) =>
  Boolean(workspace.settings.migratedToPermissionBundlesAt)

const WorkspacePermMigrationStatus = ({
  workspace,
}: {
  workspace: Workspace
}) => {
  const [migrated, setMigrated] = useState(
    isWorkspaceCutoverToPermBundles(workspace)
  )

  const handleCutover = async () => {
    try {
      await CutoverWorkspaceToPermBundles(workspace.id)
      setMigrated(true) // Set optimistically
      displaySuccessMessage(
        'Successfully cut over workspace to permission bundles!'
      )
    } catch (e) {
      if (e instanceof RequestError) {
        displayErrorMessage(e.message)
      } else {
        displayErrorMessage(
          'An error occurred while cutting over the workspace to permission bundles.'
        )
      }
    }
  }

  return (
    <Card>
      <CardHeader className="p-4">
        <h2 className="text-lg font-semibold">
          {migrated ? 'Cutover Complete!' : 'Confirm Cutover'}
        </h2>
      </CardHeader>
      <CardContent className="space-y-4 px-5">
        <p>
          {migrated
            ? 'This workspace has been migrated to permission bundles. All workspace users should be seeing the new interfaces for roles and permissions. If there are any outstanding inconsistencies, please resolve them manually.'
            : 'This workspace has not been cut over to permission bundles. Use the button below to confirm this action after validating that roles and permissions are in a good state. After this is confirmed, all workspace users will see the new interfaces for roles and permissions, regardless of whether they individually have the permission.'}
        </p>
        {!migrated && (
          <Dialog>
            <DialogTrigger asChild>
              <Button>Cutover workspace to perm bundles</Button>
            </DialogTrigger>
            <ConfirmationDialog
              title="Confirm permission bundle cutover"
              description="Are you sure you want to migrate this workspace to permission bundles? This action cannot be undone and will affect all workspace users."
              cta={{
                label: 'Confirm cutover',
                onClick: handleCutover,
              }}
              secondaryCta={{
                label: 'Cancel',
                onClick: () => {},
              }}
              variant="default"
            />
          </Dialog>
        )}
      </CardContent>
    </Card>
  )
}

export default WorkspacePermMigrationStatus

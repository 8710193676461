import React, { useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { formatDistanceToNow } from 'date-fns'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'

import { displayErrorMessage } from 'utils/toast'
import { parseIsoString } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import { useRunReview } from 'components/vault/components/vault-app-header/use-run-review'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import { RESUME_RUN_BEFORE_ADDING_NEW_COLUMNS_OR_FILES_MESSAGE } from 'components/vault/utils/vault'
import { CancelVaultHistoryItem } from 'components/vault/utils/vault-fetcher'
import { getEtaDisplayString } from 'components/vault/utils/vault-helpers'

import { ProgressPause } from './icons/progress-pause'
import { ProgressPlay } from './icons/progress-play'
import { Spinner } from './icons/spinner'

export enum ReviewRunToastState {
  IN_PROGRESS = 'in_progress',
  PAUSING = 'pausing',
  PAUSED = 'paused',
  REQUESTING = 'requesting',
}

interface ReviewRunToastProps {
  state: ReviewRunToastState
  title: string
  description: string
  progressPercentage?: number
  onClickCTA?: () => void
}

export const ReviewRunToast = ({
  state,
  title,
  description,
  progressPercentage,
  onClickCTA,
}: ReviewRunToastProps) => {
  return (
    <div
      className="relative w-[360px] rounded-lg border bg-primary py-3 pl-3.5 pr-3 shadow-md"
      data-testid="review-run-toast"
    >
      <div className="flex w-full flex-row items-center space-x-3">
        {state === ReviewRunToastState.PAUSED ? (
          <Button
            variant="ghost"
            size="smIcon"
            className="-m-1 shrink-0"
            onClick={onClickCTA}
            tooltip="Resume"
          >
            <ProgressPlay
              progressPercentage={progressPercentage}
              className="size-8"
            />
          </Button>
        ) : state === ReviewRunToastState.PAUSING ||
          state === ReviewRunToastState.REQUESTING ? (
          <Spinner className="size-6" />
        ) : (
          <Button
            variant="ghost"
            size="smIcon"
            className="-m-1 shrink-0"
            onClick={onClickCTA}
            tooltip="Pause"
          >
            <ProgressPause
              progressPercentage={progressPercentage}
              className="size-8"
            />
          </Button>
        )}
        <div className="space-y-0.5">
          <p className="text-sm font-medium">{title}</p>
          <p className="line-clamp-2 text-xs leading-[14px] text-muted">
            {description}
          </p>
        </div>
      </div>
    </div>
  )
}

export const ReviewRunToastWrapper = () => {
  const { trackEvent } = useAnalytics()
  const queryClient = useQueryClient()

  const [queryId, isQueryLoading, historyItem, isRunButtonLoading] =
    useVaultQueryDetailStore(
      useShallow((s) => [
        s.queryId,
        s.isQueryLoading,
        s.historyItem,
        s.isRunButtonLoading,
      ])
    )

  const reviewEvent = historyItem as ReviewHistoryItem | null
  const isNewQuery = queryId === 'new'

  const { progressPercentage, hasEmptyCells, handleRun } = useRunReview()
  const [lastCancelRequestedAt, setLastCancelRequestedAt] =
    useState<Date | null>(null)

  if (!reviewEvent || isNewQuery || !hasEmptyCells) return null

  const isCancelling =
    lastCancelRequestedAt &&
    reviewEvent.startedAt &&
    lastCancelRequestedAt > reviewEvent.startedAt
  const loadingText = reviewEvent.eta
    ? getEtaDisplayString(parseIsoString(reviewEvent.eta), true)
    : reviewEvent.startedAt
    ? `Processing started ${formatDistanceToNow(reviewEvent.startedAt, {
        addSuffix: true,
      })}`
    : ''

  let state: ReviewRunToastState = ReviewRunToastState.IN_PROGRESS
  let title = 'Reviewing documents…'
  let description = loadingText
  let onClickCTA = undefined
  if (isQueryLoading) {
    if (isCancelling) {
      state = ReviewRunToastState.PAUSING
      title = 'Pausing review…'
      description = loadingText
    } else {
      onClickCTA = async () => {
        trackEvent('Vault Review Query Cancel Button Clicked', {
          query_id: queryId,
        })
        setLastCancelRequestedAt(new Date())
        try {
          const response = await CancelVaultHistoryItem(queryId)
          if (response.eventCancelled) {
            await queryClient.invalidateQueries({
              queryKey: [
                HarvQueryKeyPrefix.VaultHistoryItemQuery,
                queryId,
                { type: 'diff' },
              ],
            })
          }
        } catch (e) {
          console.error(e)
          displayErrorMessage('Failed to pause query, please try again later')
        }
      }
    }
  } else {
    if (isRunButtonLoading) {
      state = ReviewRunToastState.REQUESTING
    } else {
      state = ReviewRunToastState.PAUSED
    }
    title = 'Review paused'
    description = RESUME_RUN_BEFORE_ADDING_NEW_COLUMNS_OR_FILES_MESSAGE
    onClickCTA = () => handleRun({})
  }

  return (
    <div className="fixed bottom-7 right-7 z-50">
      <ReviewRunToast
        state={state}
        title={title}
        description={description}
        progressPercentage={progressPercentage}
        onClickCTA={onClickCTA}
      />
    </div>
  )
}

import { modelMarkdownToHtmlRemark } from 'utils/markdown'

export const copyToClipboard = (
  text: string
): Promise<Record<string, Blob>> => {
  return new Promise((resolve, reject) => {
    try {
      const removeHrvySpans = (markdown: string) => {
        return markdown.replace(/ <span data-hrvy-id="[^"]+">\d+<\/span>/g, '')
      }
      const markdown = removeHrvySpans(text)
      const asHtml = modelMarkdownToHtmlRemark(markdown)
      const results = {
        'text/html': new Blob([asHtml], { type: 'text/html' }),
        'text/plain': new Blob([markdown], { type: 'text/plain' }),
      }
      const item = new ClipboardItem(results)

      if (!navigator.clipboard.write) {
        reject('Clipboard is not supported')
      }

      navigator.clipboard
        .write([item])
        .then(() => {
          resolve(results)
        })
        .catch((err) => {
          console.error('Failed to copy response to clipboard: ', err)
          reject(err)
        })
    } catch (err) {
      console.error('Failed to copy response to clipboard: ', err)
      reject(err)
    }
  })
}

import { Instance, Rect, TextLine } from 'pspdfkit'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { Maybe } from 'types'

interface PDFViewerState {
  instance: Instance | null
  isPdfLoading: boolean
  isAnnotating: boolean
  sourceAnnotations: {
    [key: string]: {
      startingPage: number
      textLines: [TextLine[], Maybe<Rect>, Maybe<Rect>]
    }
  }
  // Document annotations represent the merged annotations from all sources
  // The key is page number
  documentAnnotations: {
    [key: number]: [TextLine[], Maybe<Rect>, Maybe<Rect>][]
  }
}

interface PDFViewerAction {
  setInstance: (instance: Instance | null) => void
  setIsPdfLoading: (isPdfLoading: boolean) => void
  setIsAnnotating: (isAnnotating: boolean) => void
  updateSourceAnnotation: (
    sourceId: string,
    annotation: {
      startingPage: number
      textLines: [TextLine[], Maybe<Rect>, Maybe<Rect>]
    }
  ) => void
  updateDocumentAnnotation: (
    page: number,
    annotations: [TextLine[], Maybe<Rect>, Maybe<Rect>][]
  ) => void
  clearDocumentAnnotation: () => void
}

export const usePDFViewerStore = create(
  devtools(
    immer<PDFViewerState & PDFViewerAction>((set) => ({
      instance: null,
      isPdfLoading: false,
      isAnnotating: false,
      sourceAnnotations: {},
      documentAnnotations: {},
      setInstance: (instance) => set({ instance }),
      setIsPdfLoading: (isPdfLoading) => set({ isPdfLoading }),
      setIsAnnotating: (isAnnotating) => set({ isAnnotating }),
      updateSourceAnnotation: (sourceId, annotation) => {
        set((state) => {
          state.sourceAnnotations[sourceId] = annotation
        })
      },
      updateDocumentAnnotation: (page, annotations) => {
        set((state) => {
          state.documentAnnotations[page] = annotations
        })
      },
      clearDocumentAnnotation: () => {
        set((state) => {
          state.documentAnnotations = {}
        })
      },
    }))
  )
)

import * as React from 'react'

import { cn } from 'utils/utils'

import { DropdownMenuItem } from 'components/ui/dropdown-menu'

export interface DropdownItemProps {
  icon: JSX.Element
  title: string
  description?: string
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  disabled?: boolean
  children?: React.ReactNode
  className?: string
}

export const DropdownItem: React.FC<DropdownItemProps> = ({
  icon,
  title,
  description,
  onClick,
  disabled,
  children,
  className,
}) => {
  return (
    <DropdownMenuItem
      onClick={onClick}
      disabled={disabled}
      data-testid="input-source-button-dropdown-item"
      className={cn('p-2', className)}
    >
      <div className="flex w-full items-center gap-6">
        <div className="flex max-w-56 flex-1 flex-col">
          <p className="text-sm">{title}</p>
          <p className="line-clamp-2 text-xs text-muted">{description}</p>
        </div>
        <div className="flex size-4 items-center justify-center">
          {React.cloneElement(icon, {
            className: 'size-4 text-muted',
          })}
        </div>
      </div>
      {children}
    </DropdownMenuItem>
  )
}

/* tslint:disable */
/* eslint-disable */
/**
 * User Profiles
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Industry = {
    LEGAL: 'LEGAL',
    TAX: 'TAX',
    FINANCE: 'FINANCE',
    ACCOUNTING: 'ACCOUNTING',
    CONSULTING: 'CONSULTING',
    MULTIPLE: 'MULTIPLE'
} as const;
export type Industry = typeof Industry[keyof typeof Industry];


export function instanceOfIndustry(value: any): boolean {
    for (const key in Industry) {
        if (Object.prototype.hasOwnProperty.call(Industry, key)) {
            if (Industry[key as keyof typeof Industry] === value) {
                return true;
            }
        }
    }
    return false;
}

export function IndustryFromJSON(json: any): Industry {
    return IndustryFromJSONTyped(json, false);
}

export function IndustryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Industry {
    return json as Industry;
}

export function IndustryToJSON(value?: Industry | null): any {
    return value as any;
}

export function IndustryToJSONTyped(value: any, ignoreDiscriminator: boolean): Industry {
    return value as Industry;
}


import React, { RefObject, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useKey } from 'react-use'

import { CustomCellRendererProps } from 'ag-grid-react'
import { isNil } from 'lodash'
import { Copy } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { MessageFeedback } from 'openapi/models/MessageFeedback'
import { usePDFViewerStore } from 'stores/pdf-viewer-store'

import { unloadPDFDiv } from 'utils/pspdfkit'
import { TaskType } from 'utils/task'
import { displayInfoMessage } from 'utils/toast'
import { useClickAway } from 'utils/use-click-away'
import { EM_DASH, cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { FeedbackButton } from 'components/common/feedback/feedback'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
  HoverCardPortal,
} from 'components/ui/hover-card/hover-card'
import Icon from 'components/ui/icon/icon'
import Footnote from 'components/vault/components/vault-footnote'
import { useUpsertEventFeedback } from 'components/vault/hooks/use-feedback'
import {
  computeLeftCell,
  computeTopCell,
  hideAddColumnColumnDef,
  QuestionColumnDef,
  ROOT_NODE_ID,
  fetchSourcesForFileId,
} from 'components/vault/query-detail/data-grid-helpers'
import VaultClassifyCellRender from 'components/vault/query-detail/data-grid/cells/cell-renderer/classify-cell-render'
import ErrorPopover from 'components/vault/query-detail/data-grid/cells/error-popover'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import {
  ColumnDataType,
  ReviewAnswer,
  ReviewSource,
} from 'components/vault/utils/vault'
import {
  fileIdSearchParamKey,
  sourceIdSearchParamKey,
  questionIdSearchParamKey,
} from 'components/vault/utils/vault'
import useVaultFeedbackStore from 'components/vault/utils/vault-feedback-store'
import {
  getFileIcon,
  getDisplayAnswer,
  getRandomSkeletonSize,
  isAnswerEmpty,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

import VaultExtractionCellRender from './cell-renderer/extraction-cell-render'

interface DocumentCellProps extends CustomCellRendererProps {
  projectId: string
  queryId: string
}

interface CellViewerProps {
  summaryAnswer: string
  shouldRenderClassify: boolean
  shouldRenderExtraction: boolean
  cellProps: DocumentCellProps
  cellButtonRef: RefObject<HTMLButtonElement>
  openPanel: (sourceToOpen?: ReviewSource) => void
  resetCellViewer: () => void
}

const CellViewer = ({
  summaryAnswer,
  shouldRenderClassify,
  shouldRenderExtraction,
  cellProps,
  openPanel,
  cellButtonRef,
  resetCellViewer,
}: CellViewerProps) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const contentRef = useRef<HTMLDivElement>(null)
  const feedbackSpanRef = useRef<HTMLSpanElement>(null)
  const { upsertEventFeedback } = useUpsertEventFeedback()
  const feedbacks = useVaultFeedbackStore(useShallow((s) => s.feedbacks))

  const [queryId, cellViewerData, fetchingFileIdsSources, fileIdToSources] =
    useVaultQueryDetailStore(
      useShallow((s) => [
        s.queryId,
        s.cellViewerData,
        s.fetchingFileIdsSources,
        s.fileIdToSources,
      ])
    )

  const { file, fileId, colId } = cellViewerData ?? {}
  const { projectId } = cellProps

  const summaryLabel = shouldRenderExtraction ? 'Direct Quote' : 'Summary'
  const shouldRenderDefault = !shouldRenderClassify && !shouldRenderExtraction
  const isFetchingSources = fetchingFileIdsSources.includes(fileId ?? '')
  const cellAnswers = cellProps.data.answers.filter(
    (answer: ReviewAnswer) => answer.columnId === colId
  )

  const additionalContextAnswer = getDisplayAnswer(
    cellAnswers.find((answer: ReviewAnswer) => answer.long)
  )
  const isSummaryAnswerAndAdditionalContextAnswerEqual =
    summaryAnswer === additionalContextAnswer

  const cellSources = (fileIdToSources[fileId ?? ''] ?? []).filter(
    (s: ReviewSource) => s.questionId === colId
  )

  useClickAway([contentRef, cellButtonRef, feedbackSpanRef], resetCellViewer)

  useKey('Escape', resetCellViewer)

  const handleCopySummaryToClipboard = async () => {
    await window.navigator.clipboard.writeText(summaryAnswer)
    displayInfoMessage('Copied to clipboard')
  }

  const handleCopyFullContextToClipboard = async () => {
    await window.navigator.clipboard.writeText(additionalContextAnswer)
    displayInfoMessage('Copied to clipboard')
  }

  const onFootnoteClick = (source: ReviewSource) => {
    trackEvent('Vault Review Citation Clicked', {
      location: 'vault-review-cell-viewer',
    })
    resetCellViewer()
    openPanel(source)
  }

  const onViewFileClick = () => {
    trackEvent('Vault Review File Opened')
    resetCellViewer()
    openPanel()
  }

  const handleSubmitFeedback = async (newFeedback: MessageFeedback) => {
    if (!queryId || !projectId || !fileId || !colId) return false
    const task_type = TaskType.VAULT_REVIEW
    trackEvent('Feedback Submitted', {
      event_id: queryId,
      event_kind: task_type,
      sentiment: newFeedback.sentiment,
      comments: newFeedback.selectedComments,
      vaultFolderId: projectId,
      fileId: fileId,
      questionId: colId,
    })
    const submittedFeedback = await upsertEventFeedback({
      eventId: Number(queryId),
      vaultFolderId: projectId,
      fileId: fileId,
      questionId: colId,
      feedbackData: newFeedback,
    })
    return submittedFeedback !== null
  }

  if (!cellViewerData) return null

  return (
    <div ref={contentRef} className="flex h-full flex-col">
      <div className="flex max-h-64 w-full flex-1 flex-col gap-1 border-b px-4 pb-4 pt-2">
        <div className="flex w-full items-center justify-between">
          <p className="text-xs text-muted">{summaryLabel}</p>
          <Button
            size="xsIcon"
            variant="ghost"
            onClick={handleCopySummaryToClipboard}
            tooltip="Copy"
          >
            <Icon size="small" icon={Copy} variant="secondary" />
          </Button>
        </div>
        {shouldRenderClassify && (
          <VaultClassifyCellRender shouldWrap text={summaryAnswer} />
        )}
        {shouldRenderExtraction && (
          <VaultExtractionCellRender text={summaryAnswer} />
        )}
        {shouldRenderDefault && (
          <p className="virtualized-scrollbar whitespace-pre-line text-xs">
            {summaryAnswer}
          </p>
        )}
      </div>
      {!isSummaryAnswerAndAdditionalContextAnswerEqual && (
        <div className="flex max-h-64 w-full flex-1 flex-col gap-1 border-b px-4 pb-4 pt-2">
          <div className="flex w-full items-center justify-between">
            <p className="text-xs text-muted">Additional Context</p>
            <Button
              size="xsIcon"
              variant="ghost"
              onClick={handleCopyFullContextToClipboard}
              tooltip="Copy"
            >
              <Icon size="small" icon={Copy} variant="secondary" />
            </Button>
          </div>
          <p className="virtualized-scrollbar whitespace-pre-line text-xs">
            {additionalContextAnswer}
          </p>
        </div>
      )}
      <div
        className={cn('flex w-full flex-col gap-1 px-4 pb-4 pt-2', {
          'border-b': userInfo.IsFeedbackUser,
        })}
      >
        <p className="text-xs text-muted">Source</p>
        <p className="truncate text-xs">{file?.name}</p>
        <div className="flex gap-1">
          {isFetchingSources && cellSources.length === 0 && (
            <div
              className="h-4 animate-pulse rounded bg-skeleton"
              style={{
                width: getRandomSkeletonSize(fileId ?? '', colId ?? ''),
              }}
            />
          )}
          {!isFetchingSources && cellSources.length === 0 && (
            <Badge variant="skeleton" className="px-1.5 py-0.5">
              No citations found
            </Badge>
          )}
          {cellSources.length > 0 &&
            cellSources.map((s: ReviewSource) => (
              <Footnote
                key={s.id}
                text={s.footnote.toString()}
                isCurrentSource={false}
                onClick={() => onFootnoteClick(s)}
              />
            ))}
        </div>
        <Button
          variant="outline"
          size="sm"
          className="w-full"
          onClick={onViewFileClick}
        >
          <p className="text-xs">View Source</p>
        </Button>
      </div>
      {userInfo.IsFeedbackUser && (
        <div className="flex h-10 min-h-10 w-full items-center justify-between px-4">
          <p className="text-xs">How would you rate this response?</p>
          <div className="flex items-center gap-0.5">
            <FeedbackButton
              hasDocuments
              sentiment={
                feedbacks[queryId]?.[fileId ?? '']?.[colId ?? '']?.sentiment ??
                0
              }
              sentimentValue={1}
              buttonSize="xsIcon"
              iconSize="small"
              onSubmit={handleSubmitFeedback}
            />
            <FeedbackButton
              hasDocuments
              sentiment={
                feedbacks[queryId]?.[fileId ?? '']?.[colId ?? '']?.sentiment ??
                0
              }
              sentimentValue={-1}
              buttonSize="xsIcon"
              iconSize="small"
              feedbackSpanRef={feedbackSpanRef}
              onSubmit={handleSubmitFeedback}
            />
          </div>
        </div>
      )}
    </div>
  )
}

interface CellRendererProps {
  text: string
  trailingIcon: React.ReactNode
  shouldRenderClassify: boolean
}

const CellRenderer = ({
  text,
  trailingIcon,
  shouldRenderClassify,
}: CellRendererProps) => {
  if (shouldRenderClassify) {
    return <VaultClassifyCellRender text={text} />
  }

  return (
    <>
      <p className="truncate text-xs">{text}</p>
      {trailingIcon}
    </>
  )
}

export const DocumentCell: React.FC<DocumentCellProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const fileId = searchParams.get(fileIdSearchParamKey)
  const sourceId = searchParams.get(sourceIdSearchParamKey)
  const queryParamQuestionId = searchParams.get(questionIdSearchParamKey)
  const { trackEvent } = useAnalytics()

  const { api, node, value } = props
  const { id, file, backingReviewRowId } = props.data

  const colDef = props.colDef as QuestionColumnDef
  const { questionId, type, field, columnDataType } = colDef

  const cellButtonRef = useRef<HTMLButtonElement>(null)

  const [
    queryId,
    pendingQueryQuestions,
    pendingQueryFileIds,
    cellViewerData,
    setCellViewerData,
  ] = useVaultQueryDetailStore(
    useShallow((s) => [
      s.queryId,
      s.pendingQueryQuestions,
      s.pendingQueryFileIds,
      s.cellViewerData,
      s.setCellViewerData,
    ])
  )

  const pendingColumnIds = pendingQueryQuestions?.map((q) => q.id) ?? []
  const isNewQuery = queryId === 'new'
  const isDocumentType = type === 'document'
  const isTextType = !isDocumentType
  const isCellLoading = String(value) === 'Processing…'
  const isCellEmDash = String(value) === EM_DASH
  const isCellEmpty = isCellEmDash || isAnswerEmpty(String(value))
  const isPendingTextCell =
    isTextType &&
    (pendingColumnIds.includes(questionId) ||
      (!!pendingQueryFileIds && pendingQueryFileIds.includes(file.id)))

  const errors: { columnId: string; text: string }[] = props.data.errors
  const error = errors.find((error) => error.columnId === questionId)
  const hasErrors = !!error
  const isCellPaused =
    String(value).trim() === '' && !isPendingTextCell && !hasErrors
  const isDisabled = isCellPaused || isPendingTextCell || hasErrors

  const setActiveDocument = useVaultStore((s) => s.setActiveDocument)
  const setInstance = usePDFViewerStore((s) => s.setInstance)

  const shouldHighlightRow =
    file.id === fileId ||
    (cellViewerData !== null && file.id === cellViewerData.fileId)

  const shouldFocusCell =
    cellViewerData !== null &&
    file.id === cellViewerData.fileId &&
    field === cellViewerData.colId

  const isAlreadyOpen =
    !!cellViewerData &&
    cellViewerData.fileId === file.id &&
    cellViewerData.colId === field

  const fetchFileSources = async () => {
    if (isNewQuery) return
    await fetchSourcesForFileId(file.id, queryId, backingReviewRowId)
  }

  const onMouseEnter = () => {
    // 1. set the background color of all cells in the row
    const rows = document.querySelectorAll(`.ag-row[row-id="${file.id}"]`)
    rows.forEach((row) => {
      Array.from(row.children).forEach((child) => {
        child.classList.add('bg-button-secondary')
      })
    })
    const cell = cellButtonRef.current?.parentElement
    const cellColId = cell?.getAttribute('col-id')
    if (!cell || !cellColId || isPendingTextCell) return

    // 2. Modify the border-bottom & border-right of the current cell
    cell.classList.add('border-b-ring', 'border-r-ring')

    // 3. Check if the node is the first child of its group
    // If it is, then we need to modify the border-top of the current cell
    // If it's the first child of under the header row, then we need to modify the border-bottom of the header cell
    if (
      node.firstChild &&
      node.parent?.group &&
      node.parent.id !== ROOT_NODE_ID
    ) {
      cell.classList.add('border-t-ring')
    } else if (node.firstChild) {
      // need to modify the border-bottom of the header cell
      const headerCell = document.querySelector(
        `.ag-header-cell[col-id="${cellColId}"]`
      )
      if (!headerCell?.classList.contains('border-b-input-focused')) {
        headerCell?.classList.add('border-b-ring')
      }
    }

    // 4. Modify the border-left of the previous cell
    // If there is no previous cell, then it's the first cell after the name column
    // const previousLeftCell = cell.previousElementSibling
    const previousLeftCell = computeLeftCell(cell, cellColId)
    if (previousLeftCell) {
      previousLeftCell.classList.add('border-r-ring')
    } else {
      const rowId = file.id
      const leftPinnedColumnsContainer = document.querySelector(
        '.ag-pinned-left-cols-container'
      )
      const row = leftPinnedColumnsContainer?.querySelector(
        `.ag-row[row-id="${rowId}"]`
      )
      const cell = row?.querySelector('.ag-cell[col-id="name"]')
      cell?.classList.add('border-r-ring')
    }
    // 3. Modify the border-bottom of the previous row
    // if the current cell is the first child of its group, then we do not need to modify the border-bottom of the previous row
    if (!node.firstChild) {
      const topCell = computeTopCell(cell, cellColId)
      if (topCell) {
        topCell.classList.add('border-b-ring')
      }
    }
  }

  const onMouseLeave = () => {
    const rows = document.querySelectorAll(`.ag-row[row-id="${file.id}"]`)
    rows.forEach((row) => {
      Array.from(row.children).forEach((child) => {
        child.classList.remove('bg-button-secondary')
      })
    })
    const cell = cellButtonRef.current?.parentElement
    const cellColId = cell?.getAttribute('col-id')
    if (!cell || !cellColId || isPendingTextCell) return
    cell.classList.remove('border-b-ring', 'border-r-ring')
    if (
      node.firstChild &&
      node.parent?.group &&
      node.parent.id !== ROOT_NODE_ID
    ) {
      cell.classList.remove('border-t-ring')
    } else if (node.firstChild) {
      const headerCell = document.querySelector(
        `.ag-header-cell[col-id="${cellColId}"]`
      )
      headerCell?.classList.remove('border-b-ring')
    }
    const previousLeftCell = computeLeftCell(cell, cellColId)
    if (previousLeftCell) {
      previousLeftCell.classList.remove('border-r-ring')
    } else {
      const rowId = file.id
      const leftPinnedColumnsContainer = document.querySelector(
        '.ag-pinned-left-cols-container'
      )
      const row = leftPinnedColumnsContainer?.querySelector(
        `.ag-row[row-id="${rowId}"]`
      )
      const cell = row?.querySelector('.ag-cell[col-id="name"]')
      cell?.classList.remove('border-r-ring')
    }
    // 3. Modify the border-bottom of the previous row
    if (!node.firstChild) {
      const topCell = computeTopCell(cell, cellColId)
      if (topCell) {
        topCell.classList.remove('border-b-ring')
      }
    }
  }

  const onFocusCell = () => {
    const cell = cellButtonRef.current?.parentElement
    const cellColId = cell?.getAttribute('col-id')
    if (!cell || !cellColId || isPendingTextCell) return
    // 1. Modify the border-bottom & border-right of the current cell
    cell.classList.add('border-b-input-focused', 'border-r-input-focused')
    if (
      node.firstChild &&
      node.parent?.group &&
      node.parent.id !== ROOT_NODE_ID
    ) {
      cell.classList.add('border-t-input-focused')
    } else if (node.firstChild) {
      // need to modify the border-bottom of the header cell
      const headerCell = document.querySelector(
        `.ag-header-cell[col-id="${cellColId}"]`
      )
      headerCell?.classList.remove('border-b-ring')
      headerCell?.classList.add('border-b-input-focused')
    }
    // 2. Modify the border-left of the previous cell
    // If there is no previous cell, then it's the first cell after the name column
    const previousLeftCell = computeLeftCell(cell, cellColId)
    if (previousLeftCell) {
      previousLeftCell.classList.add('border-r-input-focused')
    } else {
      const rowId = file.id
      const leftPinnedColumnsContainer = document.querySelector(
        '.ag-pinned-left-cols-container'
      )
      const row = leftPinnedColumnsContainer?.querySelector(
        `.ag-row[row-id="${rowId}"]`
      )
      const cell = row?.querySelector('.ag-cell[col-id="name"]')
      cell?.classList.add('border-r-input-focused')
    }
    // 3. Modify the border-bottom of the previous row
    if (!node.firstChild) {
      const topCell = computeTopCell(cell, cellColId)
      if (topCell) {
        topCell.classList.add('border-b-input-focused')
      }
    }
  }

  const onBlurCell = () => {
    const cell = cellButtonRef.current?.parentElement
    const cellColId = cell?.getAttribute('col-id')
    if (!cell || !cellColId || isPendingTextCell) return
    cell.classList.remove('border-b-input-focused', 'border-r-input-focused')
    if (
      node.firstChild &&
      node.parent?.group &&
      node.parent.id !== ROOT_NODE_ID
    ) {
      cell.classList.remove('border-t-input-focused')
    } else if (node.firstChild) {
      const headerCell = document.querySelector(
        `.ag-header-cell[col-id="${cellColId}"]`
      )
      headerCell?.classList.remove('border-b-input-focused')
    }
    const previousLeftCell = computeLeftCell(cell, cellColId)
    if (previousLeftCell) {
      previousLeftCell.classList.remove('border-r-input-focused')
    } else {
      const rowId = file.id
      const leftPinnedColumnsContainer = document.querySelector(
        '.ag-pinned-left-cols-container'
      )
      const row = leftPinnedColumnsContainer?.querySelector(
        `.ag-row[row-id="${rowId}"]`
      )
      const cell = row?.querySelector('.ag-cell[col-id="name"]')
      cell?.classList.remove('border-r-input-focused')
    }
    // 3. Modify the border-bottom of the previous row
    if (!node.firstChild) {
      const topCell = computeTopCell(cell, cellColId)
      if (topCell) {
        topCell.classList.remove('border-b-input-focused')
        topCell.classList.add('border-b')
      }
    }
  }

  const handleCellClick = async () => {
    if (!field) return
    if (isAlreadyOpen) {
      resetCellViewer()
      return
    }
    trackEvent('Vault ReviewCell Viewer Opened', {
      column_type: columnDataType,
    })
    onFocusCell()
    setCellViewerData({
      fileId: file.id,
      file,
      colId: field,
    })
    await fetchFileSources()
  }

  const resetCellViewer = () => {
    setCellViewerData(null)
    onBlurCell()
  }

  const openPanel = (sourceToOpen?: ReviewSource) => {
    if (hasErrors) return
    const isExistingFile = fileId === id
    // if the file is already opened and the source is the same, then we do not need to do anything
    // if the fileId is already open and the source is different, we just need to set teh sourceId and the push sheet will take care of updating the annotations
    if (isExistingFile && !isNil(sourceToOpen) && sourceId === sourceToOpen.id)
      return
    if (fileId === id) {
      const shouldApplyQuestionQueryParam =
        isTextType && queryParamQuestionId !== questionId
      const shouldApplySourceQueryParam =
        isTextType && sourceToOpen && sourceId !== sourceToOpen.id
      if (shouldApplyQuestionQueryParam || shouldApplySourceQueryParam) {
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev)
          if (shouldApplyQuestionQueryParam) {
            newParams.set(questionIdSearchParamKey, questionId)
          }
          if (shouldApplySourceQueryParam) {
            newParams.set(sourceIdSearchParamKey, sourceToOpen.id)
          }
          return newParams
        })
      }
      return
    }
    void fetchFileSources()

    // 1. we have to hide the AddColumn column
    hideAddColumnColumnDef(api)

    // before we set the activeDocument we are going to clear the instance and unload the existing pdf
    // in case there is an existing pdf open (in the push sheet) we need to setInstance to null so the annotations do not apply (useEffect in push sheet)
    // we also want to unload the pdf immediately so that we can display a loader, otherwise the old pdf will be displayed while the new pdf is loading
    setInstance(null)
    unloadPDFDiv()
    setActiveDocument(file)
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.delete(sourceIdSearchParamKey)

      newParams.set(fileIdSearchParamKey, id)
      // if the type of the cell is text, then we are clicking on a specific question
      // let's set the source param so that the push sheet is open with the current source
      if (isTextType) {
        newParams.set(questionIdSearchParamKey, questionId)

        if (sourceToOpen) {
          newParams.set(sourceIdSearchParamKey, sourceToOpen.id)
        }
      }
      return newParams
    })
  }

  // Loading State
  if (isCellLoading && !hasErrors) {
    return (
      <div
        className={cn('flex h-full w-full items-center px-4 text-sm', {
          'block bg-secondary text-left': isDocumentType,
        })}
      >
        <div
          className="h-4 animate-pulse rounded bg-skeleton"
          style={{ width: getRandomSkeletonSize(id, questionId) }}
        />
      </div>
    )
  }

  const trailingIcon =
    isDocumentType && errors.length > 0 ? (
      <ErrorPopover fileId={file.id} />
    ) : null
  const text = hasErrors
    ? error.text
    : isPendingTextCell
    ? ''
    : isCellPaused
    ? 'Paused'
    : value
  const isOriginalText = !!value && text === value
  const isClassifyType = columnDataType === ColumnDataType.classify
  const isExtractionType = columnDataType === ColumnDataType.extraction
  const isTextEmdash = text === EM_DASH
  const shouldCustomRender = isOriginalText && !isCellEmpty && !isTextEmdash
  const shouldRenderClassify = isClassifyType && shouldCustomRender
  const shouldRenderExtraction = isExtractionType && shouldCustomRender

  const className = cn(
    'flex items-center h-full w-full items-center justify-start rounded-none px-4 text-left text-xs transition hover:bg-button-secondary',
    {
      'text-muted': isTextEmdash,
      'bg-button-secondary': shouldHighlightRow || isPendingTextCell,
      'hover:bg-skeleton bg-skeleton': isCellPaused,
      'text-muted hover:bg-primary': hasErrors,
    }
  )

  // return simple button for non-document cells
  if (!isDocumentType) {
    return (
      <HoverCard openDelay={250} open={shouldFocusCell}>
        <HoverCardTrigger asChild>
          <Button
            ref={cellButtonRef}
            variant="unstyled"
            className={className}
            disabled={isDisabled}
            onClick={handleCellClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <CellRenderer
              text={text}
              trailingIcon={trailingIcon}
              shouldRenderClassify={shouldRenderClassify}
            />
          </Button>
        </HoverCardTrigger>
        <HoverCardPortal>
          <HoverCardContent
            side="right"
            className="max-h-[656px] w-96 min-w-96 p-0"
          >
            <CellViewer
              summaryAnswer={text}
              cellProps={props}
              cellButtonRef={cellButtonRef}
              shouldRenderClassify={shouldRenderClassify}
              shouldRenderExtraction={shouldRenderExtraction}
              openPanel={openPanel}
              resetCellViewer={resetCellViewer}
            />
          </HoverCardContent>
        </HoverCardPortal>
      </HoverCard>
    )
  }

  return (
    <Button
      variant="unstyled"
      className={className}
      onClick={() => openPanel()}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Badge
        variant="secondary"
        className="flex shrink items-center gap-1 px-1 font-normal"
      >
        <Icon icon={getFileIcon(file)} size="small" />
        <p className="truncate text-xs">{text}</p>
      </Badge>
      {trailingIcon}
    </Button>
  )
}

export default DocumentCell

import React from 'react'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'
import { useFileCache } from 'stores/file-cache'

import { Source } from 'utils/task'

import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import Summary from 'components/assistant/workflows/blocks/antitrust-filing-analysis/summary/summary'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowAntitrustFilingsAnswerRenderer: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER
> = ({ blockParams, blockStatus }) => {
  const content = blockParams.answer.metadata
  const text = blockParams.answer.response
  const addFile = useFileCache((state) => state.addFile)

  const { handleSourceClick } = useSourceUtils({ sources: [] })

  const internalHandleSourceClick = (source: Source) => {
    const sourceToUploadedFile = (source: Source): UploadedFile => {
      return {
        id: source.documentName!,
        name: source.documentName!,
        path: source.documentUrl,
        url: source.documentUrl,
      }
    }
    const uploadedFile = sourceToUploadedFile(source)
    addFile(uploadedFile)
    handleSourceClick(uploadedFile.id, source.id, [source])
  }

  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowHarveyComponent>
        {text && (
          <AssistantWorkflowThreadText blockStatus={blockStatus} text={text} />
        )}
        {content && (
          <Summary
            handleSourceClick={internalHandleSourceClick}
            data={content}
          />
        )}
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowAntitrustFilingsAnswerRendererInputBox: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER
> = ({ blockParams }) => {
  const headerText = blockParams.answer.headerText
  if (!headerText) {
    return null
  }

  return (
    <WorkflowInput>
      <WorkflowInputFooter footer={headerText} />
    </WorkflowInput>
  )
}

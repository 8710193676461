import React, { useEffect, useState } from 'react'

import _ from 'lodash'

import { FetchWorkspaces, type Workspace } from 'models/workspace'

import { TagInput } from 'components/ui/tag-input/tag-input'

interface WorkspaceMultiSelectProps {
  selected: Workspace[]
  setSelected: (workspaces: Workspace[]) => void
  placeholder?: string
  disabled?: boolean
  size?: 'sm' | 'md'
}

const WorkspaceMultiSelect: React.FC<WorkspaceMultiSelectProps> = ({
  selected,
  setSelected,
  placeholder = 'Select workspaces',
  disabled = false,
  size = 'md',
}) => {
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])

  const fetchWorkspaces = async () => {
    const workspaces = await FetchWorkspaces()
    setWorkspaces(workspaces)
  }

  useEffect(() => {
    void fetchWorkspaces()
  }, [])

  const selectedSlugs = selected.map((workspace) => workspace.slug)

  const handleSelectedChange = (slugs: string[]) => {
    const selectedWorkspaces = workspaces.filter((workspace) =>
      slugs.includes(workspace.slug)
    )
    setSelected(selectedWorkspaces)
  }

  if (workspaces.length <= 1) {
    return null
  }

  return (
    <TagInput
      size={size}
      disabled={disabled}
      sortedTags={_.sortBy(workspaces, [
        (workspace) =>
          workspace.clientName ?? workspace.friendlyName ?? workspace.slug,
      ]).map((workspace) => ({
        value: workspace.slug,
        badgeDisplayText:
          workspace.clientName ?? workspace.friendlyName ?? workspace.slug,
      }))}
      placeholder={placeholder}
      shouldShowSuggestedTags
      allowCreatingNewTags={false}
      selectedTagValues={selectedSlugs}
      setSelectedTagValues={handleSelectedChange}
    />
  )
}

export default WorkspaceMultiSelect

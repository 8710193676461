/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PermissionCategory = {
    ASSISTANT: 'Assistant',
    VAULT: 'Vault',
    VAULT_WORKFLOWS: 'Vault Workflows',
    LIBRARY: 'Library',
    CLIENT_MATTERS: 'Client Matters',
    INTEGRATIONS: 'Integrations',
    HISTORY: 'History',
    ANALYTICS: 'Analytics',
    ADD_INS: 'Add-Ins',
    TAX_KNOWLEDGE_SOURCES: 'Tax Knowledge Sources',
    CASE_LAW_KNOWLEDGE_SOURCES: 'Case Law Knowledge Sources',
    WORKFLOWS: 'Workflows',
    BASE_USER_DEFAULTS: 'Base User Defaults',
    USER_MANAGEMENT: 'User Management',
    ADMIN_ONLY: 'Admin Only',
    HARVEY_EMPLOYEES_ONLY: 'Harvey Employees Only',
    API: 'API',
    ASSIGNED_BY_USER_OPS_ONLY: 'Assigned by UserOps Only'
} as const;
export type PermissionCategory = typeof PermissionCategory[keyof typeof PermissionCategory];


export function instanceOfPermissionCategory(value: any): boolean {
    for (const key in PermissionCategory) {
        if (Object.prototype.hasOwnProperty.call(PermissionCategory, key)) {
            if (PermissionCategory[key as keyof typeof PermissionCategory] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PermissionCategoryFromJSON(json: any): PermissionCategory {
    return PermissionCategoryFromJSONTyped(json, false);
}

export function PermissionCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionCategory {
    return json as PermissionCategory;
}

export function PermissionCategoryToJSON(value?: PermissionCategory | null): any {
    return value as any;
}

export function PermissionCategoryToJSONTyped(value: any, ignoreDiscriminator: boolean): PermissionCategory {
    return value as PermissionCategory;
}


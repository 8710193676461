import React from 'react'

import { EventStatus } from 'openapi/models/EventStatus'

import {
  AssistantWorkflowStepNameToDefinition,
  BlockTypeMap,
  BlockValidators,
} from 'components/assistant/workflows'

interface RenderWorkflowComponentProps<K extends keyof BlockTypeMap> {
  stepIdx: number
  blockType: K
  onCompleted: (result: BlockTypeMap[K]['outputData']) => void
  blockParams: BlockTypeMap[K]['blockParams']
  outputData: BlockTypeMap[K]['outputData'] | null
  renderType: 'thread' | 'input'
  status: EventStatus
}

export const createValidatedOnCompleted = <K extends keyof BlockTypeMap>(
  blockType: K,
  originalOnCompleted: (result: BlockTypeMap[K]['outputData']) => void,
  validators: BlockValidators
): ((result: unknown) => void) => {
  return (result: any) => {
    if (validators.outputData && !validators.outputData(result)) {
      console.error(`Invalid output data for block type ${blockType}`, result)
      // Handle the invalid data case
      throw new Error('Invalid output data provided to onCompleted')
    }
    // Safe to proceed
    const typedResult = result as BlockTypeMap[K]['outputData']
    originalOnCompleted(typedResult)
  }
}

export const RenderWorkflowComponent = <K extends keyof BlockTypeMap>({
  stepIdx,
  blockType,
  onCompleted,
  blockParams,
  outputData,
  renderType,
  status,
}: RenderWorkflowComponentProps<K>) => {
  const entry = AssistantWorkflowStepNameToDefinition[blockType]

  if (!entry) return null

  const { ThreadComponent, InputComponent, validators } = entry
  const Component = renderType === 'thread' ? ThreadComponent : InputComponent

  // Validate blockParams and outputData
  if (!validators.blockParams(blockParams)) {
    console.error(
      `Invalid block parameters for block type ${blockType}`,
      blockParams
    )
    return <div>Error: Invalid block parameters.</div>
  }

  if (
    outputData !== null &&
    validators.outputData &&
    !validators.outputData(outputData)
  ) {
    console.error(`Invalid output data for block type ${blockType}`, outputData)
    return <div>Error: Invalid output data.</div>
  }

  // Wrap onCompleted with validation
  const validatedOnCompleted = createValidatedOnCompleted(
    blockType,
    onCompleted,
    validators
  )

  // Render the component
  return (
    <Component
      stepIdx={stepIdx}
      onCompleted={validatedOnCompleted}
      blockParams={blockParams}
      outputData={outputData}
      blockStatus={status}
    />
  )
}

import React, { useEffect, useState } from 'react'

import {
  FetchAllPermBundles,
  FetchWorkspacePermBundles,
  PermBundle,
} from 'models/perms'
import { isSensitiveCustomerDataPerm } from 'models/user-info'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'

import { TagInput } from 'components/ui/tag-input/tag-input'

interface PermBundlesMultiSelectProps {
  /** If provided, will only show bundles that are enabled for this workspace */
  filterForWorkspaceId?: number
  selectedPermissions: PermissionBundleId[]
  setSelectedPermissions: (permissions: PermissionBundleId[]) => void
  excludedBundles?: PermissionBundleId[]
  disabled?: boolean
  size?: 'sm' | 'md'
}

const PermBundlesMultiSelectInline: React.FC<PermBundlesMultiSelectProps> = ({
  filterForWorkspaceId,
  selectedPermissions,
  setSelectedPermissions,
  excludedBundles,
  disabled = false,
  size = 'md',
}) => {
  const [permBundles, setPermBundles] = useState<PermBundle[]>([])

  useEffect(() => {
    const excludedBundleIdSet = new Set(excludedBundles ?? [])

    const fetchPermBundles = async () => {
      const baseFilter = (bundles: PermBundle[]) => {
        return bundles.filter((bundle) => {
          if (bundle.permissions.some(isSensitiveCustomerDataPerm)) {
            // Never show these - granted in a very bespoke way to a few users
            return false
          }
          if (excludedBundleIdSet.has(bundle.id)) {
            return false
          }
          return true
        })
      }
      if (filterForWorkspaceId) {
        const bundles = (
          await FetchWorkspacePermBundles(filterForWorkspaceId)
        ).filter((bundle) => bundle.enabledPerms.length > 0) // Only show bundles that have at least one enabled perm
        setPermBundles(baseFilter(bundles))
      } else {
        const bundles = await FetchAllPermBundles()
        setPermBundles(baseFilter(bundles))
      }
    }
    void fetchPermBundles()
  }, [excludedBundles, filterForWorkspaceId])

  return (
    <TagInput
      size={size}
      disabled={disabled}
      sortedTags={permBundles.map((bundle) => ({
        value: bundle.id,
        badgeDisplayText: `${bundle.name} (${bundle.category})`,
      }))}
      placeholder="Select permissions"
      shouldShowSuggestedTags
      allowCreatingNewTags={false}
      selectedTagValues={selectedPermissions}
      setSelectedTagValues={(values) =>
        setSelectedPermissions(values as PermissionBundleId[])
      }
    />
  )
}

export default PermBundlesMultiSelectInline

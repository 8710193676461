import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { useClickAway, useKey } from 'react-use'

import { datadogRum } from '@datadog/browser-rum'
import { GridApi } from 'ag-grid-community'
import _ from 'lodash'
import { Info, Trash } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { useGeneralStore } from 'stores/general-store'

import { displayErrorMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import useCommandEnter from 'components/common/use-command-enter'
import { Button } from 'components/ui/button'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from 'components/ui/hover-card/hover-card'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { SkeletonBlock } from 'components/ui/skeleton'
import { TagInput } from 'components/ui/tag-input/tag-input'
import { Textarea } from 'components/ui/text-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { useRunReview } from 'components/vault/components/vault-app-header/use-run-review'
import {
  getDisplayDataType,
  getDescriptionForDataType,
  getPlaceholderForDataType,
  QuestionColumnDef,
  updateAddColumnDef,
  ADD_COLUMN_FIELD,
  getIconForDataType,
  getIllustrationForDataType,
} from 'components/vault/query-detail/data-grid-helpers'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import {
  ColumnDataType,
  COLUMN_DATA_TYPES_NOT_TO_BE_USED_FOR_QUESTION,
  ReviewColumn,
} from 'components/vault/utils/vault'
import {
  DeleteReviewColumn,
  FetchSuggestedReviewColumn,
  UpdateReviewColumn,
} from 'components/vault/utils/vault-fetcher'
import { columnToQueryQuestion } from 'components/vault/utils/vault-helpers'

enum ColumnEditorScreen {
  INPUT = 'input',
  CONFIRMATION = 'confirmation',
  UPDATE = 'update',
}

export interface SuggestedReviewColumnResponse {
  dataType: ColumnDataType
  header: string
  options?: string[]
}

export const useSuggestedReviewColumn = (
  question: string,
  {
    onSuggestionReceived,
    enabled = true,
    debounce = true,
  }: {
    onSuggestionReceived?: (
      suggestion: SuggestedReviewColumnResponse,
      originalQuestion: string
    ) => void
    enabled?: boolean
    debounce?: boolean
  } = {},
  onFetchSuggestedReviewColumnError: () => void
) => {
  const [debouncedQuestion, setDebouncedQuestion] = useState(question)

  useEffect(() => {
    if (!debounce) {
      setDebouncedQuestion(question)
      return
    }

    const handler = setTimeout(() => {
      setDebouncedQuestion(question)
    }, 500)

    return () => {
      clearTimeout(handler)
    }
  }, [question, debounce])

  const {
    data: suggestion,
    isFetching,
    error,
  } = useWrappedQuery({
    queryKey: [
      HarvQueryKeyPrefix.VaultSuggestedReviewColumn,
      debouncedQuestion,
    ],
    queryFn: async ({ signal }) => {
      if (!debouncedQuestion.trim()) {
        return null
      }
      try {
        return await FetchSuggestedReviewColumn(debouncedQuestion, signal)
      } catch (e) {
        return null
      }
    },
    enabled: enabled && debouncedQuestion.trim().length > 0,
  })

  useEffect(() => {
    if (error) {
      console.error('Failed to fetch suggested review column', error)
      onFetchSuggestedReviewColumnError()
    }
  }, [error, onFetchSuggestedReviewColumnError])

  useEffect(() => {
    if (enabled && suggestion && onSuggestionReceived) {
      onSuggestionReceived(suggestion, debouncedQuestion)
    }
  }, [enabled, suggestion, onSuggestionReceived, debouncedQuestion])

  return {
    suggestion,
    isFetching,
    error,
  }
}

const VaultColumnEditor = ({
  colDef,
  colId,
  gridApi,
  isPendingColumn,
  popoverRef,
}: {
  colDef: QuestionColumnDef
  colId: string
  gridApi: GridApi
  isPendingColumn: boolean
  popoverRef: React.RefObject<HTMLDivElement>
}) => {
  const { trackEvent } = useAnalytics()
  const ref = useRef<HTMLDivElement>(null)
  const wiggle = useRef(true)
  const columnDataTypeSelectContentRef = useRef<HTMLDivElement>(null)

  const isDefaultColumnDef =
    colDef.headerName === 'Untitled' &&
    colDef.originalQuestion === '' &&
    colDef.columnDataType === ColumnDataType.freeResponse

  const [currentScreen, setCurrentScreen] = useState<ColumnEditorScreen>(
    (isPendingColumn && !isDefaultColumnDef) || !!colDef.backingReviewColumn
      ? ColumnEditorScreen.UPDATE
      : ColumnEditorScreen.INPUT
  )

  const [headerName, setHeaderName] = useState<string>(colDef.headerName ?? '')
  const [columnDataType, setColumnDataType] = useState<ColumnDataType>(
    colDef.columnDataType ?? ColumnDataType.freeResponse
  )
  const [question, setQuestion] = useState<string>(
    colDef.originalQuestion ?? ''
  )

  const [options, setOptions] = useState<string>(colDef.options ?? '')
  const [isUpdatingColumn, setIsUpdatingColumn] = useState<boolean>(false)
  const [isFetchingSuggestedReviewColumn, setIsFetchingSuggestedReviewColumn] =
    useState<boolean>(false)

  const onFetchSuggestedReviewColumnError = () => {
    // Default to free response if suggestion fails
    setColumnDataType(ColumnDataType.freeResponse)
    setHeaderName('')
    setOptions('')
  }

  const { isFetching, suggestion } = useSuggestedReviewColumn(
    question,
    {
      onSuggestionReceived: useCallback(
        (suggestion: SuggestedReviewColumnResponse) => {
          setColumnDataType(suggestion.dataType)
          setHeaderName(suggestion.header)
          setOptions(suggestion.options?.join(', ') ?? '')
        },
        []
      ),
      enabled:
        currentScreen === ColumnEditorScreen.INPUT ||
        currentScreen === ColumnEditorScreen.CONFIRMATION,
      debounce: currentScreen === ColumnEditorScreen.INPUT,
    },
    onFetchSuggestedReviewColumnError
  )

  useEffect(() => {
    setIsFetchingSuggestedReviewColumn(isFetching)
  }, [isFetching])

  useEffect(() => {
    wiggle.current = true
  }, [headerName, question, options, columnDataType])

  const isSubmitDisabled = !isPendingColumn
    ? !headerName
    : !columnDataType ||
      !question ||
      !headerName ||
      (columnDataType === ColumnDataType.classify && !options)

  const [
    historyItem,
    pendingQueryQuestions,
    setIsGridDirty,
    setCurrentPendingColumnId,
    updatePendingQueryQuestion,
    removeFromPendingQueryQuestions,
    updateColumnInHistoryItem,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.historyItem,
      state.pendingQueryQuestions,
      state.setIsGridDirty,
      state.setCurrentPendingColumnId,
      state.updatePendingQueryQuestion,
      state.removeFromPendingQueryQuestions,
      state.updateColumnInHistoryItem,
    ])
  )

  const { handleRun } = useRunReview()

  const isDirty =
    question !== colDef.originalQuestion ||
    columnDataType !== colDef.columnDataType ||
    options !== (colDef.options ?? '')

  const [addRequestToPendingRequestIds, removeRequestFromPendingRequestIds] =
    useGeneralStore(
      useShallow((s) => [
        s.addRequestToPendingRequestIds,
        s.removeRequestFromPendingRequestIds,
      ])
    )

  const reviewEvent = historyItem as ReviewHistoryItem

  useClickAway(ref, async (event) => {
    if (
      columnDataTypeSelectContentRef.current &&
      columnDataTypeSelectContentRef.current.contains(event.target as Node)
    ) {
      return
    }
    if (isDirty) {
      if (wiggle.current && popoverRef.current) {
        popoverRef.current.classList.remove('animate-wiggle-important')
        void popoverRef.current.offsetWidth
        popoverRef.current.classList.add('animate-wiggle-important')
      }
      wiggle.current = false
    } else {
      onCancelHandler()
    }
  })

  const onCancelHandler = async () => {
    if (
      currentScreen === ColumnEditorScreen.UPDATE &&
      headerName !== colDef.backingReviewColumn?.header
    ) {
      // If the header name has changed, we need to update the column in the backend
      await onSubmitHandler()
      trackEvent('Vault Review Column Label Updated')
      setCurrentPendingColumnId(null)
    } else if (isPendingColumn && isDefaultColumnDef) {
      await onDeleteColumnHandler()
    } else {
      setCurrentPendingColumnId(null)
    }
  }

  const onBackHandler = () => {
    setCurrentScreen(ColumnEditorScreen.INPUT)
  }

  const onHeaderNameChangeHandler = (value: string) => {
    setHeaderName(value)
    updatePendingQueryQuestion(colId, 'header', value)
    if (currentScreen === ColumnEditorScreen.UPDATE) {
      // Update the header name in the grid
      const existingColumnDefs = gridApi.getColumnDefs() ?? []
      const newColumnDefs = _.cloneDeep(existingColumnDefs)
      newColumnDefs.forEach((columnDef) => {
        const questionColumnDef = columnDef as QuestionColumnDef
        const columnId =
          'colId' in questionColumnDef ? questionColumnDef.colId : null
        if (columnId === colId) {
          questionColumnDef.headerName = value
        }
      })
      gridApi.setGridOption('columnDefs', newColumnDefs)
    }
  }

  const onColumnDataTypeChangeHandler = (value: ColumnDataType) => {
    setColumnDataType(value)
    updatePendingQueryQuestion(colId, 'columnDataType', value)
  }

  const onQuestionChangeHandler = (value: string) => {
    setQuestion(value)
    updatePendingQueryQuestion(colId, 'text', value)
  }

  const onOptionsChangeHandler = (value: string) => {
    setOptions(value)
    updatePendingQueryQuestion(colId, 'options', value)
  }

  const onContinueHandler = () => {
    trackEvent('Vault Review Add Column Question Typed')
    datadogRum.startDurationVital('vaultColumnGeneration')
    setCurrentScreen(ColumnEditorScreen.CONFIRMATION)
  }

  const onSubmitHandler = async () => {
    const existingColumnDefs = gridApi.getColumnDefs() ?? []
    const newColumnDefs = _.cloneDeep(existingColumnDefs)
    newColumnDefs.forEach((columnDef) => {
      const questionColumnDef = columnDef as QuestionColumnDef
      const columnId =
        'colId' in questionColumnDef ? questionColumnDef.colId : null
      if (columnId === colId) {
        questionColumnDef.headerName = headerName
        questionColumnDef.originalQuestion = question
        questionColumnDef.columnDataType = columnDataType
        questionColumnDef.options = options
      }
    })
    setIsUpdatingColumn(true)
    gridApi.setGridOption('columnDefs', newColumnDefs)
    if (isPendingColumn) {
      const pendingQuestions = pendingQueryQuestions?.map((queryQuestion) => {
        if (queryQuestion.id === colId) {
          return {
            id: colId,
            header: headerName,
            text: question,
            columnDataType: columnDataType,
            options: options,
          }
        } else {
          return queryQuestion
        }
      })
      await handleRun({
        pendingQuestions,
      })
      newColumnDefs.forEach((colDef) => {
        if ('colId' in colDef && colDef.colId === ADD_COLUMN_FIELD) {
          colDef.hide = false
        }
      })
      const haveToPinAddColumn = updateAddColumnDef(newColumnDefs)
      gridApi.setGridOption('columnDefs', newColumnDefs)
      gridApi.ensureColumnVisible(haveToPinAddColumn ? ADD_COLUMN_FIELD : 'end')
      trackEvent('Vault Review New Column Submitted', {
        column_type: columnDataType,
        user_edited_label: headerName !== suggestion?.header,
        user_edited_type: columnDataType !== suggestion?.dataType,
      })
    } else {
      const requestId = uuidv4()
      if (!colDef.backingReviewColumn) {
        throw new Error('Missing backing review column')
      }
      const existingColumn = reviewEvent.columns.find(
        (existingColumn) => existingColumn.id === colDef.backingReviewColumn?.id
      )

      if (!existingColumn) {
        throw new Error('Existing column not found')
      }
      try {
        const existingOptions = existingColumn.options?.filter(Boolean) ?? []
        const optionsArray = options
          .split(',')
          .map((option) => option.trim())
          .filter(Boolean)
        const updatedColumn = {
          ...existingColumn,
          header: headerName,
          fullText: question,
          dataType: columnDataType,
          options: optionsArray,
        }
        updateColumnInHistoryItem(updatedColumn)
        if (!colDef.eventId || !colDef.backingReviewColumn.id) {
          throw new Error('Missing eventId or columnId')
        }
        addRequestToPendingRequestIds(requestId)
        await UpdateReviewColumn({
          eventId: colDef.eventId,
          columnId: colDef.backingReviewColumn.id,
          header: headerName,
          fullText: question,
          dataType: columnDataType,
          options: optionsArray,
        })
        if (
          existingColumn.fullText !== question ||
          existingColumn.dataType !== columnDataType ||
          JSON.stringify(existingOptions) !== JSON.stringify(optionsArray)
        ) {
          await handleRun({
            pendingQuestions: [columnToQueryQuestion(updatedColumn)],
            isRetry: true,
          })
        }
        // We need to invalidate the query so that the new query is fetched
        // This will update historyItem to reflect the right state of the columns
        setIsGridDirty(true)
        trackEvent('Vault Review Existing Column Re-Run', {
          user_edited_label: headerName !== existingColumn?.header,
          user_edited_type: columnDataType !== existingColumn?.dataType,
        })
      } catch (error) {
        console.error(error)
        displayErrorMessage('Failed to update column header')
        updateColumnInHistoryItem(existingColumn)
        gridApi.setGridOption('columnDefs', existingColumnDefs)
      }
      removeRequestFromPendingRequestIds(requestId)
    }
    setIsUpdatingColumn(false)
    setCurrentPendingColumnId(null)
  }

  const onDeleteColumnHandler = async () => {
    const allColumnDefs = gridApi.getColumnDefs() ?? []
    const filteredColumns =
      allColumnDefs?.filter((column) =>
        'colId' in column ? column.colId !== colId : true
      ) ?? []
    setCurrentPendingColumnId(null)
    if (isPendingColumn) {
      removeFromPendingQueryQuestions(colId)
      // this function modifies the columnDefs array, so we don't need to set it again
      filteredColumns.forEach((colDef) => {
        if ('colId' in colDef && colDef.colId === ADD_COLUMN_FIELD) {
          colDef.hide = false
        }
      })
      const haveToPinAddColumn = updateAddColumnDef(filteredColumns)
      gridApi.setGridOption('columnDefs', filteredColumns)
      gridApi.ensureColumnVisible(haveToPinAddColumn ? ADD_COLUMN_FIELD : 'end')
    } else {
      const requestId = uuidv4()
      const existingColumn = reviewEvent.columns.find(
        (existingColumn) => existingColumn.id === colDef.backingReviewColumn?.id
      )
      try {
        const haveToPinAddColumn = updateAddColumnDef(filteredColumns)
        gridApi.setGridOption('columnDefs', filteredColumns)
        gridApi.ensureColumnVisible(
          haveToPinAddColumn ? ADD_COLUMN_FIELD : 'end'
        )
        if (existingColumn) {
          const updatedColumn = {
            ...existingColumn,
            isHidden: true,
          }
          updateColumnInHistoryItem(updatedColumn)
        }
        if (!colDef.eventId || !colDef.backingReviewColumn?.id) {
          throw new Error('Missing eventId or columnId')
        }
        addRequestToPendingRequestIds(requestId)
        await DeleteReviewColumn(colDef.eventId, colDef.backingReviewColumn.id)
        // We need to invalidate the query so that the new query is fetched
        // This will update historyItem to reflect the right state in the document cells
        setIsGridDirty(true)
        trackEvent('Vault Review Column Deleted')
      } catch (error) {
        console.error(error)
        displayErrorMessage('Failed to delete column')
        if (existingColumn) {
          updateColumnInHistoryItem(existingColumn)
        }
        const haveToPinAddColumn = updateAddColumnDef(allColumnDefs)
        gridApi.setGridOption('columnDefs', allColumnDefs)
        gridApi.ensureColumnVisible(
          haveToPinAddColumn ? ADD_COLUMN_FIELD : 'end'
        )
      }
      removeRequestFromPendingRequestIds(requestId)
    }
  }

  // escape to close
  useKey('Escape', () => {
    void onCancelHandler()
  })

  return (
    <div ref={ref} className="flex flex-col gap-4">
      {currentScreen === ColumnEditorScreen.INPUT && (
        <InputScreen
          question={question}
          columnEditorRef={ref}
          onQuestionChangeHandler={onQuestionChangeHandler}
          onContinueHandler={onContinueHandler}
          onCancelHandler={onCancelHandler}
        />
      )}
      {currentScreen === ColumnEditorScreen.CONFIRMATION && (
        <ConfirmationScreen
          columnDataType={columnDataType}
          options={options}
          columnHeaderName={headerName}
          columnDataTypeSelectContentRef={columnDataTypeSelectContentRef}
          isFetchingSuggestedReviewColumn={isFetchingSuggestedReviewColumn}
          isSubmitting={isUpdatingColumn}
          columnEditorRef={ref}
          onColumnDataTypeChangeHandler={onColumnDataTypeChangeHandler}
          onHeaderNameChangeHandler={onHeaderNameChangeHandler}
          onOptionsChangeHandler={onOptionsChangeHandler}
          onCancelHandler={onCancelHandler}
          onBackHandler={onBackHandler}
          onSubmitHandler={onSubmitHandler}
        />
      )}
      {currentScreen === ColumnEditorScreen.UPDATE && (
        <UpdateScreen
          columnDataType={columnDataType}
          question={question}
          options={options}
          columnHeaderName={headerName}
          backingReviewColumn={colDef.backingReviewColumn}
          isDefaultColumnDef={isDefaultColumnDef}
          isSubmitDisabled={isSubmitDisabled}
          isDirty={isDirty}
          isUpdatingColumn={isUpdatingColumn}
          columnDataTypeSelectContentRef={columnDataTypeSelectContentRef}
          columnEditorRef={ref}
          onQuestionChangeHandler={onQuestionChangeHandler}
          onHeaderNameChangeHandler={onHeaderNameChangeHandler}
          onColumnDataTypeChangeHandler={onColumnDataTypeChangeHandler}
          onOptionsChangeHandler={onOptionsChangeHandler}
          onCancelHandler={onCancelHandler}
          onDeleteColumnHandler={onDeleteColumnHandler}
          onSubmitHandler={onSubmitHandler}
        />
      )}
    </div>
  )
}

const InputScreen = ({
  question,
  columnEditorRef,
  onQuestionChangeHandler,
  onContinueHandler,
  onCancelHandler,
}: {
  question: string
  columnEditorRef: React.RefObject<HTMLDivElement>
  onQuestionChangeHandler: (value: string) => void
  onContinueHandler: () => void
  onCancelHandler: () => void
}) => {
  const isContinueDisabled = question.length === 0

  useCommandEnter(onContinueHandler, isContinueDisabled, columnEditorRef)

  return (
    <div className="flex flex-col justify-between gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <p className="text-sm font-semibold">Create column</p>
          <p className="text-xs">Get started by asking Harvey a question.</p>
        </div>
        <Textarea
          required
          className="h-28 resize-none text-xs *:text-xs placeholder:text-xs"
          placeholder="What is the signing date of this agreement?"
          value={question}
          onChange={(e) => {
            e.stopPropagation()
            onQuestionChangeHandler(e.target.value)
          }}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </div>
      <div className="-mx-4 border-b" />
      <div className="flex w-full justify-end gap-2">
        <Button
          variant="outline"
          size="sm"
          onClick={onCancelHandler}
          id="column-editor-cancel-button"
        >
          Cancel
        </Button>
        <Button
          size="sm"
          onClick={onContinueHandler}
          disabled={isContinueDisabled}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}

const useVaultColumnGenerationTracking = (
  isFetchingSuggestedReviewColumn: boolean
) => {
  const hasRecordedVaultColumnGeneration = useRef(false)
  useEffect(() => {
    if (
      !hasRecordedVaultColumnGeneration.current &&
      !isFetchingSuggestedReviewColumn
    ) {
      datadogRum.stopDurationVital('vaultColumnGeneration')
      hasRecordedVaultColumnGeneration.current = true
    }
  }, [isFetchingSuggestedReviewColumn])
}

const ConfirmationScreen = ({
  columnDataType,
  columnHeaderName,
  options,
  columnDataTypeSelectContentRef,
  isFetchingSuggestedReviewColumn,
  isSubmitting,
  columnEditorRef,
  onColumnDataTypeChangeHandler,
  onHeaderNameChangeHandler,
  onOptionsChangeHandler,
  onCancelHandler,
  onBackHandler,
  onSubmitHandler,
}: {
  columnDataType: ColumnDataType
  columnHeaderName: string
  options: string
  columnDataTypeSelectContentRef: React.RefObject<HTMLDivElement>
  isFetchingSuggestedReviewColumn: boolean
  isSubmitting: boolean
  columnEditorRef: React.RefObject<HTMLDivElement>
  onColumnDataTypeChangeHandler: (value: ColumnDataType) => void
  onHeaderNameChangeHandler: (value: string) => void
  onOptionsChangeHandler: (value: string) => void
  onCancelHandler: () => void
  onBackHandler: () => void
  onSubmitHandler: () => void
}) => {
  const [columnDataTypeOpen, setColumnDataTypeOpen] = useState<boolean>(false)
  const [hoveredColumnDataType, setHoveredColumnDataType] =
    useState<ColumnDataType | null>(null)

  const missingRequiredFields =
    columnHeaderName.length === 0 ||
    (columnDataType === ColumnDataType.classify && options.length === 0)

  const isSubmitDisabled =
    isFetchingSuggestedReviewColumn || missingRequiredFields

  const submitButtonTooltip = useMemo(() => {
    if (missingRequiredFields) {
      return 'Please fill in all required fields.'
    }
    if (isFetchingSuggestedReviewColumn) {
      return 'Harvey is generating the column, please wait.'
    }
    return undefined
  }, [missingRequiredFields, isFetchingSuggestedReviewColumn])

  useCommandEnter(onSubmitHandler, isSubmitDisabled, columnEditorRef)

  useVaultColumnGenerationTracking(isFetchingSuggestedReviewColumn)

  return (
    <div className="flex flex-col justify-between gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <p className="text-sm font-semibold">Confirm column properties</p>
          <p className="text-xs">
            Based on your question, Harvey has suggested the column header and
            type.
          </p>
        </div>
        <ColumnHeaderNameEditor
          headerName={columnHeaderName}
          isLoading={isFetchingSuggestedReviewColumn}
          onHeaderNameChangeHandler={onHeaderNameChangeHandler}
          shouldAutofocus
        />
        <ColumnDataTypeEditor
          columnDataType={columnDataType}
          isEditingTypeAndQuestionDisabled={false}
          columnDataTypeOpen={columnDataTypeOpen}
          hoveredColumnDataType={hoveredColumnDataType}
          selectContentRef={columnDataTypeSelectContentRef}
          isLoading={isFetchingSuggestedReviewColumn}
          setHoveredColumnDataType={setHoveredColumnDataType}
          setColumnDataTypeOpen={setColumnDataTypeOpen}
          onColumnDataTypeChangeHandler={onColumnDataTypeChangeHandler}
        />
        <AdditionalOptionsEditor
          columnDataType={columnDataType}
          options={options}
          isLoading={isFetchingSuggestedReviewColumn}
          onOptionsChangeHandler={onOptionsChangeHandler}
          isEditingTypeAndQuestionDisabled={false}
        />
      </div>
      <div className="-mx-4 border-b" />
      <div className="flex justify-between">
        <Button variant="outline" size="sm" onClick={onBackHandler}>
          Back
        </Button>
        <div className="flex w-full justify-end gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={onCancelHandler}
            id="column-editor-cancel-button"
          >
            Cancel
          </Button>
          <Button
            size="sm"
            onClick={onSubmitHandler}
            disabled={isSubmitDisabled}
            isLoading={isSubmitting}
            tooltip={submitButtonTooltip}
          >
            Add column
          </Button>
        </div>
      </div>
    </div>
  )
}

const UpdateScreen = ({
  columnDataType,
  question,
  columnHeaderName,
  options,
  backingReviewColumn,
  columnDataTypeSelectContentRef,
  isDefaultColumnDef,
  isSubmitDisabled,
  isDirty,
  isUpdatingColumn,
  columnEditorRef,
  onQuestionChangeHandler,
  onHeaderNameChangeHandler,
  onColumnDataTypeChangeHandler,
  onOptionsChangeHandler,
  onCancelHandler,
  onDeleteColumnHandler,
  onSubmitHandler,
}: {
  columnDataType: ColumnDataType
  question: string
  columnHeaderName: string
  options: string
  backingReviewColumn: ReviewColumn | undefined
  columnDataTypeSelectContentRef: React.RefObject<HTMLDivElement>
  isDefaultColumnDef: boolean
  isSubmitDisabled: boolean
  isDirty: boolean
  isUpdatingColumn: boolean
  columnEditorRef: React.RefObject<HTMLDivElement>
  onQuestionChangeHandler: (value: string) => void
  onHeaderNameChangeHandler: (value: string) => void
  onColumnDataTypeChangeHandler: (value: ColumnDataType) => void
  onOptionsChangeHandler: (value: string) => void
  onCancelHandler: () => void
  onDeleteColumnHandler: () => void
  onSubmitHandler: () => void
}) => {
  const [columnDataTypeOpen, setColumnDataTypeOpen] = useState<boolean>(false)
  const [hoveredColumnDataType, setHoveredColumnDataType] =
    useState<ColumnDataType | null>(null)
  const isColumnBackedByWorkflow = !!backingReviewColumn?.parentWorkflowColumnId

  useCommandEnter(
    onSubmitHandler,
    isSubmitDisabled && !isDirty,
    columnEditorRef
  )

  return (
    <div className="flex flex-col gap-4">
      {isColumnBackedByWorkflow && (
        <div className="-mx-4 -mb-2 -mt-4 flex items-center gap-2 bg-button-secondary px-4 py-2">
          <Icon icon={Info} />
          <p className="text-xs">
            This column was generated by a workflow and can’t be edited. Add a
            new column if you’d like to customize the data.
          </p>
        </div>
      )}
      <div className="flex flex-col gap-2">
        <Label className="flex items-center gap-1 text-xs font-normal text-muted">
          Question
        </Label>
        <Textarea
          required
          className="h-28 resize-none text-xs *:text-xs placeholder:text-xs"
          placeholder={getPlaceholderForDataType(columnDataType)}
          value={question}
          onChange={(e) => {
            e.stopPropagation()
            onQuestionChangeHandler(e.target.value)
          }}
          disabled={isColumnBackedByWorkflow}
        />
      </div>
      <ColumnDataTypeEditor
        columnDataType={columnDataType}
        isEditingTypeAndQuestionDisabled={isColumnBackedByWorkflow}
        columnDataTypeOpen={columnDataTypeOpen}
        hoveredColumnDataType={hoveredColumnDataType}
        selectContentRef={columnDataTypeSelectContentRef}
        isLoading={false}
        setHoveredColumnDataType={setHoveredColumnDataType}
        setColumnDataTypeOpen={setColumnDataTypeOpen}
        onColumnDataTypeChangeHandler={onColumnDataTypeChangeHandler}
      />
      <AdditionalOptionsEditor
        columnDataType={columnDataType}
        options={options}
        isLoading={false}
        onOptionsChangeHandler={onOptionsChangeHandler}
        isEditingTypeAndQuestionDisabled={isColumnBackedByWorkflow}
      />
      <ColumnHeaderNameEditor
        headerName={columnHeaderName}
        isLoading={false}
        onHeaderNameChangeHandler={onHeaderNameChangeHandler}
      />
      <div className="-mx-4 mt-2 border-b" />
      <div className="flex w-full justify-between gap-2">
        <Button
          variant="outline"
          size="sm"
          className="gap-1 text-destructive"
          disabled={isDefaultColumnDef}
          onClick={onDeleteColumnHandler}
        >
          <Icon icon={Trash} size="small" />
          <p className="text-xs">Delete</p>
        </Button>
        <div className="flex gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={onCancelHandler}
            onMouseDown={onCancelHandler}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmitHandler}
            disabled={isSubmitDisabled || !isDirty}
            isLoading={isUpdatingColumn}
            size="sm"
          >
            {isDefaultColumnDef ? 'Add column' : 'Rerun'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export const ColumnDataTypeEditor = ({
  columnDataType,
  isEditingTypeAndQuestionDisabled,
  columnDataTypeOpen,
  hoveredColumnDataType,
  selectContentRef,
  isLoading,
  shouldShowLabel = true,
  setColumnDataTypeOpen,
  onColumnDataTypeChangeHandler,
  setHoveredColumnDataType,
}: {
  columnDataType: ColumnDataType
  isEditingTypeAndQuestionDisabled: boolean
  columnDataTypeOpen: boolean
  hoveredColumnDataType: ColumnDataType | null
  selectContentRef: React.RefObject<HTMLDivElement>
  isLoading: boolean
  shouldShowLabel?: boolean
  setHoveredColumnDataType: (value: ColumnDataType | null) => void
  setColumnDataTypeOpen: (value: boolean) => void
  onColumnDataTypeChangeHandler: (value: ColumnDataType) => void
}) => {
  return (
    <div className="flex flex-col gap-2">
      {shouldShowLabel && (
        <Label className="flex items-center gap-1 text-xs font-normal text-muted">
          <p className="text-xs font-normal">Type</p>
          <Tooltip delayDuration={0}>
            <TooltipTrigger>
              <Icon icon={Info} size="small" className="text-muted" />
            </TooltipTrigger>
            <TooltipContent className="w-60" align="center">
              <p className="text-xs">
                The type of the column helps Harvey focus on specific
                information in your documents, such as dates, currencies, or
                legal clauses. Each cell in the column will be formatted
                according to its type.
              </p>
            </TooltipContent>
          </Tooltip>
        </Label>
      )}
      {isLoading && (
        <div className="py-2">
          <SkeletonBlock className="h-4 w-36" />
        </div>
      )}
      {!isLoading && (
        <Select
          disabled={isEditingTypeAndQuestionDisabled}
          open={columnDataTypeOpen}
          onOpenChange={setColumnDataTypeOpen}
          value={
            COLUMN_DATA_TYPES_NOT_TO_BE_USED_FOR_QUESTION.includes(
              columnDataType
            )
              ? ColumnDataType.freeResponse
              : columnDataType
          }
          onValueChange={(value) => {
            onColumnDataTypeChangeHandler(value as ColumnDataType)
          }}
        >
          <SelectTrigger className="h-8 w-full text-nowrap *:text-xs">
            <SelectValue
              placeholder="Free response"
              className="text-xs text-primary"
            />
          </SelectTrigger>
          <SelectContent ref={selectContentRef} className="overflow-visible">
            {Object.values(ColumnDataType)
              .filter(
                (value) =>
                  !COLUMN_DATA_TYPES_NOT_TO_BE_USED_FOR_QUESTION.includes(value)
              )
              .map((value) => (
                <HoverCard
                  open={hoveredColumnDataType === value}
                  key={`${value}-hovercard`}
                >
                  <HoverCardTrigger>
                    <SelectItem
                      key={value}
                      value={value}
                      onClick={(e) => {
                        e.stopPropagation()
                        setColumnDataTypeOpen(false)
                      }}
                      onMouseEnter={() => {
                        setHoveredColumnDataType(value)
                      }}
                      onMouseLeave={() => {
                        setHoveredColumnDataType(null)
                      }}
                      className="overflow-visible"
                      fontSize="xs"
                    >
                      <div className="flex items-center gap-2 text-xs">
                        <Icon icon={getIconForDataType(value)} size="small" />
                        {getDisplayDataType(value)}
                      </div>
                    </SelectItem>
                  </HoverCardTrigger>
                  <HoverCardContent
                    side="left"
                    align="start"
                    sideOffset={8}
                    className="flex min-h-fit w-72 flex-col gap-1"
                  >
                    <img
                      src={getIllustrationForDataType(value)}
                      alt="Table illustration"
                      className="pointer-events-none select-none"
                    />
                    <p className="text-xs font-semibold">
                      {getDisplayDataType(value ?? ColumnDataType.freeResponse)}
                    </p>
                    <p className="text-xs text-muted">
                      {getDescriptionForDataType(
                        value ?? ColumnDataType.freeResponse
                      )}
                    </p>
                  </HoverCardContent>
                </HoverCard>
              ))}
          </SelectContent>
        </Select>
      )}
    </div>
  )
}

export const ColumnHeaderNameEditor = ({
  headerName,
  isLoading,
  shouldShowLabel = true,
  shouldAutofocus = false,
  onHeaderNameChangeHandler,
}: {
  headerName: string
  isLoading: boolean
  shouldShowLabel?: boolean
  shouldAutofocus?: boolean
  onHeaderNameChangeHandler: (value: string) => void
}) => {
  return (
    <div className="flex flex-col gap-2">
      {shouldShowLabel && (
        <Label className="text-xs font-normal text-muted placeholder:text-xs">
          Label
        </Label>
      )}
      {isLoading && (
        <div className="py-2">
          <SkeletonBlock className="h-4 w-56" />
        </div>
      )}
      {!isLoading && (
        <Input
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={headerName.length === 0 || shouldAutofocus}
          required
          className="h-8 text-xs placeholder:text-xs"
          placeholder="Signing Date"
          value={headerName}
          onChange={(e) => {
            e.stopPropagation()
            onHeaderNameChangeHandler(e.target.value)
          }}
        />
      )}
    </div>
  )
}

export const AdditionalOptionsEditor = ({
  columnDataType,
  options,
  isLoading,
  onOptionsChangeHandler,
  isEditingTypeAndQuestionDisabled,
}: {
  columnDataType: ColumnDataType
  options: string
  isLoading: boolean
  onOptionsChangeHandler: (options: string) => void
  isEditingTypeAndQuestionDisabled: boolean
}) => {
  if (columnDataType !== ColumnDataType.classify) {
    return null
  }

  return (
    <div className="flex flex-col gap-2">
      <Label className="flex items-center gap-1 text-xs font-normal">
        <p className="text-xs font-normal text-muted">Classify options</p>
        <Tooltip>
          <TooltipTrigger>
            <Icon icon={Info} size="small" className="text-muted" />
          </TooltipTrigger>
          <TooltipContent>
            You can enter options below, like “Yes” and “No”
          </TooltipContent>
        </Tooltip>
      </Label>
      {isLoading && (
        <div className="py-2">
          <SkeletonBlock className="h-4 w-56" />
        </div>
      )}
      {!isLoading && (
        <TagInput
          placeholder="Type an option and press Enter"
          sortedTags={[]}
          selectedTagValues={options.length > 0 ? options.split(',') : []}
          shouldShowSuggestedTags={false}
          allowCreatingNewTags
          setSelectedTagValues={(values) => {
            onOptionsChangeHandler(values.join(','))
          }}
          disabled={isEditingTypeAndQuestionDisabled}
        />
      )}
    </div>
  )
}

export default VaultColumnEditor

import React from 'react'

import { FileSearchIcon } from 'lucide-react'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

interface ExampleCardItem {
  id: string
  name: string
  slug: string
}

interface ExampleCardListProps {
  title: string
  items: ExampleCardItem[]
}

const ExampleCardItem = ({ id, name, slug }: ExampleCardItem) => {
  return (
    <li className="h-full list-none" key={id}>
      <Button
        variant="unstyled"
        size="stretch"
        className="flex items-start justify-start gap-2 rounded-sm border p-3 text-left transition hover:bg-secondary-hover"
        to={`/assistant/workflows/${slug}`}
      >
        <Icon icon={FileSearchIcon} />
        <p className="-mt-px line-clamp-2 h-10 text-sm">{name}</p>
      </Button>
    </li>
  )
}

const PlaceholderCardItem = ({ index }: { index: number }) => {
  return (
    <li className="h-full list-none" key={`placeholder-${index}`}>
      <div
        className="flex cursor-not-allowed items-start justify-start gap-2 rounded-sm border border-dashed p-3
        text-left opacity-50"
        aria-disabled="true"
      >
        <Icon icon={FileSearchIcon} />
        <p className="-mt-px line-clamp-2 h-10 text-sm">Coming soon</p>
      </div>
    </li>
  )
}

const ExampleCardList = ({ title, items }: ExampleCardListProps) => {
  if (!items.length) return null

  const itemsSlice = items.slice(0, 4)
  const itemCount = itemsSlice.length
  const placeholdersNeeded = itemCount < 4 ? 4 - itemCount : 0

  return (
    <div>
      {title && <h3 className="mb-3 text-sm font-medium">{title}</h3>}

      <ul className="grid grid-cols-4 gap-4">
        {itemsSlice.map((item) => (
          <ExampleCardItem key={item.id} {...item} />
        ))}

        {/* Render placeholder cards if needed */}
        {Array.from({ length: placeholdersNeeded }).map((_, idx) => (
          <PlaceholderCardItem key={idx} index={idx} />
        ))}
      </ul>
    </div>
  )
}

export default ExampleCardList

/* tslint:disable */
/* eslint-disable */
/**
 * Transcripts API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FileUploadSource = {
    COMPUTER: 'computer',
    SHAREPOINT: 'sharepoint',
    GOOGLE_DRIVE: 'google_drive',
    IMANAGE: 'imanage',
    NETDOCS_EXTENSION: 'netdocs_extension'
} as const;
export type FileUploadSource = typeof FileUploadSource[keyof typeof FileUploadSource];


export function instanceOfFileUploadSource(value: any): boolean {
    for (const key in FileUploadSource) {
        if (Object.prototype.hasOwnProperty.call(FileUploadSource, key)) {
            if (FileUploadSource[key as keyof typeof FileUploadSource] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FileUploadSourceFromJSON(json: any): FileUploadSource {
    return FileUploadSourceFromJSONTyped(json, false);
}

export function FileUploadSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileUploadSource {
    return json as FileUploadSource;
}

export function FileUploadSourceToJSON(value?: FileUploadSource | null): any {
    return value as any;
}

export function FileUploadSourceToJSONTyped(value: any, ignoreDiscriminator: boolean): FileUploadSource {
    return value as FileUploadSource;
}


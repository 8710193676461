import React from 'react'
import { useParams } from 'react-router-dom'

import { RefreshCw, WaypointsIcon } from 'lucide-react'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

export const CompletedWorkflowFooter = () => {
  const navigate = useNavigateWithQueryParams()
  const { id: workflowId } = useParams()
  const reset = useAssistantWorkflowStore((state) => state.reset)

  const onClickRerun = () => {
    reset()
    navigate(`/assistant/workflows/${workflowId}`)
  }

  const onClickChooseAnother = () => {
    navigate('/assistant')
  }

  return (
    <div className="flex justify-center space-x-4 border-t-2 py-4">
      <Button variant="outline" onClick={onClickChooseAnother}>
        <Icon icon={WaypointsIcon} size="small" className="mr-1 text-muted" />
        Choose another workflow
      </Button>
      <Button variant="outline" onClick={onClickRerun}>
        <Icon icon={RefreshCw} size="small" className="mr-1 text-muted" />
        Rerun this workflow
      </Button>
    </div>
  )
}

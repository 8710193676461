/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BetaPermission = {
    USEASSISTANT_LOCALIZATION: 'use:assistant_localization',
    USEDISCOVER_TAB: 'use:discover_tab',
    USEDOC_PROCESSING_RPC: 'use:doc_processing_rpc',
    USEASSISTANT_COMPOSER: 'use:assistant_composer',
    USEVAULT_WORKFLOW_BROWSER: 'use:vault_workflow_browser',
    CREATECALL_SUMMARIZATION: 'create:call_summarization',
    USEDIARIZED_TRANSCRIPTION: 'use:diarized_transcription',
    USELANCE_ENTERPRISE_VAULT: 'use:lance_enterprise_vault',
    CREATEVAULT_WORKFLOW_REPS_WARRANTIES: 'create:vault_workflow_reps_warranties',
    USEDISALLOWED_CLIENT_MATTERS: 'use:disallowed_client_matters',
    USEVAULT_PROJECT_CLIENT_MATTER: 'use:vault_project_client_matter',
    USENETDOCS_EXTENSION: 'use:netdocs_extension',
    USEIMANAGE_INTEGRATION: 'use:imanage_integration',
    CREATEPLAYBOOK_REVIEW: 'create:playbook_review',
    USEDEALS_DISCOVERY_ASYNC_IMPL: 'use:deals_discovery_async_impl',
    USEDEALS_DISCOVERY_FROM_VAULT: 'use:deals_discovery_from_vault',
    CREATEUSER_PROFILES: 'create:user_profiles',
    CREATEUSER_PROFILES_OPTIONAL: 'create:user_profiles_optional',
    CLIENT_ADMINMANAGE_ASSISTANT_WORKFLOWS: 'client_admin:manage_assistant_workflows',
    VIEWLAST_ACTIVITY_DATE: 'view:last_activity_date'
} as const;
export type BetaPermission = typeof BetaPermission[keyof typeof BetaPermission];


export function instanceOfBetaPermission(value: any): boolean {
    for (const key in BetaPermission) {
        if (Object.prototype.hasOwnProperty.call(BetaPermission, key)) {
            if (BetaPermission[key as keyof typeof BetaPermission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function BetaPermissionFromJSON(json: any): BetaPermission {
    return BetaPermissionFromJSONTyped(json, false);
}

export function BetaPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BetaPermission {
    return json as BetaPermission;
}

export function BetaPermissionToJSON(value?: BetaPermission | null): any {
    return value as any;
}

export function BetaPermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): BetaPermission {
    return value as BetaPermission;
}


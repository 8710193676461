import React, { useMemo, useRef } from 'react'

import { convertMillimetersToTwip } from 'docx'
import { DownloadIcon } from 'lucide-react'

import { SocketMessageResponseAntitrustFilingsAllOfMetadata } from 'openapi/models/SocketMessageResponseAntitrustFilingsAllOfMetadata'

import { exportWordWithSections } from 'utils/markdown'
import { Source, TaskType } from 'utils/task'
import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

import CountryCard, { computeStatus } from './country-card'
import CountryDetailCard from './country-detail-card'
import { RequestForInformationComponent } from './request-for-information'

interface SummaryProps {
  className?: string
  data: SocketMessageResponseAntitrustFilingsAllOfMetadata
  handleSourceClick: (source: Source) => void
}

const getCountryCodesSortedByCountryName = (
  data: SummaryProps['data']['countryCodeToAnalysis']
) => {
  return Object.entries(data)
    .sort((a, b) => a[1].countryName.localeCompare(b[1].countryName))
    .map(([countryCode]) => countryCode)
}

const getCountriesWithAllThresholdsMet = (
  sortedCountryCodes: string[],
  data: SummaryProps['data']['countryCodeToAnalysis']
) => {
  return sortedCountryCodes.filter((countryCode) => {
    return computeStatus(data[countryCode]).includes('likely met')
  })
}

const getCountriesWithAllThresholdsNotMet = (
  sortedCountryCodes: string[],
  data: SummaryProps['data']['countryCodeToAnalysis']
) => {
  return sortedCountryCodes.filter((countryCode) => {
    return computeStatus(data[countryCode]) === 'Thresholds likely not met'
  })
}

const getCountriesWithMoreInfoRequired = (
  sortedCountryCodes: string[],
  data: SummaryProps['data']['countryCodeToAnalysis']
) => {
  return sortedCountryCodes.filter((countryCode) => {
    return computeStatus(data[countryCode]) === 'More information required'
  })
}

const Summary: React.FC<SummaryProps> = ({
  className,
  data,
  handleSourceClick,
}) => {
  const [selectedCountry, setSelectedCountry] = React.useState<string | null>(
    null
  )

  const {
    countryCodeToAnalysis,
    worldwideTargetRevenue,
    worldwideAcquirerRevenue,
  } = data

  const sortedCountryCodes = useMemo(
    () => getCountryCodesSortedByCountryName(countryCodeToAnalysis),
    [countryCodeToAnalysis]
  )

  const countriesWithMoreInfoRequired = useMemo(
    () =>
      getCountriesWithMoreInfoRequired(
        sortedCountryCodes,
        countryCodeToAnalysis
      ),
    [sortedCountryCodes, countryCodeToAnalysis]
  )

  const [acquirersWithMoreInfoRequired, targetsWithMoreInfoRequired] =
    useMemo(() => {
      const acquirersWithMoreInfoRequired = countriesWithMoreInfoRequired
        .map((countryCode) => countryCodeToAnalysis[countryCode])
        .filter((c) => c.requestsForInformation.acquirer.length > 0)

      const targetsWithMoreInfoRequired = countriesWithMoreInfoRequired
        .map((countryCode) => countryCodeToAnalysis[countryCode])
        .filter((c) => c.requestsForInformation.target.length > 0)

      return [acquirersWithMoreInfoRequired, targetsWithMoreInfoRequired]
    }, [countriesWithMoreInfoRequired, countryCodeToAnalysis])

  const rfiRef = useRef<HTMLDivElement>(null)

  const handleRfiDownload = async () => {
    await exportWordWithSections({
      defaultStyleOverrides: {
        heading1: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(5),
            },
          },
        },
        heading2: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(2),
            },
          },
        },
        heading3: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(2),
            },
          },
        },
      },
      filePrefixOverride: 'ANTITRUST_RFI',
      includeAnnotation: false,
      queryId: '0',
      sections: [
        {
          content: rfiRef.current?.innerHTML ?? '',
          type: 'html',
        },
      ],
      taskType: TaskType.WORD_DOCUMENT,
      title: 'Antitrust Filing Analysis - RFI',
    })
  }

  return (
    <div>
      <div className="mt-2 space-y-1">
        {getCountriesWithAllThresholdsMet(
          sortedCountryCodes,
          countryCodeToAnalysis
        ).length > 0 && (
          <div>
            <p className="font-medium">Thresholds likely met</p>
            <p>
              {getCountriesWithAllThresholdsMet(
                sortedCountryCodes,
                countryCodeToAnalysis
              )
                .map(
                  (countryCode) =>
                    countryCodeToAnalysis[countryCode].countryName
                )
                .join(', ')}
            </p>
          </div>
        )}

        {getCountriesWithAllThresholdsNotMet(
          sortedCountryCodes,
          countryCodeToAnalysis
        ).length > 0 && (
          <div>
            <p className="font-medium">Thresholds likely not met</p>
            <p>
              {getCountriesWithAllThresholdsNotMet(
                sortedCountryCodes,
                countryCodeToAnalysis
              )
                .map(
                  (countryCode) =>
                    countryCodeToAnalysis[countryCode].countryName
                )
                .join(', ')}
            </p>
          </div>
        )}

        {countriesWithMoreInfoRequired.length > 0 && (
          <div>
            <p className="font-medium">More information required</p>
            <p>
              {countriesWithMoreInfoRequired
                .map(
                  (countryCode) =>
                    countryCodeToAnalysis[countryCode].countryName
                )
                .join(', ')}
            </p>
          </div>
        )}
      </div>
      <div className={cn('mt-4 w-full rounded border', className)}>
        <div className="border-b px-4 py-3">
          <div className="flex items-center justify-between">
            <p>Country Breakdown</p>
            <Button
              variant="outline"
              size="sm"
              onClick={() => setSelectedCountry(null)}
            >
              View All
            </Button>
          </div>
        </div>
        {!selectedCountry && (
          <div className="grid grid-cols-2 gap-4 px-4 py-3">
            {sortedCountryCodes.map((countryCode) => (
              <CountryCard
                key={countryCode}
                countryName={countryCode}
                analysis={countryCodeToAnalysis[countryCode]}
                setSelectedCountry={setSelectedCountry}
              />
            ))}
          </div>
        )}

        {selectedCountry && (
          <div className="w-full px-4 py-3">
            <CountryDetailCard
              countryName={selectedCountry}
              analysis={countryCodeToAnalysis[selectedCountry]}
              handleSourceClick={handleSourceClick}
              worldwideAcquirerRevenue={worldwideAcquirerRevenue}
              worldwideTargetRevenue={worldwideTargetRevenue}
            />
          </div>
        )}
      </div>
      {countriesWithMoreInfoRequired.length > 0 && (
        <div className="mt-4 space-y-4" ref={rfiRef}>
          <div className="flex items-center justify-between">
            <h1 className="text-lg">Requests for information</h1>
            <Button
              variant="secondary"
              size="smIcon"
              onClick={handleRfiDownload}
            >
              <Icon icon={DownloadIcon} />
            </Button>
          </div>
          <h2 className="text-lg font-semibold">Acquirer</h2>
          {acquirersWithMoreInfoRequired.length > 0
            ? acquirersWithMoreInfoRequired.map((a) => (
                <RequestForInformationComponent
                  key={a.countryName}
                  countryName={a.countryName}
                  requests={a.requestsForInformation.acquirer}
                />
              ))
            : 'None'}
          <h2 className="text-lg font-semibold">Target</h2>
          {targetsWithMoreInfoRequired.length > 0
            ? targetsWithMoreInfoRequired.map((a) => (
                <RequestForInformationComponent
                  key={a.countryName}
                  countryName={a.countryName}
                  requests={a.requestsForInformation.target}
                />
              ))
            : 'None'}
        </div>
      )}
    </div>
  )
}

export default Summary

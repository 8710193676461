import React, { useEffect, useState } from 'react'

import { UploadCloudIcon } from 'lucide-react'

import Icon from 'components/ui/icon/icon'

interface VaultUploadInsetProps {
  isDragActive: boolean
}

const VaultUploadInset = ({ isDragActive }: VaultUploadInsetProps) => {
  // Using useState to control the staged animations
  const [showBackground, setShowBackground] = useState(false)
  const [showContent, setShowContent] = useState(false)

  // Handle animation staging with useEffect
  useEffect(() => {
    if (isDragActive) {
      setShowBackground(true)
      const contentTimer = setTimeout(() => {
        setShowContent(true)
      }, 200) // Wait for background animation to complete
      return () => clearTimeout(contentTimer)
    } else {
      setShowContent(false)
      const backgroundTimer = setTimeout(() => {
        setShowBackground(false)
      }, 200) // Wait for content to fade out first
      return () => clearTimeout(backgroundTimer)
    }
  }, [isDragActive])

  if (!showBackground && !isDragActive) return null

  return (
    <div
      className={`absolute inset-0 z-50 flex items-center justify-center transition-all duration-200 ease-out
        ${
          showBackground ? 'bg-primary/80 backdrop-blur-sm' : 'bg-transparent'
        }`}
    >
      <div
        className={`flex flex-col items-center justify-center space-y-4 transition-opacity duration-200 ease-out
          ${showContent ? 'opacity-100' : 'opacity-0'}`}
      >
        <Icon icon={UploadCloudIcon} className="size-[64px] stroke-inactive" />
        <div className="flex flex-col items-center space-y-2 pt-6">
          <p className="text-xl font-semibold">Drop to upload</p>
          <p className="text-lg text-muted">
            Attach files to your Vault project
          </p>
        </div>
      </div>
    </div>
  )
}

export default VaultUploadInset

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import _ from 'lodash'
import { UploadCloudIcon } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { FileUploadSource } from 'openapi/models/FileUploadSource'
import { IntegrationType } from 'openapi/models/IntegrationType'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { useIntegrationsStore } from 'stores/integrations-store'
import { FileType, FileTypeToExtension } from 'types/file'

import { NETDOCS_INPUT_CONTAINER_CLASSNAME } from 'hooks/use-netdocs-input'
import { onDrop } from 'utils/dropzone'
import { mbToBytes } from 'utils/file-utils'
import { cn } from 'utils/utils'

import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { useAssistantFileUpload } from 'components/assistant/hooks/use-assistant-file-upload'
import {
  FileUploadingState,
  useAssistantStore,
} from 'components/assistant/stores/assistant-store'
import {
  ACCEPTED_FILE_TYPES,
  MAX_FILE_SIZE,
  MAX_TOTAL_FILE_SIZE,
  MAX_ZIP_FILE_SIZE,
  NUM_MAX_FILES,
} from 'components/assistant/utils/constants'
import AddMoreFiles from 'components/common/add-more-files'
import { useAuthUser } from 'components/common/auth-context'
import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import GoogleDriveButton from 'components/common/google-drive-button'
import SharepointButton from 'components/common/sharepoint-button'
import { IntegrationDefinitions } from 'components/settings/integrations/integration-definitions'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'

import AssistantFiles from './assistant-files'
import AssistantInputSourceHeader from './assistant-input-source-header'
import { AssistantMode } from './assistant-mode-select'

type Props = {
  dropzoneSources?: (JSX.Element | null)[]
  hasFilesHeader: boolean
  mode: AssistantMode
  onUpload: () => void
  onCancel: () => void
  setAskHarveyDisabled: (disabled: boolean) => void
}

const AssistantFilesInput: React.FC<Props> = ({
  dropzoneSources,
  hasFilesHeader,
  mode,
  onUpload,
  onCancel,
  setAskHarveyDisabled,
}) => {
  const authUser = useAuthUser()
  const { handleFileUpload, handleRemoveFile, handleRemoveAllFiles } =
    useAssistantFileUpload(mode)
  const trackEvent = useAssistantAnalytics()

  const documents = useAssistantStore((s) => s.documents)
  const documentsUploading = useAssistantStore((s) => s.documentsUploading)

  const [zipFiles, setZipFiles] = useState<File[]>([])
  const [pstFiles, setPstFiles] = useState<File[]>([])
  const hasUploadedFiles =
    documents.length > 0 ||
    documentsUploading.length > 0 ||
    zipFiles.length > 0 ||
    pstFiles.length > 0
  const filesAttachedCount = documents.length + documentsUploading.length

  const sharepointEnabled =
    IntegrationDefinitions[IntegrationType.SHAREPOINT].available(authUser)

  const googleDriveEnabled =
    IntegrationDefinitions[IntegrationType.GOOGLE_DRIVE].available(authUser)

  const hasNetdocsExperiment = authUser.hasNetdocsExperiment

  const handleFileUploadWithTracking = useCallback(
    async (files: File[], fileUploadSource: FileUploadSource) => {
      setZipFiles([])
      setPstFiles([])
      await handleFileUpload(files, fileUploadSource)
    },
    [handleFileUpload]
  )

  const handleRemoveFileWithTracking = (
    file: UploadedFile | FileUploadingState
  ) => {
    handleRemoveFile(file)
  }

  const prevUploadedFiles = useRef(!!hasUploadedFiles)
  useEffect(() => {
    if (prevUploadedFiles.current && !hasUploadedFiles) {
      onCancel()
    } else if (!prevUploadedFiles.current && hasUploadedFiles) {
      onUpload()
    }
    prevUploadedFiles.current = hasUploadedFiles
  }, [hasUploadedFiles, onCancel, onUpload])

  const onAssistantDrop = useCallback(
    async (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      fileUploadSource: FileUploadSource
    ) => {
      setAskHarveyDisabled(true)
      setZipFiles(acceptedFiles.filter((file) => file.type === FileType.ZIP))
      setPstFiles(
        acceptedFiles.filter(
          (file) =>
            file.type === FileType.OUTLOOK &&
            file.name.toLowerCase().endsWith('.pst')
        )
      )

      return onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: documents.length + documentsUploading.length,
        maxFiles: NUM_MAX_FILES,
        acceptedFileTypes: ACCEPTED_FILE_TYPES,
        maxFileSize: mbToBytes(MAX_FILE_SIZE),
        maxZipFileSize: mbToBytes(MAX_ZIP_FILE_SIZE),
        maxTotalFileSizeProps: {
          maxTotalFileSize: mbToBytes(MAX_TOTAL_FILE_SIZE),
          currentTotalFileSize: documents.reduce(
            (total, file) => total + (file.size ?? 0),
            0
          ),
        },
        handleAcceptedFiles: (files) =>
          handleFileUploadWithTracking(files, fileUploadSource),
      }).finally(() => {
        setAskHarveyDisabled(false)
        setZipFiles([])
        setPstFiles([])
      })
    },
    [
      handleFileUploadWithTracking,
      documents,
      documentsUploading,
      setAskHarveyDisabled,
    ]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) =>
      onAssistantDrop(acceptedFiles, fileRejections, FileUploadSource.COMPUTER),
    maxFiles: NUM_MAX_FILES,
    noClick: hasUploadedFiles,
  })

  const getRootPropsWithTracking = () => {
    const rootProps = getRootProps()
    return {
      ...rootProps,
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        rootProps.onClick?.(e)
        trackUploadFromComputer()
      },
    }
  }

  const onUploadFromIntegration = useCallback(
    async (files: File[], fileUploadSource: FileUploadSource) => {
      await onAssistantDrop(files, [], fileUploadSource)
    },
    [onAssistantDrop]
  )

  const trackUploadFromComputer = () => trackEvent('Upload Files Clicked')

  const onUploadFromComputer = () => {
    open()
    trackUploadFromComputer()
  }

  const handleNetdocsFileDrop = useCallback(
    async (files: File[]) => {
      await onAssistantDrop(files, [], FileUploadSource.NETDOCS_EXTENSION)
    },
    [onAssistantDrop]
  )

  const [setSharepointPickerOpenState, setIntegrationFilePickerOpenState] =
    useIntegrationsStore(
      useShallow((state) => [
        state.setSharepointPickerOpenState,
        state.setIntegrationFilePickerOpenState,
      ])
    )

  const handleIntegrationClick = (integrationType: IntegrationType) => {
    const acceptedFileExtensions = _.flatMap(
      ACCEPTED_FILE_TYPES,
      (type) => FileTypeToExtension[type] ?? []
    )
    if (integrationType === IntegrationType.SHAREPOINT) {
      setSharepointPickerOpenState({
        acceptedFileTypes: acceptedFileExtensions,
        onUploadFromSharepoint: (files) =>
          onUploadFromIntegration(
            files,
            IntegrationDefinitions[integrationType].fileUploadSource
          ),
      })
    } else {
      setIntegrationFilePickerOpenState({
        acceptedFileTypes: ACCEPTED_FILE_TYPES,
        onUploadFromIntegration: (files) =>
          onUploadFromIntegration(
            files,
            IntegrationDefinitions[integrationType].fileUploadSource
          ),
        integrationType,
        maxFileCount: NUM_MAX_FILES,
      })
    }
  }

  const localDropzoneSources = useMemo(() => {
    const result = [...(dropzoneSources ?? [])]
    if (sharepointEnabled) {
      result.push(
        <div
          className="flex w-52 space-x-2"
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          role="button"
          tabIndex={0}
          key="sharepoint-button"
        >
          <SharepointButton
            onUploadFromSharepoint={(files) =>
              onUploadFromIntegration(files, FileUploadSource.SHAREPOINT)
            }
            acceptedFileTypes={ACCEPTED_FILE_TYPES}
          />
        </div>
      )
    }

    if (googleDriveEnabled) {
      result.push(
        <div
          className="flex w-52 space-x-2"
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          role="button"
          tabIndex={0}
          key="google-drive-button"
        >
          <GoogleDriveButton
            onUploadFromGoogleDrive={(files) =>
              onUploadFromIntegration(files, FileUploadSource.GOOGLE_DRIVE)
            }
            acceptedFileTypes={ACCEPTED_FILE_TYPES}
            maxFileCount={NUM_MAX_FILES}
          />
        </div>
      )
    }

    return result
  }, [
    dropzoneSources,
    googleDriveEnabled,
    sharepointEnabled,
    onUploadFromIntegration,
  ])

  return (
    <div
      {...(hasUploadedFiles ? getRootProps() : {})}
      className="relative flex h-full flex-col pb-2"
    >
      {hasFilesHeader && (
        <p className="pb-2 text-xs font-medium text-secondary">Files</p>
      )}
      {hasUploadedFiles && (
        <AssistantInputSourceHeader
          actions={
            <>
              <Button size="sm" variant="ghost" onClick={handleRemoveAllFiles}>
                Cancel
              </Button>
              <AddMoreFiles
                onUploadFromComputer={onUploadFromComputer}
                onUploadFromIntegration={handleIntegrationClick}
                handleNetdocsFileDrop={
                  hasNetdocsExperiment ? handleNetdocsFileDrop : undefined
                }
              />
            </>
          }
        >
          {`${filesAttachedCount} ${pluralize(
            'file',
            filesAttachedCount
          )} attached`}
        </AssistantInputSourceHeader>
      )}
      {hasUploadedFiles && (
        <input className="hidden" {...getRootPropsWithTracking()} />
      )}
      {hasUploadedFiles && isDragActive && (
        <div className="absolute z-10 size-full bg-primary p-4">
          <div className="flex size-full flex-col items-center justify-center rounded border border-dashed">
            <Icon icon={UploadCloudIcon} className="mb-2" />
            <p className="font-semibold">Drop files here</p>
            <p className="text-sm">or click to upload</p>
          </div>
        </div>
      )}
      {hasUploadedFiles ? (
        <ScrollArea className="h-full">
          <AssistantFiles
            files={documents}
            uploadingFiles={documentsUploading}
            handleRemoveFile={handleRemoveFileWithTracking}
            zipFiles={zipFiles}
            pstFiles={pstFiles}
          />
        </ScrollArea>
      ) : (
        <Dropzone
          className={cn('h-full py-4', {
            'border-ring bg-secondary-hover': isDragActive,
            [NETDOCS_INPUT_CONTAINER_CLASSNAME]: hasNetdocsExperiment,
          })}
          hasNetdocsExperiment={hasNetdocsExperiment}
          isLoading={false}
          dropzone={{ getRootProps: getRootPropsWithTracking, getInputProps }}
          description={
            <DropzoneDescription
              fileTypes={ACCEPTED_FILE_TYPES}
              maxFiles={50}
              maxSize="20MB"
              totalSize="100MB"
              descriptionClassName="max-w-[440px]"
              className="max-w-[440px]"
            >
              {!!localDropzoneSources.length && (
                <div className="inline-flex flex-col items-center">
                  or
                  <div className="mt-2 flex flex-col items-center gap-2 whitespace-nowrap md:grid md:w-[440px] md:grid-cols-2 md:gap-y-2">
                    {localDropzoneSources.length === 1 ? (
                      <div className="md:col-span-2 md:flex md:justify-center">
                        {localDropzoneSources[0]}
                      </div>
                    ) : (
                      localDropzoneSources
                    )}
                  </div>
                </div>
              )}
            </DropzoneDescription>
          }
        />
      )}
    </div>
  )
}

export default AssistantFilesInput

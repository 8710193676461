import React from 'react'

import _ from 'lodash'

import { UserInfo } from 'models/user-info'
import { IntegrationType } from 'openapi/models/IntegrationType'

import IntegrationConnectionCard from './integration-connection-card'
import { IntegrationDefinitions } from './integration-definitions'
import { ConnectedIntegration } from './utils'

interface IntegrationConnectionProps {
  userInfo: UserInfo
  connections: ConnectedIntegration[]
}

const IntegrationConnection: React.FC<IntegrationConnectionProps> = ({
  userInfo,
  connections,
}) => {
  const availableIntegrations = userInfo.workspace.integrations
  const connectionsObj = React.useMemo(
    () => _.keyBy(connections, 'integrationType'),
    [connections]
  )
  if (_.isEmpty(availableIntegrations)) {
    return (
      <div className="flex h-1/2 translate-y-1/2 flex-col items-center space-y-4">
        <div className="text-center">
          <p className="text-muted">No integrations available to connect</p>
        </div>
      </div>
    )
  }

  return (
    <div>
      <h1 className="font-semibold">Integrations</h1>
      <p className="text-xs text-muted">
        Connect apps to access their data and information in Harvey
      </p>
      {Object.entries(IntegrationDefinitions).map(([key, definition]) => {
        if (!definition.available(userInfo)) {
          return null
        }
        const conn = connectionsObj[key]
        return (
          <IntegrationConnectionCard
            key={key}
            integrationType={key as IntegrationType}
            integrationDefinition={definition}
            connected={!_.isNil(conn)}
          />
        )
      })}
    </div>
  )
}

export default IntegrationConnection

import React, { useMemo, useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { Workspace } from 'models/workspace'
import { WorkspaceFeature } from 'openapi/models/WorkspaceFeature'

import { useAuthUser } from 'components/common/auth-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'

import VaultAddonDialog from './vault-add-on-dialog'
import VaultDisableDialog from './vault-disable-dialog'
import { useVaultManagementStore } from './vault-management-store'

interface VaultEnablementProps {
  workspace: Workspace
  fetchWorkspace: () => Promise<void>
}

const VaultEnablement = ({
  workspace,
  fetchWorkspace,
}: VaultEnablementProps) => {
  const userInfo = useAuthUser()
  const [vaultAddOnDialogOpen, setVaultAddOnDialogOpen] =
    useState<boolean>(false)
  const [vaultDisableDialogOpen, setVaultDisableDialogOpen] =
    useState<boolean>(false)

  const totalSeats = useVaultManagementStore(
    useShallow((state) => state.totalSeats)
  )
  const vaultAddOnUsers = useVaultManagementStore(
    useShallow((state) => state.vaultAddOnUsers)
  )
  const isUnlimitedSeats = useMemo(() => {
    return workspace.vaultUserCountIsUnlimited
  }, [workspace.vaultUserCountIsUnlimited])
  const remainingSeats = useMemo(() => {
    if (!workspace.vaultUsersCountLimit) {
      return undefined
    }
    return workspace.vaultUsersCountLimit - totalSeats
  }, [workspace.vaultUsersCountLimit, totalSeats])
  const remainingAddOnReviewFiles = useMemo(() => {
    if (!workspace.hasVaultAddOn) return undefined
    const workspaceLimit =
      workspace.vaultAddOnSettings
        .vault_review_files_count_limit_per_workspace_per_month
    const perUserLimit =
      workspace.vaultAddOnSettings
        .vault_review_files_count_limit_per_user_per_month
    if (
      !workspaceLimit ||
      !(perUserLimit || perUserLimit === 0) ||
      isUnlimitedSeats ||
      remainingSeats === undefined
    )
      return undefined
    const existingLimit = vaultAddOnUsers.reduce(
      (acc, user) => acc + user.reviewLimit,
      0
    )
    return workspaceLimit - remainingSeats * perUserLimit - existingLimit
  }, [
    workspace.hasVaultAddOn,
    workspace.vaultAddOnSettings,
    vaultAddOnUsers,
    isUnlimitedSeats,
    remainingSeats,
  ])

  const hasVaultAddOn = workspace.hasVaultAddOn

  return (
    <div>
      <div className="space-y-5 p-4">
        <div className="flex items-center justify-between gap-2">
          {hasVaultAddOn ? (
            <>
              <div className="flex items-center space-x-2">
                <p>Vault Add-On is enabled for {workspace.friendlyName}</p>
                <div className="flex flex-wrap gap-2">
                  <Badge variant="secondary">
                    {!isUnlimitedSeats
                      ? `Remaining seats: ${remainingSeats}`
                      : 'Unlimited seats'}
                  </Badge>
                  {(remainingAddOnReviewFiles ||
                    remainingAddOnReviewFiles === 0) && (
                    <Badge variant="secondary">
                      Remaining review query files to distribute:{' '}
                      {remainingAddOnReviewFiles}
                    </Badge>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap justify-end gap-2">
                <Button
                  onClick={() => setVaultAddOnDialogOpen(!vaultAddOnDialogOpen)}
                >
                  Edit
                </Button>
                <Button
                  variant="destructive"
                  disabled={!userInfo.IsInternalAdminWriter}
                  onClick={() => setVaultDisableDialogOpen(true)}
                  tooltipAlign="start"
                >
                  Disable
                </Button>
              </div>
            </>
          ) : (
            <>
              <p>Enable Vault Add-On for {workspace.friendlyName}</p>
              <Button
                onClick={() => setVaultAddOnDialogOpen(!vaultAddOnDialogOpen)}
              >
                Enable
              </Button>{' '}
            </>
          )}
        </div>
      </div>

      <VaultAddonDialog
        open={vaultAddOnDialogOpen}
        onOpenChange={setVaultAddOnDialogOpen}
        workspace={workspace}
        type={hasVaultAddOn ? 'edit' : 'add'}
        onEnable={() => fetchWorkspace()}
      />

      <VaultDisableDialog
        open={vaultDisableDialogOpen}
        onOpenChange={setVaultDisableDialogOpen}
        workspace={workspace}
        type={WorkspaceFeature.VAULT_ADD_ON}
        onDisable={() => fetchWorkspace()}
      />
    </div>
  )
}

export default VaultEnablement

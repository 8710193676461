import React from 'react'
import { useUnmount } from 'react-use'

import CreateProject from './components/new-project/vault-create-project'
import CreateProjectFooter from './components/new-project/vault-create-project-footer'
import { AppMain } from 'components/common/app-main'
import { ScrollArea } from 'components/ui/scroll-area'

import { useVaultCreateProjectStore } from './utils/vault-create-project-store'

const VaultNewProject = () => {
  const setIsSubmitting = useVaultCreateProjectStore((s) => s.setIsSubmitting)
  const setDroppedFiles = useVaultCreateProjectStore((s) => s.setDroppedFiles)
  const setFilesToUpload = useVaultCreateProjectStore((s) => s.setFilesToUpload)
  const setTotalFileSizeInBytes = useVaultCreateProjectStore(
    (s) => s.setTotalFileSizeInBytes
  )
  const setNewFolderName = useVaultCreateProjectStore((s) => s.setNewFolderName)

  const cancelHandler = () => {
    setIsSubmitting(false)
    setDroppedFiles([])
    setFilesToUpload([])
    setTotalFileSizeInBytes(0)
    setNewFolderName('')
  }

  useUnmount(() => {
    cancelHandler()
  })

  return (
    <AppMain className="flex flex-col">
      <ScrollArea className="grow h-full" isFullHeight>
        <CreateProject />
      </ScrollArea>
      <CreateProjectFooter cancelHandler={cancelHandler} />
    </AppMain>
  )
}
export default VaultNewProject

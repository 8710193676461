import * as React from 'react'

import { Button } from 'components/ui/button'

interface SourcesToolbarProps {
  handleCancel: () => void
  text: string
  icon?: React.ReactElement
  description?: string
}

const SourcesToolbar = ({
  handleCancel,
  text,
  icon,
  description,
}: SourcesToolbarProps) => {
  return (
    <div className="flex items-center justify-between border-t-[0.5px] px-4 py-[10px]">
      <div className="flex items-center gap-2">
        {icon &&
          React.cloneElement(icon, {
            className: 'h-[14px] w-[14px]',
          })}
        <p className="text-xs font-medium">{text}</p>
        {description && <p className="text-xs text-muted">{description}</p>}
      </div>
      <Button size="sm" variant="outline" onClick={handleCancel}>
        Cancel
      </Button>
    </div>
  )
}

export default SourcesToolbar

import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { useUnmount } from 'react-use'

import { AssistantWorkflowPage } from 'components/assistant/workflows/pages/assistant-workflow-page'
import { AssistantWorkflowsHomePage } from 'components/assistant/workflows/pages/assistant-workflows-home-page'
import Artifact from 'components/research/artifact/artifact-page'

import { useAssistantChatStreamHandler } from './hooks/use-assistant-chat'
import { useAssistantDraftStreamHandler } from './hooks/use-assistant-draft'
import AssistantHome from './pages/assistant-home-page'
import AssistantModeSwitcher from './pages/assistant-mode-switcher'
import { AssistantSharedIndex } from './pages/assistant-shared-page'
import AssistantV1Redirect from './pages/assistant-v1-redirect'

const AssistantIndex = () => {
  const useChat = useAssistantChatStreamHandler()
  const useDraft = useAssistantDraftStreamHandler()

  useUnmount(() => {
    useChat.sendCancelRequest()
    useDraft.sendCancelRequest()
  })

  return (
    <Routes>
      <Route
        path="/"
        element={<AssistantHome useChat={useChat} useDraft={useDraft} />}
      />
      <Route
        path=":mode/:eventId/:messageId?"
        element={
          <AssistantModeSwitcher useChat={useChat} useDraft={useDraft} />
        }
      />
      <Route path=":id" element={<AssistantV1Redirect />} />
      <Route path="/shared" element={<AssistantSharedIndex />} />
      <Route path="/artifact/:id" element={<Artifact basePath="assistant" />} />
      <Route path="/workflows" element={<AssistantWorkflowsHomePage />} />
      <Route
        path="/workflows/:id/:eventId?"
        element={<AssistantWorkflowPage />}
      />
    </Routes>
  )
}

export default AssistantIndex

import React, { useMemo } from 'react'

import { Table2 } from 'lucide-react'

import { Tag } from 'openapi/models/Tag'

import { cn } from 'utils/utils'

import { Card } from 'components/ui/card'
import Icon from 'components/ui/icon/icon'
import { SimpleDocumentClassificationPill } from 'components/vault/components/vault-document-classification'

import { WorkflowSource } from './workflows-helpers'

export enum WorkflowCategory {
  LEGACY = 'legacy',
  VAULT = 'vault',
  DRAFT = 'draft',
}

type BaseWorkflowInfo = {
  id: string
  name: string
  description: string
  category: WorkflowCategory
}

export type VaultWorkflowInfo = BaseWorkflowInfo & {
  category: WorkflowCategory.VAULT
  numColumns: number
  documentTag?: Tag
}

export type LegacyWorkflowInfo = BaseWorkflowInfo & {
  category: WorkflowCategory.LEGACY
  path: string
}

export type WorkflowInfo = VaultWorkflowInfo | LegacyWorkflowInfo

type WorkflowCardProps = {
  workflowInfo: WorkflowInfo
  source: WorkflowSource
  onClick: (workflowInfo: WorkflowInfo) => void
  className?: string
}

const WorkflowCard: React.FC<WorkflowCardProps> = ({
  workflowInfo,
  source,
  onClick,
  className,
}) => {
  return (
    <Card
      onClick={() => onClick(workflowInfo)}
      className={cn(
        'h-32 w-full items-center justify-center rounded-lg border-none bg-secondary p-4 transition hover:cursor-pointer hover:border-input-focused hover:bg-secondary-hover',
        className
      )}
    >
      <div className="flex size-full flex-col items-start justify-between gap-4">
        <div className="flex h-[72px] w-full flex-col overflow-hidden">
          <div className="line-clamp-2">
            <p className="text-sm font-medium">{workflowInfo.name}</p>
          </div>
          <div
            className={cn({
              'line-clamp-1': workflowInfo.name.length > 30,
              'line-clamp-2': workflowInfo.name.length <= 30,
            })}
          >
            <p className="text-xs text-muted">{workflowInfo.description}</p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <WorkflowCardFooter workflowInfo={workflowInfo} source={source} />
        </div>
        {/* TODO(stella): add Vault workflow card footer once design finalized */}
      </div>
    </Card>
  )
}

const WorkflowCardFooter = ({
  workflowInfo,
  source,
}: {
  workflowInfo: WorkflowInfo
  source: WorkflowSource
}) => {
  const shouldShowVaultDocumentTags =
    workflowInfo.category === WorkflowCategory.VAULT &&
    source === WorkflowSource.VAULT_PROJECT_PAGE &&
    workflowInfo.documentTag

  const workflowIcon = useMemo(() => {
    switch (workflowInfo.category) {
      case WorkflowCategory.VAULT:
        return Table2
      default:
        return null
    }
  }, [workflowInfo])

  const workflowTypeDisplay = useMemo(() => {
    switch (workflowInfo.category) {
      case WorkflowCategory.VAULT:
        return 'Table'
      default:
        return null
    }
  }, [workflowInfo])

  const workflowMetadata = useMemo(() => {
    switch (workflowInfo.category) {
      case WorkflowCategory.VAULT:
        return `${workflowInfo.numColumns} columns`
      default:
        return null
    }
  }, [workflowInfo])

  if (workflowInfo.category === WorkflowCategory.LEGACY) {
    return null
  }

  return (
    <div className="flex flex-row items-center gap-2">
      {shouldShowVaultDocumentTags ? (
        <SimpleDocumentClassificationPill
          isPill
          key={workflowInfo.documentTag!.id}
          tagName={workflowInfo.documentTag!.name}
          tagId={workflowInfo.documentTag!.id}
        />
      ) : (
        <div className="flex flex-row items-center gap-2">
          {workflowIcon && (
            <Icon className="stroke-muted" icon={workflowIcon} />
          )}
          <p className="text-xs text-muted">
            {workflowTypeDisplay} · {workflowMetadata}
          </p>
        </div>
      )}
    </div>
  )
}

export default WorkflowCard

import React from 'react'

import { SocketMessageResponseAntitrustFilingsAllOfMetadataCountryCodeToAnalysis } from 'openapi/models/SocketMessageResponseAntitrustFilingsAllOfMetadataCountryCodeToAnalysis'

import { Source } from 'utils/task'
import { cn } from 'utils/utils'

import { Accordion } from 'components/ui/accordion'

import { Citations } from './citations'
import { computeStatus } from './country-card'
import StatusBadge from './status-badge'
import ThresholdComponent from './threshold'

interface CountryCardProps {
  className?: string
  countryName: string
  analysis: SocketMessageResponseAntitrustFilingsAllOfMetadataCountryCodeToAnalysis
  handleSourceClick: (source: Source) => void
  worldwideAcquirerRevenue: number
  worldwideTargetRevenue: number
}

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value)
}

const TurnoverData: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <div className="flex items-center justify-between">
      <p className="text-xs text-muted">{label}</p>
      <p className="text-xs">{value}</p>
    </div>
  )
}

const CountryCard: React.FC<CountryCardProps> = ({
  className,
  analysis,
  handleSourceClick,
  worldwideAcquirerRevenue,
  worldwideTargetRevenue,
}) => {
  if (
    analysis.acquirerRevenue === undefined ||
    analysis.targetRevenue === undefined
  )
    return null
  const insightsParagraphs = analysis.additionalInsights.split(/\n+/)

  return (
    <div
      className={cn(
        'h-full w-full transform rounded bg-secondary px-4 py-3 text-left',
        className
      )}
    >
      <div className="flex items-center justify-between">
        <div className="flex">
          <p className="text-base">{analysis.countryName}</p>
          <Citations
            handleSourceClick={handleSourceClick}
            sources={analysis.sources as Source[]}
          />
        </div>
        <div className="flex items-center gap-2">
          <StatusBadge label={computeStatus(analysis)} />
        </div>
      </div>
      <div className="mt-4 flex space-x-4">
        <div>
          {insightsParagraphs.map((insightsParagraph, index) => (
            <p
              key={index}
              className={`text-xs ${
                index < insightsParagraphs.length - 1 ? 'mb-4' : ''
              }`}
            >
              {insightsParagraph}
            </p>
          ))}
        </div>
        <div className="flex h-fit w-1/3 shrink-0 flex-col gap-4">
          <div className="rounded bg-accent px-4 py-3">
            <p className="mb-1 text-xs">Local Turnover</p>
            <div className="space-y-1">
              <TurnoverData
                label="Acquirer"
                value={formatCurrency(analysis.acquirerRevenue)}
              />
              <TurnoverData
                label="Target"
                value={formatCurrency(analysis.targetRevenue)}
              />
              <TurnoverData
                label="Combined"
                value={formatCurrency(
                  analysis.acquirerRevenue + analysis.targetRevenue
                )}
              />
            </div>
          </div>
          <div className="rounded bg-accent px-4 py-3">
            <p className="mb-1 text-xs">Worldwide Turnover</p>
            <div className="space-y-1">
              <TurnoverData
                label="Acquirer"
                value={formatCurrency(worldwideAcquirerRevenue)}
              />
              <TurnoverData
                label="Target"
                value={formatCurrency(worldwideTargetRevenue)}
              />
              <TurnoverData
                label="Combined"
                value={formatCurrency(
                  worldwideAcquirerRevenue + worldwideTargetRevenue
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 w-full">
        <Accordion type="single" collapsible>
          {analysis.thresholds.map((threshold, idx) => (
            <ThresholdComponent
              key={threshold.name}
              idx={idx}
              thresholdName={threshold.name}
              analysis={analysis}
              handleSourceClick={handleSourceClick}
            />
          ))}
        </Accordion>
      </div>
    </div>
  )
}

export default CountryCard

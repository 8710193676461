import React from 'react'

import { WorkspaceRole } from 'models/roles'

import { MultiSelect } from 'components/ui/multi-select'

export const RolesMultiSelect: React.FC<{
  roles: WorkspaceRole[]
  selectedRolePks: string[]
  setSelectedRolePks: (selectedRolePks: string[]) => void
}> = ({ roles, selectedRolePks, setSelectedRolePks }) => {
  return (
    <MultiSelect
      placeholder="Role"
      sortedEntries={roles.map((role) => ({
        text: role.name,
        value: role.rolePk,
        values: [role.rolePk],
      }))}
      selectedValues={selectedRolePks}
      setSelectedValues={setSelectedRolePks}
    />
  )
}

import React, { useEffect, useState } from 'react'

import {
  MigrateWorkspaceToPermBundles,
  PermBundleMigrationResult,
} from 'models/perms'
import { FetchWorkspaces, Workspace } from 'models/workspace'
import { RequestError } from 'services/backend/backend'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import WorkspaceDropdown from 'components/common/workspace-dropdown'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Button } from 'components/ui/button'
import { Dialog } from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'

const MigrateWorkspaceToPermBundlesComponent = ({
  workspace,
}: {
  workspace?: Workspace
}) => {
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [selectedWorkspace, setSelectedWorkspace] = useState<
    Workspace | undefined
  >(workspace)
  const [lastResult, setLastResult] = useState<PermBundleMigrationResult>()
  const [dryRun, setDryRun] = useState<boolean>(true)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [migrationComplete, setMigrationComplete] = useState(false)

  const fetchWorkspaces = async () => {
    const workspaces = await FetchWorkspaces()
    setWorkspaces(workspaces)
  }

  useEffect(() => {
    if (!workspace) {
      void fetchWorkspaces()
    }
  }, [workspace])

  useEffect(() => {
    setDryRun(true)
    setLastResult(undefined)
  }, [selectedWorkspace?.id])

  const handleMigrate = async (dryRun: boolean = true) => {
    if (!selectedWorkspace) return
    try {
      const result = await MigrateWorkspaceToPermBundles(
        [selectedWorkspace.id],
        dryRun
      )
      setLastResult(result)
      setDryRun(false)
      displaySuccessMessage(
        dryRun
          ? 'Dry run successful - please confirm the logs before proceeding.'
          : 'Migration successful! Please confirm the logs again for what changes were applied.'
      )
      if (!dryRun) {
        setMigrationComplete(true)
      }
    } catch (e) {
      if (e instanceof RequestError) {
        displayErrorMessage(e.message)
      } else {
        displayErrorMessage('An unknown error occurred')
      }
    }
  }

  return (
    <>
      <Accordion
        type="single"
        collapsible
        className="rounded-md border"
        defaultValue={
          workspace ? 'migrate-workspace-to-perm-bundles' : undefined
        }
      >
        <AccordionItem value="migrate-workspace-to-perm-bundles">
          <AccordionTrigger className="p-4">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold">
                Migrate workspaces to perm bundles
              </h2>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="flex flex-col items-start gap-2 px-5">
              {workspace ? (
                <p className="text-sm">
                  We don&apos;t currently track whether this step of the
                  migration has been run. However, you may rerun the dry run
                  migration below to see whether any updates would be applied
                  (this step itself is an no-op and will not change any user
                  permissions).
                </p>
              ) : (
                <>
                  <p className="text-sm font-medium text-primary">
                    Choose a workspace to migrate.
                  </p>
                  <WorkspaceDropdown
                    workspaces={workspaces.filter((w) => !w.deletedAt)}
                    selected={selectedWorkspace}
                    setSelected={setSelectedWorkspace}
                  />
                </>
              )}
              {lastResult && (
                <ScrollArea maxHeight="max-h-96 ">
                  {Object.entries(lastResult).map(([workspaceId, logs]) => (
                    <div key={workspaceId}>
                      {logs.map((log: string) => (
                        <p key={log}>{log}</p>
                      ))}
                    </div>
                  ))}
                </ScrollArea>
              )}
              <Button
                disabled={migrationComplete}
                onClick={async () => {
                  if (dryRun) {
                    await handleMigrate(true)
                  } else {
                    setShowConfirmDialog(true)
                  }
                }}
              >
                {migrationComplete
                  ? 'Migration complete'
                  : dryRun
                  ? 'Dry Run Migration'
                  : 'Migrate for real'}
              </Button>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <ConfirmationDialog
          title="Confirm Permission Bundle Migration"
          description="Are you sure you want to proceed with the migration? This action will possibly impact permissions for all users in the selected workspace. Please review the dry run logs above carefully before proceeding."
          variant="destructive"
          cta={{
            label: 'Migrate',
            onClick: () => {
              void handleMigrate(false)
              setShowConfirmDialog(false)
            },
          }}
          secondaryCta={{
            label: 'Cancel',
            onClick: () => setShowConfirmDialog(false),
          }}
        />
      </Dialog>
    </>
  )
}

export default MigrateWorkspaceToPermBundlesComponent

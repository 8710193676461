import React, { useEffect, memo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useMount, useUnmount, useWindowSize } from 'react-use'

import { datadogRum } from '@datadog/browser-rum'
import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { useGeneralStore } from 'stores/general-store'
import { HistoryItem } from 'types/history'

import { TaskStatus } from 'utils/task'
import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import FullscreenLoading from 'components/common/fullscreen-loading'
import { VaultAddFilesDialog } from 'components/vault/dialogs/vault-add-files-dialog'
import { useFetchEventFeedbackSentiments } from 'components/vault/hooks/use-feedback'
import { useVaultHistoryItemQuery } from 'components/vault/hooks/use-vault-history-item'
import { projectsPath, ReviewEvent } from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

import { updateAddColumnDef } from './data-grid-helpers'
import VaultDataGridHeader from './data-grid/header/vault-data-grid-header'
import { VaultColumnBuilderDialog } from './data-grid/vault-column-builder-dialog'
import VaultDataGridWrapper from './data-grid/vault-data-grid'
import { ReviewRunToastWrapper } from './review-run/review-run-toast'
import VaultPushSheet from './sheets/vault-push-sheet'
import VaultDataGridToolbelt from './toolbelt/vault-data-grid-toolbelt'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from './vault-query-detail-store'

export const LEFT_PINNED_COLUMNS = ['row', 'name']
export const EXCLUDED_HEADER_NAMES_FROM_EXPORT = ['folderPath', 'addColumn']
export const EXCLUDED_COLIDS_FROM_RESIZE_FILTER = ['row', 'addColumn']
export const EXCLUDED_COLIDS_FROM_COLUMN_CLICK = ['row', 'name', 'addColumn']
export const EXCLUDED_HEADER_NAMES_FROM_DELETE_COLUMN = [
  'row',
  'name',
  'addColumn',
  'folderPath',
]

const VaultQueryDetailLoading = ({ isLoading }: { isLoading: boolean }) => {
  const [gridApi] = useVaultQueryDetailStore(
    useShallow((state) => [state.gridApi])
  )

  const isLoadingGrid = !gridApi

  return (
    <FullscreenLoading zIndex="z-50" isLoading={isLoadingGrid || isLoading} />
  )
}

const VaultQueryDetailResizeObserver = () => {
  const { width } = useWindowSize()
  const [gridApi] = useVaultQueryDetailStore(
    useShallow((state) => [state.gridApi])
  )
  useEffect(() => {
    if (gridApi) {
      const columnDefs = gridApi.getColumnDefs() ?? []
      const haveToPinAddColumn = updateAddColumnDef(columnDefs)
      gridApi.setGridOption('columnDefs', columnDefs)
      if (haveToPinAddColumn) {
        gridApi.dispatchEvent({ type: 'bodyScroll' })
      }
    }
  }, [width, gridApi])
  return null
}

const VaultDataGridToolbarWrapper = () => {
  const isSidebarOpen = useGeneralStore(
    useShallow((state) => state.isSidebarOpen)
  )

  return (
    <VaultDataGridToolbelt
      className={cn('fixed ml-24 max-w-[calc(100%-192px)]', {
        'ml-8 max-w-[calc(100%-64px)]': !isSidebarOpen,
      })}
    />
  )
}

const VaultQueryResultsTable = memo(() => {
  const [setCurrentSortColumnId, setDisplayedRows] =
    useVaultDataGridFilterStore(
      useShallow((state) => [
        state.setCurrentSortColumnId,
        state.setDisplayedRows,
      ])
    )

  useMount(() => {
    document.body.classList.add('disable-swipe-navigation')
  })

  useUnmount(() => {
    setCurrentSortColumnId('name')
    setDisplayedRows([])
    document.body.classList.remove('disable-swipe-navigation')
  })

  return (
    <>
      <VaultAddFilesDialog />
      <VaultColumnBuilderDialog />
      <VaultQueryDetailResizeObserver />
      <div className="flex h-full w-full flex-col">
        <ReviewRunToastWrapper />
        <div className="flex h-full w-full">
          <div className="flex h-full w-full flex-col">
            <VaultDataGridHeader />
            <VaultDataGridWrapper />
            <VaultDataGridToolbarWrapper />
          </div>
          <VaultPushSheet />
        </div>
      </div>
    </>
  )
})

interface UseVaultReviewQueryLoadTrackingProps {
  isLoading: boolean
  historyItem: ReviewEvent | HistoryItem | null | undefined
}

const useVaultReviewQueryLoadTracking = ({
  isLoading,
  historyItem,
}: UseVaultReviewQueryLoadTrackingProps) => {
  const hasRecordedVaultReviewQueryFullLoad = useRef(false)

  useEffect(() => {
    if (
      !hasRecordedVaultReviewQueryFullLoad.current &&
      !isLoading &&
      _.get(historyItem, 'eventStatus') !== TaskStatus.IN_PROGRESS
    ) {
      datadogRum.stopDurationVital('vaultReviewQueryFullLoad', {
        context: {
          reviewQueryFileCount: _.get(historyItem, 'numFiles', 0),
          reviewQueryQuestionCount: _.get(historyItem, 'numQuestions', 0),
        },
      })
      hasRecordedVaultReviewQueryFullLoad.current = true
    }
  }, [isLoading, historyItem])
}

const VaultQueryDetailWrapper = () => {
  const { projectId, queryId } = useParams()
  const userInfo = useAuthUser()

  const [setError] = useVaultStore(useShallow((state) => [state.setError]))
  const [setIsSidebarOpenAndToggle, revertSidebarOpen] = useGeneralStore(
    useShallow((state) => [
      state.setIsSidebarOpenAndToggle,
      state.revertSidebarOpen,
    ])
  )

  const [isQueryLoading, isFetchingQuery, setHistoryItem, setCellViewerData] =
    useVaultQueryDetailStore(
      useShallow((state) => [
        state.isQueryLoading,
        state.isFetchingQuery,
        state.setHistoryItem,
        state.setCellViewerData,
      ])
    )

  const isNewQuery = queryId === 'new'

  // If we don't have a history item, we need to fetch the history item (without sources to speed up the process)
  const { historyItem, error } = useVaultHistoryItemQuery({
    id: queryId,
    vaultFolderId: projectId!,
    isEnabled: !isNewQuery,
    throwOnError: true,
    refetchInterval: () => {
      return isQueryLoading ? 2_500 : false
    },
  })

  const isLoading = !isNewQuery && isFetchingQuery

  useEffect(() => {
    // let's only update the history item if it's not null
    // it usually is null when we hit the run button and react query does not fetch anything for new queries
    if (!historyItem) return

    const isReviewEvent = historyItem.eventKind === EventKind.VAULT_REVIEW
    if (isReviewEvent) {
      const reviewHistoryEvent = historyItem as ReviewHistoryItem
      setHistoryItem(reviewHistoryEvent)
    }
  }, [historyItem, setHistoryItem])

  useVaultReviewQueryLoadTracking({
    isLoading,
    historyItem,
  })

  // Fetch the feedback sentiments for the query
  useFetchEventFeedbackSentiments(
    !isNewQuery ? Number(queryId) : null,
    userInfo
  )

  useMount(() => {
    // on mount we want to set the sidebar to false
    setIsSidebarOpenAndToggle(false)
    setCellViewerData(null)
  })

  useUnmount(() => {
    revertSidebarOpen()
    setHistoryItem(null)
  })

  if (error) {
    setError({
      message: `The requested history item ${queryId} you are trying to access does not exist.\nContact support@harvey.ai if this issue persists.`,
      cta: {
        redirectUri: `${BaseAppPath.Vault}${projectsPath}${projectId}`,
        message: 'Back to Vault project',
      },
    })
    return null
  }

  return (
    <AppMain className="flex w-full">
      <VaultQueryDetailLoading isLoading={isLoading} />
      {!isLoading && <VaultQueryResultsTable />}
    </AppMain>
  )
}

VaultQueryResultsTable.displayName = 'VaultQueryDetail'
export default VaultQueryDetailWrapper

import * as React from 'react'

import { type LucideIcon } from 'lucide-react'

import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'
import Markdown from 'components/common/markdown/markdown'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

export interface AnswerBlockToolbarAction {
  icon: LucideIcon
  onClick: () => void
  tooltip: string
}

interface AnswerBlockProps {
  toolbarTitle: string
  toolbarActions: AnswerBlockToolbarAction[]
  content: string
  getHrvyInfoMetadata: ReturnType<typeof useSourceUtils>['getHrvyInfoMetadata']
}

interface AnswerBlockToolbarProps {
  title: string
  actions: AnswerBlockToolbarAction[]
}

const AnswerBlockToolbar: React.FC<AnswerBlockToolbarProps> = ({
  title,
  actions,
}) => {
  return (
    <div className="flex min-h-12 items-center justify-between rounded-t-md border-b border-primary bg-secondary px-4 py-2">
      <div className="text-sm font-medium text-primary">{title}</div>
      <div className="flex items-center space-x-1">
        {actions.map((action) => (
          <Button
            variant="secondary"
            size="smIcon"
            key={action.tooltip}
            onClick={action.onClick}
            tooltip={action.tooltip}
          >
            <Icon icon={action.icon} />
          </Button>
        ))}
      </div>
    </div>
  )
}

const AnswerBlock: React.FC<AnswerBlockProps> = ({
  content,
  getHrvyInfoMetadata,
  toolbarTitle,
  toolbarActions,
}) => {
  return (
    <div>
      <AnswerBlockToolbar title={toolbarTitle} actions={toolbarActions} />
      <Markdown
        content={content}
        getHrvyInfoMetadata={getHrvyInfoMetadata}
        className="rounded-b-md bg-secondary p-4"
      />
    </div>
  )
}

export default AnswerBlock

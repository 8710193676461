export const INTEGRATION_CONNECTED_METRIC = 'integration_connected'
export const INTEGRATION_DISCONNECTED_METRIC = 'integration_disconnected'

export const INTEGRATION_FILE_PICKER_CLICKED_METRIC =
  'integration_file_picker_clicked'
export const INTEGRATION_FILE_PICKER_OPENED_METRIC =
  'integration_file_picker_opened'
export const INTEGRATION_FILES_DOWNLOADED_METRIC =
  'integration_files_downloaded'

// admin metrics
export const INTEGRATION_ENABLEMENT_METRIC = 'integration_enablement'
export const INTEGRATION_CONNECTION_INITIATED_METRIC =
  'integration_connection_initiated'
export const INTEGRATION_UPDATE_RESOURCE_URL_METRIC =
  'integration_update_resource_url'

// location from where users triggered the integration connection
export const SETTINGS = 'settings'
export const IN_PRODUCT = 'in_product'

/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const IntegrationType = {
    SHAREPOINT: 'sharepoint',
    GOOGLE_DRIVE: 'google-drive',
    IMANAGE: 'imanage'
} as const;
export type IntegrationType = typeof IntegrationType[keyof typeof IntegrationType];


export function instanceOfIntegrationType(value: any): boolean {
    for (const key in IntegrationType) {
        if (Object.prototype.hasOwnProperty.call(IntegrationType, key)) {
            if (IntegrationType[key as keyof typeof IntegrationType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function IntegrationTypeFromJSON(json: any): IntegrationType {
    return IntegrationTypeFromJSONTyped(json, false);
}

export function IntegrationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationType {
    return json as IntegrationType;
}

export function IntegrationTypeToJSON(value?: IntegrationType | null): any {
    return value as any;
}

export function IntegrationTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): IntegrationType {
    return value as IntegrationType;
}


export const ISSUES_LIST_NON_REDLINES_UPLOADED_METRIC =
  'ui.issues_list_non_redlines_uploaded'
export const ISSUES_LIST_ASSISTANT_REDIRECT_CLICKED_METRIC =
  'ui.issues_list_assistant_redirect_clicked'
export const AUTO_GENERATE_ISSUES_CLICKED_METRIC =
  'ui.auto_generate_issues_clicked'

export enum WorkflowSource {
  WORKFLOWS_PAGE = 'workflowsPage',
  VAULT_PROJECT_PAGE = 'vaultProjectPage',
}

import React, { useState, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import {
  Plus,
  SquareAsterisk,
  Table2,
  ChevronDown,
  ChevronUp,
  LucideIcon,
} from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { ReviewWorkflowVisibilityKind } from 'openapi/models/ReviewWorkflowVisibilityKind'
import { Tag } from 'openapi/models/Tag'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import Icon from 'components/ui/icon/icon'
import { SkeletonBlock } from 'components/ui/skeleton'
import { ReviewWorkflow } from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'
import VaultDeleteWorkflow from 'components/vault/workflows/vault-delete-workflow'
import VaultNewWorkflow from 'components/vault/workflows/vault-new-workflow'
import VaultPublishWorkflow from 'components/vault/workflows/vault-publish-workflow'
import VaultUnpublishWorkflow from 'components/vault/workflows/vault-unpublish-workflow'
import WorkflowBrowserDialog from 'components/workflows/workflow-browser-dialog'
import WorkflowCard, {
  VaultWorkflowInfo,
  WorkflowCategory,
} from 'components/workflows/workflow-card'
import { WorkflowSource } from 'components/workflows/workflows-helpers'

import { useVaultWorkflows } from './use-vault-workflows'
import VaultCreateBlank from './vault-create-blank'
import VaultEditWorkflow from './vault-edit-workflow'
import VaultWorkflowBuilder from './vault-workflow-builder'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from './vault-workflow-store'

interface WorkflowCardListProps {
  isFetching: boolean
  workflows: ReviewWorkflow[]
  isShowingAll: boolean
}

type WorkflowWithTagMatchInfo = ReviewWorkflow & {
  icon: LucideIcon
  matchCount?: number
  matchingTag?: Tag
}

const WorkflowCardList = ({
  isFetching,
  workflows,
  isShowingAll,
}: WorkflowCardListProps) => {
  const [currentProject, projectIdToFileIds, fileIdsToVaultFile] =
    useVaultStore(
      useShallow((state) => [
        state.currentProject,
        state.projectIdToFileIds,
        state.fileIdToVaultFile,
      ])
    )

  const sortedWorkflows = useMemo(() => {
    // If there are no workflows, return an empty array
    if (!currentProject || workflows.length === 0) return []
    const currentProjectId = currentProject.id
    const currentProjectFileIds = projectIdToFileIds[currentProjectId] ?? []

    // If there are no files in the project, return all workflows in order
    if (currentProjectFileIds.length === 0) {
      return workflows
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((workflow) => ({
          ...workflow,
          icon: SquareAsterisk,
        }))
    }
    const fileDocClassificationTags = currentProjectFileIds.map((fileId) => {
      const vaultFile = fileIdsToVaultFile[fileId]
      return vaultFile?.tags ?? []
    })

    // Create a map of workflow names to their total tag count in the project
    const workflowTagCounts = new Map<string, number>()
    // Create a map of workflow names to a matching tag name if there is one
    const workflowMatchingTags = new Map<string, Tag>()

    // Initialize counts for each workflow
    workflows.forEach((workflow) => {
      workflowTagCounts.set(workflow.id, 0)
    })

    // Count matching tags for each file
    fileDocClassificationTags.forEach((fileTags) => {
      if (fileTags.length === 0) return

      workflows.forEach((workflow) => {
        // Check if any of the workflow's tags match this file's tags
        const matchingTags =
          workflow.tags?.filter((workflowTag) =>
            fileTags.some((fileTag) => {
              // tagName is the old field, name is the new field
              // TODO: Remove this once all cached workflows have been updated
              // @ts-expect-error temporary backwards compatibility
              const workflowTagName = workflowTag.name ?? workflowTag.tagName
              return (
                fileTag.name.toLowerCase() === workflowTagName.toLowerCase()
              )
            })
          ) ?? []

        if (matchingTags.length > 0) {
          // Increment the count for this workflow
          workflowTagCounts.set(
            workflow.id,
            (workflowTagCounts.get(workflow.id) || 0) + 1
          )
          if (!workflowMatchingTags.has(workflow.id)) {
            // Set the matching tag name for this workflow
            workflowMatchingTags.set(workflow.id, matchingTags[0])
          }
        }
      })
    })

    // Sort workflows by tag count DESC, then alphabetically
    return workflows
      .sort((a, b) => {
        const aCount = workflowTagCounts.get(a.id) || 0
        const bCount = workflowTagCounts.get(b.id) || 0

        if (bCount !== aCount) {
          return bCount - aCount // Sort by count DESC
        }
        return a.name.localeCompare(b.name) // Secondary sort alphabeticallyretu
      })
      .map(
        (workflow) =>
          ({
            ...workflow,
            icon: SquareAsterisk,
            matchCount: workflowTagCounts.get(workflow.id) || 0,
            matchingTag: workflowMatchingTags.get(workflow.id),
          }) as WorkflowWithTagMatchInfo
      )
  }, [currentProject, projectIdToFileIds, fileIdsToVaultFile, workflows])

  if (isFetching) {
    return [0, 1, 2].map((i) => <WorkflowCardSkeleton key={i} index={i} />)
  }

  if (workflows.length > 0) {
    return (
      <>
        {(sortedWorkflows as WorkflowWithTagMatchInfo[]).map(
          (workflow, index) => {
            return (
              <VaultWorkflowCard
                key={workflow.id}
                index={index}
                workflow={workflow}
                isShowingAll={isShowingAll}
                tagMatchCount={workflow.matchCount ?? 0}
                matchingTag={workflow.matchingTag}
              />
            )
          }
        )}
      </>
    )
  }
}

const VaultWorkflowCard = ({
  workflow,
  index,
  isShowingAll,
  tagMatchCount,
  matchingTag,
}: {
  workflow: ReviewWorkflow
  index: number
  isShowingAll: boolean
  tagMatchCount: number
  matchingTag?: Tag
}) => {
  const { trackEvent } = useAnalytics()
  const userInfo = useAuthUser()

  const [setWorkflowModalState, setSelectedWorkflow] = useVaultWorkflowStore(
    useShallow((state) => [
      state.setWorkflowModalState,
      state.setSelectedWorkflow,
    ])
  )
  const isWorkflowBrowserUser = userInfo.IsVaultWorkflowBrowserUser
  const workflowInfo: VaultWorkflowInfo = {
    id: workflow.id,
    name: workflow.name,
    description: workflow.description,
    category: WorkflowCategory.VAULT,
    numColumns: workflow.columns.length,
    documentTag: matchingTag,
  }

  const onClick = () => {
    trackEvent('Vault Workflow Clicked', {
      workflow_id: workflow.id,
      workflow_name: workflow.name,
    })
    setSelectedWorkflow(workflow)
    setWorkflowModalState(WorkflowModalState.Builder)
  }

  if (isWorkflowBrowserUser) {
    return (
      <WorkflowCard
        workflowInfo={workflowInfo}
        source={WorkflowSource.VAULT_PROJECT_PAGE}
        onClick={onClick}
        // TODO(stella): change height to h-32 once design is finalized
        className={cn({
          // We want to show the All Workflows card in the same row as the workflow cards,
          // so we hide one additional workflow card than we would otherwise
          'hidden sm:flex': index === 0 && !isShowingAll && tagMatchCount > 0,
          'hidden md:flex': index === 0 && !isShowingAll && tagMatchCount > 0,
          'hidden lg:flex': index === 1 && !isShowingAll && tagMatchCount > 0,
          hidden: tagMatchCount === 0 || (index > 1 && !isShowingAll),
        })}
      />
    )
  }

  return (
    <Card
      className={cn(
        'h-28 w-full items-center justify-center rounded border-none bg-accent p-4 transition hover:cursor-pointer hover:bg-accent-hover',
        {
          'hidden sm:flex': index === 0 && !isShowingAll,
          'hidden md:flex': index === 1 && !isShowingAll,
          'hidden lg:flex': index === 2 && !isShowingAll,
          hidden: index > 2 && !isShowingAll,
        }
      )}
      onClick={onClick}
    >
      <div className="flex size-full flex-col items-start justify-between gap-4">
        <div className="flex flex-col justify-start gap-1">
          <p className="line-clamp-1 text-xs font-medium">{workflow.name}</p>
          <p className="line-clamp-2 text-xs text-muted">
            {workflow.description}
          </p>
        </div>
        <div className="flex items-center gap-1">
          <Icon icon={Table2} size="small" className="text-muted" />
          <p className="text-2xs text-muted">
            {workflow.columns.length} columns
          </p>
        </div>
      </div>
    </Card>
  )
}

const WorkflowCardSkeleton = ({ index }: { index: number }) => {
  const userInfo = useAuthUser()
  return (
    <Card
      className={cn(
        'h-28 w-full items-center justify-center rounded border-none bg-accent p-4 shadow-sm',
        {
          'hidden sm:flex': index === 0,
          'hidden md:flex': index === 1,
          'hidden lg:flex': index === 2,
          'h-32': userInfo.IsVaultWorkflowBrowserUser,
        }
      )}
    >
      <div className="flex h-full w-full flex-col items-start justify-between gap-2">
        <div className="flex w-full flex-col justify-start gap-2">
          <SkeletonBlock className="h-4 w-1/2" />
          <SkeletonBlock className="h-4 w-full" />
        </div>
        <SkeletonBlock className="h-4 w-full" />
      </div>
    </Card>
  )
}

const NewWorkflowCard = () => {
  const { trackEvent } = useAnalytics()
  const userInfo = useAuthUser()
  const [setWorkflowModalState, setSelectedWorkflow] = useVaultWorkflowStore(
    useShallow((state) => [
      state.setWorkflowModalState,
      state.setSelectedWorkflow,
    ])
  )

  return (
    <Card
      onClick={() => {
        trackEvent('Vault Create Blank Clicked')
        setSelectedWorkflow(null)
        setWorkflowModalState(WorkflowModalState.CreateBlank)
      }}
      className={cn(
        'card-separator relative mr-4 flex h-28 w-auto flex-1 shrink-0 flex-col items-start justify-between rounded border p-4 transition hover:cursor-pointer hover:border-input-focused',
        {
          'h-32': userInfo.IsVaultWorkflowBrowserUser,
        }
      )}
      id="vault-new-workflow-card"
    >
      <div className="flex flex-col justify-start gap-1">
        <p className="text-xs font-medium">Create new query</p>
        <p className="text-xs text-muted">Choose query type and files</p>
      </div>
      <div className="flex flex-col items-center gap-2">
        <Icon icon={Plus} className="text-muted" />
      </div>
    </Card>
  )
}

const AllWorkflowsCard = () => {
  const userInfo = useAuthUser()

  const setWorkflowModalState = useVaultWorkflowStore(
    (state) => state.setWorkflowModalState
  )
  // TODO(stella): redesign this once card design is finalized
  return (
    <Card
      className={cn(
        'flex h-28 w-auto shrink-0 items-center justify-center rounded border-none bg-accent p-2.5 shadow-sm transition hover:cursor-pointer hover:bg-accent-hover',
        {
          'h-32': userInfo.IsVaultWorkflowBrowserUser,
        }
      )}
      onClick={() => setWorkflowModalState(WorkflowModalState.Selector)}
    >
      <p className="text-xs font-medium">View all</p>
    </Card>
  )
}

const VaultWorkflowList = () => {
  const { isFetching } = useVaultWorkflows()
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const [isShowingAll, setIsShowingAll] = useState(false)

  const [workflows] = useVaultWorkflowStore(
    useShallow((state) => [state.workflows])
  )

  const filteredWorkflows = useMemo(() => {
    return (Object.values(workflows).filter(Boolean) as ReviewWorkflow[])
      .filter(
        (workflow) => workflow.visibility !== ReviewWorkflowVisibilityKind.DRAFT
      )
      .filter(
        (workflow) =>
          !(
            workflow.visibility === ReviewWorkflowVisibilityKind.WORKSPACE &&
            // Only check additionalVisibilitiesInfo if it exists
            workflow.additionalVisibilitiesInfo &&
            !workflow.additionalVisibilitiesInfo.some(
              (visibility) =>
                visibility.visibility ===
                  ReviewWorkflowVisibilityKind.WORKSPACE &&
                visibility.workspaceId === userInfo.workspace.id
            )
          )
      )
  }, [workflows, userInfo.workspace.id])

  const [isHotKeyPressed, setIsHotKeyPressed] = useState(false)
  useHotkeys(
    'Control+h',
    () => {
      setIsHotKeyPressed(!isHotKeyPressed)
    },
    [isHotKeyPressed]
  )

  const shouldShowAllWorkflowsCard =
    userInfo.IsVaultWorkflowBrowserUser ||
    (userInfo.IsVaultWorkflowAdminUser && isHotKeyPressed)

  return (
    <div className="flex flex-col gap-4">
      <p className="text-sm font-semibold">Create new</p>
      <div
        className="grid gap-3 gap-x-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
        id="vault-workflow-list"
      >
        <NewWorkflowCard />
        <WorkflowCardList
          isFetching={isFetching && filteredWorkflows.length === 0}
          isShowingAll={isShowingAll}
          workflows={filteredWorkflows}
        />
        {shouldShowAllWorkflowsCard && <AllWorkflowsCard />}
        <WorkflowBrowserDialog />
        <VaultNewWorkflow />
        <VaultPublishWorkflow />
        <VaultEditWorkflow />
        <VaultUnpublishWorkflow />
        <VaultDeleteWorkflow />
        <VaultWorkflowBuilder source={WorkflowSource.VAULT_PROJECT_PAGE} />
        <VaultCreateBlank />
      </div>
      {!userInfo.IsVaultWorkflowBrowserUser && (
        <div
          className={cn('flex w-full items-center', {
            hidden: filteredWorkflows.length <= 1,
            'hidden sm:flex md:hidden': filteredWorkflows.length > 1,
            'hidden md:flex lg:hidden': filteredWorkflows.length > 2,
            'hidden lg:flex': filteredWorkflows.length > 3,
          })}
        >
          <hr className="grow border-t-[0.5px]" />
          <Button
            variant="outline"
            size="sm"
            className="w-fit gap-2"
            onClick={() => {
              trackEvent('Vault Workflow Show More Clicked', {
                show_all: !isShowingAll,
              })
              setIsShowingAll(!isShowingAll)
            }}
          >
            <p className="text-xs">
              {isShowingAll ? 'Show less' : 'Show more'}
            </p>
            <Icon size="small" icon={isShowingAll ? ChevronUp : ChevronDown} />
          </Button>
          <hr className="grow border-t-[0.5px]" />
        </div>
      )}
    </div>
  )
}

export default VaultWorkflowList

import { EventKind } from 'openapi/models/EventKind'
import { FileUploadSource } from 'openapi/models/FileUploadSource'

import { useAnalytics } from 'components/common/analytics/analytics-context'

export const useDropzoneTrack = (eventKind: EventKind | string) => {
  const { trackEvent } = useAnalytics()

  return (files: File[], fileUploadSource?: FileUploadSource) => {
    trackEvent('Documents Uploaded', {
      event_kind: eventKind,
      num_documents: files.length,
      file_upload_source: fileUploadSource,
    })
  }
}

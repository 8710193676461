import React from 'react'

import { convertMillimetersToTwip } from 'docx'

import { EventStatus } from 'openapi/models/EventStatus'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'

import { exportWordWithSections } from 'utils/markdown'
import { TaskType } from 'utils/task'

import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'

import { AssistantWorkflowAnswerRenderer } from './assistant-workflow-answer-renderer'

export const AssistantWorkflowTranscriptSummarizationRenderer: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.TRANSCRIPT_SUMMARIZATION_RENDER
> = ({ blockParams, stepIdx }) => {
  if (!blockParams.answer) {
    return null
  }

  const handleDownload = async () => {
    await exportWordWithSections({
      defaultStyleOverrides: {
        heading1: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(5),
            },
          },
        },
        heading2: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(2),
            },
          },
        },
        heading3: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(2),
            },
          },
        },
      },
      filePrefixOverride: TaskType.CALL_SUMMARIZATION,
      includeAnnotation: false,
      queryId: '0',
      sections: [
        {
          content: blockParams.answer?.response ?? '',
          type: 'html',
        },
      ],
      taskType: TaskType.CALL_SUMMARIZATION,
      title: 'Call Summarization',
    })
  }

  return (
    <AssistantWorkflowAnswerRenderer
      stepIdx={stepIdx}
      onCompleted={() => null}
      blockParams={{
        answer: blockParams.answer,
      }}
      outputData={null}
      blockStatus={EventStatus.COMPLETED}
      onDownload={handleDownload}
    />
  )
}

export const AssistantWorkflowTranscriptSummarizationRendererInputBox: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.TRANSCRIPT_SUMMARIZATION_RENDER
> = ({ blockParams }) => {
  const progress: number | undefined = blockParams.answer?.progress
  if (!progress || progress === 100) {
    return null
  }

  return (
    <WorkflowInput>
      <WorkflowInputFooter footer={`${progress}% complete..`} />
    </WorkflowInput>
  )
}

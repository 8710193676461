import { EventStatus } from 'openapi/models/EventStatus'
import {
  WorkflowAnswerRenderBlockBlockParams,
  instanceOfWorkflowAnswerRenderBlockBlockParams,
} from 'openapi/models/WorkflowAnswerRenderBlockBlockParams'
import {
  WorkflowAntitrustFilingsRendererBlockParams,
  instanceOfWorkflowAntitrustFilingsRendererBlockParams,
} from 'openapi/models/WorkflowAntitrustFilingsRendererBlockParams'
import {
  WorkflowFileUploadInputBlockBlockParams,
  instanceOfWorkflowFileUploadInputBlockBlockParams,
} from 'openapi/models/WorkflowFileUploadInputBlockBlockParams'
import {
  WorkflowFileUploadInputBlockOutput,
  instanceOfWorkflowFileUploadInputBlockOutput,
} from 'openapi/models/WorkflowFileUploadInputBlockOutput'
import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'
import {
  WorkflowSelectInputBlockBlockParams,
  instanceOfWorkflowSelectInputBlockBlockParams,
} from 'openapi/models/WorkflowSelectInputBlockBlockParams'
import {
  WorkflowSelectInputBlockOutput,
  instanceOfWorkflowSelectInputBlockOutput,
} from 'openapi/models/WorkflowSelectInputBlockOutput'
import {
  WorkflowTableSelectInputBlockBlockParams,
  instanceOfWorkflowTableSelectInputBlockBlockParams,
} from 'openapi/models/WorkflowTableSelectInputBlockBlockParams'
import {
  WorkflowTableSelectInputBlockOutput,
  instanceOfWorkflowTableSelectInputBlockOutput,
} from 'openapi/models/WorkflowTableSelectInputBlockOutput'
import {
  instanceOfWorkflowTextInputBlockBlockParams,
  WorkflowTextInputBlockBlockParams,
} from 'openapi/models/WorkflowTextInputBlockBlockParams'
import {
  instanceOfWorkflowTextInputBlockOutput,
  WorkflowTextInputBlockOutput,
} from 'openapi/models/WorkflowTextInputBlockOutput'
import {
  instanceOfWorkflowTranscriptSummarizationRendererBlockParams,
  WorkflowTranscriptSummarizationRendererBlockParams,
} from 'openapi/models/WorkflowTranscriptSummarizationRendererBlockParams'

import {
  AssistantWorkflowTableRendererInput,
  AssistantWorkflowTableRendererThread,
} from 'components/assistant/workflows/blocks/assistant-workflow-table-renderer'

import {
  AssistantWorkflowAnswerRenderer,
  AssistantWorkflowAnswerRendererInputBox,
} from './blocks/assistant-workflow-answer-renderer'
import {
  AssistantWorkflowAntitrustFilingsAnswerRenderer,
  AssistantWorkflowAntitrustFilingsAnswerRendererInputBox,
} from './blocks/assistant-workflow-antitrust-filings-answer-renderer'
import {
  AssistantWorkflowFileUploadInput,
  AssistantWorkflowFileUploadThread,
} from './blocks/assistant-workflow-file-upload-input'
import {
  AssistantWorkflowSelectInput,
  AssistantWorkflowSelectThread,
} from './blocks/assistant-workflow-select-input'
import {
  AssistantWorkflowTableSelectThread,
  AssistantWorkflowTableSelectInput,
} from './blocks/assistant-workflow-select-table-input'
import {
  AssistantWorkflowTextInput,
  AssistantWorkflowTextThread,
} from './blocks/assistant-workflow-text-input'
import {
  AssistantWorkflowTranscriptSummarizationRenderer,
  AssistantWorkflowTranscriptSummarizationRendererInputBox,
} from './blocks/assistant-workflow-transcript-summarization-renderer'

export type AssistantWorkflowComponent<K extends keyof BlockTypeMap> = React.FC<
  AssistantWorkflowComponentParams<
    BlockTypeMap[K]['blockParams'],
    BlockTypeMap[K]['outputData']
  >
>

export interface BlockValidators {
  blockParams: (params: any) => boolean
  outputData?: (data: any) => boolean
}

export interface AssistantWorkflowComponentParams<TBlockParams, TOutputData> {
  stepIdx: number
  onCompleted: (result: TOutputData) => void
  blockParams: TBlockParams
  outputData: TOutputData | null
  blockStatus: EventStatus
  onDownload?: () => void
}

export interface BlockTypeMap {
  [WorkflowInputComponentBlocks.FILE_UPLOAD]: {
    blockParams: WorkflowFileUploadInputBlockBlockParams
    outputData: WorkflowFileUploadInputBlockOutput | null
  }
  [WorkflowInputComponentBlocks.SELECT]: {
    blockParams: WorkflowSelectInputBlockBlockParams
    outputData: WorkflowSelectInputBlockOutput | null
  }
  [WorkflowRenderComponentBlocks.ANSWER]: {
    blockParams: WorkflowAnswerRenderBlockBlockParams
    outputData: null
  }
  [WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER]: {
    blockParams: WorkflowAntitrustFilingsRendererBlockParams
    outputData: null
  }
  [WorkflowInputComponentBlocks.TABLE_SELECT]: {
    blockParams: WorkflowTableSelectInputBlockBlockParams
    outputData: WorkflowTableSelectInputBlockOutput | null
  }
  [WorkflowRenderComponentBlocks.TABLE]: {
    blockParams: WorkflowTableSelectInputBlockBlockParams
    outputData: null
  }
  [WorkflowInputComponentBlocks.TEXT]: {
    blockParams: WorkflowTextInputBlockBlockParams
    outputData: WorkflowTextInputBlockOutput | null
  }
  [WorkflowRenderComponentBlocks.TRANSCRIPT_SUMMARIZATION_RENDER]: {
    blockParams: WorkflowTranscriptSummarizationRendererBlockParams
    outputData: null
  }
}

export const blockValidators: {
  [K in keyof BlockTypeMap]: BlockValidators
} = {
  [WorkflowInputComponentBlocks.FILE_UPLOAD]: {
    blockParams: instanceOfWorkflowFileUploadInputBlockBlockParams,
    outputData: instanceOfWorkflowFileUploadInputBlockOutput,
  },
  [WorkflowInputComponentBlocks.SELECT]: {
    blockParams: instanceOfWorkflowSelectInputBlockBlockParams,
    outputData: instanceOfWorkflowSelectInputBlockOutput,
  },
  [WorkflowRenderComponentBlocks.ANSWER]: {
    blockParams: instanceOfWorkflowAnswerRenderBlockBlockParams,
  },
  [WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER]: {
    blockParams: instanceOfWorkflowAntitrustFilingsRendererBlockParams,
  },
  [WorkflowInputComponentBlocks.TABLE_SELECT]: {
    blockParams: instanceOfWorkflowTableSelectInputBlockBlockParams,
    outputData: instanceOfWorkflowTableSelectInputBlockOutput,
  },
  [WorkflowRenderComponentBlocks.TABLE]: {
    blockParams: instanceOfWorkflowTableSelectInputBlockBlockParams,
  },
  [WorkflowInputComponentBlocks.TEXT]: {
    blockParams: instanceOfWorkflowTextInputBlockBlockParams,
    outputData: instanceOfWorkflowTextInputBlockOutput,
  },
  [WorkflowRenderComponentBlocks.TRANSCRIPT_SUMMARIZATION_RENDER]: {
    blockParams: instanceOfWorkflowTranscriptSummarizationRendererBlockParams,
  },
}

export interface WorkflowComponentEntry<K extends keyof BlockTypeMap> {
  InputComponent: AssistantWorkflowComponent<K>
  ThreadComponent: AssistantWorkflowComponent<K>
  validators: BlockValidators
}

export const AssistantWorkflowStepNameToDefinition: {
  [K in keyof BlockTypeMap]: WorkflowComponentEntry<K>
} = {
  [WorkflowInputComponentBlocks.FILE_UPLOAD]: {
    InputComponent: AssistantWorkflowFileUploadInput,
    ThreadComponent: AssistantWorkflowFileUploadThread,
    validators: blockValidators[WorkflowInputComponentBlocks.FILE_UPLOAD],
  },
  [WorkflowInputComponentBlocks.SELECT]: {
    InputComponent: AssistantWorkflowSelectInput,
    ThreadComponent: AssistantWorkflowSelectThread,
    validators: blockValidators[WorkflowInputComponentBlocks.SELECT],
  },
  [WorkflowRenderComponentBlocks.ANSWER]: {
    InputComponent: AssistantWorkflowAnswerRendererInputBox,
    ThreadComponent: AssistantWorkflowAnswerRenderer,
    validators: blockValidators[WorkflowRenderComponentBlocks.ANSWER],
  },
  [WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER]: {
    InputComponent: AssistantWorkflowAntitrustFilingsAnswerRendererInputBox,
    ThreadComponent: AssistantWorkflowAntitrustFilingsAnswerRenderer,
    validators:
      blockValidators[WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER],
  },
  [WorkflowInputComponentBlocks.TABLE_SELECT]: {
    InputComponent: AssistantWorkflowTableSelectInput,
    ThreadComponent: AssistantWorkflowTableSelectThread,
    validators: blockValidators[WorkflowInputComponentBlocks.TABLE_SELECT],
  },
  [WorkflowRenderComponentBlocks.TABLE]: {
    InputComponent: AssistantWorkflowTableRendererInput,
    ThreadComponent: AssistantWorkflowTableRendererThread,
    validators: blockValidators[WorkflowRenderComponentBlocks.TABLE],
  },
  [WorkflowInputComponentBlocks.TEXT]: {
    InputComponent: AssistantWorkflowTextInput,
    ThreadComponent: AssistantWorkflowTextThread,
    validators: blockValidators[WorkflowInputComponentBlocks.TEXT],
  },
  [WorkflowRenderComponentBlocks.TRANSCRIPT_SUMMARIZATION_RENDER]: {
    InputComponent: AssistantWorkflowTranscriptSummarizationRendererInputBox,
    ThreadComponent: AssistantWorkflowTranscriptSummarizationRenderer,
    validators:
      blockValidators[
        WorkflowRenderComponentBlocks.TRANSCRIPT_SUMMARIZATION_RENDER
      ],
  },
}

import React, { useEffect, useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogContent,
  Dialog,
} from 'components/ui/dialog'
import { Switch } from 'components/ui/switch'
import { TagInput } from 'components/ui/tag-input/tag-input'
import { SimpleDocumentClassificationPill } from 'components/vault/components/vault-document-classification'
import { useDocumentClassificationStore } from 'components/vault/utils/use-document-classification-store'
import {
  UpdateDraftWorkflow,
  UpdateDraftWorkflowParams,
} from 'components/vault/utils/vault-fetcher'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

const VaultEditWorkflow: React.FC = () => {
  const [documentClassificationTags] = useDocumentClassificationStore(
    useShallow((state) => [state.documentClassificationTags])
  )
  const [
    selectedWorkflow,
    workflowModalState,
    setWorkflowModalState,
    setWorkflow,
    updateWorkflow,
  ] = useVaultWorkflowStore(
    useShallow((state) => [
      state.selectedWorkflow,
      state.workflowModalState,
      state.setWorkflowModalState,
      state.setWorkflow,
      state.updateWorkflow,
    ])
  )

  // TODO: This only works because the workspace document classification tags are the same as the workflow document classification tags
  // because the workspace that created the workflow is the same as the workspace that is currently selected
  const [isSubmittingUpdateWorkflow, setIsSubmittingUpdateWorkflow] =
    useState(false)
  const [newStrictDocumentType, setNewStrictDocumentType] = useState(false)
  const [
    selectedDocumentClassificationTags,
    setSelectedDocumentClassificationTags,
  ] = useState<string[]>([])

  useEffect(() => {
    if (selectedWorkflow) {
      setSelectedDocumentClassificationTags(
        selectedWorkflow.tags?.map((tag) => tag.id) ?? []
      )
      setNewStrictDocumentType(selectedWorkflow.strictDocumentType)
    } else {
      setSelectedDocumentClassificationTags([])
      setNewStrictDocumentType(false)
    }
  }, [selectedWorkflow])

  const onSubmit = async () => {
    if (!selectedWorkflow) return
    const existingWorkflowTags = selectedWorkflow.tags ?? []
    const existingWorkflowTagIds = existingWorkflowTags.map((tag) => tag.id)
    const tagsToDelete = existingWorkflowTags.filter(
      (tag) => !selectedDocumentClassificationTags.includes(tag.id)
    )
    const tagsToAdd = selectedDocumentClassificationTags.filter(
      (tagId) => !existingWorkflowTagIds.includes(tagId)
    )
    try {
      setIsSubmittingUpdateWorkflow(true)
      const params: UpdateDraftWorkflowParams = {
        workflowId: selectedWorkflow.id,
        reviewWorkflowTagIdsToDelete: tagsToDelete.map((tag) => tag.id),
        reviewWorkflowTagsToAdd: tagsToAdd,
      }
      if (newStrictDocumentType !== selectedWorkflow.strictDocumentType) {
        params.strictDocumentType = newStrictDocumentType
      }
      const response = await UpdateDraftWorkflow(params)
      if (response && 'workflow' in response) {
        displaySuccessMessage('Workflow updated successfully')
        setWorkflow(response.workflow)
        updateWorkflow(response.workflow)
        setWorkflowModalState(WorkflowModalState.Selector)
      }
    } catch (e) {
      displayErrorMessage('Failed to update workflow')
    }
    setIsSubmittingUpdateWorkflow(false)
  }

  return (
    <Dialog
      open={workflowModalState === WorkflowModalState.Edit}
      onOpenChange={(open) => {
        if (!open) {
          setWorkflowModalState(WorkflowModalState.None)
        }
      }}
    >
      <DialogContent className="min-h-64] bg-secondary sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Edit Workflow</DialogTitle>
          <DialogDescription>Edit the workflow</DialogDescription>
        </DialogHeader>
        <div className="space-y-2">
          <div className="flex items-center justify-between gap-2">
            <p className="mb-1 text-xs font-medium">
              Strict Document Classification
            </p>
            <Switch
              id="shouldBeStrictDocumentClassification"
              checked={newStrictDocumentType}
              onCheckedChange={setNewStrictDocumentType}
            />
          </div>
          <div className="flex min-h-64 flex-col">
            <p className="mb-1 text-xs font-medium">
              Document Classification Tags
            </p>
            <TagInput
              placeholder="Select document classification tags"
              shouldShowSuggestedTags
              allowCreatingNewTags={false}
              selectedTagValues={selectedDocumentClassificationTags}
              setSelectedTagValues={setSelectedDocumentClassificationTags}
              sortedTags={documentClassificationTags.map((tag) => {
                return {
                  badgeDisplayText: tag.name,
                  value: tag.id,
                  component: (
                    <SimpleDocumentClassificationPill
                      tagName={tag.name}
                      tagId={tag.id}
                    />
                  ),
                }
              })}
            />
          </div>
          <div className="flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={() => setWorkflowModalState(WorkflowModalState.Selector)}
            >
              Cancel
            </Button>
            <Button onClick={onSubmit} isLoading={isSubmittingUpdateWorkflow}>
              {isSubmittingUpdateWorkflow ? 'Updating…' : 'Update Workflow'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultEditWorkflow

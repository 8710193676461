import React from 'react'
import { useLocation } from 'react-router-dom'

import { getMergedQueryPath } from 'hooks/use-navigate-with-query-params'
import { cn } from 'utils/utils'

import Link from 'components/ui/link/link'
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/ui/tooltip'

interface NavItemProps {
  label: string
  icon: React.ReactElement
  href: string
  comingSoon?: boolean
  isSidebarOpen?: boolean
  removeParams?: string[]
  isExternalLink?: boolean
  onClick?: () => void
}

const NavItem: React.FC<NavItemProps> = ({
  label,
  icon,
  href,
  comingSoon,
  isSidebarOpen,
  removeParams,
  isExternalLink = false,
  onClick,
}) => {
  const location = useLocation()

  const active = location.pathname.startsWith(href)
  const destination = isExternalLink
    ? href
    : getMergedQueryPath(href, { search: location.search }, removeParams)

  const linkClassName = cn(
    'flex h-8 w-full cursor-pointer items-center rounded-md text-primary transition hover:bg-accent-hover',
    {
      'bg-accent-hover text-primary': active,
      'pointer-events-none text-muted': comingSoon,
      'w-9 justify-center': !isSidebarOpen,
      'justify-between px-2.5': isSidebarOpen,
    }
  )

  const linkContent = (
    <div
      className="flex items-center space-x-2"
      data-testid={`sidebar-item--${label}`}
    >
      {React.cloneElement(icon, {
        className: cn('h-4 w-4 shrink-0 text-secondary', {
          text: active,
        }),
      })}
      {isSidebarOpen && (
        <span
          className={cn('text-sm text-secondary', {
            'text-muted': comingSoon,
            text: active,
          })}
        >
          {label}
        </span>
      )}
    </div>
  )

  const linkElement = isExternalLink ? (
    <a
      href={destination}
      target="_blank"
      rel="noopener noreferrer"
      id={`sidebar-item--${label}`}
      className={linkClassName}
      onClick={onClick}
    >
      {linkContent}
    </a>
  ) : (
    <Link
      to={destination}
      id={`sidebar-item--${label}`}
      className={linkClassName}
      onClick={onClick}
    >
      {linkContent}
    </Link>
  )

  return (
    <Tooltip>
      <TooltipTrigger className="block w-full" asChild>
        {linkElement}
      </TooltipTrigger>

      {comingSoon && <TooltipContent side="right">Coming soon</TooltipContent>}

      <TooltipContent
        side="right"
        className={cn({ 'opacity-0': isSidebarOpen || comingSoon })}
      >
        {label}
      </TooltipContent>
    </Tooltip>
  )
}

export default NavItem

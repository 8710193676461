import { useCallback, useEffect, useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { FetchSharingWorkspaceUsers } from 'models/fetchers/sharing-fetcher'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'
import { useSharingStore } from 'stores/sharing-store'

import { SafeRecord } from 'utils/safe-types'

import { BaseAppPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import {
  FetchVaultExampleProjects,
  FetchVaultFolder,
  FetchVaultProjects,
} from 'components/vault/utils/vault-fetcher'
import {
  fetchFoldersMetadata,
  getVaultProjects,
} from 'components/vault/utils/vault-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

interface RequestOptions {
  includeExamples?: boolean
  isEnabled?: boolean
  loadAllMetadata?: boolean
}

export const useVaultProjects = (
  projectId: string | undefined,
  options: RequestOptions
) => {
  const userInfo = useAuthUser()
  const isSharingEnabled = userInfo.IsVaultViewSharesUser
  const { includeExamples, isEnabled = true, loadAllMetadata } = options

  const [
    allFolderIdToVaultFolder,
    rootVaultFolderIds,
    sharedProjectIds,
    setCurrentProject,
    setError,
    setExampleProjectIds,
    setShowProcessingProgress,
    setAreExampleProjectsLoaded,
    upsertVaultFolders,
    upsertVaultFiles,
    addToProjectsMetadata,
  ] = useVaultStore(
    useShallow((s) => [
      s.allFolderIdToVaultFolder,
      s.rootVaultFolderIds,
      s.sharedProjectIds,
      s.setCurrentProject,
      s.setError,
      s.setExampleProjectIds,
      s.setShowProcessingProgress,
      s.setAreExampleProjectsLoaded,
      s.upsertVaultFolders,
      s.upsertVaultFiles,
      s.addToProjectsMetadata,
    ])
  )

  const [setSharingUsersForWorkspace, setDidFetchSharingUsersForWorkspaceFail] =
    useSharingStore(
      useShallow((s) => [
        s.setSharingUsersForWorkspace,
        s.setDidFetchSharingUsersForWorkspaceFail,
      ])
    )

  const setPermissionsForProjectId = useVaultSharingStore(
    (s) => s.setPermissionsForProjectId
  )

  const allVaultProjects = getVaultProjects({
    allFolderIdToVaultFolder,
    rootVaultFolderIds,
    userId: userInfo.dbId,
    sharedProjectIds,
  })

  const [isFetching, setIsFetching] = useState(true)
  const [areProjectsLoaded, setAreProjectsLoaded] = useState(false)

  const fetchAndSaveProjectMetadata = useCallback(
    async (
      projects: VaultFolder[],
      isExampleProject: boolean
    ): Promise<SafeRecord<string, VaultFolderMetadata>> => {
      const responses = await Promise.all(
        projects.map(async (project) => {
          try {
            const projectsMetadata = await fetchFoldersMetadata(project)
            addToProjectsMetadata(projectsMetadata)
            Object.values(projectsMetadata).forEach((projectMetadata) => {
              upsertVaultFiles(
                projectMetadata.descendantFiles ?? [],
                project.id
              )
              upsertVaultFolders(
                projectMetadata.descendantFolders ?? [],
                userInfo.dbId,
                isExampleProject,
                project.id
              )
            })
            return projectsMetadata
          } catch (e) {
            console.error('Error fetching project metadata', e)
            return {}
          }
        })
      )
      return responses.reduce((acc, response) => {
        return { ...acc, ...response }
      }, {})
    },
    [addToProjectsMetadata, upsertVaultFiles, upsertVaultFolders, userInfo.dbId]
  )

  useEffect(() => {
    if (!isEnabled) return
    setIsFetching(true)
    setAreProjectsLoaded(false)

    const fetchProjects = async () => {
      // Step 1: Fetch the example projects if includeExamples is true
      let exampleProjects: VaultFolder[] = []
      if (includeExamples) {
        try {
          setAreExampleProjectsLoaded(false)
          const exampleProjectsResponse = await FetchVaultExampleProjects()
          exampleProjects = exampleProjectsResponse.folders ?? []
          if (exampleProjects.length > 0) {
            setExampleProjectIds(
              new Set(exampleProjects.map((project) => project.id))
            )
            // Do not upsert the example projects here. We will do that after
            // we have fetched the remaining projects.
          }
          setAreExampleProjectsLoaded(true)
        } catch (e) {
          console.error('Error fetching example project', e)
        }
      }

      // Step 2: Fetch the project if a projectId is provided
      if (projectId) {
        try {
          const project = await FetchVaultFolder(projectId, true)
          setCurrentProject(project)
          upsertVaultFolders(
            [project],
            userInfo.dbId,
            !exampleProjects.find((p) => p.id === projectId),
            projectId
          )
          if (project.shareStatus) {
            setPermissionsForProjectId({
              projectId,
              userId: userInfo.dbId,
              workspaceId: userInfo.workspace.id,
              permissions: project.shareStatus,
            })
          }

          const currentProjectMetadataResponse =
            await fetchAndSaveProjectMetadata([project], false)
          const currentProjectMetadata =
            currentProjectMetadataResponse[projectId]

          if (currentProjectMetadata) {
            const shouldShowProcessingProgress =
              currentProjectMetadata.failedFiles !== 0 ||
              currentProjectMetadata.completedFiles !==
                currentProjectMetadata.totalFiles

            if (shouldShowProcessingProgress) {
              setShowProcessingProgress(projectId, true)
            }
          }
        } catch (e) {
          console.error('Error fetching project', e)
          setError({
            message: `The requested Vault project does not exist.\nContact support@harvey.ai if this issue persists.`,
            cta: { redirectUri: BaseAppPath.Vault, message: 'Back to Vault' },
          })
        }
      }

      // Step 3: Fetch the remaining projects
      const remainingProjects: VaultFolder[] = []
      try {
        if (!projectId || loadAllMetadata) {
          const vaultProjectsResponse = await FetchVaultProjects({
            includeSharedFolders: isSharingEnabled,
            includeSharedWithWorkspaceFolders: isSharingEnabled,
          })
          const vaultProjects = vaultProjectsResponse.folders
          for (const project of vaultProjects) {
            upsertVaultFolders([project], userInfo.dbId, false, project.id)
            if (project.shareStatus) {
              setPermissionsForProjectId({
                projectId: project.id,
                userId: userInfo.dbId,
                workspaceId: userInfo.workspace.id,
                permissions: project.shareStatus,
              })
            }
          }
          remainingProjects.push(...vaultProjects)
        }
        // Upsert the example projects after we have fetched the remaining projects
        // This is to ensure on the UI render, we don't show the example projects first
        // and then add the remaining projects.
        for (const project of exampleProjects) {
          upsertVaultFolders([project], userInfo.dbId, true, project.id)
        }
      } catch (e) {
        console.error('Error fetching vault projects', e)
      } finally {
        setAreProjectsLoaded(true)
      }

      if (loadAllMetadata) {
        // TODO(stella): try and not do this separately
        await fetchAndSaveProjectMetadata(exampleProjects, true)
        await fetchAndSaveProjectMetadata(
          remainingProjects.filter((project) => project.id !== projectId),
          false
        )
      }

      if (userInfo.IsVaultCreateSharesUser) {
        try {
          const response = await FetchSharingWorkspaceUsers(
            userInfo.workspace.id,
            'vault'
          )
          const users = response.users
          setSharingUsersForWorkspace(users)
          setDidFetchSharingUsersForWorkspaceFail(false)
        } catch (e) {
          console.error('Error fetching sharing workspace users', e)
          setDidFetchSharingUsersForWorkspaceFail(true)
        }
      }

      setIsFetching(false)
    }

    void fetchProjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled])

  return { projects: allVaultProjects, isFetching, areProjectsLoaded }
}

import React, { useEffect } from 'react'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { diligenceSectionsQuery } from 'models/queries/use-diligence-sections-query'
import { DiligenceSectionFromJSON } from 'openapi/models/DiligenceSection'

import { Checkbox } from 'components/ui/checkbox'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'
import {
  DiligenceTaxonomy,
  DiligenceTaxonomyDependencies,
} from 'components/workflows/workflow/discovery/types'
import { sectionsToTaxonomy } from 'components/workflows/workflow/discovery/util'

import { DiligenceTaxonomySelector } from './diligence-taxonomy-selector'

const META_SECTION_TITLES = ['At-a-Glance Summary', 'Deal Hypotheses']

export const SelectDetailsForm: React.FC<{
  handleSelectionChanged: () => void
}> = ({ handleSelectionChanged }) => {
  const [availableSections, setAvailableSections] = useDiligenceStore(
    useShallow((s) => [s.availableSections, s.setAvailableSections])
  )
  const [
    availableTaxonomies,
    setAvailableTaxonomies,
    selectedTaxonomies,
    setSelectedTaxonomies,
  ] = useDiligenceStore(
    useShallow((s) => [
      s.availableTaxonomies,
      s.setAvailableTaxonomies,
      s.selectedTaxonomies,
      s.setSelectedTaxonomies,
    ])
  )
  const taxonomyDependencies: DiligenceTaxonomyDependencies =
    availableSections.reduce((acc, section) => {
      section.tasks.forEach((task) => {
        acc[task.identifier] = task.dependentTasks || []
      })
      return acc
    }, {} as DiligenceTaxonomyDependencies)
  const tasksTitleToIdMap = availableSections.reduce(
    (acc, section) => {
      section.tasks.forEach((task) => {
        acc[task.title] = task.identifier
      })
      return acc
    },
    {} as Record<string, string>
  )

  const { queryKey, queryFn } = diligenceSectionsQuery()
  const queryResult = useWrappedQuery({ queryKey, queryFn })
  const { data: fetchedSections, isPending } = queryResult

  const taxonomiesNotFetchedYet =
    _.isEmpty(availableTaxonomies) || _.isNil(availableTaxonomies)

  // Effect to fetch the taxonomies one time from the backend and set the _initial_ state
  useEffect(() => {
    if (isPending) {
      return
    }

    if (!taxonomiesNotFetchedYet) {
      return
    }

    if (_.isEmpty(fetchedSections) || _.isNil(fetchedSections)) {
      throw new Error('No diligence sections found')
    }

    const parsedSections = fetchedSections.map((section) => {
      const parsed = JSON.parse(section as any)
      return DiligenceSectionFromJSON(parsed)
    })

    const resultSections = parsedSections.filter(
      (section) => section.tasks.length
    )
    setAvailableSections(resultSections)
    const availableTaxonomies = sectionsToTaxonomy(resultSections)
    setAvailableTaxonomies(availableTaxonomies)
  }, [
    fetchedSections,
    isPending,
    setAvailableSections,
    setAvailableTaxonomies,
    taxonomiesNotFetchedYet,
  ])

  const numSelected = selectedTaxonomies.reduce(
    (acc, t) => acc + t.rows.length,
    0
  )

  const allSelected =
    availableTaxonomies.reduce((acc, t) => acc + t.rows.length, 0) ===
    numSelected

  const someSelected = numSelected > 0

  function numSelectedExcludingMetaSections(taxonomies: DiligenceTaxonomy[]) {
    return taxonomies
      .filter((taxonomy) => !META_SECTION_TITLES.includes(taxonomy.title))
      .reduce((acc, taxonomy) => acc + taxonomy.rows.length, 0)
  }

  function setSelectedTaxonomiesWrapper(taxonomies: DiligenceTaxonomy[]) {
    // If the user deselects all the other taxonomies, unselect the meta sections
    if (numSelectedExcludingMetaSections(taxonomies) === 0) {
      setSelectedTaxonomies([])
    } else {
      const sortedTaxonomies = taxonomies.sort((a, b) => {
        return (
          availableTaxonomies.findIndex((t) => t.title === a.title) -
          availableTaxonomies.findIndex((t) => t.title === b.title)
        )
      })
      const filteredTaxonomies = sortedTaxonomies.filter(
        (taxonomy) => taxonomy.rows.length > 0
      )
      setSelectedTaxonomies(filteredTaxonomies)
    }

    handleSelectionChanged()
  }

  // Disable the checkbox for the meta sections if no other sections are selected
  function checkboxDisabled(title: string) {
    if (
      META_SECTION_TITLES.includes(title) &&
      numSelectedExcludingMetaSections(selectedTaxonomies) === 0
    ) {
      return true
    }

    // disable tasks if their dependencies aren't selected yet
    const taskId = tasksTitleToIdMap[title]
    const dependentTasks = taxonomyDependencies[taskId]
    if (!dependentTasks || dependentTasks.length === 0) {
      return false
    }
    const selectedTaskIds = selectedTaxonomies
      .flatMap((taxonomy) => taxonomy.rows)
      .reduce((acc: string[], row) => [...acc, tasksTitleToIdMap[row]], [])
    return dependentTasks.every((dep) => !selectedTaskIds.includes(dep))
  }

  const toggleSelectAll = () => {
    if (allSelected) {
      setSelectedTaxonomies([])
    } else {
      setSelectedTaxonomies(availableTaxonomies)
    }
  }

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex justify-end space-x-2">
        <span className="text-sm font-medium">Select All</span>
        <Checkbox
          className="self-center"
          onClick={toggleSelectAll}
          checked={someSelected}
          isIndeterminate={!allSelected && someSelected}
        />
      </div>
      <DiligenceTaxonomySelector
        availableTaxonomies={availableTaxonomies}
        selectedTaxonomies={selectedTaxonomies}
        setSelectedTaxonomies={setSelectedTaxonomiesWrapper}
        checkboxDisabled={checkboxDisabled}
        disabledTooltipText="Select at least one other section"
      />
    </div>
  )
}

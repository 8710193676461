/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const IntegrationPermission = {
    USESHAREPOINT_INTEGRATION: 'use:sharepoint_integration',
    USEGOOGLE_DRIVE_INTEGRATION: 'use:google_drive_integration',
    USEIMANAGE_INTEGRATION: 'use:imanage_integration'
} as const;
export type IntegrationPermission = typeof IntegrationPermission[keyof typeof IntegrationPermission];


export function instanceOfIntegrationPermission(value: any): boolean {
    for (const key in IntegrationPermission) {
        if (Object.prototype.hasOwnProperty.call(IntegrationPermission, key)) {
            if (IntegrationPermission[key as keyof typeof IntegrationPermission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function IntegrationPermissionFromJSON(json: any): IntegrationPermission {
    return IntegrationPermissionFromJSONTyped(json, false);
}

export function IntegrationPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationPermission {
    return json as IntegrationPermission;
}

export function IntegrationPermissionToJSON(value?: IntegrationPermission | null): any {
    return value as any;
}

export function IntegrationPermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): IntegrationPermission {
    return value as IntegrationPermission;
}


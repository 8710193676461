// This file is used to import all the markdown files and export them as an object.
import {
  Announcement,
  AudienceType,
  PersistanceBehavior,
  AnnouncementType,
} from 'components/common/announcements/announcement.types'
import client_matter_announcement_admin from 'components/common/announcements/data/content/client-matter-announcement-admin'
// Markdown content imports
import client_matter_announcement_user from 'components/common/announcements/data/content/client-matter-announcement-user'
import test_long from 'components/common/announcements/data/content/test-long'
import test_short from 'components/common/announcements/data/content/test-short'
// Product tour imports
import { runClientMatterAdminTour } from 'components/common/product-tours/client-matter-tour-admin'
import { runClientMatterUserTour } from 'components/common/product-tours/client-matter-tour-user'
import { runKnowledgeSourceTour } from 'components/common/product-tours/knowledge-source-tour'

import { assistantSurveyAnnouncement } from './content/assistant-survey-announcement'
import { harveyForWordAnnouncement } from './content/harvey-for-word-announcement'
import { knowledgeSourceAnnouncement } from './content/knowledge-source-announcement'
import { knowledgeSourceDeprecationAnnouncement } from './content/knowledge-source-deprecation-announcement'
import {
  isClientMatterTourEnabled,
  navigateToAssistantFeedbackForm,
} from './utils'

// List of all announcements
// Add an element to this list below to add a new announcement
const AnnouncementList: Announcement[] = [
  {
    enabled: false,
    announcementKey: 'test-short',
    cardTitle: 'Test Short Announcement',
    cardDescription: 'This is a fun announcement',
    announcementType: AnnouncementType.MODAL,
    modalContent: test_short,
    criteria: {
      startDate: new Date('2022-02-01'),
      endDate: new Date('2022-02-28'),
      audienceType: AudienceType.ONLY_USERS_CREATED_BEFORE_ANNOUNCEMENT,
      customValidator: (userInfo) => {
        return Boolean(userInfo)
      },
    },
    allowDismiss: true,
  },
  {
    enabled: false,
    announcementKey: 'test-long',
    cardTitle: 'Test Long Announcement',
    cardDescription: 'This is a second announcement',
    announcementType: AnnouncementType.MODAL,
    modalContent: test_long,
    modalHeight: 'h-[80vh]',
    modalWidth: 'max-w-4xl',
    criteria: {
      startDate: new Date('2022-02-01'),
      endDate: new Date('2022-02-28'),
      audienceType: AudienceType.ONLY_USERS_CREATED_BEFORE_ANNOUNCEMENT,
    },
    allowDismiss: true,
  },
  {
    enabled: true,
    announcementKey: 'client-matter-announcement-user',
    cardTitle: 'Client matter',
    cardDescription: 'Attach client matter numbers to queries',
    announcementType: AnnouncementType.MODAL,
    modalContent: client_matter_announcement_user,
    modalHeight: '',
    modalWidth: '',
    criteria: {
      audienceType: AudienceType.EVERYONE,
      persistanceBehavior: PersistanceBehavior.HIDE_AFTER_VIEWED,
      customValidator: (userInfo) => {
        return !userInfo.IsTerritoryAdmin && isClientMatterTourEnabled(userInfo)
        // Note: Using this would only tell us about user specific perms: userInfo.permissions.includes(Permission.CLIENT_MATTERS_READ)
      },
    },
    allowDismiss: true,
    announcementAction: runClientMatterUserTour,
  },
  {
    enabled: true,
    announcementKey: 'client-matter-announcement-admin',
    cardTitle: 'Client matter',
    cardDescription: 'Attach client matter numbers to queries',
    announcementType: AnnouncementType.MODAL,
    modalContent: client_matter_announcement_admin,
    zIndex: 11,
    criteria: {
      audienceType: AudienceType.EVERYONE,
      persistanceBehavior: PersistanceBehavior.HIDE_AFTER_VIEWED,
      customValidator: (userInfo) => {
        return userInfo.IsTerritoryAdmin && isClientMatterTourEnabled(userInfo)
      },
    },
    allowDismiss: true,
    announcementAction: runClientMatterAdminTour,
  },
  {
    enabled: false,
    announcementKey: 'assistant-survey',
    cardTitle: 'Assistant Feedback',
    cardDescription: 'Share your thoughts on the new Assistant',
    actionButtonLabel: 'Share',
    announcementType: AnnouncementType.MODAL,
    modalContent: assistantSurveyAnnouncement,
    criteria: {
      audienceType: AudienceType.EVERYONE,
      customValidator: (userInfo) => {
        return userInfo.IsAssistantV2User && userInfo.IsAssistantV2SurveyUser
      },
    },
    allowDismiss: true,
    autoOpen: true,
    announcementAction: navigateToAssistantFeedbackForm,
  },
  {
    enabled: false,
    announcementKey: 'knowledge-sources',
    cardTitle: 'Research modules have moved',
    cardDescription: 'Research modules have moved over to Assistant',
    actionButtonLabel: 'Learn more',
    announcementType: AnnouncementType.MODAL,
    modalContent: knowledgeSourceAnnouncement,
    criteria: {
      audienceType: AudienceType.EVERYONE,
    },
    allowDismiss: true,
    announcementAction: runKnowledgeSourceTour,
  },
  {
    enabled: true,
    announcementKey: 'knowledge-sources-deprecation',
    cardTitle: 'Research tab removal',
    cardDescription: 'The Research tab will be removed on Dec. 12.',
    announcementType: AnnouncementType.MODAL,
    modalContent: knowledgeSourceDeprecationAnnouncement,
    criteria: {
      audienceType: AudienceType.EVERYONE,
      persistanceBehavior: PersistanceBehavior.HIDE_AFTER_VIEWED,
      endDate: new Date('2024-12-12'),
    },

    actionButtonLabel: 'Learn More',
    allowDismiss: true,
    hideActionButton: true,
  },
  {
    enabled: true,
    announcementKey: 'harvey-for-word',
    cardTitle: 'Use Harvey for Word',
    cardDescription:
      'Install the Microsoft Word Add-In to bring Harvey’s expertise directly into your documents.',
    announcementType: AnnouncementType.MODAL,
    modalContent: harveyForWordAnnouncement,
    modalWidth: 'max-w-[580px]',
    criteria: {
      audienceType: AudienceType.EVERYONE,
      customValidator: (userInfo) => {
        return userInfo.canViewWordAddInAnnouncement
      },
      startDate: new Date('2024-12-05'),
      persistanceBehavior: PersistanceBehavior.PERSIST_UNTIL_DISMISSED,
    },
    allowDismiss: true,
    actionButtonLabel: 'Get Started',
  },
]

// exports
export { AnnouncementList }

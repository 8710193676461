import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ReactTyped } from 'react-typed'

import { User } from 'lucide-react'

import { EventStatus } from 'openapi/models/EventStatus'

import { FILE_ID_PARAM } from 'components/assistant/utils/assistant-helpers'
import HarveyLogo from 'components/common/harvey-logo'
import Icon from 'components/ui/icon/icon'

export const AssistantWorkflowYouComponent: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <div className="flex items-start space-x-3">
      <div className="flex size-6 shrink-0 items-center justify-center rounded-full border">
        <Icon icon={User} className="size-4 text-inactive" />
      </div>
      <div className="flex flex-col gap-[2px]">
        <p className="text-sm text-inactive">You</p>
        {children}
      </div>
    </div>
  )
}

export const AssistantWorkflowHarveyComponent: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <div className="flex items-start space-x-3">
      <div className="flex size-6 shrink-0 items-center justify-center rounded-full bg-accent">
        <HarveyLogo className="size-3" />
      </div>
      <div className="flex flex-1 flex-col gap-[2px]">
        <p className="text-sm text-inactive">Harvey</p>
        {children}
      </div>
    </div>
  )
}

export const AssistantWorkflowThreadText: React.FC<{
  text: string
  blockStatus: EventStatus
}> = ({ text, blockStatus }) => {
  const [hasDoneInitialTypewriter, setHasDoneInitialTypewriter] =
    useState(false)

  // TODO: PENDING isn't in EventStatus?
  const doTypewriter =
    ['PENDING', EventStatus.IN_PROGRESS].includes(blockStatus) &&
    !hasDoneInitialTypewriter

  return doTypewriter ? (
    <ReactTyped
      className="text-base text-primary"
      strings={[text]}
      typeSpeed={5}
      showCursor={!hasDoneInitialTypewriter}
      onComplete={() => setHasDoneInitialTypewriter(true)}
    />
  ) : (
    <p className="text-base text-primary">{text}</p>
  )
}

export const AssistantWorkflowThreadBlock: React.FC<{
  children: React.ReactNode
  sidebar?: React.ReactNode
}> = ({ children, sidebar }) => {
  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(FILE_ID_PARAM)
  // 324x is 288 (width of citations) + 36px (margin)
  const sideColumnWidth = fileId ? 'minmax(24px, 1fr)' : 'minmax(324px, 1fr)'
  return (
    <div
      className="grid"
      style={{
        gridTemplateColumns: `${sideColumnWidth} minmax(640px, 1000px) ${sideColumnWidth}`,
      }}
    >
      <div />
      <div className="mx-auto w-full space-y-4">{children}</div>
      <div className="relative">{sidebar}</div>
    </div>
  )
}

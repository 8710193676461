import React, { useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import _ from 'lodash'
import {
  PencilLine,
  Plus,
  Send,
  SquareAsterisk,
  Trash2,
  Undo,
} from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { ReviewWorkflowKind } from 'openapi/models/ReviewWorkflowKind'
import { ReviewWorkflowVisibilityKind } from 'openapi/models/ReviewWorkflowVisibilityKind'

import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { Icon } from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { useWorkspaces } from 'components/vault/utils/use-workspaces'
import { ReviewWorkflow } from 'components/vault/utils/vault-fetcher'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

export const convertKindToTabLabel = (kind: ReviewWorkflowKind) => {
  return kind
    .split('_')
    .map((part, index) =>
      index === 0 ? _.capitalize(part) : part.toLowerCase()
    )
    .join(' ')
}

const WorkflowBrowserDialog: React.FC = () => {
  const userInfo = useAuthUser()
  const [workflows, workflowModalState, setWorkflowModalState] =
    useVaultWorkflowStore(
      useShallow((state) => [
        Object.values(state.workflows) as ReviewWorkflow[],
        state.workflowModalState,
        state.setWorkflowModalState,
      ])
    )

  const { data: workspaces } = useWorkspaces()
  const [isShowingWorkflowAdminInfo, setIsShowingWorkflowAdminInfo] =
    useState(false)

  useHotkeys(
    'Control+h',
    () => {
      if (userInfo.IsVaultWorkflowAdminUser) {
        // Only admins can see draft workflows
        setIsShowingWorkflowAdminInfo(!isShowingWorkflowAdminInfo)
      }
    },
    [isShowingWorkflowAdminInfo]
  )

  // Filter published workflows based on search term
  const publishedWorkflows = useMemo(
    () =>
      workflows.filter(
        (workflow) => workflow.visibility !== ReviewWorkflowVisibilityKind.DRAFT
      ),
    [workflows]
  )

  const draftWorkflows = useMemo(
    () =>
      workflows.filter(
        (workflow) => workflow.visibility === ReviewWorkflowVisibilityKind.DRAFT
      ),
    [workflows]
  )

  return (
    <Dialog
      open={workflowModalState === WorkflowModalState.Selector}
      onOpenChange={(open) => {
        if (!open) {
          setWorkflowModalState(WorkflowModalState.None)
        }
      }}
    >
      <DialogContent
        className="sm:max-w-[800px]"
        hasContainer={false}
        innerClassName="flex flex-col p-0 py-5 space-y-4 h-[550px]"
      >
        <DialogHeader className="h-9 border-b border-b-primary px-6">
          <DialogTitle>Vault workflows</DialogTitle>
        </DialogHeader>
        <ScrollArea className="flex flex-col gap-y-6 px-6">
          <div className="grid min-h-40 grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
            {publishedWorkflows.map((workflow) => (
              <WorkflowCard
                key={workflow.id}
                workflow={workflow}
                isShowingWorkflowAdminInfo={isShowingWorkflowAdminInfo}
                workspaces={workspaces}
              />
            ))}
          </div>
          {publishedWorkflows.length === 0 && (
            <div className="flex min-h-40 items-center justify-center">
              <p className="text-center text-muted">No workflows found</p>
            </div>
          )}
          {isShowingWorkflowAdminInfo && (
            <div className="flex flex-col gap-y-4">
              <p className="text-base font-medium">
                Draft workflows (admin only)
              </p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                <NewWorkflowCard />
                {draftWorkflows
                  .sort((a, b) => {
                    if (a.visibility === 'DRAFT' && b.visibility !== 'DRAFT')
                      return 1
                    if (a.visibility !== 'DRAFT' && b.visibility === 'DRAFT')
                      return -1
                    return a.name.localeCompare(b.name)
                  })
                  .map((workflow) => (
                    <WorkflowCard
                      key={workflow.id}
                      workflow={workflow}
                      isShowingWorkflowAdminInfo={isShowingWorkflowAdminInfo}
                      workspaces={workspaces}
                    />
                  ))}
              </div>
            </div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}

// Component to render individual workflow card
const WorkflowCard: React.FC<{
  workflow: ReviewWorkflow
  isShowingWorkflowAdminInfo: boolean
  workspaces: { id: number; name: string }[] | undefined
}> = ({ workflow, isShowingWorkflowAdminInfo, workspaces }) => {
  const userInfo = useAuthUser()
  const [setWorkflowModalState, setSelectedWorkflow] = useVaultWorkflowStore(
    useShallow((state) => [
      state.setWorkflowModalState,
      state.setSelectedWorkflow,
    ])
  )
  const isDraft =
    isShowingWorkflowAdminInfo &&
    workflow.visibility === ReviewWorkflowVisibilityKind.DRAFT
  const showUnPublishButton =
    isShowingWorkflowAdminInfo &&
    workflow.visibility !== ReviewWorkflowVisibilityKind.DRAFT
  const isPublishedToPublic =
    workflow.visibility === ReviewWorkflowVisibilityKind.PUBLIC
  const hiddenForCertainWorkspace =
    isShowingWorkflowAdminInfo &&
    workflow.additionalVisibilitiesInfo?.some(
      (info) => info.isHiddenForWorkspace
    )
  const visibleWorkspaceCount = workflow.additionalVisibilitiesInfo?.filter(
    (visibility) =>
      visibility.visibility === ReviewWorkflowVisibilityKind.WORKSPACE
  ).length
  const isNotVisibleToCurrentWorkspace =
    workflow.visibility === ReviewWorkflowVisibilityKind.WORKSPACE &&
    !workflow.additionalVisibilitiesInfo?.some(
      (visibility) =>
        visibility.visibility === ReviewWorkflowVisibilityKind.WORKSPACE &&
        visibility.workspaceId === userInfo.workspace.id
    )
  return (
    <Card
      className={cn(
        'flex h-28 w-full items-center justify-center rounded border border-primary p-4 shadow-sm transition hover:cursor-pointer hover:border-input-focused',
        {
          'border-dashed': isDraft,
        }
      )}
      onClick={() => {
        setSelectedWorkflow(workflow)
        setWorkflowModalState(WorkflowModalState.Builder)
      }}
    >
      <div className="flex w-full flex-col items-start gap-2">
        <div className="flex items-center justify-center gap-2">
          <Icon icon={isDraft ? PencilLine : SquareAsterisk} />
          <p className="line-clamp-1 text-sm font-medium">{workflow.name}</p>
        </div>
        <p
          className={cn('line-clamp-3 text-xs text-muted', {
            'line-clamp-1': isShowingWorkflowAdminInfo,
          })}
        >
          {workflow.description}
        </p>
        {isDraft && (
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              size="sm"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedWorkflow(workflow)
                setWorkflowModalState(WorkflowModalState.Publish)
              }}
            >
              <Icon icon={Send} size="small" className="mr-1" />
              <p className="text-xs">Publish</p>
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedWorkflow(workflow)
                setWorkflowModalState(WorkflowModalState.Edit)
              }}
            >
              <Icon icon={PencilLine} size="small" className="mr-1" />
              <p className="text-xs">Edit</p>
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedWorkflow(workflow)
                setWorkflowModalState(WorkflowModalState.Delete)
              }}
            >
              <Icon icon={Trash2} size="small" className="mr-1" />
              <p className="text-xs">Delete</p>
            </Button>
          </div>
        )}
        {showUnPublishButton && (
          <div className="flex items-center gap-2">
            {isPublishedToPublic && !hiddenForCertainWorkspace ? (
              <Badge variant="secondary" className="text-xs">
                Public
              </Badge>
            ) : (
              <Tooltip delayDuration={250}>
                <TooltipTrigger>
                  <Badge variant="secondary" className="text-xs">
                    {isPublishedToPublic
                      ? 'Public *'
                      : `${visibleWorkspaceCount} ${pluralize(
                          'workspace',
                          visibleWorkspaceCount
                        )}${isNotVisibleToCurrentWorkspace ? ' *' : ''}`}
                  </Badge>
                </TooltipTrigger>
                <TooltipContent>
                  {workflow.additionalVisibilitiesInfo
                    ?.filter(
                      (info) =>
                        info.visibility !== ReviewWorkflowVisibilityKind.PUBLIC
                    )
                    .map((info) => (
                      <p key={info.id} className="text-xs">
                        {info.isHiddenForWorkspace
                          ? '* Hidden to '
                          : 'Visible to '}
                        {workspaces?.find(
                          (workspace) => workspace.id === info.workspaceId
                        )?.name ?? info.workspaceId}
                      </p>
                    ))}
                  {isNotVisibleToCurrentWorkspace && (
                    <p className="text-xs">
                      * This workflow is not visible to your workspace
                    </p>
                  )}
                </TooltipContent>
              </Tooltip>
            )}
            <Button
              variant="outline"
              size="sm"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedWorkflow(workflow)
                setWorkflowModalState(WorkflowModalState.Unpublish)
              }}
            >
              <Icon icon={Undo} size="small" className="mr-1" />
              <p className="text-xs">Un-publish</p>
            </Button>
          </div>
        )}
      </div>
    </Card>
  )
}

// Component to render the "New Workflow" card
const NewWorkflowCard: React.FC = () => {
  const [setWorkflowModalState] = useVaultWorkflowStore(
    useShallow((state) => [state.setWorkflowModalState])
  )
  return (
    <Card
      onClick={() => {
        setWorkflowModalState(WorkflowModalState.New)
      }}
      className="flex h-28 w-full items-center justify-center rounded border border-dashed p-4 shadow-sm transition hover:cursor-pointer hover:border-input-focused"
    >
      <div className="flex flex-col items-center gap-2">
        <Icon icon={Plus} size="large" className="text-muted" />
        <p className="text-sm font-medium">New draft workflow</p>
      </div>
    </Card>
  )
}

export default WorkflowBrowserDialog

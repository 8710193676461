import React from 'react'

import { PracticeAreaTaxonomy } from 'openapi/models/PracticeAreaTaxonomy'
import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'

import ExampleCardList from 'components/assistant/workflows/components/example-card-list/example-card-list'
import { useLoadWorkflowsQuery } from 'components/assistant/workflows/workflow-loader'
import Skeleton from 'components/ui/skeleton'

const PracticeAreas = {
  GENERAL: 'GENERAL',
  TRANSACTIONAL: PracticeAreaTaxonomy.TRANSACTIONAL,
  LITIGATION: PracticeAreaTaxonomy.LITIGATION,
  ALLEN_AND_OVERY: 'ALLEN_AND_OVERY',
  FINANCIAL_SERVICES: 'FINANCIAL_SERVICES',
} as const

type PracticeAreas = (typeof PracticeAreas)[keyof typeof PracticeAreas]
type CategorizedHarveyEvents = Record<
  Lowercase<PracticeAreas>,
  WorkflowDefinitionListItem[]
>

const AssistantWorkflowExamples = () => {
  const { data: workflows, isLoading } = useLoadWorkflowsQuery()

  const categorizedWorkflows = workflows?.reduce<CategorizedHarveyEvents>(
    (acc, workflow) => {
      if (!workflow.tags) return acc
      workflow.tags.forEach((tag) => {
        const tagKey = tag.toLowerCase() as string
        if (tagKey in acc) {
          ;(acc as any)[tagKey].push(workflow)
        }
      })
      return acc
    },
    {
      allen_and_overy: [],
      transactional: [],
      litigation: [],
      general: [],
      financial_services: [],
    }
  )

  if (isLoading) {
    return <Skeleton className="py-4" rows={3} rowHeight="h-4" />
  }

  if (!workflows?.length) {
    return (
      <p className="pt-6 text-center text-muted">
        Example workflows will appear here
      </p>
    )
  }

  const {
    transactional,
    litigation,
    general,
    allen_and_overy,
    financial_services,
  } = categorizedWorkflows || {
    transactional: [],
    litigation: [],
    general: [],
    allen_and_overy: [],
    financial_services: [],
  }

  return (
    <div className="mt-6 flex flex-col gap-6">
      <ExampleCardList items={allen_and_overy} title="A&O Shearman" />
      <ExampleCardList items={general} title="For General Work" />

      <ExampleCardList items={transactional} title="For Transactional Work" />
      <ExampleCardList items={litigation} title="For Litigation Work" />
      <ExampleCardList
        items={financial_services}
        title="For Financial Services"
      />
    </div>
  )
}
export default AssistantWorkflowExamples

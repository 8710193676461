import * as React from 'react'
import { createPortal } from 'react-dom'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'

import { CloudUploadIcon } from 'lucide-react'

import { cn } from 'utils/utils'

interface AssistantDropzoneProps {
  rootProps: DropzoneRootProps
  inputProps: DropzoneInputProps
  children: React.ReactNode
}

const FullScreenUploader: React.FC<{
  isDragActive: boolean
}> = ({ isDragActive }) => {
  return createPortal(
    <div
      className={cn(
        'absolute inset-0 z-50 flex h-full w-full flex-col items-center justify-center bg-primary opacity-0 transition-opacity duration-75',
        isDragActive ? 'pointer-events-auto opacity-90' : 'pointer-events-none'
      )}
    >
      <CloudUploadIcon className="size-16 text-inactive" />
      <h1 className="mb-1 mt-6 text-2xl font-medium">Drop to upload</h1>
      <p className="text-lg text-muted">Attach files to your query</p>
    </div>,
    document.body
  )
}

const AssistantDropzone: React.FC<AssistantDropzoneProps> = ({
  rootProps,
  inputProps,
  children,
}) => {
  const [isDragActive, setIsDragActive] = React.useState(false)
  const dragCounter = React.useRef(0)

  const handleDrag = React.useCallback((event: DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  const handleDragIn = React.useCallback((event: DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    dragCounter.current++
    if (
      event.dataTransfer !== null &&
      event.dataTransfer.types.includes('Files')
    ) {
      setIsDragActive(true)
    }
  }, [])

  const handleDragOut = React.useCallback((event: DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    dragCounter.current--
    if (dragCounter.current > 0) return
    setIsDragActive(false)
  }, [])

  const handleDrop = React.useCallback((event: DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragActive(false)
    if (
      event.dataTransfer !== null &&
      event.dataTransfer.types.includes('Files')
    ) {
      dragCounter.current = 0
    }
  }, [])

  const handleDragStart = React.useCallback((event: DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    event.dataTransfer?.clearData()
  }, [])

  React.useEffect(() => {
    window.addEventListener('dragenter', handleDragIn)
    window.addEventListener('dragleave', handleDragOut)
    window.addEventListener('dragover', handleDrag)
    window.addEventListener('drop', handleDrop)
    window.addEventListener('dragstart', handleDragStart)
    return function cleanUp() {
      window.removeEventListener('dragenter', handleDragIn)
      window.removeEventListener('dragleave', handleDragOut)
      window.removeEventListener('dragover', handleDrag)
      window.removeEventListener('drop', handleDrop)
      window.removeEventListener('dragstart', handleDragStart)
    }
  }, [handleDragIn, handleDragOut, handleDrag, handleDrop, handleDragStart])

  return (
    <div {...rootProps}>
      <FullScreenUploader isDragActive={isDragActive} />
      <input {...inputProps} />
      {children}
    </div>
  )
}

export default AssistantDropzone

import * as React from 'react'

import { X } from 'lucide-react'

import { cn } from 'utils/utils'

import { DialogClose } from 'components/ui/dialog'

interface ModalCloseProps {
  onClick: () => void
}

const ModalClose: React.FC<ModalCloseProps> = ({ onClick }) => {
  return (
    <DialogClose
      onClick={onClick}
      className={cn(
        'absolute right-2 top-2 z-10 flex size-8 items-center justify-center rounded-sm ring-offset-primary transition-opacity',
        'opacity-70 hover:opacity-100 focus-visible:opacity-100',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none',
        'data-[state=open]:bg-secondary-hover data-[state=open]:text-muted'
      )}
    >
      <X className="size-4" />
      <span className="sr-only">Close</span>
    </DialogClose>
  )
}

export default ModalClose

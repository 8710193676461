import React from 'react'

import Markdown from 'components/common/markdown/markdown'

const DataRow: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <div className="flex space-x-4">
      <p className="w-24 shrink-0 text-xs text-muted">{label}</p>
      <Markdown className="text-xs" content={value} />
    </div>
  )
}

export default DataRow

import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { useInterval } from 'react-use'

import { addSeconds, differenceInMilliseconds } from 'date-fns'
import _ from 'lodash'
import {
  CheckCircle2,
  MessageSquarePlus,
  RotateCw,
  Trash,
  X,
} from 'lucide-react'
import pluralize from 'pluralize'
import { toast } from 'sonner'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'
import Services from 'services'
import { useGeneralStore } from 'stores/general-store'

import { LONG_TOAST_DURATION, displayWarningMessage } from 'utils/toast'
import { parseIsoString } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import AlertIcon from 'components/ui/icons/alert-icon'
import { Progress } from 'components/ui/progress'
import { Separator } from 'components/ui/separator'
import { Spinner } from 'components/ui/spinner'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import useRecentQueries from 'components/vault/hooks/use-recent-queries'
import useRetryHandler from 'components/vault/hooks/use-retry-handler'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { NUM_ALL_QUERIES_TO_FETCH } from 'components/vault/utils/vault'
import { FetchJobQueueEta } from 'components/vault/utils/vault-fetcher'
import {
  fileAsItem,
  getDescendantFilesForProject,
  getEtaDisplayString,
  getExistingCompletedVaultReviewQueries,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

import VaultReviewQuerySelectionDialog from './vault-review-query-selection-dialog'

const getEtaDate = (
  areAllFilesUploaded: boolean,
  jobQueueEtaInSeconds?: number
) => {
  if (!areAllFilesUploaded || !jobQueueEtaInSeconds) {
    return null
  }
  const etaDate = addSeconds(Date.now(), jobQueueEtaInSeconds)
  return etaDate
}

const VaultProgress = () => {
  const { trackEvent } = useAnalytics()

  const filesUploading = useGeneralStore(useShallow((s) => s.filesUploading))

  const [
    projectIdToFileIds,
    fileIdToVaultFile,
    folderIdToVaultFolder,
    currentProject,
    currentProjectMetadata,
    showProcessingProgress,
    setRequiresProjectDataRefetch,
    setRecentlyUploadedFileIds,
  ] = useVaultStore(
    useShallow((s) => [
      s.projectIdToFileIds,
      s.fileIdToVaultFile,
      s.folderIdToVaultFolder,
      s.currentProject,
      s.currentProjectMetadata,
      s.showProcessingProgress,
      s.setRequiresProjectDataRefetch,
      s.setRecentlyUploadedFileIds,
    ])
  )
  const removeFolderIdFromFilesUploading = useGeneralStore(
    useShallow((s) => s.removeFolderIdFromFilesUploading)
  )

  const { onRetryHandler } = useRetryHandler()

  const lastFileUploadedAt = useMemo(
    () => currentProjectMetadata.lastFileUploadedAt,
    [currentProjectMetadata]
  )

  const recentlyUploadedFiles = useMemo(() => {
    const projectId = currentProject?.id
    const descendantFiles = projectId
      ? getDescendantFilesForProject(
          projectId,
          projectIdToFileIds,
          fileIdToVaultFile
        )
      : []
    if (!descendantFiles) return []

    return descendantFiles.filter((file) => {
      const uploadedAt = file.uploadedAt
        ? parseIsoString(file.uploadedAt)
        : parseIsoString(file.createdAt)
      if (!lastFileUploadedAt) return true
      const lastFileUploadedAtDate = parseIsoString(lastFileUploadedAt)
      return (
        differenceInMilliseconds(
          new Date(lastFileUploadedAtDate),
          new Date(uploadedAt)
        ) <= 1000
      )
    }) as VaultFile[]
  }, [
    currentProject?.id,
    projectIdToFileIds,
    fileIdToVaultFile,
    lastFileUploadedAt,
  ])

  const hasRetryableFailedFiles = recentlyUploadedFiles.some(
    (file) =>
      !file.readyToQuery && file.failureReason && file.failureRecoverable
  )
  const onRetry = async (event: React.MouseEvent) => {
    const retryableFailedFiles = recentlyUploadedFiles.filter(
      (file) =>
        !file.readyToQuery &&
        file.failureReason &&
        file.failureRecoverable &&
        // Only retry files that have a path (uploaded successfully)
        file.path
    )
    const unretryableFailedFiles = recentlyUploadedFiles.filter(
      (file) =>
        !file.readyToQuery &&
        file.failureReason &&
        !file.failureRecoverable &&
        // Only retry files that have a path (uploaded successfully)
        file.path
    )
    Services.HoneyComb.Record({
      metric: 'ui.vault_bulk_retry_file_event',
      retryableFailedFilesCount: retryableFailedFiles.length,
      unretryableFailedFilesCount: unretryableFailedFiles.length,
    })
    trackEvent('Vault Failed Files Retried', {
      retryable_failed_files_count: retryableFailedFiles.length,
      unretryable_failed_files_count: unretryableFailedFiles.length,
    })
    event.stopPropagation()
    await onRetryHandler(retryableFailedFiles.map((file) => file.id))
    setRequiresProjectDataRefetch(true)
  }

  useEffect(() => {
    setRecentlyUploadedFileIds(recentlyUploadedFiles.map((file) => file.id))
  }, [recentlyUploadedFiles, setRecentlyUploadedFileIds])
  let processedCount = 0
  let failedFileCount = 0
  let failedToUploadFileCount = 0
  recentlyUploadedFiles.forEach((file) => {
    if (file.readyToQuery) {
      processedCount++
    }
    if (file.failureReason) {
      if (!file.path) {
        failedToUploadFileCount++
      }
      failedFileCount++
      processedCount++
    }
  })
  const totalCount = recentlyUploadedFiles.length
  const progress = totalCount === 0 ? 100 : (processedCount / totalCount) * 100
  const finished = progress === 100

  useEffect(() => {
    // Only track event immediately after files finish processing
    if (
      finished &&
      lastFileUploadedAt &&
      currentProject &&
      showProcessingProgress[currentProject.id]
    ) {
      const lastFileUploadedAtDate = parseIsoString(lastFileUploadedAt)
      const processingDuration = differenceInMilliseconds(
        new Date(),
        lastFileUploadedAtDate
      )
      trackEvent('Vault File Processing Finished', {
        total_files: totalCount,
        failed_files: failedFileCount,
        processing_duration: processingDuration,
      })
    }
  }, [
    finished,
    totalCount,
    failedFileCount,
    trackEvent,
    lastFileUploadedAt,
    showProcessingProgress,
    currentProject,
  ])

  const [isSlowUploading, setIsSlowUploading] = useState(false)
  const [localFilesToUploadCount, setLocalFilesToUploadCount] = useState(0)
  const [lastCheckedTime, setLastCheckedTime] = useState(Date.now())
  const [isWarningDisplayed, setIsWarningDisplayed] = useState(false)
  const toastIdRef = useRef<string | number | null>(null)

  const shouldNotRender =
    !currentProject ||
    !showProcessingProgress[currentProject.id] ||
    currentProjectMetadata.totalFiles === 0 ||
    totalCount === 0

  const checkFileUploadProgress = useCallback(() => {
    const currentLocalFilesToUploadCount = recentlyUploadedFiles.filter(
      (file) => !file.path && !file.failureReason
    ).length

    if (
      currentLocalFilesToUploadCount === localFilesToUploadCount &&
      currentLocalFilesToUploadCount > 0 &&
      localFilesToUploadCount > 0
    ) {
      if (Date.now() - lastCheckedTime >= 5 * 60_000) {
        setIsSlowUploading(true)
        if (currentProject?.id && !isWarningDisplayed) {
          removeFolderIdFromFilesUploading(currentProject.id)
          toastIdRef.current = displayWarningMessage(
            'File upload is taking longer than expected, might be due to unstable network',
            LONG_TOAST_DURATION
          )
          setIsWarningDisplayed(true)
        }
      }
    } else {
      setIsSlowUploading(false)
      setLocalFilesToUploadCount(currentLocalFilesToUploadCount)
      setLastCheckedTime(Date.now())
      setIsWarningDisplayed(false)
      if (toastIdRef.current) {
        toast.dismiss(toastIdRef.current)
        toastIdRef.current = null
      }
    }
  }, [
    recentlyUploadedFiles,
    localFilesToUploadCount,
    lastCheckedTime,
    currentProject?.id,
    removeFolderIdFromFilesUploading,
    isWarningDisplayed,
  ])

  // Check file upload progress every 10 seconds to detect slow uploads and display warning
  useInterval(
    checkFileUploadProgress,
    filesUploading.length > 0 ? 10_000 : null
  )

  const isAnyFileFailed = failedFileCount > 0
  const areAllFilesFailed = totalCount > 0 && totalCount === failedFileCount

  const areAllFilesUploaded = recentlyUploadedFiles.every((file) => {
    if (file.path || file.failureReason) {
      // If the file has a path or failure reason, it's uploaded
      return true
    }
    // It's not uploaded, but we need to check if current folder is its ancestor
    let folderId: string | undefined = file.vaultFolderId
    while (folderId) {
      const folder: VaultFolder | undefined = folderIdToVaultFolder[folderId]
      if (folder?.id === currentProject?.id) {
        // If the file is the current folder's descendant, and it's not uploaded, we should return false
        return false
      }
      folderId = folder?.parentId
    }
    // If the file is not the current folder's descendant, it's does not matter if it's uploaded or not
    return true
  })

  const { data: jobQueueEtaInSeconds } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.JobQueueEtaQuery, currentProject?.id],
    queryFn: () =>
      currentProject ? FetchJobQueueEta(currentProject.id) : null,
    // Fetch job queue ETA and update ETA on the UI every minute
    refetchInterval: 60_000,
    select: (data) =>
      _.isNumber(data?.etaInSeconds)
        ? Math.round(data.etaInSeconds)
        : undefined,
  })

  const eta = getEtaDate(areAllFilesUploaded, jobQueueEtaInSeconds)

  const messageText = useMemo(() => {
    if (isSlowUploading) {
      return 'File upload might fail due to unstable network. Please refresh and re-upload any missing documents.'
    }
    if (finished) {
      if (isAnyFileFailed) {
        const failedToProcessFileCount =
          failedFileCount - failedToUploadFileCount
        const failedToProcessText =
          failedToProcessFileCount > 0
            ? `${pluralizeFiles(failedToProcessFileCount)} ${pluralize(
                'were',
                failedToProcessFileCount
              )} not processed`
            : ''
        const failedToUploadText =
          failedToUploadFileCount > 0
            ? `${pluralizeFiles(failedToUploadFileCount)} ${pluralize(
                'were',
                failedToUploadFileCount
              )} not uploaded`
            : ''
        return [failedToProcessText, failedToUploadText]
          .filter(Boolean)
          .join(', ')
      } else {
        if (totalCount > 1) {
          return 'All files were processed successfully'
        } else {
          return 'The file was processed successfully'
        }
      }
    } else if (
      recentlyUploadedFiles.filter((file) => !file.path && !file.failureReason)
        .length
    ) {
      return 'Uploading files…'
    } else {
      return 'Processing files…'
    }
  }, [
    isSlowUploading,
    finished,
    recentlyUploadedFiles,
    isAnyFileFailed,
    failedFileCount,
    failedToUploadFileCount,
    totalCount,
  ])

  if (shouldNotRender) {
    return null
  }

  const getLeadingIcon = () => {
    if (areAllFilesFailed) {
      return <AlertIcon />
    }
    if (isAnyFileFailed) {
      return (
        <Tooltip>
          <TooltipTrigger className="shrink-0">
            <AlertIcon />
          </TooltipTrigger>
          <TooltipContent align="start">
            Some files failed to be processed.
          </TooltipContent>
        </Tooltip>
      )
    }
    return <Icon icon={CheckCircle2} />
  }

  return (
    <div className="my-4 flex flex-col gap-y-4 rounded-lg border px-6 py-4">
      {finished && failedFileCount === 0 && (
        <SuccessVaultProgressBody
          getLeadingIcon={getLeadingIcon}
          messageText={messageText}
          currentProjectId={currentProject.id}
        />
      )}
      {finished && failedFileCount > 0 && (
        <FailureVaultProgressBody
          getLeadingIcon={getLeadingIcon}
          messageText={messageText}
          currentProjectId={currentProject.id}
          hasRetryableFailedFiles={hasRetryableFailedFiles}
          onRetry={onRetry}
        />
      )}
      {!finished && (
        <ProcessingVaultProgressBody
          eta={eta}
          messageText={messageText}
          totalCount={totalCount}
          processedCount={processedCount}
          progress={progress}
        />
      )}
    </div>
  )
}

type VaultProcessingProgressBodyProps = {
  eta: Date | null
  messageText: string
  totalCount: number
  processedCount: number
  progress: number
}

const ProcessingVaultProgressBody = ({
  eta,
  messageText,
  totalCount,
  processedCount,
  progress,
}: VaultProcessingProgressBodyProps) => {
  return (
    <>
      <div className="gap-y-2">
        <p className="mr-1 truncate">{messageText}</p>
        <div className="flex flex-col items-start gap-x-2 md:flex-row md:items-center">
          {totalCount > 0 && (
            <p className="truncate text-xs leading-6 text-muted">
              {`${processedCount} of ${totalCount} processed`}
            </p>
          )}
          {eta && (
            <Tooltip>
              <TooltipTrigger className="grid">
                <Badge
                  variant="secondary"
                  className="rounded-full border-0 px-2 py-1 text-muted"
                >
                  <Spinner size="xxs" className="mx-0 mr-1 shrink-0" />
                  <p className="text-xs">
                    {getEtaDisplayString(eta, false, null)}
                  </p>
                </Badge>
              </TooltipTrigger>
              <TooltipContent side="top" className="max-w-96">
                This is the time taken for our AI to ingest & understand your
                document. This time may vary based on the size/complexity of the
                files & time of day.
              </TooltipContent>
            </Tooltip>
          )}
        </div>
      </div>
      {totalCount > 0 && (
        <Progress value={progress} className="mb-2 h-2 w-full" />
      )}
    </>
  )
}

type VaultSuccessProgressBodyProps = {
  getLeadingIcon: () => React.ReactNode
  messageText: string
  currentProjectId: string
}

const SuccessVaultProgressBody = ({
  getLeadingIcon,
  messageText,
  currentProjectId,
}: VaultSuccessProgressBodyProps) => {
  const { trackEvent } = useAnalytics()
  const currentProjectMetadata = useVaultStore(
    useShallow((s) => s.currentProjectMetadata)
  )
  const recentlyUploadedFileIds = useVaultStore(
    useShallow((s) => s.recentlyUploadedFileIds)
  )
  const currentProject = useVaultStore(useShallow((s) => s.currentProject))
  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })
  const setShowProcessingProgress = useVaultStore(
    (s) => s.setShowProcessingProgress
  )
  const setIsReviewQuerySelectionDialogOpen = useVaultStore(
    (s) => s.setIsReviewQuerySelectionDialogOpen
  )

  const { historyData } = useRecentQueries({
    projectId: currentProjectMetadata.id,
    maxQueries: NUM_ALL_QUERIES_TO_FETCH,
    hasInProgressHistoryEvents: false,
  })

  const reviewQueries = getExistingCompletedVaultReviewQueries(
    historyData?.events || [],
    new Set(recentlyUploadedFileIds)
  )
  return (
    <div className="flex items-center justify-between space-x-2">
      <div className="flex flex-row items-center space-x-2">
        {getLeadingIcon()}
        <p className="mr-1 truncate">{messageText}</p>
      </div>
      <div className="flex items-center space-x-4">
        {reviewQueries.length > 0 && doesCurrentUserHaveEditPermission && (
          <>
            <Button
              variant="ghost"
              size="sm"
              className="shrink-0 space-x-1"
              onClick={() => {
                trackEvent('Vault Progress Bar Query Button Clicked', {
                  num_files: recentlyUploadedFileIds.length,
                })
                setIsReviewQuerySelectionDialogOpen(true)
              }}
              data-testid="vault-progress-success-dismiss-button"
            >
              <Icon icon={MessageSquarePlus} />
              <p className="truncate">Query</p>
            </Button>
            <Separator orientation="vertical" className="h-6" />
          </>
        )}
        <Button
          variant="ghost"
          size="sm"
          className="shrink-0 px-1"
          onClick={() => {
            trackEvent('Vault Progress Bar Dismiss Button Clicked', {
              progress_status: 'success',
            })
            setShowProcessingProgress(currentProjectId, false)
          }}
          data-testid="vault-progress-success-dismiss-button"
        >
          <Icon icon={X} />
        </Button>
      </div>
      <VaultReviewQuerySelectionDialog />
    </div>
  )
}

type VaultFailureProgressBodyProps = {
  getLeadingIcon: () => React.ReactNode
  messageText: string
  currentProjectId: string
  hasRetryableFailedFiles: boolean
  onRetry: (event: React.MouseEvent) => Promise<void>
}

const FailureVaultProgressBody = ({
  getLeadingIcon,
  messageText,
  currentProjectId,
  hasRetryableFailedFiles,
  onRetry,
}: VaultFailureProgressBodyProps) => {
  const { trackEvent } = useAnalytics()
  const setShowProcessingProgress = useVaultStore(
    (s) => s.setShowProcessingProgress
  )
  const fileIdToVaultFile = useVaultStore(
    useShallow((s) => s.fileIdToVaultFile)
  )
  const recentlyUploadedFileIds = useVaultStore(
    useShallow((s) => s.recentlyUploadedFileIds)
  )
  const recentlyUploadedFiles = recentlyUploadedFileIds
    .map((fileId) => fileIdToVaultFile[fileId])
    .filter(Boolean) as VaultFile[]
  const currentProject = useVaultStore(useShallow((s) => s.currentProject))
  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })
  const setDeleteRecords = useVaultStore((s) => s.setDeleteRecords)
  const setIsDeleteDialogOpen = useVaultStore((s) => s.setIsDeleteDialogOpen)
  return (
    <div className="flex flex-col items-center justify-between space-x-2 sm:flex-row">
      <div className="flex items-center space-x-2">
        {getLeadingIcon()}
        <p className="mr-1 truncate">{messageText}</p>
      </div>
      <div className="flex items-center space-x-4">
        {doesCurrentUserHaveEditPermission && (
          <Button
            variant="ghost"
            className="shrink-0 space-x-1"
            onClick={() => {
              const newFilesToDelete = recentlyUploadedFiles.filter(
                (file) => file.failureReason
              )
              trackEvent('Vault Failed Files Delete Button Clicked', {
                num_files: newFilesToDelete.length,
              })
              setDeleteRecords(newFilesToDelete.map((file) => fileAsItem(file)))
              setIsDeleteDialogOpen(true)
            }}
          >
            <Icon icon={Trash} />
            <p className="truncate">Delete</p>
          </Button>
        )}
        {hasRetryableFailedFiles && doesCurrentUserHaveEditPermission && (
          <>
            <Button
              variant="ghost"
              className="shrink-0 space-x-1"
              onClick={onRetry}
            >
              <Icon icon={RotateCw} />
              <p className="truncate">Try again</p>
            </Button>
            <Separator orientation="vertical" className="h-6" />
          </>
        )}
        <Button
          variant="ghost"
          className="shrink-0 px-1"
          onClick={() => {
            trackEvent('Vault Progress Bar Dismiss Button Clicked', {
              progress_status: 'failure',
            })
            setShowProcessingProgress(currentProjectId, false)
          }}
          data-testid="vault-progress-failure-dismiss-button"
          size="smIcon"
        >
          <Icon icon={X} />
        </Button>
      </div>
    </div>
  )
}

export default VaultProgress

import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { DialogClose } from '@radix-ui/react-dialog'
import { Divider } from 'antd'
import _ from 'lodash'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { site } from 'utils/server-data'
import { getSiteColor, getSiteName } from 'utils/site'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import RouterBreadcrumbs from 'components/common/router-breadcrumbs'
import useWorkspaceDetail from 'components/settings/hooks/use-workspace-detail'
import useWorkspaceOffboardJobs from 'components/settings/hooks/use-workspace-offboard-jobs'
import useWorkspacePermUserCounts from 'components/settings/hooks/use-workspace-perm-user-counts'
import useWorkspaceUsers from 'components/settings/hooks/use-workspace-users'
import useWorkspaces from 'components/settings/hooks/use-workspaces'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'

import WorkspaceDetailsImplementation from './workspace-details-implementation'
import WorkspaceDetailsUsers from './workspace-details-users'

const RAW_WORKSPACE_INFO_MESSAGE = 'Raw workspace metadata'

const WorkspaceDetails: React.FC = () => {
  const navigate = useNavigateWithQueryParams()
  const { id: workspaceId } = useParams()
  const { workspaces } = useWorkspaces()
  const { workspace, isLoadingWorkspace } = useWorkspaceDetail({ workspaceId })
  const { workspaceUsers: users, fetchWorkspaceUsers } = useWorkspaceUsers({
    workspaceId,
  })
  const {
    permUserCounts: permUserCountByWorkspace,
    fetchWorkspacePermUserCounts,
  } = useWorkspacePermUserCounts({
    workspaceId,
  })
  const userInfo = useAuthUser()

  const { workspaceOffboardJobs } = useWorkspaceOffboardJobs(
    Number(workspaceId)
  )

  const handleEdit = (): void => {
    navigate(`/settings/internal_admin/workspaces/${workspace.id}/edit`)
  }

  const fetchData = useCallback(async () => {
    if (_.isNil(workspace)) {
      return
    }
    await fetchWorkspaceUsers()
    await fetchWorkspacePermUserCounts()
  }, [workspace, fetchWorkspaceUsers, fetchWorkspacePermUserCounts])

  if (
    _.isNil(userInfo) ||
    !userInfo.IsInternalAdminReader ||
    isLoadingWorkspace ||
    _.isEmpty(workspace)
  )
    return null

  const channelPartner = workspace.settings.channelPartner ?? ''
  const badgeColor = workspace.site === site ? '' : getSiteColor(workspace.site)

  const displayOffboardBadge =
    !_.isNil(workspaceOffboardJobs?.jobs) &&
    workspaceOffboardJobs.jobs.length > 0 &&
    workspaceOffboardJobs.jobs.some((job) => job.jobStatus !== 'CANCELLED')

  const offboardBadge = workspaceOffboardJobs?.jobs.every(
    (job) => job.jobStatus === 'COMPLETED'
  ) ? (
    <Badge variant="destructive" className="h-6">
      Offboarded
    </Badge>
  ) : (
    <Badge variant="destructive" className="h-6">
      Offboarding
    </Badge>
  )

  const migrationBadge = (
    <Badge
      variant="secondary"
      className={cn('h-6', {
        'text-primary-inverse': !_.isEmpty(badgeColor),
      })}
      style={{ backgroundColor: badgeColor }}
    >
      {!_.isEmpty(badgeColor)
        ? `Migrated to ${getSiteName(workspace.site)}`
        : getSiteName(workspace.site)}
      {}
    </Badge>
  )

  const rawWorkspaceInfoModal = (
    <Dialog>
      <DialogTrigger>
        <Button variant="outline">{RAW_WORKSPACE_INFO_MESSAGE}</Button>
      </DialogTrigger>
      <DialogContent hasContainer={false} className="max-w-screen-lg">
        <DialogHeader>
          <DialogTitle>{RAW_WORKSPACE_INFO_MESSAGE}</DialogTitle>
        </DialogHeader>
        <div className="relative flex-col space-y-4">
          {userInfo.IsInternalAdminWriter && (
            <div className="flex flex-row justify-between space-x-4 rounded-lg bg-secondary px-4 py-2">
              <div className="flex flex-col justify-center">
                <text>
                  You can edit some workspace metadata from the ones listed
                  below. This option is available only to you and other super
                  admins.
                </text>
              </div>
              <div className="flex flex-col justify-center">
                <Button
                  variant="outline"
                  onClick={handleEdit}
                  className="min-w-[110px]"
                >
                  Edit metadata
                </Button>
              </div>
            </div>
          )}
          <ScrollArea
            isFullHeight
            className="relative max-h-[45vh] max-w-screen-lg overflow-auto rounded-lg bg-secondary p-2"
          >
            <pre className="overflow-auto">
              {JSON.stringify(workspace, null, 2)}
            </pre>
          </ScrollArea>
        </div>
        <DialogFooter>
          <DialogClose>
            <Button>Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )

  return (
    <>
      <SettingsAppHeader isInternalAdmin />
      <SettingsLayout>
        <RouterBreadcrumbs
          overridePath={`workspaces/${workspace.clientName}`}
          pathForIndexFunc={(index) => {
            if (index === 0) return '/settings/internal_admin/workspaces'
            return ''
          }}
        />
        <div className="flex items-center justify-between space-x-4">
          <div className="flex flex-row items-center space-x-3">
            <h3 className="text-2xl font-medium">{workspace.clientName}</h3>
            {/* NOTE: we can use a zustand store to update this badge automatically after user actions */}
            {displayOffboardBadge && offboardBadge}
            {migrationBadge}
          </div>
          {_.isEmpty(channelPartner) ? null : (
            <Badge variant="secondary" className="h-6">
              From {_.startCase(channelPartner.toLowerCase())}
            </Badge>
          )}
          {rawWorkspaceInfoModal}
        </div>
        <div className="mt-4">
          <WorkspaceDetailsUsers
            workspace={workspace}
            workspaces={workspaces}
            permUserCountByWorkspace={permUserCountByWorkspace}
            users={users}
            fetchData={fetchData}
          />
        </div>

        <div className="my-4">
          <Divider>Workspace implementation</Divider>
          <WorkspaceDetailsImplementation
            workspace={workspace}
            fetchData={fetchData}
          />
        </div>
      </SettingsLayout>
    </>
  )
}

export default WorkspaceDetails

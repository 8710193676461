import React, { useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'

import { Plus, X } from 'lucide-react'

import { cn } from 'utils/utils'

import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'components/ui/command'
import { Icon } from 'components/ui/icon/icon'

export type Tag = {
  value: string
  badgeDisplayText: string
  component?: React.ReactNode // optional custom component to render in options list
}

interface TagInputProps {
  placeholder: string
  sortedTags: Tag[]
  selectedTagValues: string[]
  shouldShowSuggestedTags: boolean
  allowCreatingNewTags: boolean
  disabled?: boolean
  setSelectedTagValues: (tagValues: string[]) => void
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void
  size?: 'sm' | 'md'
}

const TagInput: React.FC<TagInputProps> = ({
  placeholder,
  sortedTags,
  selectedTagValues,
  shouldShowSuggestedTags = true,
  allowCreatingNewTags = false,
  disabled = false,
  setSelectedTagValues,
  onFocus,
  size = 'sm',
}: TagInputProps) => {
  const [isInputFocused, setIsInputFocused] = React.useState(false)
  const [searchInputValue, setSearchInputValue] = useState('')

  const ref = useRef<HTMLDivElement>(null)
  const commandInputRef = useRef<HTMLInputElement>(null)

  const badgeDisplayTextByValue = useMemo(() => {
    return (sortedTags || []).reduce(
      (acc, tag) => {
        acc[tag.value] = tag.badgeDisplayText
        return acc
      },
      {} as Record<string, string>
    )
  }, [sortedTags])

  const getBadgeDisplayText = (tagValue: string) => {
    if (shouldShowSuggestedTags && !allowCreatingNewTags) {
      return badgeDisplayTextByValue[tagValue]
    }
    return badgeDisplayTextByValue[tagValue] ?? tagValue
  }

  useClickAway(ref, () => {
    setIsInputFocused(false)
    setSearchInputValue('')
  })

  const handleSelect = (tagValue: string) => {
    setSelectedTagValues([...selectedTagValues, tagValue])
    // Clear search input
    setSearchInputValue('')

    // Refocus input
    setTimeout(() => {
      commandInputRef.current?.focus()
    }, 0)
  }

  const handleRemoveTag = (tagValue: string) => {
    setSelectedTagValues(
      selectedTagValues.filter((value) => value !== tagValue)
    )
  }

  const handleKeyDownOnInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Backspace' &&
      searchInputValue === '' &&
      selectedTagValues.length > 0
    ) {
      setSelectedTagValues(selectedTagValues.slice(0, -1))
    }
  }

  const allTagsSelectedAndNoCreate =
    sortedTags.every((tag) => selectedTagValues.includes(tag.value)) &&
    !allowCreatingNewTags
  const shouldShowCommandList =
    isInputFocused &&
    shouldShowSuggestedTags &&
    (searchInputValue.length > 0 ||
      allTagsSelectedAndNoCreate ||
      sortedTags.some((tag) => !selectedTagValues.includes(tag.value)))

  const shouldShowAddNewTag =
    allowCreatingNewTags &&
    searchInputValue.length > 0 &&
    !sortedTags.some(
      (tag) =>
        tag.badgeDisplayText.toLowerCase() === searchInputValue.toLowerCase()
    )

  return (
    <div
      ref={ref}
      onFocus={onFocus}
      data-testid="vault-sharing-tag-input"
      className="relative"
    >
      <Command
        className="rounded"
        filter={(value, search) => {
          if (value.toLowerCase().includes(search.toLowerCase())) return 1
          return 0
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className={cn(
            'flex cursor-text flex-wrap items-center gap-1 rounded border p-1',
            {
              'cursor-not-allowed': disabled,
            }
          )}
          onClick={() => commandInputRef.current?.focus()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              commandInputRef.current?.focus()
            }
          }}
        >
          {(selectedTagValues || []).map((tagValue) => (
            <Badge
              key={`${tagValue}-badge`}
              variant="secondary"
              className={cn('flex h-fit items-center gap-1 px-1', {
                'hover:bg-button-secondary': disabled,
                'p-1 px-2': size === 'md',
              })}
            >
              <span
                className={cn('text-xs font-normal', {
                  'text-muted': disabled,
                  'text-sm': size === 'md',
                })}
              >
                {getBadgeDisplayText(tagValue)}
              </span>
              {!disabled && (
                <Button
                  variant="ghost"
                  className="h-3 w-3 p-0 text-muted"
                  onClick={() => {
                    handleRemoveTag(tagValue)
                  }}
                >
                  <Icon icon={X} size="small" />
                </Button>
              )}
            </Badge>
          ))}
          <CommandInput
            ref={commandInputRef}
            showSearchStyling={false}
            placeholder={
              selectedTagValues.length === 0 ? placeholder : undefined
            }
            value={searchInputValue}
            onValueChange={setSearchInputValue}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            onKeyDown={handleKeyDownOnInput}
            className={cn('h-3 w-full px-1 text-xs', {
              'w-0': !isInputFocused && selectedTagValues.length > 0,
              'cursor-not-allowed': disabled,
              'text-sm': size === 'md',
            })}
            disabled={disabled}
          />
        </div>
        <CommandList
          className={cn(
            'absolute top-full z-20 mt-1 max-h-60 w-full overflow-y-auto rounded border bg-primary',
            {
              hidden: !shouldShowCommandList && !shouldShowAddNewTag,
            }
          )}
        >
          {shouldShowCommandList && (
            <>
              {(searchInputValue.length > 0 || allTagsSelectedAndNoCreate) && (
                <CommandEmpty
                  className={cn('px-2 py-4', {
                    'px-3': size === 'md',
                  })}
                >
                  <p
                    className={cn('text-xs text-muted', {
                      'text-sm': size === 'md',
                    })}
                  >
                    No results found
                  </p>
                </CommandEmpty>
              )}
              <CommandGroup
                className={cn({ hidden: allTagsSelectedAndNoCreate })}
              >
                {(sortedTags || [])
                  .filter((tag) => !selectedTagValues.includes(tag.value))
                  .map((tag) => (
                    <CommandItem
                      key={tag.value}
                      onSelect={() => handleSelect(tag.value)}
                      value={tag.badgeDisplayText}
                      onMouseDown={(e) => {
                        e.preventDefault() // Prevent blur, otherwise list will close without appending new tag
                      }}
                    >
                      {/* render custom component if it exists, otherwise show badgeDisplayText */}
                      {tag.component ? (
                        tag.component
                      ) : (
                        <span
                          className={cn('text-xs', {
                            'text-sm': size === 'md',
                          })}
                        >
                          {tag.badgeDisplayText}
                        </span>
                      )}
                    </CommandItem>
                  ))}
              </CommandGroup>
            </>
          )}
          {shouldShowAddNewTag && (
            <CommandGroup>
              <CommandItem onSelect={() => handleSelect(searchInputValue)}>
                <div className="flex w-full items-center justify-between">
                  <span
                    className={cn('text-xs', {
                      'text-sm': size === 'md',
                    })}
                  >
                    “{searchInputValue}”
                  </span>
                  <Icon icon={Plus} size="small" />
                </div>
              </CommandItem>
            </CommandGroup>
          )}
        </CommandList>
      </Command>
    </div>
  )
}

export { TagInput }

import React from 'react'

import { cn } from 'utils/utils'

interface EmailIconProps {
  className?: string
}

const EmailIcon: React.FC<EmailIconProps> = ({ className = '' }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <path
        d="M17.375 3H7.25C6.65326 3 6.08097 3.23178 5.65901 3.64437C5.23705 4.05694 5 4.61652 5 5.2V22.8C5 23.3835 5.23705 23.9431 5.65901 24.3556C6.08097 24.7682 6.65326 25 7.25 25H20.75C21.3467 25 21.919 24.7682 22.341 24.3556C22.7629 23.9431 23 23.3835 23 22.8V8.5L17.375 3Z"
        fill="#F3F3F1"
        stroke="#CDCAC6"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 11.5L14.515 14.35C14.3606 14.4467 14.1822 14.498 14 14.498C13.8178 14.498 13.6394 14.4467 13.485 14.35L9 11.5M10 10H18C18.5523 10 19 10.4477 19 11V17C19 17.5523 18.5523 18 18 18H10C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10Z"
        stroke="#8E8B85"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EmailIcon

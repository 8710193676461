import React, { useCallback, useEffect, useMemo, useState } from 'react'

import emailAddresses from 'email-addresses'
import _ from 'lodash'
import pluralize from 'pluralize'

import {
  FetchWorkspaces,
  MoveToWorkspace,
  MoveToWorkspaceResponse,
  Workspace,
  WorkspaceKind,
} from 'models/workspace'
import { Maybe } from 'types'

import {
  displayErrorMessage,
  displaySuccessMessage,
  displayWarningMessage,
} from 'utils/toast'

import WorkspaceDropdown from 'components/common/workspace-dropdown'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import { Checkbox } from 'components/ui/checkbox'
import { Separator } from 'components/ui/separator'

import BulkUpsertUserModal from './bulk-upsert-user-modal'
import MultiEmailTextarea, { getCleanEmails } from './multi-email-textarea'
import { onUpsertUsers } from './user-management-utils'

const MAX_NUM_USERS = 500

const SettingsBulkMoveUsers = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [textAreaValue, setTextAreaValue] = useState<string>('')
  const [userLimitExceeded, setUserLimitExceeded] = useState<boolean>(false)
  const [textAreaLines, setTextAreaLines] = useState<number>(0)
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<Maybe<Workspace>>(null)
  const [moveDialogOpen, setMoveDialogOpen] = useState<boolean>(false)
  const [mismatchDomainUsers, setMismatchDomainUsers] = useState<string[]>([])
  const [successEmails, setSuccessEmails] = useState<string[]>([])
  const [failedEmails, setFailedEmails] = useState<string[]>([])
  const [moveWithPermsChecked, setMoveWithPermsChecked] =
    useState<boolean>(false)

  useEffect(() => {
    const fetchWorkspaces = async () => {
      const workspaces = await FetchWorkspaces()
      setWorkspaces(workspaces)
    }
    setLoading(true)
    fetchWorkspaces()
    setLoading(false)
  }, [])

  const orgDomains = useMemo(() => {
    if (!selectedWorkspace) return []
    let parentWorkspace: Maybe<Workspace> = selectedWorkspace
    if (parentWorkspace.kind !== WorkspaceKind.ORGANIZATION) {
      parentWorkspace = workspaces?.find(
        (w) => w.id === selectedWorkspace.parentId
      )
    }
    return parentWorkspace?.domains || []
  }, [selectedWorkspace, workspaces])

  const onMoveUserClick = useCallback(() => {
    onUpsertUsers(
      setLoading,
      setMoveDialogOpen,
      setMismatchDomainUsers,
      textAreaValue,
      orgDomains
    )
  }, [textAreaValue, orgDomains])

  const onMoveUserDialogSubmit = useCallback(async () => {
    if (textAreaLines === 0 || !selectedWorkspace) return
    const emails = getCleanEmails(textAreaValue)
    const [validEmails, invalidEmails] = _.partition(emails, (email) => {
      const parsed = emailAddresses.parseOneAddress(email)
      return parsed && /^[^@]+@[^@]+\.[^@]+$/.test(email) // Regex to ensure domain part has a dot
    })
    setFailedEmails(invalidEmails)

    try {
      const { usersMoved, usersFailed }: MoveToWorkspaceResponse =
        await MoveToWorkspace(
          selectedWorkspace?.id,
          validEmails,
          moveWithPermsChecked
        )

      setSuccessEmails(usersMoved)
      setFailedEmails((prev) => [...prev, ...usersFailed])
      if (usersFailed.length > 0 && usersMoved.length > 0) {
        displayWarningMessage(
          `Moved ${usersMoved.length} users successfully, failed to move ${usersFailed.length} users`,
          10
        )
      } else if (usersMoved.length > 0) {
        displaySuccessMessage(
          `Moved ${usersMoved.length} users successfully`,
          10
        )
      } else {
        displayErrorMessage(`Failed to move ${usersFailed.length} users`, 10)
      }
    } catch (error) {
      displayErrorMessage('Failed to move users', 10)
    } finally {
      setLoading(false)
    }
    setLoading(false)
    setMoveDialogOpen(false)
    setMoveWithPermsChecked(false)
  }, [textAreaValue, textAreaLines, selectedWorkspace, moveWithPermsChecked])

  const moveButtonDisabled =
    userLimitExceeded || textAreaLines === 0 || !selectedWorkspace || loading

  const cleanState = () => {
    setTextAreaValue('')
    setTextAreaLines(0)
    setSelectedWorkspace(null)
    setLoading(false)
    setMoveDialogOpen(false)
    setMismatchDomainUsers([])
    setSuccessEmails([])
    setFailedEmails([])
    setMoveWithPermsChecked(false)
  }

  return (
    <>
      <Accordion type="single" collapsible className="rounded-md border">
        <AccordionItem value="bulk-move-users">
          <AccordionTrigger className="p-4">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold">Bulk move users</h2>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="p-5">
              <p className="text-sm">
                Move multiple users to another workspace in bulk. This will
                delete all existing user workspace associations and create a new
                entry for the destination workspace. This doesnt move the user
                history or associated data from their existing workspace. If the
                destination workspace is a SAML workspace, only users matching
                the email domain will be moved.
              </p>
              <br />
              <p>
                Users can also be moved with their user-permissions tied to
                their current active workspace. This action applies to all users
                being moved, for selective permission moves use, user-inspector.
              </p>
              <div className="mt-4">
                <MultiEmailTextarea
                  value={textAreaValue}
                  onChange={(emails: string) => setTextAreaValue(emails)}
                  maxNumUsers={MAX_NUM_USERS}
                  emailsValidCallback={(valid: boolean) =>
                    setUserLimitExceeded(!valid)
                  }
                  placeholder="Enter user emails to move, one email per line"
                  setNumEmails={(numEmails: number) =>
                    setTextAreaLines(numEmails)
                  }
                />
                <div className="mt-4 flex justify-between">
                  <div className="flex space-x-4">
                    <WorkspaceDropdown
                      selected={selectedWorkspace}
                      setSelected={setSelectedWorkspace}
                      workspaces={workspaces.filter((w) => !w.deletedAt)}
                      className="w-60"
                      showClearOption
                    />
                    <Separator orientation="vertical" />
                    <Checkbox
                      label="Move with user permissions"
                      checked={moveWithPermsChecked}
                      onCheckedChange={() =>
                        setMoveWithPermsChecked(!moveWithPermsChecked)
                      }
                    />
                  </div>
                  <div className="space-x-2">
                    {successEmails.length + failedEmails.length > 0 ? (
                      <Button variant="destructive" onClick={cleanState}>
                        Clear
                      </Button>
                    ) : (
                      <Button
                        disabled={moveButtonDisabled}
                        onClick={onMoveUserClick}
                      >
                        Move{' '}
                        {textAreaLines === 0
                          ? 'Users'
                          : `${textAreaLines} ${pluralize(
                              'Users',
                              textAreaLines
                            )}`}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              {successEmails.length + failedEmails.length > 0 && (
                <div className="mt-4">
                  <Card>
                    <CardHeader>
                      <h3 className="text-lg font-semibold">
                        Bulk move results
                      </h3>
                    </CardHeader>
                    <CardContent className="mt-2 space-y-2">
                      <p>Success users: {successEmails.length}</p>
                      {failedEmails.length > 0 && (
                        <div className="flex">
                          <p>Failed users ({failedEmails.length}):</p>
                          {_.sortBy(failedEmails).map((user, index) => (
                            <Badge key={index} variant="ghost">
                              {user}
                            </Badge>
                          ))}
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </div>
              )}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <BulkUpsertUserModal
        open={moveDialogOpen}
        onOpenChange={(open) => {
          setMoveDialogOpen(open)
          setLoading(false)
        }}
        onUpsert={onMoveUserDialogSubmit}
        mismatchDomainUsers={mismatchDomainUsers}
        selectedUsers={getCleanEmails(textAreaValue)}
        workspace={selectedWorkspace}
        customerFacing={false}
        type="move"
        movingWithPerms={moveWithPermsChecked}
        orgDomains={orgDomains}
      />
    </>
  )
}

export default SettingsBulkMoveUsers

import React from 'react'

import { displayFileUploadError } from 'utils/dropzone'

export function usePasswordProtectedFiles(
  passwordProtectedFiles: File[],
  onRemoveFile: (fileName: string) => void
) {
  const [fileToPasswordMap, setFileToPasswordMap] = React.useState<
    Record<string, string>
  >({})
  const [isPasswordCollectionDialogOpen, setIsPasswordCollectionDialogOpen] =
    React.useState(false)

  // Open dialog when files are provided
  React.useEffect(() => {
    if (passwordProtectedFiles.length > 0) {
      setIsPasswordCollectionDialogOpen(true)
    } else {
      setIsPasswordCollectionDialogOpen(false)
    }
  }, [passwordProtectedFiles])

  const handlePasswordChange = React.useCallback(
    (fileName: string, password: string) => {
      setFileToPasswordMap((prev) => ({ ...prev, [fileName]: password }))
    },
    []
  )

  const handleFileRemove = React.useCallback(
    (fileName: string) => {
      // Remove password from map
      setFileToPasswordMap((prev) => {
        const newMap = { ...prev }
        delete newMap[fileName]
        return newMap
      })

      // Remove file using provided callback
      onRemoveFile(fileName)
    },
    [onRemoveFile]
  )

  const resetState = React.useCallback(() => {
    setFileToPasswordMap({})
    setIsPasswordCollectionDialogOpen(false)
  }, [])

  const handleCancel = React.useCallback(() => {
    const fileNames = passwordProtectedFiles.map((file) => file.name)
    resetState()
    displayFileUploadError(
      fileNames,
      'The following password protected files were not uploaded: ',
      false
    )
  }, [passwordProtectedFiles, resetState])

  return {
    fileToPasswordMap,
    isPasswordCollectionDialogOpen,
    handlePasswordChange,
    handleFileRemove,
    handleCancel,
    resetState,
  }
}

import * as React from 'react'

import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import AssistantFiles from 'components/assistant/components/assistant-files'
import SourcesToolbar from 'components/assistant/features/composer/components/sources-toolbar'
import { useAssistantFileUpload } from 'components/assistant/hooks/use-assistant-file-upload'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { ScrollArea } from 'components/ui/scroll-area'

interface FilesRendererProps {
  handleCancel: () => void
}

const FilesRenderer: React.FC<FilesRendererProps> = ({ handleCancel }) => {
  const [mode, documents, documentsUploading, zipFiles, pstFiles] =
    useAssistantStore(
      useShallow((s) => [
        s.mode,
        s.documents,
        s.documentsUploading,
        s.zipFiles,
        s.pstFiles,
      ])
    )
  const { handleRemoveFile } = useAssistantFileUpload(mode)

  const numFiles = documents.length + documentsUploading.length
  return (
    <>
      <SourcesToolbar
        handleCancel={handleCancel}
        text={`${numFiles} ${pluralize('file', numFiles)} attached`}
      />
      <div className="flex flex-col border-t-[0.5px]">
        {/* 214 = 36 (file item height) * 5.5 + 16 (padding) */}
        <ScrollArea className="h-full" maxHeight="max-h-[214px]">
          <div className="-mx-2">
            <AssistantFiles
              files={documents}
              uploadingFiles={documentsUploading}
              handleRemoveFile={handleRemoveFile}
              zipFiles={zipFiles}
              pstFiles={pstFiles}
            />
          </div>
        </ScrollArea>
      </div>
    </>
  )
}

export default FilesRenderer

import { QueryCapRuleLevel } from 'openapi/models/QueryCapRuleLevel'
import { QueryCapRuleTimeFrame } from 'openapi/models/QueryCapRuleTimeFrame'
import { QueryCapRuleUnitLevel } from 'openapi/models/QueryCapRuleUnitLevel'
import { WorkspaceFeature } from 'openapi/models/WorkspaceFeature'
import Services from 'services'
import { RequestError } from 'services/backend/backend'

import { TaskType } from 'utils/task'

export const levelToDisplayString: Record<QueryCapRuleLevel, string> = {
  [QueryCapRuleLevel.PER_USER]: 'Per user',
  [QueryCapRuleLevel.PER_WORKSPACE]: 'Per workspace',
}

export const timeFrameToDisplayString: Record<QueryCapRuleTimeFrame, string> = {
  [QueryCapRuleTimeFrame.CALENDAR_MONTH]: 'Calendar month',
  [QueryCapRuleTimeFrame.ANNUAL]: 'Annual',
  [QueryCapRuleTimeFrame.LIFETIME]: 'Lifetime',
}

export const unitLevelToDisplayString: Record<QueryCapRuleUnitLevel, string> = {
  [QueryCapRuleUnitLevel.CELL]: 'Cell',
  [QueryCapRuleUnitLevel.FILE]: 'File',
  [QueryCapRuleUnitLevel.QUERY]: 'Query',
}

export const vaultFeatureToDisplayString: Record<WorkspaceFeature, string> = {
  [WorkspaceFeature.VAULT_ADD_ON]: 'Add-On',
}

export interface QueryCapRule {
  id: string
  level: QueryCapRuleLevel
  workspaceId: number
  userId?: string
  taskType: TaskType
  timeFrame: QueryCapRuleTimeFrame
  unitLevel: QueryCapRuleUnitLevel
  value: number
}

export const getWorkspaceQueryCapRules = async (
  workspaceId: number
): Promise<QueryCapRule[]> => {
  const searchParams = new URLSearchParams()
  searchParams.append('workspace_id', workspaceId.toString())
  return Services.Backend.Get<QueryCapRule[]>(
    `internal_admin/query_cap_rules?${searchParams.toString()}`
  )
}

export const getUserQueryCapRules = async (
  userId?: string,
  workspaceId?: number
): Promise<QueryCapRule[]> => {
  if (!userId || !workspaceId) {
    return []
  }
  const searchParams = new URLSearchParams()
  searchParams.append('user_id', userId)
  searchParams.append('workspace_id', workspaceId.toString())
  return Services.Backend.Get<QueryCapRule[]>(
    `internal_admin/query_cap_rules?${searchParams.toString()}`
  )
}

export const createQueryCapRule = async (
  queryCapRule: QueryCapRule
): Promise<QueryCapRule> => {
  return Services.Backend.Post<QueryCapRule>(
    'internal_admin/query_cap_rules',
    queryCapRule,
    { throwOnError: true }
  )
}

export const updateQueryCapRule = async (
  queryCapRule: QueryCapRule
): Promise<QueryCapRule> => {
  const response = await Services.Backend.Patch<QueryCapRule>(
    `internal_admin/query_cap_rules/${queryCapRule.id}`,
    queryCapRule,
    { throwOnError: true }
  )
  if (response instanceof RequestError) {
    throw new Error(response.message)
  }
  return response
}

export const deleteQueryCapRule = async (id: string): Promise<void> => {
  await Services.Backend.Delete(`internal_admin/query_cap_rules/${id}`, {
    throwOnError: true,
  })
}

import React from 'react'

import { CopyIcon, DownloadIcon } from 'lucide-react'

import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'
import { Maybe } from 'types'

import { copyToClipboard } from 'utils/copy-to-clipboard'
import { Source } from 'utils/task'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { download, downloadString } from 'utils/utils'

import AssistantSources from 'components/assistant/components/assistant-sources'
import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import AnswerBlock, {
  AnswerBlockToolbarAction,
} from 'components/assistant/workflows/components/answer-block/answer-block'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowAnswerRenderer: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = ({ blockParams, blockStatus, onDownload }) => {
  const content = blockParams.answer.response
  const headerText = blockParams.answer.headerText
  const { getHrvyInfoMetadata, handleSourceClick } = useSourceUtils({
    sources: (blockParams.answer.sources || []) as Source[],
  })

  const toolbarActions: AnswerBlockToolbarAction[] = [
    {
      icon: CopyIcon,
      tooltip: 'Copy',
      onClick: () => {
        copyToClipboard(content)
          .then(() => displaySuccessMessage('Copied text to clipboard'))
          .catch(() => displayErrorMessage('Sorry, something went wrong'))
      },
    },
    {
      icon: DownloadIcon,
      tooltip: 'Download',
      onClick: () => {
        if (onDownload) {
          return onDownload()
        }
        const hasTranslatedDocUrl = (
          metadata: any
        ): metadata is { translatedDocUrl: string } => {
          return metadata && typeof metadata.translatedDocUrl === 'string'
        }

        if (hasTranslatedDocUrl(blockParams.answer.metadata)) {
          download(blockParams.answer.metadata.translatedDocUrl)
            .then((message) => displaySuccessMessage(message))
            .catch((error) => displayErrorMessage(error))
          return
        }

        downloadString(content, headerText)
          .then((message) => displaySuccessMessage(message))
          .catch((error) => displayErrorMessage(error))
      },
    },
  ]

  const Sidebar = blockParams.answer.sources.length ? (
    <AssistantSources
      allDocuments={[]}
      sources={blockParams.answer.sources as Maybe<Source[]>}
      isStreaming={blockParams.answer.progress !== 100}
      onSetActiveFileId={handleSourceClick}
      // 68px makes it align with the content of the block
      className="sticky left-0 top-[68px] ml-4 sm:ml-4"
    />
  ) : undefined

  return (
    <AssistantWorkflowThreadBlock sidebar={Sidebar}>
      <AssistantWorkflowHarveyComponent>
        <AssistantWorkflowThreadText
          blockStatus={blockStatus}
          text={headerText}
        />
        {content.length > 0 && (
          <div className="mt-5">
            <AnswerBlock
              content={content}
              getHrvyInfoMetadata={getHrvyInfoMetadata}
              toolbarTitle={headerText}
              toolbarActions={toolbarActions}
            />
          </div>
        )}
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowAnswerRendererInputBox: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = ({ blockParams }) => {
  const headerText = blockParams.answer.headerText

  return (
    <WorkflowInput>
      <WorkflowInputFooter footer={headerText} />
    </WorkflowInput>
  )
}

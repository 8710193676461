// dropzone.ts
import { FileRejection } from 'react-dropzone'

import JSZip from 'jszip'
import _ from 'lodash'
import pluralize from 'pluralize'

import { PstFile } from 'openapi/models/PstFile'
import { PstFileResponse } from 'openapi/models/PstFileResponse'
import Services from 'services'
import {
  FileType,
  FileTypeReadableName,
  removeSubsetDuplicates,
} from 'types/file'
import { FileTypeToExtension } from 'types/file'

import {
  removeSlashesInFileName,
  bytesToReadable,
  isPDFFilePasswordProtected,
} from './file-utils'
import { joinWithAnd } from './string'
import { displayErrorMessage, displayWarningMessage } from './toast'

export const DISPLAY_FILE_ERROR_COUNT = 3

const isValidZipFileExtension = (
  filepath: string,
  allowedFileTypes: FileType[]
): boolean => {
  const validExtensions = allowedFileTypes.flatMap(
    (fileType) => FileTypeToExtension[fileType]
  )
  const hiddenFilesPrefix = '._' // Zip will contain metadata clones of each file with '._' as a prefix
  const fileName = filepath.split('/').pop()

  if (fileName === undefined) {
    return false
  }
  if (fileName.startsWith(hiddenFilesPrefix)) {
    return false
  }

  if (
    validExtensions.some((extension) =>
      fileName.toLowerCase().endsWith(extension)
    )
  ) {
    return true
  }
  return false
}

interface MaxTotalFileSizeProps {
  maxTotalFileSize: number
  currentTotalFileSize: number
}

interface FileDropProps {
  acceptedFiles: File[]
  fileRejections: FileRejection[]
  currentFileCount: number
  maxFiles: number
  acceptedFileTypes: FileType[]
  maxFileSize: number
  maxExcelFileSize?: number
  maxZipFileSize?: number
  maxTotalFileSizeProps?: MaxTotalFileSizeProps
  shouldSkipPasswordProtectionCheck?: boolean
  handleAcceptedFiles: (files: File[]) => Promise<void>
  handleRejectedFiles?: () => void
  handlePasswordProtectedFiles?: (files: File[]) => void // optional new handler for password protected files
}

export const onDrop = async (params: FileDropProps) => {
  const {
    acceptedFiles,
    fileRejections,
    currentFileCount,
    maxFiles,
    acceptedFileTypes,
    maxFileSize,
    maxExcelFileSize,
    maxZipFileSize,
    maxTotalFileSizeProps,
    shouldSkipPasswordProtectionCheck,
    handleAcceptedFiles,
    handleRejectedFiles = () => {},
    handlePasswordProtectedFiles,
  } = params

  const { maxTotalFileSize, currentTotalFileSize } = maxTotalFileSizeProps || {}

  if (
    fileRejections.length + acceptedFiles.length + currentFileCount >
    maxFiles
  ) {
    displayErrorMessage(
      `You can’t upload more than ${maxFiles} ${pluralize('files', maxFiles)}.`
    )
    handleRejectedFiles()
    return
  }

  const files: File[] = []
  const pstFiles: File[] = []
  const excelFilesToBig: string[] = []
  const filesTooBig: string[] = []
  const passwordProtectedFiles: File[] = []
  const invalidFileTypes: string[] = []
  const emptyFiles: string[] = []

  await Promise.all(
    acceptedFiles.map(async (file) => {
      const isExcelFile =
        file.type === FileType.EXCEL || file.type === FileType.EXCEL_LEGACY
      if (file.type === FileType.ZIP || file.type === FileType.ZIP_LEGACY) {
        if (!_.isNil(maxZipFileSize) && file.size > maxZipFileSize) {
          filesTooBig.push(file.name)
        } else {
          let filesFromZip: { files: File[]; invalidFiles: File[] } = {
            files: [],
            invalidFiles: [],
          }
          try {
            filesFromZip = await getFilesFromZip(file)
          } catch (error) {
            displayErrorMessage(`${error}`)
            return
          }
          filesFromZip.files.forEach((f) => {
            const isExcelFile =
              f.type === FileType.EXCEL || f.type === FileType.EXCEL_LEGACY
            const isPstFile = f.name.endsWith('.pst')
            if (
              isExcelFile &&
              !_.isNil(maxExcelFileSize) &&
              f.size > maxExcelFileSize
            ) {
              excelFilesToBig.push(f.name)
            } else if (isPstFile) {
              pstFiles.push(f)
            } else if (f.size > maxFileSize) {
              filesTooBig.push(f.name)
            } else {
              files.push(f)
            }
          })
          invalidFileTypes.push(...filesFromZip.invalidFiles.map((f) => f.name))
        }
      } else if (file.name.endsWith('.pst')) {
        if (!_.isNil(maxZipFileSize) && file.size > maxZipFileSize) {
          filesTooBig.push(file.name)
        } else {
          const filesFromPst = await getFilesFromPst(file)
          filesFromPst.files.forEach((f) => {
            if (f.size > maxFileSize) {
              filesTooBig.push(f.name)
            } else {
              const sanitizedFileName = removeSlashesInFileName(f.name)
              files.push(new File([f], sanitizedFileName, { type: f.type }))
            }
          })
          invalidFileTypes.push(...filesFromPst.invalidFiles.map((f) => f.name))
        }
      } else if (
        isExcelFile &&
        !_.isNil(maxExcelFileSize) &&
        file.size > maxExcelFileSize
      ) {
        excelFilesToBig.push(file.name)
      } else if (file.size > maxFileSize) {
        filesTooBig.push(file.name)
      } else {
        const sanitizedFileName = removeSlashesInFileName(file.name)
        files.push(new File([file], sanitizedFileName, { type: file.type }))
      }
    })
  )

  // if any of the files have a pst extension, we need to unpack them
  if (pstFiles.length > 0) {
    await Promise.all(
      pstFiles.map(async (file) => {
        const filesFromPst = await getFilesFromPst(file)

        filesFromPst.files.forEach((f) => {
          if (f.size > maxFileSize) {
            filesTooBig.push(f.name)
          } else {
            files.push(f)
          }
        })

        invalidFileTypes.push(...filesFromPst.invalidFiles.map((f) => f.name))
      })
    )
  }

  if (fileRejections.length > 0) {
    fileRejections.forEach((rejection) => {
      const file = rejection.file
      rejection.errors.forEach((error) => {
        if (error.code === 'file-too-large') {
          filesTooBig.push(file.name)
        } else if (error.code === 'file-invalid-type') {
          invalidFileTypes.push(file.name)
        }
      })
    })
  }

  let totalFileSize = 0

  for (const file of files) {
    if (!shouldSkipPasswordProtectionCheck) {
      const isPasswordProtected = await isPDFFilePasswordProtected(file)
      if (isPasswordProtected) {
        passwordProtectedFiles.push(file)
      }
    }

    const isFileTypeAccepted = file.type
      ? (acceptedFileTypes as string[]).includes(file.type)
      : isValidZipFileExtension(file.name, acceptedFileTypes)

    if (!isFileTypeAccepted) {
      invalidFileTypes.push(file.name)
    }

    if (file.size === 0) {
      emptyFiles.push(file.name)
    }

    totalFileSize += file.size
  }

  const passwordProtectedFileNames = passwordProtectedFiles.map(
    (file) => file.name
  )

  if (files.length + currentFileCount > maxFiles) {
    displayErrorMessage(
      `Upload failed. You can’t upload more than ${maxFiles} ${pluralize(
        'files',
        maxFiles
      )} at a time.`
    )
    handleRejectedFiles()
    return
  }

  if (
    !_.isNil(maxTotalFileSize) &&
    !_.isNil(currentTotalFileSize) &&
    totalFileSize + currentTotalFileSize >= maxTotalFileSize
  ) {
    displayErrorMessage(
      `Upload failed. You can’t upload more than a total of ${bytesToReadable(
        maxTotalFileSize,
        0
      )} at a time.`
    )
    handleRejectedFiles()
    return
  }

  if (!_.isNil(maxExcelFileSize) && excelFilesToBig.length > 0) {
    displayFileUploadError(
      excelFilesToBig,
      `Upload failed, max allowed Excel file size is ${bytesToReadable(
        maxExcelFileSize,
        0
      )}. The following files are too large: `,
      files.length > 0
    )
  }

  if (filesTooBig.length > 0) {
    displayFileUploadError(
      filesTooBig,
      `Upload failed, max allowed file size is ${bytesToReadable(
        maxFileSize,
        0
      )}. The following files are too large: `,
      files.length > 0
    )
  }

  if (passwordProtectedFiles.length > 0) {
    if (
      !_.isNil(handlePasswordProtectedFiles) &&
      _.isFunction(handlePasswordProtectedFiles)
    ) {
      handlePasswordProtectedFiles(passwordProtectedFiles)
    } else {
      displayFileUploadError(
        passwordProtectedFileNames,
        'Upload failed, the following files are password protected: ',
        files.length > 0
      )
    }
  }

  if (invalidFileTypes.length > 0) {
    displayFileUploadError(
      invalidFileTypes,
      `One or more files you tried to upload failed because of an unsupported file type. Supported file types are ${joinWithAnd(
        removeSubsetDuplicates(
          acceptedFileTypes.map((fileType) => FileTypeReadableName[fileType])
        )
      )}. The following files have invalid file types: `,
      files.length > 0
    )
  }

  if (emptyFiles.length > 0) {
    displayFileUploadError(
      emptyFiles,
      `Upload failed, one or more files you have uploaded are empty: `,
      files.length > 0
    )
  }

  const unfilteredFiles = new Set([
    ...emptyFiles,
    ...passwordProtectedFileNames,
    ...invalidFileTypes,
    ...filesTooBig,
  ])

  const filteredFiles = files.filter((file) => !unfilteredFiles.has(file.name))
  await handleAcceptedFiles(filteredFiles)
}

const FILE_UPLOAD_ERROR_DURATION = 20

export const displayFileUploadError = (
  filenames: string[],
  error: string,
  acceptedSomeFiles: boolean
) => {
  let fileNames = filenames.slice(0, DISPLAY_FILE_ERROR_COUNT).join(', ')

  if (filenames.length > DISPLAY_FILE_ERROR_COUNT) {
    fileNames = fileNames.concat(
      ` and ${filenames.length - DISPLAY_FILE_ERROR_COUNT} more`
    )
  }

  if (acceptedSomeFiles) {
    displayWarningMessage(`${error} ${fileNames}`, FILE_UPLOAD_ERROR_DURATION)
  } else {
    displayErrorMessage(`${error} ${fileNames}`, FILE_UPLOAD_ERROR_DURATION)
  }
}

const IGNORE_FILE_PREFIXES = ['._', '__MACOSX']
const IGNORE_FILE_SUFFIXES = ['.DS_Store']

export const getFilesFromZip = async (
  zipFile: File
): Promise<{ files: File[]; invalidFiles: File[] }> => {
  const jszip = new JSZip()
  const data = await zipFile.arrayBuffer()
  const zip = await jszip.loadAsync(data)
  const invalidFiles: File[] = []
  const filePromises: Array<Promise<File>> = []

  zip.forEach((relativePath: string, zipObject: JSZip.JSZipObject) => {
    if (
      IGNORE_FILE_PREFIXES.some((prefix) => relativePath.startsWith(prefix)) ||
      IGNORE_FILE_SUFFIXES.some((suffix) => relativePath.endsWith(suffix))
    ) {
      return
    }

    if (zipObject.dir) {
      return
    }

    const filePromise = zipObject.async('blob').then((content: BlobPart) => {
      const fileExtension = '.' + relativePath.toLowerCase().split('.').pop()
      const match = Object.entries(FileTypeToExtension).find(([, extensions]) =>
        extensions.includes(fileExtension)
      )
      const type = (match?.at(0) || '') as string
      return new File([content], relativePath, { type })
    })
    filePromises.push(filePromise)
  })
  const files = await Promise.all(filePromises)
  return { files, invalidFiles }
}

export const getFilesFromPst = async (
  pstFile: File
): Promise<{ files: File[]; invalidFiles: File[] }> => {
  const formData = new FormData()
  formData.append('file', pstFile)

  try {
    const response = await Services.Backend.Post<PstFileResponse>(
      'parse_pst',
      formData
    )
    // Convert the files unpacked from the PST into File objects
    const pstFileName = pstFile.name.replace('.pst', '')
    const files: File[] = (response.files ?? []).map((fileData: PstFile) => {
      const byteString = atob(fileData.content)

      // Create a typed array from the byte string
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      // Create a blob from the typed array
      const blob = new Blob([ia], { type: fileData.mimeType })

      // Create and return a File object
      const relativePath = `${pstFileName}/${fileData.name}`
      return new File([blob], relativePath, { type: fileData.mimeType })
    })

    return { files, invalidFiles: [] }
  } catch (error) {
    return { files: [], invalidFiles: [] }
  }
}

import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMount } from 'react-use'

import { IRowNode } from 'ag-grid-community'
import { CustomNoRowsOverlayProps } from 'ag-grid-react'
import { X, FilePlus2 } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import { filtersSearchParamKey } from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

const NoRowsOverlay = (props: CustomNoRowsOverlayProps) => {
  const { api } = props

  const [totalRowCount, setTotalRowCount] = useState<number>(0)

  const [, setSearchParams] = useSearchParams()
  const resetFilterState = useVaultDataGridFilterStore(
    (state) => state.resetFilterState
  )

  const [currentProject, projectIdToFileIds] = useVaultStore(
    useShallow((state) => [state.currentProject, state.projectIdToFileIds])
  )

  const currentAppliedFilters = useVaultDataGridFilterStore(
    useShallow((state) => state.currentAppliedFilters)
  )

  const setIsAddFilesDialogOpen = useVaultStore(
    useShallow((state) => state.setIsAddFilesDialogOpen)
  )

  const totalFileCount = currentProject
    ? projectIdToFileIds[currentProject.id]?.length ?? 0
    : 0

  useMount(() => {
    let totalRowCount = 0
    api.forEachNode((node: IRowNode) => {
      // if the node is a group row, we don't want to count it
      if (node.group) return
      totalRowCount++
    })
    setTotalRowCount(totalRowCount)
  })

  const clearFilters = () => {
    resetFilterState()
    api.setFilterModel(null)
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.delete(filtersSearchParamKey)
      return newParams
    })
  }

  const addFilesHandler = () => {
    setIsAddFilesDialogOpen(true)
  }

  // if there are filters applied, show the filters and a clear filters button
  if (currentAppliedFilters.length > 0) {
    return (
      <div role="presentation" className="flex flex-col gap-4 text-muted">
        <p>No rows to display</p>
        <div className="flex items-center justify-between gap-4 rounded-md border px-4 py-2 sm:min-w-96">
          <p className="text-xs">{`${totalRowCount} files hidden by filters`}</p>
          <Button
            onClick={clearFilters}
            variant="ghost"
            className="flex items-center gap-1"
          >
            <Icon icon={X} size="small" />
            <p className="text-xs">Clear filters</p>
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div role="presentation" className="flex flex-col gap-4 text-muted">
      <p>Get started by adding files for the query</p>
      <div className="flex items-center justify-between gap-4 rounded-md border px-4 py-2 sm:min-w-96">
        <p className="text-xs">{`${totalFileCount} files available`}</p>
        <Button
          onClick={addFilesHandler}
          variant="ghost"
          className="flex items-center gap-1"
        >
          <Icon icon={FilePlus2} size="small" />
          <p className="text-xs">Add files</p>
        </Button>
      </div>
    </div>
  )
}

export default NoRowsOverlay

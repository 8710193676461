import React from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'

import { cn } from 'utils/utils'

interface ProgressPauseProps extends React.SVGProps<SVGSVGElement> {
  className?: string
  progressPercentage?: number
}

export const ProgressPause = ({
  className,
  progressPercentage = 0,
}: ProgressPauseProps): JSX.Element => {
  return (
    <div className={cn(className, 'p-1.5')}>
      <CircularProgressbarWithChildren
        value={progressPercentage}
        styles={{
          // Path styling (the actual progress arc)
          path: {
            stroke: 'hsl(var(--neutral-950))',
            strokeWidth: '8',
            strokeLinecap: 'round',
          },
          // Trail styling (the background circle)
          trail: {
            stroke: 'hsl(var(--neutral-200))',
            strokeWidth: '8',
          },
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="7"
            y="6.5"
            width="2"
            height="7"
            rx="1"
            fill="hsl(var(--neutral-950))"
          />
          <rect
            x="11"
            y="6.5"
            width="2"
            height="7"
            rx="1"
            fill="hsl(var(--neutral-950))"
          />
        </svg>
      </CircularProgressbarWithChildren>
    </div>
  )
}

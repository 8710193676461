import * as React from 'react'

import { FileType } from 'types/file'

import { cn } from 'utils/utils'

import { FileToShow } from 'components/assistant/workflows/blocks/assistant-workflow-file-upload-input'
import { Button } from 'components/ui/button'
import DocIcon from 'components/ui/icons/doc-icon'
import PdfIcon from 'components/ui/icons/pdf-icon'
import SheetIcon from 'components/ui/icons/sheet-icon'
import UnknownUploadIcon from 'components/ui/icons/unknown-upload-icon'
import { Spinner } from 'components/ui/spinner'

type FileToShowWithLoading = FileToShow & {
  isLoading?: boolean
}

interface MultipleDocumentsCardProps {
  files: FileToShowWithLoading[]
  headerText?: string
  // map of file id to onClick function
  onClickDict?: { [id: string]: (file: FileToShowWithLoading) => void }
}

const FILE_ICONS = {
  [FileType.PDF]: PdfIcon,
  [FileType.WORD]: DocIcon,
  [FileType.EXCEL]: SheetIcon,
  [FileType.WORD_LEGACY]: DocIcon,
  [FileType.EXCEL_LEGACY]: SheetIcon,
}

const MultipleDocumentsCard: React.FC<MultipleDocumentsCardProps> = ({
  files,
  headerText,
  onClickDict,
}) => {
  return (
    <div className="rounded-lg border bg-primary p-[6px]">
      <div className="flex items-center justify-between px-4 pb-2 pt-3">
        <div className="text-sm font-medium">Files</div>
        <div className="text-xs text-muted">{headerText}</div>
      </div>
      {files.map((file) => {
        let FileIcon = UnknownUploadIcon
        if (file.fileType && Object.keys(FILE_ICONS).includes(file.fileType)) {
          FileIcon = FILE_ICONS[file.fileType as keyof typeof FILE_ICONS]
        }

        const onClick = onClickDict?.[file.id]
        const disableClick = !onClick

        return (
          <Button
            className={cn(
              'group h-8 w-full bg-primary pl-[6px] pr-[10px] transition',
              !disableClick && 'hover:bg-secondary disabled:hover:bg-primary',
              disableClick && 'cursor-default'
            )}
            onClick={!disableClick ? () => onClick(file) : undefined}
            variant="unstyled"
            disabled={file.isLoading}
            key={file.name}
          >
            <div
              className={cn(
                'flex h-full items-center gap-[10px] transition duration-75',
                !file.isLoading && 'group-active:opacity-70'
              )}
            >
              <div className="flex size-4 shrink-0 items-center justify-center">
                {file.isLoading ? (
                  <Spinner size="xxs" className="mr-0 fill-primary" />
                ) : (
                  <FileIcon className="size-4" />
                )}
              </div>
              <div className="flex h-full flex-1 items-center border-b group-last:border-b-0">
                <p className="text-xs">{file.name}</p>
              </div>
            </div>
          </Button>
        )
      })}
    </div>
  )
}

export default MultipleDocumentsCard

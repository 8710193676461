import React, { useMemo } from 'react'

import { WorkspacePermBundle } from 'models/perms'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'

import { PERMISSION_CATEGORIES } from 'components/settings/workspace/workspace-details/workspace-details-roles-v2'
import { MultiSelect } from 'components/ui/multi-select'

type PermBundleForSelect = Pick<WorkspacePermBundle, 'id' | 'name' | 'category'>

export const PermBundlesMultiSelect: React.FC<{
  permBundles: PermBundleForSelect[]
  selectedValues: PermissionBundleId[]
  setSelectedValues: (selectedValues: PermissionBundleId[]) => void
}> = ({ permBundles, selectedValues, setSelectedValues }) => {
  const sortedGroups = useMemo(() => {
    return PERMISSION_CATEGORIES.map((category) => ({
      label: category,
      entries: permBundles
        .filter((permBundle) => permBundle.category === category)
        .map((permBundle) => ({
          text: permBundle.name,
          value: permBundle.id,
          values: [permBundle.id],
        })),
    }))
  }, [permBundles])

  // In the interim, some may not be visible due to not having a category.
  const visiblePermBundles = permBundles.filter((permBundle) =>
    PERMISSION_CATEGORIES.includes(permBundle.category)
  )

  return (
    <MultiSelect
      sortedGroups={sortedGroups}
      sortedEntries={[]}
      placeholder="Permissions"
      selectedValues={selectedValues}
      setSelectedValues={(selectedValues) =>
        setSelectedValues(selectedValues as PermissionBundleId[])
      }
      toggleAll={{
        toggleAllEntry: {
          text: 'Select all',
          value: 'select-all',
          values: permBundles.map((permBundle) => permBundle.id),
        },
        onToggleAll: () => {
          if (selectedValues.length >= visiblePermBundles.length) {
            setSelectedValues([])
          } else {
            setSelectedValues(
              visiblePermBundles.map((permBundle) => permBundle.id)
            )
          }
        },
      }}
    />
  )
}

import React, { useCallback, useMemo, useRef, useState } from 'react'

import _ from 'lodash'

import {
  DEFAULT_VAULT_FILES_COUNT_LIMIT,
  setVaultEnablement,
  setVaultSettings,
  VaultSettingsObject,
  Workspace,
} from 'models/workspace'
import { WorkspaceFeature } from 'openapi/models/WorkspaceFeature'
import { Maybe } from 'types'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { intervalToReadable } from 'utils/utils'

import { Button } from 'components/ui/button'
import Combobox from 'components/ui/combobox/combobox'
import { Dialog, DialogContent, DialogFooter } from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Switch } from 'components/ui/switch'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'

import VaultEditOption from './vault-edit-option'

const DEFAULT_VAULT_REVIEW_FILES_COUNT_LIMIT_PER_QUERY = 150
const DEFAULT_VAULT_REVIEW_QUESTIONS_COUNT_LIMIT_PER_QUERY = 50
const DEFAULT_VAULT_PROJECTS_COUNT_LIMIT = 10
const DEFAULT_VAULT_REVIEW_QUERIES_COUNT_PER_MONTH = 3
const DEFAULT_VAULT_REVIEW_FILES_COUNT_PER_MONTH = 200

interface VaultAddonDialogProps {
  type: 'add' | 'edit'
  open: boolean
  onOpenChange: (open: boolean) => void
  workspace: Workspace
  onEnable: () => Promise<void>
}

enum AddOnType {
  UNLIMITED = 'Unlimited',
  FILE_LIMIT = 'File limit',
  QUERY_LIMIT = 'Query limit',
}

enum FileLimitType {
  WORKSPACE = 'Per user',
  USER = 'Shared within workspace',
}

const retentionOptions = [
  { value: '0', label: intervalToReadable(0, true) },
  { value: '172800', label: intervalToReadable(172800, true) },
  { value: '604800', label: intervalToReadable(604800, true) },
  { value: '1209600', label: intervalToReadable(1209600, true) },
  { value: '2592000', label: intervalToReadable(2592000, true) },
  { value: '15768000', label: intervalToReadable(15768000, true) },
  { value: 'unlimited', label: 'Unlimited' },
  { value: 'custom', label: 'Custom' },
]

interface RetentionPeriodSelectorProps {
  label: string
  optionValue: string
  setOptionValue: (value: string) => void
  customValue: number
  setCustomValue: (value: number) => void
}

const RetentionPeriodSelector = ({
  label,
  optionValue,
  setOptionValue,
  customValue,
  setCustomValue,
}: RetentionPeriodSelectorProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <div ref={containerRef} className="space-y-1">
      <Label htmlFor="value">{label}</Label>
      <Combobox
        value={optionValue}
        setValue={setOptionValue}
        defaultText="Select retention period"
        options={retentionOptions}
        containerRef={containerRef}
      />
      {optionValue === 'custom' && (
        <div className="flex items-center space-x-2">
          <Input
            id="value"
            type="number"
            value={customValue}
            onChange={(e) => setCustomValue(parseInt(e.target.value))}
            placeholder="Enter value"
            min={0}
            max={31536000}
            className="mt-1 w-[200px]"
          />
          <p className="text-sm text-muted">
            Equals to {intervalToReadable(customValue || 0, true)}
          </p>
        </div>
      )}
    </div>
  )
}

const VaultAddonDialog = ({
  type,
  open,
  onOpenChange,
  workspace,
  onEnable,
}: VaultAddonDialogProps) => {
  const vaultSettings: VaultSettingsObject = workspace.vaultAddOnSettings

  const [vaultUsersCountLimit, setVaultUsersCountLimit] = useState<
    number | null
  >(workspace.vaultUsersCountLimit)
  const [
    customVaultEventsRetentionPeriod,
    setCustomVaultEventsRetentionPeriod,
  ] = useState<number>(workspace.vaultEventsRetentionPeriod ?? 0)
  const [
    vaultEventsRetentionPeriodOptionValue,
    setVaultEventsRetentionPeriodOptionValue,
  ] = useState<string>(
    workspace.vaultEventsRetentionPeriod
      ? retentionOptions.some(
          (option) =>
            option.value === workspace.vaultEventsRetentionPeriod?.toString()
        )
        ? workspace.vaultEventsRetentionPeriod.toString()
        : 'custom'
      : 'unlimited'
  )
  const [
    customVaultProjectsRetentionPeriod,
    setCustomVaultProjectsRetentionPeriod,
  ] = useState<number>(workspace.vaultProjectsRetentionPeriod ?? 0)
  const [
    vaultProjectsRetentionPeriodOptionValue,
    setVaultProjectsRetentionPeriodOptionValue,
  ] = useState<string>(
    workspace.vaultProjectsRetentionPeriod
      ? retentionOptions.some(
          (option) =>
            option.value === workspace.vaultProjectsRetentionPeriod?.toString()
        )
        ? workspace.vaultProjectsRetentionPeriod.toString()
        : 'custom'
      : 'unlimited'
  )

  const [
    vaultReviewFilesCountLimitPerQuery,
    setVaultReviewFilesCountLimitPerQuery,
  ] = useState<number>(
    vaultSettings.vault_review_files_count_limit_per_query ??
      DEFAULT_VAULT_REVIEW_FILES_COUNT_LIMIT_PER_QUERY
  )
  const [
    vaultReviewQuestionsCountLimitPerQuery,
    setVaultReviewQuestionsCountLimitPerQuery,
  ] = useState<number>(
    vaultSettings.vault_review_questions_count_limit_per_query ??
      DEFAULT_VAULT_REVIEW_QUESTIONS_COUNT_LIMIT_PER_QUERY
  )
  const [vaultProjectsCountLimitPerUser, setVaultProjectsCountLimitPerUser] =
    useState<number>(
      vaultSettings.vault_projects_count_limit_per_user ??
        DEFAULT_VAULT_PROJECTS_COUNT_LIMIT
    )
  const [vaultFilesCountLimitPerProject, setVaultFilesCountLimitPerProject] =
    useState<number>(
      vaultSettings.vault_files_count_limit_per_project ??
        DEFAULT_VAULT_FILES_COUNT_LIMIT
    )
  const [
    vaultReviewQueriesCountLimitPerUserPerMonth,
    setVaultReviewQueriesCountLimitPerUserPerMonth,
  ] = useState<number>(
    vaultSettings.vault_review_queries_count_limit_per_user_per_month ??
      DEFAULT_VAULT_REVIEW_QUERIES_COUNT_PER_MONTH
  )
  const [
    vaultReviewFilesCountLimitPerWorkspacePerMonth,
    setVaultReviewFilesCountLimitPerWorkspacePerMonth,
  ] = useState<number>(
    vaultSettings.vault_review_files_count_limit_per_workspace_per_month ?? 0
  )
  const [
    vaultReviewFilesCountLimitPerUserPerMonth,
    setVaultReviewFilesCountLimitPerUserPerMonth,
  ] = useState<number>(
    vaultSettings.vault_review_files_count_limit_per_user_per_month ??
      DEFAULT_VAULT_REVIEW_FILES_COUNT_PER_MONTH
  )

  const currentAddOnType =
    !_.isNil(
      vaultSettings.vault_review_files_count_limit_per_workspace_per_month
    ) ||
    !_.isNil(vaultSettings.vault_review_files_count_limit_per_user_per_month)
      ? AddOnType.FILE_LIMIT
      : !_.isNil(
          vaultSettings.vault_review_queries_count_limit_per_user_per_month
        )
      ? AddOnType.QUERY_LIMIT
      : AddOnType.UNLIMITED

  const [addOnType, setAddOnType] = useState<AddOnType>(
    type === 'edit' ? currentAddOnType : AddOnType.UNLIMITED
  )
  const addOnTypeSetter = (type: string) => {
    setAddOnType(type as AddOnType)
  }

  const currentFileLimitType =
    _.isNil(vaultSettings.vault_review_files_count_limit_per_user_per_month) &&
    !_.isNil(
      vaultSettings.vault_review_files_count_limit_per_workspace_per_month
    )
      ? FileLimitType.WORKSPACE
      : FileLimitType.USER
  const [fileLimitType, setFileLimitType] = useState<FileLimitType>(
    type === 'edit' ? currentFileLimitType : FileLimitType.USER
  )
  const fileLimitTypeSetter = (type: string) => {
    setFileLimitType(type as FileLimitType)
  }

  const [vaultProjectsRetentionOnUpdate, setVaultProjectsRetentionOnUpdate] =
    useState<boolean>(workspace.vaultProjectsRetentionOnUpdate ?? false)

  const vaultSettingSetter = useCallback(async () => {
    let vaultSettings: Record<string, Maybe<number | boolean>> = {
      vaultUsersCountLimit,
      vaultEventsRetentionPeriod:
        vaultEventsRetentionPeriodOptionValue === 'unlimited'
          ? null
          : vaultEventsRetentionPeriodOptionValue === 'custom'
          ? customVaultEventsRetentionPeriod
          : parseInt(vaultEventsRetentionPeriodOptionValue),
      vaultProjectsRetentionPeriod:
        vaultProjectsRetentionPeriodOptionValue === 'unlimited'
          ? null
          : vaultProjectsRetentionPeriodOptionValue === 'custom'
          ? customVaultProjectsRetentionPeriod
          : parseInt(vaultProjectsRetentionPeriodOptionValue),
      vaultProjectsRetentionOnUpdate,
      vaultReviewQuestionsCountLimitPerQuery,
      vaultProjectsCountLimitPerUser,
      vaultFilesCountLimitPerProject,
    }
    if (addOnType === AddOnType.FILE_LIMIT) {
      if (fileLimitType === FileLimitType.USER) {
        if (vaultUsersCountLimit === null) {
          // Unlimited vault users count does not have review files count limit for entire workspace
          vaultSettings = {
            ...vaultSettings,
            vaultReviewFilesCountLimitPerUserPerMonth,
            vaultReviewFilesCountLimitPerWorkspacePerMonth: null,
          }
        } else {
          // Per user file limit has review files count limit for entire workspace as well as per user
          vaultSettings = {
            ...vaultSettings,
            vaultReviewFilesCountLimitPerUserPerMonth,
            vaultReviewFilesCountLimitPerWorkspacePerMonth,
          }
        }
      } else {
        // Per workspace file limit does not have review files count limit for per user
        vaultSettings = {
          ...vaultSettings,
          vaultReviewFilesCountLimitPerUserPerMonth: null,
          vaultReviewFilesCountLimitPerWorkspacePerMonth,
        }
      }
    } else if (addOnType === AddOnType.QUERY_LIMIT) {
      vaultSettings = {
        ...vaultSettings,
        vaultReviewFilesCountLimitPerQuery,
        vaultReviewQueriesCountLimitPerUserPerMonth,
      }
    } else {
      vaultSettings = {
        ...vaultSettings,
        vaultReviewFilesCountLimitPerUserPerMonth: null,
        vaultReviewQueriesCountLimitPerUserPerMonth: null,
        vaultReviewFilesCountUnlimitedPerUserPerMonth: 1, // 1 means true
      }
    }
    return setVaultSettings(
      workspace.id,
      vaultSettings,
      WorkspaceFeature.VAULT_ADD_ON
    )
  }, [
    vaultUsersCountLimit,
    vaultEventsRetentionPeriodOptionValue,
    customVaultEventsRetentionPeriod,
    vaultProjectsRetentionPeriodOptionValue,
    customVaultProjectsRetentionPeriod,
    vaultProjectsRetentionOnUpdate,
    vaultReviewQuestionsCountLimitPerQuery,
    vaultProjectsCountLimitPerUser,
    vaultFilesCountLimitPerProject,
    addOnType,
    workspace.id,
    vaultReviewFilesCountLimitPerWorkspacePerMonth,
    fileLimitType,
    vaultReviewFilesCountLimitPerUserPerMonth,
    vaultReviewFilesCountLimitPerQuery,
    vaultReviewQueriesCountLimitPerUserPerMonth,
  ])

  const onAdd = async () => {
    // API call to enable vault add on
    const enabled = await setVaultEnablement(
      WorkspaceFeature.VAULT_ADD_ON,
      workspace.id,
      true
    )

    if (enabled) {
      const updated = await onSetVaultSettings(true)
      if (!updated) {
        const disabled = await setVaultEnablement(
          WorkspaceFeature.VAULT_ADD_ON,
          workspace.id,
          false
        )
        if (!disabled) {
          displayErrorMessage('Failed to disable vault add on')
        }
      }
    } else {
      displayErrorMessage('Failed to enable vault add on')
    }
  }

  const onSetVaultSettings = async (initialAdd: boolean) => {
    try {
      await vaultSettingSetter()
      await onEnable()
      onOpenChange(false)
      displaySuccessMessage(
        initialAdd
          ? 'Vault add on enabled successfully'
          : 'Vault add on updated successfully'
      )
      return true
    } catch (e) {
      displayErrorMessage(
        e instanceof Error ? e.message : 'Failed to set vault settings'
      )
      return false
    }
  }

  const buttonDisabledReason = useMemo(() => {
    if (vaultUsersCountLimit !== null && isNaN(vaultUsersCountLimit))
      return 'Vault users count limit is not a number'
    if (isNaN(vaultReviewFilesCountLimitPerUserPerMonth))
      return 'Vault review files count limit per user per month is not a number'
    if (isNaN(vaultReviewFilesCountLimitPerWorkspacePerMonth))
      return 'Vault review files count limit per workspace per month is not a number'
    if (isNaN(vaultReviewQueriesCountLimitPerUserPerMonth))
      return 'Vault review queries count limit per user per month is not a number'
    if (isNaN(vaultReviewFilesCountLimitPerQuery))
      return 'Vault review files count limit per query is not a number'
    if (isNaN(vaultReviewQuestionsCountLimitPerQuery))
      return 'Vault review questions count limit per query is not a number'
    if (isNaN(vaultProjectsCountLimitPerUser))
      return 'Vault projects count limit per user is not a number'
    if (isNaN(vaultFilesCountLimitPerProject))
      return 'Vault files count limit per project is not a number'
    if (vaultUsersCountLimit !== null && vaultUsersCountLimit === 0)
      return 'Vault users count limit should be a positive integer'
    if (vaultReviewQuestionsCountLimitPerQuery === 0)
      return 'Vault review questions count limit per query should be a positive integer'
    if (
      addOnType === AddOnType.FILE_LIMIT &&
      (vaultUsersCountLimit !== null ||
        fileLimitType === FileLimitType.WORKSPACE) &&
      vaultReviewFilesCountLimitPerWorkspacePerMonth === 0
    )
      return 'Vault review files count limit per workspace per month should be a positive integer'
    if (
      addOnType === AddOnType.FILE_LIMIT &&
      fileLimitType === FileLimitType.USER &&
      vaultUsersCountLimit !== null &&
      vaultUsersCountLimit * vaultReviewFilesCountLimitPerUserPerMonth >
        vaultReviewFilesCountLimitPerWorkspacePerMonth
    )
      return 'Vault users count limit * vault review files count limit per user per month should be ≤ vault review files count limit per workspace per month'
    if (
      addOnType === AddOnType.QUERY_LIMIT &&
      (vaultReviewQueriesCountLimitPerUserPerMonth === 0 ||
        vaultReviewFilesCountLimitPerQuery === 0)
    )
      return 'Vault review queries count limit per user per month and vault review files count limit per query should be a positive integer'
    return undefined
  }, [
    vaultUsersCountLimit,
    vaultReviewFilesCountLimitPerUserPerMonth,
    vaultReviewFilesCountLimitPerWorkspacePerMonth,
    vaultReviewQueriesCountLimitPerUserPerMonth,
    vaultReviewFilesCountLimitPerQuery,
    vaultReviewQuestionsCountLimitPerQuery,
    vaultProjectsCountLimitPerUser,
    vaultFilesCountLimitPerProject,
    addOnType,
    fileLimitType,
  ])

  const reviewFilesCountLimitText = (() => {
    if (
      (vaultUsersCountLimit !== null && isNaN(vaultUsersCountLimit)) ||
      isNaN(vaultReviewFilesCountLimitPerUserPerMonth) ||
      isNaN(vaultReviewFilesCountLimitPerWorkspacePerMonth)
    ) {
      return undefined
    }
    if (vaultReviewFilesCountLimitPerWorkspacePerMonth === 0) {
      return `Cannot have 0 total vault review files count per month for entire workspace`
    }
    if (
      vaultUsersCountLimit !== null &&
      vaultUsersCountLimit * vaultReviewFilesCountLimitPerUserPerMonth >
        vaultReviewFilesCountLimitPerWorkspacePerMonth
    ) {
      return `Total vault review files count per month for entire workspace (${vaultReviewFilesCountLimitPerWorkspacePerMonth.toLocaleString()}) must be ≥ vault users count limit (${vaultUsersCountLimit.toLocaleString()}) * vault review files count per month per user (${vaultReviewFilesCountLimitPerUserPerMonth.toLocaleString()})`
    } else if (vaultUsersCountLimit !== null) {
      return `Each user will have ${vaultReviewFilesCountLimitPerUserPerMonth.toLocaleString()} vault review files count per month by default, and the workspace will have ${(
        vaultReviewFilesCountLimitPerWorkspacePerMonth -
        vaultReviewFilesCountLimitPerUserPerMonth * vaultUsersCountLimit
      ).toLocaleString()} remaining per month to manage manually`
    }
  })()

  const reviewFilesCountLimitTextForWorkspaceShared = (() => {
    if (vaultReviewFilesCountLimitPerWorkspacePerMonth === 0) {
      return `Cannot have 0 total vault review files count per month for entire workspace`
    }
    return `Entire workspace will share the ${vaultReviewFilesCountLimitPerWorkspacePerMonth.toLocaleString()} vault review files count per month`
  })()

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent showCloseIcon={false}>
        {type === 'add' ? (
          <p className="text-xl">
            Enable vault add on for {workspace.friendlyName}
          </p>
        ) : (
          <p className="text-xl">
            Edit vault add on settings for {workspace.friendlyName}
          </p>
        )}

        {type === 'add' && (
          <p>
            This will create a new vault add on role for the workspace with
            appropriate permission
          </p>
        )}

        <div className="flex items-center">
          <Switch
            checked={vaultUsersCountLimit === null}
            onCheckedChange={(value) => {
              setVaultUsersCountLimit(value ? null : 0)
              setVaultReviewFilesCountLimitPerUserPerMonth(0)
              setVaultReviewFilesCountLimitPerWorkspacePerMonth(0)
              setAddOnType(AddOnType.UNLIMITED)
            }}
            id="unlimited-vault-users-count"
          />
          <Label
            className="cursor-pointer pl-2 font-normal text-muted"
            htmlFor="unlimited-vault-users-count"
          >
            Unlimited vault users count per workspace
          </Label>
        </div>

        {vaultUsersCountLimit !== null && (
          <VaultEditOption
            label="Vault users count limit per workspace"
            value={vaultUsersCountLimit}
            setValue={(value) => {
              setVaultUsersCountLimit(value)
              if (!isNaN(value) && value > 0) {
                setVaultReviewFilesCountLimitPerUserPerMonth(
                  DEFAULT_VAULT_REVIEW_FILES_COUNT_PER_MONTH
                )
                setVaultReviewFilesCountLimitPerWorkspacePerMonth(
                  DEFAULT_VAULT_REVIEW_FILES_COUNT_PER_MONTH * value
                )
              } else {
                setVaultReviewFilesCountLimitPerUserPerMonth(0)
                setVaultReviewFilesCountLimitPerWorkspacePerMonth(0)
              }
            }}
            initialEditing
          />
        )}
        <RetentionPeriodSelector
          label="Vault queries retention period"
          optionValue={vaultEventsRetentionPeriodOptionValue}
          setOptionValue={setVaultEventsRetentionPeriodOptionValue}
          customValue={customVaultEventsRetentionPeriod}
          setCustomValue={setCustomVaultEventsRetentionPeriod}
        />
        <VaultEditOption
          label="Vault projects count limit per user"
          value={vaultProjectsCountLimitPerUser}
          setValue={setVaultProjectsCountLimitPerUser}
        />
        <VaultEditOption
          label="Vault files count limit per project"
          value={vaultFilesCountLimitPerProject}
          setValue={setVaultFilesCountLimitPerProject}
        />
        <VaultEditOption
          label="Vault review questions count limit per query"
          value={vaultReviewQuestionsCountLimitPerQuery}
          setValue={setVaultReviewQuestionsCountLimitPerQuery}
        />
        <Tabs value={addOnType} onValueChange={addOnTypeSetter}>
          <TabsList>
            <TabsTrigger value={AddOnType.UNLIMITED}>Unlimited</TabsTrigger>
            <TabsTrigger value={AddOnType.FILE_LIMIT}>File Limit</TabsTrigger>
            <TabsTrigger value={AddOnType.QUERY_LIMIT}>Query Limit</TabsTrigger>
          </TabsList>
          <TabsContent value={AddOnType.UNLIMITED}>
            <p className="mt-1 text-sm text-muted">
              This will enable unlimited vault review queries for all users
            </p>
          </TabsContent>
          <TabsContent value={AddOnType.QUERY_LIMIT}>
            <VaultEditOption
              label="Vault review files count limit per query"
              value={vaultReviewFilesCountLimitPerQuery}
              setValue={setVaultReviewFilesCountLimitPerQuery}
            />
            <VaultEditOption
              label="Vault review queries count per month per user"
              value={vaultReviewQueriesCountLimitPerUserPerMonth}
              setValue={setVaultReviewQueriesCountLimitPerUserPerMonth}
              className="mt-2"
            />
          </TabsContent>
          <TabsContent value={AddOnType.FILE_LIMIT}>
            <Tabs
              value={fileLimitType}
              onValueChange={fileLimitTypeSetter}
              className="mt-2"
            >
              <TabsList>
                <TabsTrigger value={FileLimitType.USER}>Per User</TabsTrigger>
                <TabsTrigger value={FileLimitType.WORKSPACE}>
                  Shared within workspace
                </TabsTrigger>
              </TabsList>
              <TabsContent value={FileLimitType.USER}>
                <VaultEditOption
                  label="Vault review files count per month per user"
                  value={vaultReviewFilesCountLimitPerUserPerMonth}
                  setValue={(value) => {
                    if (
                      !isNaN(value) &&
                      vaultUsersCountLimit !== null &&
                      value * vaultUsersCountLimit >
                        vaultReviewFilesCountLimitPerWorkspacePerMonth
                    ) {
                      setVaultReviewFilesCountLimitPerWorkspacePerMonth(
                        value * vaultUsersCountLimit
                      )
                    }
                    setVaultReviewFilesCountLimitPerUserPerMonth(value)
                  }}
                />
                {vaultUsersCountLimit !== null && (
                  <>
                    <VaultEditOption
                      label="Total vault review files count per month for entire workspace"
                      value={vaultReviewFilesCountLimitPerWorkspacePerMonth}
                      setValue={
                        setVaultReviewFilesCountLimitPerWorkspacePerMonth
                      }
                      className="mt-2"
                    />
                    <p className="mt-1 text-sm text-muted">
                      {reviewFilesCountLimitText}
                    </p>
                  </>
                )}
              </TabsContent>
              <TabsContent value={FileLimitType.WORKSPACE}>
                <VaultEditOption
                  label="Total vault review files count per month for entire workspace"
                  value={vaultReviewFilesCountLimitPerWorkspacePerMonth}
                  setValue={setVaultReviewFilesCountLimitPerWorkspacePerMonth}
                />
                <p className="mt-1 text-sm text-muted">
                  {reviewFilesCountLimitTextForWorkspaceShared}
                </p>
              </TabsContent>
            </Tabs>
          </TabsContent>
        </Tabs>

        <RetentionPeriodSelector
          label="Vault projects retention period"
          optionValue={vaultProjectsRetentionPeriodOptionValue}
          setOptionValue={setVaultProjectsRetentionPeriodOptionValue}
          customValue={customVaultProjectsRetentionPeriod}
          setCustomValue={setCustomVaultProjectsRetentionPeriod}
        />

        <Tabs
          value={vaultProjectsRetentionOnUpdate ? 'update' : 'create'}
          onValueChange={(value) =>
            setVaultProjectsRetentionOnUpdate(value === 'update')
          }
        >
          <TabsList>
            <TabsTrigger value="create">On Project Creation</TabsTrigger>
            <TabsTrigger value="update">On Project Update</TabsTrigger>
          </TabsList>
          <TabsContent value="update">
            <p className="mt-1 text-sm text-muted">
              Project retention period will start counting from the last update
              to the project
            </p>
          </TabsContent>
          <TabsContent value="create">
            <p className="mt-1 text-sm text-muted">
              Project retention period will start counting from when the project
              is first created
            </p>
          </TabsContent>
        </Tabs>

        <DialogFooter>
          <Button variant="secondary" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button
            disabled={!!buttonDisabledReason}
            tooltip={buttonDisabledReason}
            onClick={async () => {
              if (type === 'add') {
                await onAdd()
              } else {
                await onSetVaultSettings(false)
              }
            }}
          >
            {type === 'add' ? 'Enable' : 'Update'} Vault Add On
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default VaultAddonDialog

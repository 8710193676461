import _ from 'lodash'

import { PermissionBundleId } from 'openapi/models/PermissionBundleId'
import { ResetOnboardingRequestToJSON } from 'openapi/models/ResetOnboardingRequest'
import { ResetOnboardingResponse } from 'openapi/models/ResetOnboardingResponse'
import Services from 'services'
import { RequestError } from 'services/backend/backend'
import { Maybe } from 'types'

import { PWCCsvUser } from 'components/settings/pwc/pwc-user-add'

export interface UserAddedSuccess {
  email: string
  password?: string
}

export interface ExistingUserResponse {
  email: string
  workspaceId: number
}

export type DryRunUser = [string, number]

export interface CreateUsersResult {
  usersAdded: UserAddedSuccess[]
  usersAlreadyExist: ExistingUserResponse[]
  usersFailed: string[]
  usersDuplicate?: Maybe<string[]>
  deleteUsers?: Maybe<string[]>
  permsUsers?: Maybe<string[]>
  message: string
  totalUsersRequested?: number
  dryRunUsersAdded?: DryRunUser[]
  dryRunUsersFailed?: string[]
  dryRunUsersAlreadyExist?: string[]
  dryRunUsersMoved?: DryRunUser[]
}

export interface RemoveUsersResult {
  usersDeleted: string[]
  usersFailed: string[]
}

export interface RawUser {
  id: string
  email: string
  settings: Record<string, any>
  pseudonymizedEmail: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
}

export interface RawUserEnriched extends RawUser {
  roles: UserRole[]
  extraPermissions: UserPermBundle[]
}

export interface UserRole {
  id: string
  name: string
}

export interface UserPermBundle {
  userId: string
  permBundleId: PermissionBundleId
  permBundleName: string
  includedPerms: string[]
  excludedPerms: string[]
}

export interface BulkDeleteUsersResult {
  usersDeleted: string[]
  usersFailed: string[]
}

export const createNewUsersInternalAdmin = async (
  users: string[],
  workspaceId?: number
) => {
  const result = await Services.Backend.Post(
    'internal_admin/users/create',
    {
      users,
      workspaceId,
    },
    {
      maxRetryCount: 0,
    }
  )
  if (_.isNil(result)) {
    throw new Error(`Failed to create users`)
  }
  return result as CreateUsersResult
}

export const createNewUsersCustomerFacing = async (
  users: string[],
  workspaceId?: number
) => {
  const result = await Services.Backend.Post(
    'client_admin/users/add',
    {
      users,
      workspaceId,
    },
    {
      maxRetryCount: 0,
    }
  )
  if (_.isNil(result)) {
    throw new Error(`Failed to create users`)
  }
  return result as CreateUsersResult
}

export const createNewPwcUsers = async (users: PWCCsvUser[]) => {
  const result = await Services.Backend.Post('internal_admin/users/pwc', users)
  if (_.isNil(result)) {
    throw new Error(`Failed to create users`)
  }
  return result as CreateUsersResult
}

export const removePwcUsers = async (users: PWCCsvUser[]) => {
  const result = await Services.Backend.Delete(
    'internal_admin/users/pwc',
    users
  )
  return result as RemoveUsersResult
}

export const fetchAllWorkspaceUsersV2 = async (workspaceId: number) => {
  const result = await Services.Backend.Get<RawUserEnriched[]>(
    `client_admin/workspace/${workspaceId}/users`,
    { throwOnError: true }
  )
  if (_.isNil(result)) {
    throw new Error(`Failed to fetch users`)
  }
  return result as RawUserEnriched[]
}

export const fetchAllWorkspaceUsers = async (workspaceId: number) => {
  const result = await Services.Backend.Get<RawUser[]>(
    `internal_admin/workspaces/${workspaceId}/users`
  )
  if (_.isNil(result)) {
    throw new Error(`Failed to fetch users`)
  }
  return result as RawUser[]
}

export const fetchAllActiveWorkspaceUsersInternal = async (
  workspaceId: number
) => {
  const result = await Services.Backend.Get<RawUser[]>(
    `internal_admin/workspaces/${workspaceId}/active_users`
  )
  if (_.isNil(result)) {
    throw new Error(`Failed to fetch users`)
  }
  return result as RawUser[]
}

export const fetchAllWorkspaceVaultUsers = async (workspaceId: number) => {
  const result = await Services.Backend.Get<
    (RawUser & { vaultPermSources: string[] })[]
  >(`internal_admin/workspaces/${workspaceId}/vault_users`)
  if (_.isNil(result)) {
    throw new Error(`Failed to fetch vault users`)
  }
  return result as (RawUser & { vaultPermSources: string[] })[]
}

export const fetchWorkspaceUsersClientAdmin = async (workspaceId: number) => {
  const result = await Services.Backend.Get<RawUser[]>(
    `client_admin/users/${workspaceId}`
  )
  if (_.isNil(result)) {
    throw new Error(`Failed to fetch users`)
  }
  return result as RawUser[]
}

export const InternalAdminBulkDeleteUser = async (emails: string[]) => {
  const result = await Services.Backend.Post(
    'settings/users/bulk_delete_users',
    emails
  )
  return result as BulkDeleteUsersResult
}

export const ClientAdminDeleteUsers = async (
  workspaceId: number,
  emails: string[]
) => {
  const result = await Services.Backend.Post('client_admin/users/remove', {
    workspaceId,
    emails,
  })
  return result as BulkDeleteUsersResult
}

export const ResetUserProductTour = async (
  userEmail: string
): Promise<{ updatedUsers: number }> => {
  const result = await Services.Backend.Patch<{ updatedUsers: number }>(
    'internal_admin/user/reset_product_tour',
    {
      userEmail,
    }
  )
  if (result instanceof RequestError) {
    throw new Error(result.message)
  }
  return result
}

export const ResetOnboarding = async (
  userEmail: string
): Promise<ResetOnboardingResponse> => {
  const result = await Services.Backend.Patch<ResetOnboardingResponse>(
    'internal_admin/user/reset_onboarding',
    ResetOnboardingRequestToJSON({
      userEmail,
    }),
    {
      throwOnError: true,
    }
  )

  if (result instanceof RequestError) {
    throw new Error(result.message)
  }
  return result
}

import React, { useMemo } from 'react'
import { useUnmount } from 'react-use'

import { HTTPError } from 'ky'
import { useShallow } from 'zustand/react/shallow'

import { mbToBytes } from 'utils/file-utils'
import { displayErrorMessage } from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { DialogFooter, DialogTitle } from 'components/ui/dialog'
import { DialogHeader } from 'components/ui/dialog'
import VaultCreateProject from 'components/vault/components/new-project/vault-create-project'
import {
  MAX_TOTAL_FILE_SIZE_IN_MB,
  FILE_COUNT_OR_SIZE_OVER_LIMIT_TOOLTIP,
} from 'components/vault/utils/vault'
import { useVaultCreateProjectStore } from 'components/vault/utils/vault-create-project-store'
import { CreateVaultFolder } from 'components/vault/utils/vault-fetcher'
import {
  createVaultFoldersHierarchy,
  computeFileHierarchy,
  optimisticallyCreateVaultHierarchyFiles,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const VaultWorkflowUploadFiles = ({
  onBackButtonClicked,
  handleNavigateToFilesProgress,
}: {
  onBackButtonClicked: () => void
  handleNavigateToFilesProgress: () => void
}) => {
  const userInfo = useAuthUser()
  const [
    filesToUpload,
    isSubmitting,
    isDropzoneLoading,
    newFolderName,
    totalFileSizeInBytes,
    setIsSubmitting,
    setFileUploadMetadata,
    setNewFolderName,
  ] = useVaultCreateProjectStore(
    useShallow((s) => [
      s.filesToUpload,
      s.isSubmitting,
      s.isDropzoneLoading,
      s.newFolderName,
      s.totalFileSizeInBytes,
      s.setIsSubmitting,
      s.setFileUploadMetadata,
      s.setNewFolderName,
    ])
  )

  const [
    upsertVaultFolders,
    upsertVaultFiles,
    updateProjectMetadata,
    setCurrentProject,
    setShowProcessingProgress,
  ] = useVaultStore(
    useShallow((s) => [
      s.upsertVaultFolders,
      s.upsertVaultFiles,
      s.updateProjectMetadata,
      s.setCurrentProject,
      s.setShowProcessingProgress,
    ])
  )

  const isTotalFileSizeExceeded =
    totalFileSizeInBytes > mbToBytes(MAX_TOTAL_FILE_SIZE_IN_MB)
  const isFilesCountExceeded =
    filesToUpload.length >
    userInfo.workspace.getVaultFilesCountLimit(userInfo.vaultFeature)

  const isCreateProjectDisabled = useMemo(() => {
    return (
      isDropzoneLoading ||
      isSubmitting ||
      newFolderName.trim().length === 0 ||
      isFilesCountExceeded ||
      isTotalFileSizeExceeded
      //   shouldDisableDueToNoClientMatter   // add back if needed
    )
  }, [
    isDropzoneLoading,
    isSubmitting,
    newFolderName,
    isFilesCountExceeded,
    isTotalFileSizeExceeded,
    // shouldDisableDueToNoClientMatter   // add back if needed
  ])

  const tooltipDisabledMessage = useMemo(() => {
    if (isFilesCountExceeded || isTotalFileSizeExceeded) {
      return FILE_COUNT_OR_SIZE_OVER_LIMIT_TOOLTIP
    }
  }, [isFilesCountExceeded, isTotalFileSizeExceeded])

  useUnmount(() => {
    setNewFolderName('')
  })

  const onCreateProject = async () => {
    const folderName = newFolderName.trim()
    if (folderName.includes('/') || folderName.includes('\\')) {
      displayErrorMessage(
        `Project name is not valid: it cannot contain “/” or “\\”`
      )
      return
    }
    setIsSubmitting(true)

    try {
      const hierarchyRootFolder = await CreateVaultFolder(
        folderName,
        null
        //   clientMatterId // add back if needed
      )
      const fileHierarchy = computeFileHierarchy(filesToUpload)
      const { folderIdToVaultFolderId, createdFolders } =
        await createVaultFoldersHierarchy(fileHierarchy, hierarchyRootFolder.id)
      upsertVaultFolders(
        [hierarchyRootFolder, ...createdFolders],
        userInfo.dbId,
        false,
        hierarchyRootFolder.id
      )
      const { filesGroupedByVaultFolderId, vaultFolderIdToPrefix } =
        optimisticallyCreateVaultHierarchyFiles({
          rootFolderId: hierarchyRootFolder.id,
          projectId: hierarchyRootFolder.id,
          fileHierarchy,
          folderIdToVaultFolderId,
          upsertVaultFiles,
          updateProjectMetadata,
        })
      setFileUploadMetadata(filesGroupedByVaultFolderId, vaultFolderIdToPrefix)
      setCurrentProject(hierarchyRootFolder, true)
      setShowProcessingProgress(hierarchyRootFolder.id, true)

      handleNavigateToFilesProgress()
    } catch (e) {
      if (e instanceof HTTPError && e.response.status === 409) {
        displayErrorMessage(`Project name already exists.`)
      } else if (e instanceof HTTPError) {
        displayErrorMessage(`Something went wrong. Could not create project.`)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <DialogHeader className="h-9 border-b border-b-primary px-6">
        <DialogTitle>Add files</DialogTitle>
      </DialogHeader>
      <VaultCreateProject inModal />
      <DialogFooter className="flex w-full flex-row justify-end gap-2 px-6">
        <Button variant="outline" onClick={onBackButtonClicked}>
          Back
        </Button>
        <Button
          disabled={isCreateProjectDisabled}
          tooltip={tooltipDisabledMessage}
          isLoading={isSubmitting}
          onClick={onCreateProject}
        >
          Continue
        </Button>
      </DialogFooter>
    </>
  )
}

export default VaultWorkflowUploadFiles

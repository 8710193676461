import * as Sentry from '@sentry/browser'

import { FetchUserWorkspaces } from 'models/workspace'
import Services from 'services'

import { HarvQueryKeyPrefix } from './all-query-keys'
import { useWrappedQuery } from './lib/use-wrapped-query'

export const useUserWorkspacesQuery = (enabled: boolean) => {
  const { isPending, error, data } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.UserWorkspacesQuery],
    queryFn: () => FetchUserWorkspaces(),
    refetchOnWindowFocus: false,
    enabled,
  })

  if (error) {
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
  }

  return { data, isPending, error }
}

import React, { useMemo } from 'react'
import { useEffect } from 'react'

import {
  ColumnDef,
  RowSelectionState,
  createColumnHelper,
} from '@tanstack/react-table'
import pluralize from 'pluralize'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { useShallow } from 'zustand/react/shallow'

import { TableSelect } from 'openapi/models/TableSelect'
import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'
import { Maybe } from 'types'

import { Source } from 'utils/task'

import AssistantSources from 'components/assistant/components/assistant-sources'
import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import TableSelectTable, {
  generateColumns,
} from 'components/assistant/workflows/components/table-select/table-select'
import WorkflowInput from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
} from './assistant-workflow-block-layout'

interface BlockStoreState {
  tableData: TableSelect | null
  selectedRows: RowSelectionState
  isLoading: boolean
}

interface BlockStoreActions {
  setTableData: (tableData: TableSelect) => void
  setSelectedRows: (selection: RowSelectionState) => void
  setAllSelectedRows: (value: boolean) => void
  setIsLoading: (loading: boolean) => void
}

const initialState: BlockStoreState = {
  tableData: null,
  selectedRows: {},
  isLoading: false,
}

const createBlockStore = () =>
  create(
    devtools(
      immer<BlockStoreState & BlockStoreActions>((set) => ({
        ...initialState,

        reset: () => set(() => initialState),
        setTableData: (tableData) =>
          set((state) => {
            state.tableData = tableData
          }),
        setAllSelectedRows: (value: boolean) => {
          set((state) => {
            state.selectedRows =
              state.tableData?.rows.reduce((acc, row, idx) => {
                acc[idx] = value
                return acc
              }, {} as RowSelectionState) ?? {}
          })
        },
        setSelectedRows: (selectedRows) => {
          set((state) => {
            state.selectedRows = {
              ...state.selectedRows,
              ...selectedRows,
            }
          })
        },
        setIsLoading: (loading) =>
          set((state) => {
            state.isLoading = loading
          }),
      }))
    )
  )

// TODO: Can we make this zudstand stuff more generic for other blocks?
export const useBlockStore = (
  stepIdx: number
): ReturnType<typeof createBlockStore> => {
  const [blockStores, addBlockStore] = useAssistantWorkflowStore(
    useShallow((state) => [state.blockStores, state.addBlockStore])
  )

  if (!blockStores[stepIdx]) {
    const blockStore = createBlockStore()
    addBlockStore(stepIdx, blockStore)
    return blockStore
  }

  return blockStores[stepIdx]
}

type RowData = TableSelect['rows'][number]
const columnHelper = createColumnHelper<RowData>()

export const AssistantWorkflowTableSelectThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.TABLE_SELECT
> = ({ blockParams, blockStatus, stepIdx, outputData }) => {
  const { headerText } = blockParams
  const { handleSourceClick, getHrvyInfoMetadata } = useSourceUtils({
    sources: (blockParams.sources || []) as Source[],
  })
  const [
    tableData,
    setTableData,
    selectedRows,
    setSelectedRows,
    setAllSelectedRows,
  ] = useBlockStore(stepIdx)(
    useShallow((state) => [
      state.tableData,
      state.setTableData,
      state.selectedRows,
      state.setSelectedRows,
      state.setAllSelectedRows,
    ])
  )

  useEffect(() => {
    if (!blockParams.table) {
      return
    }
    setTableData(blockParams.table)
    const selectedRowData = outputData
      ? outputData.table.rows
      : blockParams.table.rows
    setSelectedRows(
      selectedRowData.reduce((acc, row, idx) => {
        acc[idx] = typeof row.isSelected === 'boolean' ? row.isSelected : true
        return acc
      }, {} as RowSelectionState)
    )
  }, [blockParams.table, setTableData, setSelectedRows, outputData])

  const Sidebar = useMemo(
    () =>
      (blockParams.sources?.length || 0) > 0 ? (
        <AssistantSources
          allDocuments={[]}
          sources={blockParams.sources as Maybe<Source[]>}
          onSetActiveFileId={handleSourceClick}
          // 68px makes it align with the content of the block
          className="absolute left-0 top-[68px] ml-4 sm:ml-4"
        />
      ) : undefined,
    [blockParams.sources, handleSourceClick]
  )

  if (!tableData) {
    return <p>Loading table...</p>
  }

  const columns: ColumnDef<RowData>[] = [
    columnHelper.display({
      id: 'select',
      header: ({ table }) => (
        <div className="flex items-center gap-2">
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && 'indeterminate')
            }
            onCheckedChange={(value) => {
              table.toggleAllPageRowsSelected(!!value)
              setAllSelectedRows(!!value)
            }}
            disabled={!!outputData}
            aria-label="Select all"
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              row.toggleSelected(!!value)
              setSelectedRows({ [row.index]: !!value })
            }}
            disabled={!!outputData}
            aria-label="Select row"
          />
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
      size: 36,
    }),
    ...generateColumns(tableData, getHrvyInfoMetadata),
  ]

  return (
    <AssistantWorkflowThreadBlock sidebar={Sidebar}>
      <AssistantWorkflowHarveyComponent>
        <AssistantWorkflowThreadText
          blockStatus={blockStatus}
          text={headerText}
        />
        <div className="mt-5">
          <TableSelectTable
            data={tableData.rows}
            columns={columns}
            selectedRows={selectedRows}
          />
        </div>
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowTableSelectInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.TABLE_SELECT
> = ({ onCompleted, stepIdx, outputData }) => {
  const [selectedRows, tableData, isLoading, setIsLoading] = useBlockStore(
    stepIdx
  )(
    useShallow((state) => [
      state.selectedRows,
      state.tableData,
      state.isLoading,
      state.setIsLoading,
    ])
  )

  if (!tableData) {
    return <p>Loading table...</p>
  }

  const numSelected = Object.values(selectedRows).filter(Boolean).length

  const handleSubmit = () => {
    setIsLoading(true)
    const newTableData = {
      ...tableData,
      rows: tableData.rows.map((row, idx) => {
        return {
          ...row,
          isSelected: selectedRows[idx],
        }
      }),
    }
    onCompleted({ table: newTableData })
  }

  return (
    <WorkflowInput>
      <div className="flex items-center justify-between gap-2 bg-transparent">
        <p className="pl-[10px] text-xs text-inactive">
          {numSelected} {pluralize('row', numSelected)} selected
        </p>
        <Button isLoading={isLoading || !!outputData} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </WorkflowInput>
  )
}

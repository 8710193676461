import React from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'

import { cn } from 'utils/utils'

interface ProgressPlayProps extends React.SVGProps<SVGSVGElement> {
  className?: string
  progressPercentage?: number
}

export const ProgressPlay = ({
  className,
  progressPercentage = 0,
}: ProgressPlayProps): JSX.Element => {
  return (
    <div className={cn(className, 'p-1.5')}>
      <CircularProgressbarWithChildren
        value={progressPercentage}
        styles={{
          // Path styling (the actual progress arc)
          path: {
            stroke: 'hsl(var(--neutral-400))',
            strokeWidth: '8',
            strokeLinecap: 'round',
          },
          // Trail styling (the background circle)
          trail: {
            stroke: 'hsl(var(--neutral-200))',
            strokeWidth: '8',
          },
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 8L16 12L10 16V8Z"
            fill="hsl(var(--neutral-950))"
            stroke="hsl(var(--neutral-950))"
            strokeWidth="1.44643"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </CircularProgressbarWithChildren>
    </div>
  )
}

/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying the possible bulk role assignment options.
 * @export
 */
export const BulkRoleAssignmentOption = {
    BASE: 'base',
    ADMIN: 'admin',
    CUSTOM_ROLES: 'custom_roles'
} as const;
export type BulkRoleAssignmentOption = typeof BulkRoleAssignmentOption[keyof typeof BulkRoleAssignmentOption];


export function instanceOfBulkRoleAssignmentOption(value: any): boolean {
    for (const key in BulkRoleAssignmentOption) {
        if (Object.prototype.hasOwnProperty.call(BulkRoleAssignmentOption, key)) {
            if (BulkRoleAssignmentOption[key as keyof typeof BulkRoleAssignmentOption] === value) {
                return true;
            }
        }
    }
    return false;
}

export function BulkRoleAssignmentOptionFromJSON(json: any): BulkRoleAssignmentOption {
    return BulkRoleAssignmentOptionFromJSONTyped(json, false);
}

export function BulkRoleAssignmentOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkRoleAssignmentOption {
    return json as BulkRoleAssignmentOption;
}

export function BulkRoleAssignmentOptionToJSON(value?: BulkRoleAssignmentOption | null): any {
    return value as any;
}

export function BulkRoleAssignmentOptionToJSONTyped(value: any, ignoreDiscriminator: boolean): BulkRoleAssignmentOption {
    return value as BulkRoleAssignmentOption;
}


import React from 'react'

import { Source } from 'utils/task'

import Citation from 'components/ui/citation'

type Props = {
  handleSourceClick?: (source: Source) => void
  sources?: Source[]
}

export const Citations = ({ handleSourceClick, sources }: Props) => {
  return (
    <div className="ml-2 flex items-center">
      {(sources as Source[] | undefined)?.map((source, idx) => (
        <Citation key={idx} onClick={() => handleSourceClick?.(source)}>
          {source.footnote}
        </Citation>
      ))}
    </div>
  )
}

import * as React from 'react'

export const WorkflowInputHeader = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <div className="rounded-md bg-primary px-4 py-[14px] text-sm font-medium">
      {children}
    </div>
  )
}

export const WorkflowInputFooter = ({
  children,
  footer,
}: {
  children?: React.ReactNode
  footer: string
}) => {
  return (
    <div className="flex min-h-8 w-full items-center justify-between">
      <p className="pl-[10px] pr-6 text-inactive">{footer}</p>
      {children}
    </div>
  )
}

const WorkflowInput = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="mx-auto w-full pb-6">
      <div className="space-y-2 rounded-md bg-accent p-[10px]">{children}</div>
    </div>
  )
}

export default WorkflowInput

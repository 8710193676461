import React from 'react'

import { cn } from 'utils/utils'

interface DocIconProps {
  className?: string
}

const DocIcon: React.FC<DocIconProps> = ({ className = '' }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <path
        d="M17.375 3H7.25C6.65326 3 6.08097 3.23178 5.65901 3.64437C5.23705 4.05694 5 4.61652 5 5.2V22.8C5 23.3835 5.23705 23.9431 5.65901 24.3556C6.08097 24.7682 6.65326 25 7.25 25H20.75C21.3467 25 21.919 24.7682 22.341 24.3556C22.7629 23.9431 23 23.3835 23 22.8V8.5L17.375 3Z"
        fill="#FAFAF9"
        stroke="#CDCAC6"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 11.05H9.5M18.5 14.05H9.5M15.55 17H9.5"
        stroke="#1652B5"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DocIcon

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { SafeRecord } from 'utils/safe-types'

import { VaultItemWithIndex } from 'components/vault/utils/vault'
import { ReviewWorkflow } from 'components/vault/utils/vault-fetcher'

const DOCUMENT_CLASSIFICATION_MISMATCH_WARNING_THRESHOLD = 0.5

export enum WorkflowModalState {
  None,
  Selector,
  Builder,
  New,
  Publish,
  Unpublish,
  Edit,
  Delete,
  AddFilesWorkflow,
  AddFilesReviewTable,
  AddFilesAssistantThread,
  CreateBlank,
  AssistantThreadInput,
  ProjectChooser,
  UploadFiles,
  FilesProgress,
}

interface VaultWorkflowState {
  workflows: SafeRecord<string, ReviewWorkflow>
  workflowModalState: WorkflowModalState
  selectedWorkflow: ReviewWorkflow | null
  selectedFilesForWorkflow: VaultItemWithIndex[]
  showDocClassificationMismatchWarning: boolean
}

interface VaultWorkflowAction {
  setWorkflows: (workflows: ReviewWorkflow[]) => void
  updateWorkflow: (workflow: ReviewWorkflow) => void
  setWorkflow: (workflow: ReviewWorkflow) => void
  removeWorkflow: (workflowId: string) => void
  setWorkflowModalState: (state: WorkflowModalState) => void
  setSelectedWorkflow: (workflow: ReviewWorkflow | null) => void
  setSelectedFilesForWorkflow: (files: VaultItemWithIndex[]) => void
  setShowDocClassificationMismatchWarning: (show: boolean) => void
}

const useVaultWorkflowStore = create(
  devtools(
    immer<VaultWorkflowState & VaultWorkflowAction>((set) => ({
      // VaultWorkflowState
      workflows: {},
      workflowModalState: WorkflowModalState.None,
      selectedWorkflow: null,
      selectedFilesForWorkflow: [],
      showDocClassificationMismatchWarning: false,
      // VaultWorkflowAction
      setWorkflows: (workflows) =>
        set((state) => {
          state.workflows = workflows.reduce(
            (acc, workflow) => {
              acc[workflow.id] = workflow
              return acc
            },
            {} as SafeRecord<string, ReviewWorkflow>
          )
        }),
      updateWorkflow: (workflow) =>
        set((state) => {
          state.workflows[workflow.id] = workflow
        }),
      setWorkflow: (workflow) =>
        set((state) => {
          state.workflows[workflow.id] = workflow
        }),
      removeWorkflow: (workflowId) =>
        set((state) => {
          delete state.workflows[workflowId]
        }),
      setWorkflowModalState: (state) =>
        set(() => ({ workflowModalState: state })),
      setSelectedWorkflow: (workflow) =>
        set(() => ({ selectedWorkflow: workflow })),
      setSelectedFilesForWorkflow: (files) => {
        set((state) => {
          state.selectedFilesForWorkflow = files
          const shouldCheckForDocClassificationMismatch =
            files.length > 0 &&
            state.selectedWorkflow &&
            state.selectedWorkflow.strictDocumentType &&
            state.selectedWorkflow.tags &&
            state.selectedWorkflow.tags.length > 0
          if (shouldCheckForDocClassificationMismatch) {
            const selectedFileTags = files
              .map((file) => file.tags)
              .flat()
              .filter(Boolean)
            const selectedFileTagNames = selectedFileTags
              .map((tag) => tag?.name)
              .filter(Boolean) as string[]
            const matchingTags = selectedFileTagNames.filter((tagName) =>
              // @ts-expect-error don't need to worry since we checked that selectedWorkflow exists above
              state.selectedWorkflow.tags
                // tagName is the old field, name is the new field
                // TODO: Remove this once all cached workflows have been updated
                // @ts-expect-error temporary backwards compatibility
                .map((tag) => tag.name ?? tag.tagName)
                .includes(tagName)
            )
            state.showDocClassificationMismatchWarning =
              matchingTags.length / selectedFileTagNames.length <=
              DOCUMENT_CLASSIFICATION_MISMATCH_WARNING_THRESHOLD
          } else {
            state.showDocClassificationMismatchWarning = false
          }
        })
      },
      setShowDocClassificationMismatchWarning: (show) =>
        set(() => ({ showDocClassificationMismatchWarning: show })),
    }))
  )
)

export default useVaultWorkflowStore

import React, { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Form, Modal, Select } from 'antd'
import _ from 'lodash'

import { fetchRoleByIdOrPk, WorkspaceRole } from 'models/roles'
import { FetchWorkspace, Workspace } from 'models/workspace'
import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'
import Services from 'services'
import { Maybe } from 'types'

import { displayErrorMessage } from 'utils/toast'

import { loadWorkflows } from 'components/assistant/workflows/workflow-loader'
import { useAuthUser } from 'components/common/auth-context'
import WorkflowsTable from 'components/settings/permissions/workflows-table'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import { Button as TailwindButton } from 'components/ui/button'
import Tag from 'components/ui/tag'

import RolesPermsCard from './roles-perms-card'
import RolesUsersCard from './roles-users-card'

const WorkspaceRolesInspector = () => {
  const userInfo = useAuthUser()
  const location = useLocation()
  const roleId: string = location.state?.roleId
  const [rolePk, setRolePk] = React.useState<string | null>(null)
  const [rolePermCount, setRolePermCount] = React.useState<number>(0)
  const [roleUserCount, setRoleUserCount] = React.useState<number>(0)
  const [workspaceRole, setWorkspaceRole] =
    React.useState<Maybe<WorkspaceRole>>(null)
  const [workspace, setWorkspace] = React.useState<Maybe<Workspace>>(null)
  const [workflows, setWorkflows] = React.useState<
    WorkflowDefinitionListItem[]
  >([])
  const [workflowOptions, setWorkflowOptions] = React.useState<
    WorkflowDefinitionListItem[]
  >([])
  const [selectedWorkflows, setSelectedWorkflows] = React.useState<string[]>([])
  const [allWorkflows, setAllWorkflows] = React.useState<
    WorkflowDefinitionListItem[]
  >([])

  useEffect(() => {
    const fetchWorkspaceRole = async () => {
      const role = await fetchRoleByIdOrPk(roleId)
      setWorkspaceRole(role)
      if (role) {
        setRolePk(role.rolePk)
        const workspace = await FetchWorkspace(role.workspaceId)
        setWorkspace(workspace)
      }
    }
    void fetchWorkspaceRole()
  }, [roleId])

  const fetchAllData = useCallback(async () => {
    if (_.isNil(rolePk)) return

    try {
      const workflowIds = await Services.Backend.Get<string[]>(
        `internal_admin/workflows/permissions/role/${rolePk}`
      )

      const workflows = await loadWorkflows()
      setAllWorkflows(workflows)
      const availableWorkflows = workflows.filter((w) =>
        workflowIds.includes(w.id)
      )
      setWorkflows(availableWorkflows)
      setWorkflowOptions(
        workflows.filter((w) => !availableWorkflows.includes(w))
      )
    } catch (error) {
      displayErrorMessage('Unable to fetch workflows')
    }
  }, [rolePk])

  useEffect(() => {
    void fetchAllData()
  }, [fetchAllData])

  function addWorkflowsToRole(
    rolePk: string,
    workflows: string[]
  ): Promise<boolean> {
    return Services.Backend.Post(
      `internal_admin/workflows/permissions/role/${rolePk}`,
      {
        workflowIds: workflows,
      },
      { throwOnError: true, maxRetryCount: 0 }
    )
  }

  const handleAddWorkflows = async (): Promise<void> => {
    if (_.isNil(rolePk)) {
      displayErrorMessage('Role not found while adding workflows')
      return
    }
    try {
      await addWorkflowsToRole(rolePk, selectedWorkflows)

      const newWorkflows = [
        ...workflows,
        ...allWorkflows.filter((w) => selectedWorkflows.includes(w.id)),
      ]
      setWorkflows(newWorkflows)
      setWorkflowOptions(
        allWorkflows.filter(
          (w) => !newWorkflows.map((w) => w.id).includes(w.id)
        )
      )

      setSelectedWorkflows([])
    } catch (error) {
      displayErrorMessage('Unable to add workflows')
    }
  }

  const deleteWorkflow = async (workflowId: string): Promise<void> => {
    if (_.isNil(userInfo)) {
      return
    }

    Modal.confirm({
      title: `Are you sure you want to revoke ${workflows.find(
        (w) => w.id === workflowId
      )?.name}?`,
      content: (
        <p>
          This will revoke this permission for <b>{roleId}</b> tied to workspace{' '}
          <b>{workspace?.clientName}</b>
        </p>
      ),
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      width: 600,
      onOk: async () => {
        try {
          await Services.Backend.Delete(
            `internal_admin/workflows/${workflowId}/permissions/role/${rolePk}`,
            {
              workspaceId: userInfo.workspace.id,
            },
            { throwOnError: true, maxRetryCount: 0 }
          )
          const newWorkflows = workflows.filter((w) => w.id !== workflowId)
          setWorkflows(newWorkflows)
          setWorkflowOptions([
            ...workflowOptions,
            ...allWorkflows.filter((w) => workflowId === w.id),
          ])
        } catch (error) {
          displayErrorMessage('Unable to delete workflow')
        }
      },
    })
  }

  if (
    _.isNil(workspaceRole) ||
    _.isNil(workspace) ||
    !userInfo.IsInternalAdminReader
  ) {
    return null
  }

  return (
    <>
      <SettingsAppHeader isInternalAdmin />
      <SettingsLayout>
        <div className="space-y-5">
          <div className="flex flex-row space-x-3">
            <p className="font-bold text-xl text-secondary">
              {_.startCase(workspaceRole.name)}
            </p>
            <Tag text={workspaceRole.roleId} />
          </div>
          <div>
            <RolesUsersCard
              workspaceRole={workspaceRole}
              setRoleUserCount={setRoleUserCount}
              rolePermCount={rolePermCount}
              workspace={workspace}
            />
          </div>
          <div>
            <RolesPermsCard
              workspaceRole={workspaceRole}
              setRolePermCount={setRolePermCount}
              roleUserCount={roleUserCount}
              workspace={workspace}
            />
          </div>
          {userInfo.isUserManagement && (
            <div>
              <Form.Item
                name="workflows"
                label={
                  <h6>
                    <span className="text-md mr-1 font-semibold">
                      Add workflows
                    </span>
                  </h6>
                }
                className="mt-4"
              >
                {/* TODO: Figure out why the Select doesn't re-render when selectedWorkflows updates without the following div */}
                <div className="hidden">{selectedWorkflows}</div>
                <Select
                  mode="multiple"
                  placeholder="Add workflows"
                  virtual={false}
                  style={{ width: '100%' }}
                  optionFilterProp="label"
                  options={_.sortBy(workflowOptions, 'name').map((workflow) => {
                    return {
                      label: `${workflow.name} - ${workflow.id}`,
                      value: workflow.id,
                    }
                  })}
                  onChange={(value) => setSelectedWorkflows(value)}
                  value={selectedWorkflows}
                />
              </Form.Item>
              <Form.Item>
                <TailwindButton
                  variant="default"
                  onClick={() => {
                    void handleAddWorkflows()
                  }}
                  disabled={_.isEmpty(selectedWorkflows)}
                >
                  Add
                </TailwindButton>
              </Form.Item>
              <WorkflowsTable
                workflows={workflows}
                onDeleteWorkflow={deleteWorkflow}
              />
            </div>
          )}
        </div>
      </SettingsLayout>
    </>
  )
}

export default WorkspaceRolesInspector

import React, { useEffect, useRef, useState, useCallback } from 'react'
import { NavigateOptions } from 'react-router-dom'

import {
  Cell,
  flexRender,
  Row,
  type Table as TypeTable,
} from '@tanstack/react-table'
import {
  notUndefined,
  useVirtualizer,
  VirtualItem,
} from '@tanstack/react-virtual'

import Services from 'services'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { cn } from 'utils/utils'

import { ScrollArea } from 'components/ui/scroll-area'
import Skeleton from 'components/ui/skeleton'
import {
  Table,
  TableCaption,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'

import './data-table.css'

interface DataTableProps<TData> {
  table: TypeTable<TData>
  className?: string
  onRowClick?: (
    row: Row<TData>,
    event: React.MouseEvent | React.KeyboardEvent
  ) => void
  hrefForRow?: (row: Row<TData>) => string | null
  isRowDisabled?: (row: Row<TData>) => boolean
  hrefOptions?: (row: Row<TData>) => {
    navigateOptions?: NavigateOptions
    removeParams?: string[]
  }
  isLoading?: boolean
  // If true, the columns will have fixed width based on the column definition
  tableFixed?: boolean
  useVirtual?: boolean
  virtualEstimateSize?: number
  emptyStateText?: string
  emptyCta?: React.ReactNode
  analyticsName?: string
  trackRowEvent?: (row: Row<TData>, inNewTab: boolean) => void
  analyticsMetadataForRow?: (row: Row<TData>) => Record<string, unknown>
  onRowClickAuditLog?: (row: Row<TData>) => Promise<void>
  marginTop?: number
  stickyFirstColumn?: boolean
  hideHeaders?: boolean
  hideTableBorder?: boolean
  tableCellClassName?: string
  tableHeaderCellClassName?: string
  tableBodyCellClassName?: string

  id?: string
  caption?: string
  tableRowClassName?: (row: Row<TData>) => string
}

export function DataTable<TData>({
  table,
  className,
  onRowClick,
  hrefForRow,
  isRowDisabled,
  hrefOptions,
  isLoading,
  tableFixed,
  useVirtual,
  virtualEstimateSize,
  emptyStateText,
  emptyCta,
  analyticsName,
  trackRowEvent,
  analyticsMetadataForRow,
  onRowClickAuditLog,
  marginTop = 0,
  stickyFirstColumn = false,
  hideHeaders = false,
  hideTableBorder = false,
  tableCellClassName,
  tableHeaderCellClassName,
  tableBodyCellClassName,
  tableRowClassName,
  id,
  caption,
}: DataTableProps<TData>) {
  const columnCount = table.getAllColumns().length
  const navigate = useNavigateWithQueryParams()
  // TODO: when tableFixed is true, sticky header will not work because of
  // the table-fixed class. We need to find a way to make it work.
  const useStickyHeader = !tableFixed
  const headerRef = useRef<HTMLTableSectionElement | null>(null)
  const [headerHeight, setHeaderHeight] = useState(
    useStickyHeader && !hideHeaders ? 48 : 0
  )

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight)
    }
  }, [])

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement
    const hasScrolled = target.scrollLeft > 0

    const fixedCells = target.querySelectorAll('[data-fixed-column="true"]')
    fixedCells.forEach((cell) => {
      if (hasScrolled) {
        cell.classList.add('fade-in')
      } else {
        cell.classList.remove('fade-in')
      }
    })
  }, [])

  const headerRender = () =>
    !hideHeaders && (
      <TableHeader
        data-testid="table-header"
        className={cn({
          'sticky top-0 z-10 rounded-t-lg bg-primary/70 shadow-[0_1px_0_0_hsl(var(--border-primary))] backdrop-blur':
            useStickyHeader,
          // Hide 1px gap on top of borderless sticky header
          'shadow-[0_1px_0_0_hsl(var(--border-primary)),0_-1px_0_hsl(var(--background-primary))]':
            useStickyHeader && hideTableBorder,
        })}
        ref={headerRef}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow
            data-testid="table-header-row"
            key={headerGroup.id}
            id={`table-header-row-${headerGroup.id}`}
            className={cn({ 'border-none': useStickyHeader })}
          >
            {headerGroup.headers.map((header, index) => (
              <TableHead
                scope="col"
                data-testid="table-head"
                data-fixed-column={
                  stickyFirstColumn && index === 0 ? 'true' : undefined
                }
                id={`table-head-${header.id}`}
                key={header.id}
                colSpan={header.colSpan}
                // eslint-disable-next-line react/forbid-component-props
                style={{ width: header.getSize() }}
                className={cn(
                  tableCellClassName,
                  tableHeaderCellClassName,
                  stickyFirstColumn &&
                    index === 0 &&
                    'sticky left-0 z-10 !overflow-visible bg-primary'
                )}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
    )

  const cellRender = (cell: Cell<TData, unknown>, index: number) => {
    return (
      <TableCell
        key={cell.id}
        data-testid="table-cell"
        data-fixed-column={
          stickyFirstColumn && index === 0 ? 'true' : undefined
        }
        // eslint-disable-next-line react/forbid-component-props
        style={{ width: cell.column.getSize() }}
        className={cn(
          tableCellClassName,
          tableBodyCellClassName,
          stickyFirstColumn && index === 0 && 'sticky left-0 z-[5] bg-primary'
        )}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </TableCell>
    )
  }

  const handleRowClick = async (
    event: React.MouseEvent | React.KeyboardEvent,
    row: Row<TData>
  ) => {
    if (onRowClickAuditLog) {
      await onRowClickAuditLog(row)
    }
    if (hrefForRow && hrefForRow(row)) {
      const href = hrefForRow(row)!
      const { navigateOptions, removeParams } = hrefOptions?.(row) || {}
      const openInNewTab = event.metaKey || event.ctrlKey
      Services.HoneyComb.Record({
        metric: analyticsName
          ? `ui.${analyticsName}_table_row_clicked`
          : 'ui.table_row_clicked',
        href: href,
        open_in_new_tab: openInNewTab,
        ...analyticsMetadataForRow?.(row),
      })
      if (trackRowEvent) {
        trackRowEvent(row, openInNewTab)
      }
      if (openInNewTab) {
        window.open(href, '_blank')
      } else {
        navigate(href, navigateOptions, removeParams)
      }
    } else if (onRowClick) {
      onRowClick(row, event)
    }
  }

  const canHandleRowClick = (row: Row<TData>) =>
    !!onRowClick || hrefForRow?.(row)
  const rowRender = (row: Row<TData>, style?: React.CSSProperties) => (
    <TableRow
      key={row.id}
      data-state={row.getIsSelected() && 'selected'}
      className={cn({
        'cursor-pointer': canHandleRowClick(row),
        'opacity-50': isRowDisabled?.(row),
        ...(tableRowClassName ? { [tableRowClassName(row)]: true } : {}),
      })}
      // eslint-disable-next-line react/forbid-component-props
      style={style}
      data-testid="table-row"
      tabIndex={isRowDisabled?.(row) ? -1 : 0}
      role={!!hrefForRow && hrefForRow(row) ? 'button' : undefined}
      aria-disabled={isRowDisabled?.(row)}
      onClick={async (event) => {
        if (!isRowDisabled?.(row) && canHandleRowClick(row)) {
          await handleRowClick(event, row)
        }
      }}
      onKeyDown={async (event) => {
        if (
          !isRowDisabled?.(row) &&
          canHandleRowClick(row) &&
          event.key === 'Enter'
        ) {
          await handleRowClick(event, row)
        }
      }}
    >
      {row.getVisibleCells().map(cellRender)}
    </TableRow>
  )

  const loadingOrEmptyRender = () => (
    <TableRow className="transition hover:bg-transparent">
      <TableCell
        data-testid="empty-table-cell"
        colSpan={columnCount}
        className="h-24"
      >
        {isLoading ? (
          <Skeleton rows={5} rowHeight="h-4" />
        ) : (
          <div className="flex size-full flex-col items-center justify-center gap-2">
            <p className="text-sm text-muted">
              {emptyStateText ?? 'No results'}
            </p>
            {emptyCta}
          </div>
        )}
      </TableCell>
    </TableRow>
  )

  const parentRef = React.useRef<HTMLDivElement>(null)
  const virtualizer = useVirtualizer({
    count: table.getRowModel().rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => virtualEstimateSize ?? 48,
    overscan: 5,
  })
  const items = virtualizer.getVirtualItems()
  const [before, after] =
    items.length > 0
      ? [
          notUndefined(items[0]).start - virtualizer.options.scrollMargin,
          virtualizer.getTotalSize() -
            notUndefined(items[items.length - 1]).end,
        ]
      : [0, 0]

  if (useVirtual) {
    const virtualRowRender = (virtualRow: VirtualItem) => {
      const row = table.getRowModel().rows[virtualRow.index]
      return rowRender(row, {
        height: `${virtualRow.size}px`,
      })
    }

    return (
      <ScrollArea
        className={cn(
          'rounded-lg',
          {
            border: !hideTableBorder,
          },
          className
        )}
        scrollBarTop={useStickyHeader && !hideHeaders ? headerHeight : 0}
        // 146px = 112px top bar + py-4 padding + border
        // eslint-disable-next-line react/forbid-component-props
        style={{ maxHeight: `calc(100vh - ${146 + marginTop}px` }}
        hasHorizontalScroll
        onScroll={handleScroll}
        isFullHeight
        ref={parentRef}
        id={id}
      >
        <div
          style={{
            height: virtualizer.getTotalSize()
              ? `${virtualizer.getTotalSize() + headerHeight}px`
              : undefined,
          }}
        >
          <table
            className={cn('w-full caption-bottom bg-primary text-sm', {
              relative: useStickyHeader,
              'table-fixed': tableFixed,
            })}
          >
            {caption && (
              <TableCaption className="sr-only">{caption}</TableCaption>
            )}
            {headerRender()}
            <TableBody data-testid="table-body">
              {before > 0 && (
                <tr>
                  <td colSpan={columnCount} style={{ height: before }} />
                </tr>
              )}
              {table.getRowModel().rows.length
                ? virtualizer.getVirtualItems().map(virtualRowRender)
                : loadingOrEmptyRender()}
              {after > 0 && (
                <tr>
                  <td colSpan={columnCount} style={{ height: after }} />
                </tr>
              )}
            </TableBody>
          </table>
        </div>
      </ScrollArea>
    )
  }

  return (
    <ScrollArea
      className={cn(
        'rounded-lg',
        {
          border: !hideTableBorder,
        },
        className
      )}
      scrollBarTop={useStickyHeader && !hideHeaders ? headerHeight : 0}
      // TODO margin top is a confusing prop name, this component can be cleaned up
      // eslint-disable-next-line react/forbid-component-props
      style={
        marginTop
          ? { maxHeight: `calc(100vh - ${98 + marginTop}px` }
          : undefined
      }
      hasHorizontalScroll
      onScroll={handleScroll}
      id={id}
    >
      <Table
        className={cn({
          relative: useStickyHeader,
          'table-fixed': tableFixed,
        })}
      >
        {caption && <TableCaption className="sr-only">{caption}</TableCaption>}
        {headerRender()}
        <TableBody data-testid="table-body">
          {table.getRowModel().rows.length
            ? table.getRowModel().rows.map((row) => rowRender(row))
            : loadingOrEmptyRender()}
        </TableBody>
      </Table>
    </ScrollArea>
  )
}

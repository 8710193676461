import React, { useEffect, useState } from 'react'
import { DateRange } from 'react-day-picker'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { Copy } from 'lucide-react'
import pluralize from 'pluralize'

import { getWorkspaceAuditLogs, Workspace } from 'models/workspace'
import { AuditLogEntry } from 'models/workspace'

import { getTableDateString } from 'utils/date-utils'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { HARVEY_START_DATE } from 'utils/utils'

import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import DateRangePicker from 'components/ui/date-range-picker'
import Icon from 'components/ui/icon/icon'
import { Textarea } from 'components/ui/text-area'

interface WorkspaceDetailsAuditLogsProps {
  workspace: Workspace
}

const getStartOfToday = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return today
}

const WorkspaceDetailsAuditLogs = ({
  workspace,
}: WorkspaceDetailsAuditLogsProps) => {
  const [auditLogs, setAuditLogs] = React.useState<AuditLogEntry[]>([])
  const [isLoading, setIsLoading] = React.useState(true)

  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRange | undefined
  >({
    from: getStartOfToday(),
    to: new Date(),
  })

  useEffect(() => {
    const fetchAuditLogs = async () => {
      const createdAfterOrEqual = selectedDateRange?.from ?? getStartOfToday()

      const createdBeforeOrEqual =
        selectedDateRange?.to ??
        (selectedDateRange?.from ? new Date(createdAfterOrEqual) : new Date())
      createdBeforeOrEqual.setHours(23, 59, 59)

      setIsLoading(true)
      const auditLogs = await getWorkspaceAuditLogs(workspace.id, {
        from: createdAfterOrEqual,
        to: createdBeforeOrEqual,
      })
      setAuditLogs(auditLogs)
      setIsLoading(false)
    }
    fetchAuditLogs()
  }, [selectedDateRange?.from, selectedDateRange?.to, workspace])

  const columns: ColumnDef<AuditLogEntry>[] = [
    {
      accessorKey: 'email',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Email" />
      },
      cell: ({ getValue }) => <div className="ml-3">{String(getValue())}</div>,
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'logType',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Log Type" />
      },
      cell: ({ getValue }) => <div className="ml-3">{String(getValue())}</div>,
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'timestamp',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Timestamp" />
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Created At" />
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'data',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Data" />
      },
      cell: ({ getValue }) => {
        const value = getValue()
        const prettyValue =
          typeof value === 'object'
            ? JSON.stringify(value, null, 2)
            : String(value)

        const copyToClipboard = () => {
          navigator.clipboard
            .writeText(prettyValue)
            .then(() => {
              displaySuccessMessage('Copied to clipboard')
            })
            .catch((err) => {
              displayErrorMessage('Failed to copy: ' + err)
            })
        }
        return (
          <div className="relative flex min-w-64 justify-between">
            <Button
              className="absolute right-0 top-0 z-10 pr-0"
              size="smIcon"
              variant="ghost"
              onClick={copyToClipboard}
            >
              <Icon icon={Copy} size="small" />
            </Button>
            <Textarea
              className="ml-3 h-10 max-h-10 flex-grow whitespace-pre-wrap pr-10"
              value={prettyValue}
              readOnly
            />
          </div>
        )
      },
      enableGlobalFilter: false,
    },
    {
      // this is only for search
      id: 'dataHidden',
      accessorKey: 'data',
      header: () => null,
      cell: () => null,
      enableGlobalFilter: true,
      accessorFn: (row) => {
        const value = row.data // Assuming the JSON is in the 'data' field
        if (typeof value === 'object') {
          // Stringify the object, but remove the outer quotes
          return JSON.stringify(value).slice(1, -1)
        }
        return String(value)
      },
    },
  ]

  const [filter, setFilter] = useState<string>('')

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: auditLogs,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
      columnVisibility: {
        dataHidden: false,
      },
    },
    onPaginationChange: setTablePaginationState,
    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'log',
    table.getFilteredRowModel().rows.length
  )}`

  return (
    <div>
      <DataTableHeader
        searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
      >
        <div className="mr-2 flex w-full justify-end">
          <DateRangePicker
            selectedDateRange={selectedDateRange}
            onSelectDateRange={setSelectedDateRange}
            disableFutureDates
            disablePastDatesBeforeDate={HARVEY_START_DATE}
          />
        </div>
      </DataTableHeader>
      <div className="mt-2">
        <DataTable
          table={table}
          className="min-h-[420px]"
          emptyStateText="No audit logs found"
          isLoading={isLoading}
        />
      </div>
      <DataTableFooter table={table}>
        <p>{rowCountCopy}</p>
      </DataTableFooter>
    </div>
  )
}

export default WorkspaceDetailsAuditLogs

import React from 'react'

import { Plus } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { VaultFolder } from 'openapi/models/VaultFolder'

import {
  EmptyProjects,
  FetchingSpinner,
  ProjectChooser,
} from 'components/assistant/features/composer/assistant-vault-ks-picker'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import {
  DialogTitle,
  DialogHeader,
  DialogDescription,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { useVaultProjects } from 'components/vault/hooks/use-vault-projects'
import { getVaultProjects } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const VaultWorkflowProjectChooser = ({
  selectedWorkflowName,
  handleSelectProjectForWorkflow,
  handleCreateProjectCardClick,
}: {
  selectedWorkflowName: string
  handleSelectProjectForWorkflow: (project: VaultFolder) => void
  handleCreateProjectCardClick: () => void
}) => {
  const userInfo = useAuthUser()
  const {
    projects: allVaultProjects,
    isFetching,
    areProjectsLoaded,
  } = useVaultProjects(undefined, {
    includeExamples: false,
    loadAllMetadata: true,
    isEnabled: true,
  })

  const [allFolderIdToVaultFolder, rootVaultFolderIds] = useVaultStore(
    useShallow((s) => [s.allFolderIdToVaultFolder, s.rootVaultFolderIds])
  )

  const handleSelectProject = (project: VaultFolder) => {
    handleSelectProjectForWorkflow(project)
  }

  const isEmpty = areProjectsLoaded && allVaultProjects.length === 0

  const projectsCount =
    getVaultProjects({
      allFolderIdToVaultFolder,
      rootVaultFolderIds,
      userId: userInfo.dbId,
    }).length + 1
  const projectsCountLimit = userInfo.workspace.getVaultProjectsCountLimit(
    userInfo.vaultFeature
  )
  const isProjectCountExceeded = projectsCount > projectsCountLimit

  if (isEmpty) {
    return <EmptyProjects />
  }

  return (
    <div className="flex h-[550px] flex-col gap-y-4">
      <DialogHeader className="h-16 flex-none border-b border-b-primary px-6">
        <DialogTitle className="flex items-center">
          Choose project for {selectedWorkflowName}
          {isFetching && (
            <FetchingSpinner delayMs={allVaultProjects.length > 0 ? 1000 : 0} />
          )}
        </DialogTitle>
        <DialogDescription>
          Select a Vault project or start one from scratch
        </DialogDescription>
      </DialogHeader>

      <div className="min-h-0 flex-1">
        <ScrollArea className="h-full">
          <div className="flex flex-col gap-y-4">
            {!isProjectCountExceeded && (
              <div className="grid w-full grid-cols-1 justify-start gap-3 px-6">
                <CreateProjectCard
                  handleCreateProjectCardClick={handleCreateProjectCardClick}
                />
              </div>
            )}
            <div className="flex flex-col gap-y-4">
              <p className="px-6 text-sm font-medium">Existing projects</p>
              <ProjectChooser
                isLoaded={areProjectsLoaded}
                onSelectProject={handleSelectProject}
                projects={allVaultProjects}
                shouldShowCreateProjectCard={false}
              />
            </div>
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}

const CreateProjectCard = ({
  handleCreateProjectCardClick,
}: {
  handleCreateProjectCardClick: () => void
}) => {
  return (
    <Button
      // TODO(stella): fix height once we standardize
      className="flex h-28 w-60 justify-start p-4"
      onClick={handleCreateProjectCardClick}
      variant="outline"
    >
      <div className="flex flex-col items-start justify-start gap-y-4">
        <Icon icon={Plus} className="size-6 stroke-inactive" />
        <div className="flex flex-col items-start justify-start">
          <p className="text-sm font-medium">Create project</p>
          <p className="text-xs text-muted">Upload a new set of files</p>
        </div>
      </div>
    </Button>
  )
}

export default VaultWorkflowProjectChooser

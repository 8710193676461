import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { getMergedQueryPath } from 'hooks/use-navigate-with-query-params'

import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { KnowledgeSourceItem } from 'components/assistant/utils/assistant-knowledge-sources'
import { useAnalytics } from 'components/common/analytics/analytics-context'

export const useHandleKnowledgeSources = () => {
  const [mode, setKnowledgeSource] = useAssistantStore(
    useShallow((s) => [s.mode, s.setKnowledgeSource])
  )
  const navigate = useNavigate()
  const location = useLocation()
  const { trackEvent } = useAnalytics()

  const handleSetKnowledgeSource = (
    knowledgeSource: KnowledgeSourceItem | null
  ) => {
    setKnowledgeSource(knowledgeSource)

    navigate(getMergedQueryPath(location.pathname, location), {
      state: { knowledge: { type: knowledgeSource } },
    })
    trackEvent('Knowledge Source Selected', {
      source_type: knowledgeSource,
      assistant_mode: mode,
    })
  }

  const handleRemoveKnowledgeSources = () => {
    setKnowledgeSource(null)

    // Replace location state locKnowledge and unset ref
    navigate(getMergedQueryPath(location.pathname, location), {
      replace: true,
    })
  }

  return { handleSetKnowledgeSource, handleRemoveKnowledgeSources }
}

import * as React from 'react'
import { useForm } from 'react-hook-form'

import { useAuth0 } from '@auth0/auth0-react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { LanguageCode } from 'openapi/models/LanguageCode'
import { PracticeArea } from 'openapi/models/PracticeArea'
import { Profession } from 'openapi/models/Profession'
import { Title } from 'openapi/models/Title'

import {
  shouldShowPracticeAreas,
  shouldShowTitle,
  shouldShowYoe,
  validateUserProfileData,
} from 'utils/user-profile-helpers'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import ProfileDropdown from 'components/common/profile/profile-dropdown'
import ProfileMadlibs from 'components/common/profile/profile-madlibs'
import ProfilePracticeAreasForm, {
  FORM_DESCRIPTION as PA_FORM_DESC,
  FORM_HEADING as PA_FORM_HEAD,
} from 'components/common/profile/profile-practice-areas-form'
import ProfileLanguageForm, {
  FORM_DESCRIPTION as LANG_FORM_DESC,
  FORM_HEADING as LANG_FORM_HEAD,
} from 'components/common/profile/profile-preferred-language-form'
import ProfileProfessionForm, {
  FORM_DESCRIPTION as PROF_FORM_DESC,
  FORM_HEADING as PROF_FORM_HEAD,
} from 'components/common/profile/profile-primary-profession-form'
import ProfileTitleForm, {
  FORM_DESCRIPTION as TITLE_FORM_DESC,
  FORM_HEADING as TITLE_FORM_HEAD,
} from 'components/common/profile/profile-title-form'
import ProfileExperienceForm, {
  FORM_DESCRIPTION as YOE_FORM_DESC,
  FORM_HEADING as YOE_FORM_HEAD,
  MAX_YOE,
  MIN_YOE,
} from 'components/common/profile/profile-yoe-form'
import {
  ProfileData,
  useUserProfileStore,
} from 'components/common/user-profile-store'

const profileSchema = z
  .object({
    primaryProfession: z.nativeEnum(Profession),
    title: z.nativeEnum(Title).optional().nullable(),
    yoe: z.coerce
      .number()
      .int()
      .min(MIN_YOE)
      .max(MAX_YOE)
      .optional()
      .nullable(),
    practiceAreas: z.array(z.nativeEnum(PracticeArea)).max(3).optional(),
    preferredLanguage: z.nativeEnum(LanguageCode),
  })
  .refine(
    (input) =>
      !(
        shouldShowPracticeAreas(input.primaryProfession, input.title) &&
        input.practiceAreas?.length === 0
      )
  )

export type ProfileSchema = z.infer<typeof profileSchema>

const ProfileForm: React.FC = () => {
  const { user } = useAuth0()
  const userProfile = useUserProfileStore((s) => s.userProfile)

  const editUserProfile = useUserProfileStore((s) => s.editUserProfile)
  const [isSaving, setIsSaving] = React.useState(false)

  const form = useForm<ProfileSchema>({
    resolver: zodResolver(profileSchema),
    values: {
      primaryProfession: userProfile?.primaryProfession || 'LEGAL',
      title: userProfile?.title,
      yoe: userProfile?.yoe,
      practiceAreas: userProfile?.practiceAreas?.map(
        (practiceArea) => practiceArea.name
      ),
      preferredLanguage: userProfile?.preferredLanguage || 'en-us',
    },
  })
  const { trackEvent } = useAnalytics()

  React.useEffect(() => {
    trackEvent('profile_settings_page_viewed')
  }, [trackEvent])

  if (!user || !userProfile) throw new Error('User or user profile not found')

  const onSubmit = form.handleSubmit(async (data: ProfileSchema) => {
    const profileData: ProfileData = {
      primaryProfession: data.primaryProfession,
      practiceAreas: data.practiceAreas,
      title: data.title,
      yoe: data.yoe,
      preferredLanguage: data.preferredLanguage,
    }
    setIsSaving(true)
    await editUserProfile(user, profileData)
    trackEvent('profile_settings_saved', {
      primary_profession: data.primaryProfession,
      title: data.title,
      yoe: data.yoe,
      practice_areas: data.practiceAreas,
      preferred_language: data.preferredLanguage,
    })
    setIsSaving(false)
  })

  const primaryProfession = form.watch('primaryProfession')
  const title = form.watch('title')
  const yoe = form.watch('yoe')
  const practiceAreas = form.watch('practiceAreas')
  const preferredLanguage = form.watch('preferredLanguage')

  const clearTitleOrYoe = (primaryProfession: Profession) => {
    if (shouldShowTitle(primaryProfession)) {
      form.setValue('yoe', null)
    } else if (shouldShowYoe(primaryProfession)) {
      form.setValue('title', null)
    }
  }

  const clearPracticeAreas = () => form.setValue('practiceAreas', [])

  const isSavable =
    form.formState.isDirty &&
    validateUserProfileData({
      primaryProfession,
      practiceAreas,
      title,
      yoe,
      preferredLanguage,
    })

  return (
    <div className="max-w-[600px] space-y-4">
      <div className="rounded-md border px-4 py-3">
        <ProfileMadlibs
          primaryProfession={userProfile.primaryProfession}
          title={userProfile.title}
          practiceAreas={userProfile.practiceAreas?.map(
            (practiceArea) => practiceArea.name
          )}
          yoe={userProfile.yoe}
          preferredLanguage={userProfile.preferredLanguage}
        />
      </div>
      <ProfileDropdown
        key={1}
        onSave={onSubmit}
        onReset={form.reset}
        isResetable={form.formState.isDirty}
        isSavable={isSavable}
        isSaving={isSaving}
      >
        <form onSubmit={onSubmit} className="space-y-6">
          <div className="space-y-4">
            <div className="space-y-1">
              <h3 className="text-xs font-semibold">{PROF_FORM_HEAD}</h3>
              <p className="text-xs text-secondary">{PROF_FORM_DESC}</p>
            </div>
            <ProfileProfessionForm
              control={form.control}
              onChange={(primaryProfession) => {
                // Opting to clear when the profession changes
                // User can reset if they want to revert to previous values
                // Avoid overthinking
                clearTitleOrYoe(primaryProfession)
                clearPracticeAreas()
              }}
            />
          </div>
          {shouldShowTitle(primaryProfession) && (
            <div className="space-y-4">
              <div className="space-y-1">
                <h3 className="text-xs font-semibold">{TITLE_FORM_HEAD}</h3>
                <p className="text-xs text-secondary">{TITLE_FORM_DESC}</p>
              </div>
              <ProfileTitleForm
                control={form.control}
                onChange={(title) => {
                  if (
                    // Opting to clear when the profession changes
                    // User can reset if they want to revert to previous values
                    // Avoid overthinking
                    !shouldShowPracticeAreas(primaryProfession, title)
                  ) {
                    clearPracticeAreas()
                  }
                }}
              />
            </div>
          )}
          {shouldShowYoe(primaryProfession) && (
            <div className="space-y-4">
              <div className="space-y-1">
                <h3 className="text-xs font-semibold">{YOE_FORM_HEAD}</h3>
                <p className="text-xs text-secondary">{YOE_FORM_DESC}</p>
              </div>
              <ProfileExperienceForm control={form.control} />
            </div>
          )}
          {shouldShowPracticeAreas(primaryProfession, title) && (
            <div className="space-y-4">
              <div className="space-y-1">
                <h3 className="text-xs font-semibold">{PA_FORM_HEAD}</h3>
                <p className="text-xs text-secondary">{PA_FORM_DESC}</p>
              </div>
              <ProfilePracticeAreasForm
                control={form.control}
                primaryProfession={form.watch('primaryProfession')}
              />
            </div>
          )}
          <div className="space-y-4">
            <div className="space-y-1">
              <h3 className="text-xs font-semibold">{LANG_FORM_HEAD}</h3>
              <p className="text-xs text-secondary">{LANG_FORM_DESC}</p>
            </div>
            <ProfileLanguageForm control={form.control} />
          </div>
        </form>
      </ProfileDropdown>
    </div>
  )
}

export default ProfileForm

import React, { useMemo } from 'react'
import { useMount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { WorkflowType } from 'types/workflows'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import FullscreenLoading from 'components/common/fullscreen-loading'
import Link from 'components/ui/link/link'
import { MAX_QUESTIONS_LIMIT } from 'components/vault/utils/vault'
import { ReviewWorkflow } from 'components/vault/utils/vault-fetcher'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'
import { useVaultWorkflows } from 'components/vault/workflows/use-vault-workflows'
import VaultWorkflowBuilder from 'components/vault/workflows/vault-workflow-builder'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

import { getWorkflowDescriptions } from './constants'
import WorkflowCard, {
  LegacyWorkflowInfo,
  VaultWorkflowInfo,
  WorkflowCategory,
  WorkflowInfo,
} from './workflow-card'
import { WorkflowTypeToDetails } from './workflow-definitions'
import { WorkflowSource } from './workflows-helpers'
import { userHasPermissionForWorkflow } from './workflows-utils'

const useVaultWorkflowSetup = () => {
  const userInfo = useAuthUser()

  const [vaultWorkflows] = useVaultWorkflowStore(
    useShallow((state) => [state.workflows])
  )

  const [setReviewFilesPerQueryLimit, setReviewQuestionsPerQueryLimit] =
    useVaultUsageStore(
      useShallow((s) => [
        s.setReviewFilesPerQueryLimit,
        s.setReviewQuestionsPerQueryLimit,
      ])
    )

  const { isFetching } = useVaultWorkflows()

  const vaultWorkflowInfo: VaultWorkflowInfo[] = useMemo(
    () =>
      (Object.values(vaultWorkflows).filter(Boolean) as ReviewWorkflow[]).map(
        (workflow) => ({
          id: workflow.id,
          name: workflow.name,
          description: workflow.description,
          category: WorkflowCategory.VAULT,
          numColumns: workflow.columns?.length ?? 0,
          documentTags: workflow.tags,
        })
      ),
    [vaultWorkflows]
  )

  const hasVaultWorkflows = Object.values(vaultWorkflows).length > 0

  useMount(() => {
    if (userInfo.vaultFeature) {
      setReviewFilesPerQueryLimit(
        userInfo.workspace.getVaultReviewFilesCountLimit(userInfo.vaultFeature)
      )
      setReviewQuestionsPerQueryLimit(
        userInfo.workspace.getVaultReviewQuestionsCountLimit(
          userInfo.vaultFeature
        )
      )
    } else {
      setReviewFilesPerQueryLimit(null)
      setReviewQuestionsPerQueryLimit(MAX_QUESTIONS_LIMIT)
    }
  })

  return {
    isFetchingVaultWorkflows: isFetching,
    vaultWorkflowInfo,
    hasVaultWorkflows,
  }
}

const WorkflowsList: React.FC = () => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const [vaultWorkflows, setWorkflowModalState, setSelectedWorkflow] =
    useVaultWorkflowStore(
      useShallow((state) => [
        state.workflows,
        state.setWorkflowModalState,
        state.setSelectedWorkflow,
      ])
    )

  const legacyWorkflows = Object.values(WorkflowType)
    .filter((workflow) => userHasPermissionForWorkflow(userInfo, workflow))
    .map((workflow) => WorkflowTypeToDetails[workflow])
  const legacyWorkflowDescriptions = getWorkflowDescriptions()
  const legacyWorkflowInfo: LegacyWorkflowInfo[] = legacyWorkflows.map(
    (workflow) => ({
      id: workflow.name, // TODO(stella): improve typing once workflow cards finalized
      name: workflow.name,
      description: legacyWorkflowDescriptions[workflow.type].body,
      category: WorkflowCategory.LEGACY,
      path: workflow.path,
    })
  )

  const { isFetchingVaultWorkflows, vaultWorkflowInfo, hasVaultWorkflows } =
    useVaultWorkflowSetup()

  if (isFetchingVaultWorkflows || !hasVaultWorkflows) {
    return <FullscreenLoading isLoading zIndex="z-50" />
  }

  const handleVaultWorkflowClick = (workflow: WorkflowInfo) => {
    trackEvent('Vault Workflow Clicked', {
      workflow_id: workflow.id,
      workflow_name: workflow.name,
    })
    setWorkflowModalState(WorkflowModalState.Builder)
    setSelectedWorkflow(vaultWorkflows[workflow.id] ?? null)
  }

  return (
    <div className="flex flex-col gap-10">
      {/* TODO(stella): move legacy workflows to updated workflow cards and use WorkflowSection */}
      <WorkflowsSection
        title="Legacy"
        workflowInfo={legacyWorkflowInfo}
        category={WorkflowCategory.LEGACY}
      />
      <WorkflowsSection
        title="Vault"
        workflowInfo={vaultWorkflowInfo}
        category={WorkflowCategory.VAULT}
        onWorkflowClick={handleVaultWorkflowClick}
      />
      <VaultWorkflowBuilder source={WorkflowSource.WORKFLOWS_PAGE} />
    </div>
  )
}

type WorkflowsSectionProps = {
  title: string
  workflowInfo: WorkflowInfo[]
  category: WorkflowCategory
  onWorkflowClick?: (workflow: WorkflowInfo) => void
}

const WorkflowsSection: React.FC<WorkflowsSectionProps> = ({
  title,
  workflowInfo,
  category,
  onWorkflowClick,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="text-lg font-medium">{title}</p>
      <div className="grid grid-cols-2 gap-3 lg:grid-cols-3 xl:grid-cols-4">
        {workflowInfo.map((workflow) =>
          category === WorkflowCategory.LEGACY ? (
            <Link
              className="group rounded-lg"
              key={workflow.name}
              to={`${(workflow as LegacyWorkflowInfo).path}${location.search}`}
            >
              <WorkflowCard
                key={workflow.id}
                workflowInfo={workflow}
                onClick={() => {}}
                source={WorkflowSource.WORKFLOWS_PAGE}
              />
            </Link>
          ) : (
            <WorkflowCard
              key={workflow.id}
              workflowInfo={workflow}
              onClick={onWorkflowClick ?? (() => {})}
              source={WorkflowSource.WORKFLOWS_PAGE}
            />
          )
        )}
      </div>
    </div>
  )
}

export default WorkflowsList

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'

import {
  FetchReviewRows,
  ReviewRowsResponse,
} from 'components/vault/utils/vault-fetcher'

const PAGE_SIZE = 100

export const useFileReviewRows = (fileId: string) => {
  const { data, isPending: isLoading } = useWrappedQuery<ReviewRowsResponse>({
    queryKey: [HarvQueryKeyPrefix.VaultFileReviewRows, fileId, PAGE_SIZE, 1],
    queryFn: () => FetchReviewRows(fileId, PAGE_SIZE, 1),
    enabled: !!fileId,
    // Disable refetch on window focus to prevent unnecessary re-fetches
    refetchOnWindowFocus: false,
  })

  return {
    reviewRows: data?.rows ?? [],
    isLoading,
    total: data?.total ?? 0,
  }
}

import * as React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultKnowledgeSource } from 'openapi/models/VaultKnowledgeSource'

import AssistantFiles from 'components/assistant/components/assistant-files'
import SourcesToolbar from 'components/assistant/features/composer/components/sources-toolbar'
import {
  FileUploadingState,
  useAssistantStore,
} from 'components/assistant/stores/assistant-store'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { useVaultStore } from 'components/vault/utils/vault-store'

interface VaultRendererProps {
  handleCancel: () => void
}

const VaultRenderer: React.FC<VaultRendererProps> = ({ handleCancel }) => {
  const [knowledgeSource, removeFileIds] = useAssistantStore(
    useShallow((s) => [s.knowledgeSource, s.removeFileIds])
  )
  const [fileIdToVaultFile, currentProject] = useVaultStore(
    useShallow((s) => [s.fileIdToVaultFile, s.currentProject])
  )
  const knowledgeSourceItem = knowledgeSource as VaultKnowledgeSource
  const activeFiles = knowledgeSourceItem.fileIds
    .map((fileId) => fileIdToVaultFile[fileId])
    .filter(Boolean) as VaultFile[]
  const projectName = currentProject?.name
  const handleRemoveFile = (file: UploadedFile | FileUploadingState) => {
    if ('id' in file) {
      removeFileIds([file.id])
    }
  }

  return (
    <>
      <SourcesToolbar
        handleCancel={handleCancel}
        text="Vault"
        description={`${projectName} (${activeFiles.length} selected)`}
        icon={<FolderShieldIcon />}
      />
      <div className="flex flex-col border-t-[0.5px]">
        {/* 214 = 36 (file item height) * 5.5 + 16 (padding) */}
        <ScrollArea className="h-full" maxHeight="max-h-[214px]">
          <div className="-mx-2">
            <AssistantFiles
              files={activeFiles as UploadedFile[]}
              handleRemoveFile={handleRemoveFile}
            />
          </div>
        </ScrollArea>
      </div>
    </>
  )
}

export default VaultRenderer

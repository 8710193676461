import React from 'react'

interface NetdocsIconProps {
  className?: string
}

const NetdocsIcon: React.FC<NetdocsIconProps> = ({ className = '' }) => {
  return (
    <img
      className={className}
      src="/img/logos/netdocs.jpg"
      alt="NetDocuments"
    />
  )
}

export default NetdocsIcon

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { IntegrationType } from 'openapi/models/IntegrationType'
import { Maybe } from 'types'

import { ConnectedIntegrationToken } from 'components/settings/integrations/utils'

interface SharepointOpenState {
  acceptedFileTypes: string[]
  onUploadFromSharepoint: (files: File[]) => Promise<void>
}

interface IntegrationFilePickerOpenState {
  acceptedFileTypes: string[]
  onUploadFromIntegration: (files: File[]) => Promise<void>
  integrationType: IntegrationType
  maxFileCount?: number
}

interface IntegrationsState {
  sharepointPickerOpenState: Maybe<SharepointOpenState>
  integrationFilePickerOpenState: Maybe<IntegrationFilePickerOpenState>
  tokens: { [key in IntegrationType]?: ConnectedIntegrationToken }
}

interface IntegrationsAction {
  setSharepointPickerOpenState: (
    sharepointPickerOpenState: Maybe<SharepointOpenState>
  ) => void
  setIntegrationToken: (
    integrationType: IntegrationType,
    token: ConnectedIntegrationToken
  ) => void
  getIntegrationToken: (
    integrationType: IntegrationType
  ) => Maybe<ConnectedIntegrationToken>
  clearIntegrationToken: (integrationType: IntegrationType) => void
  setIntegrationFilePickerOpenState: (
    integrationFilePickerOpenState: Maybe<IntegrationFilePickerOpenState>
  ) => void
}

export const useIntegrationsStore = create(
  devtools(
    immer<IntegrationsState & IntegrationsAction>((set) => ({
      sharepointPickerOpenState: null,
      integrationFilePickerOpenState: null,
      tokens: {},
      setIntegrationToken: (integrationType, token) =>
        set((state) => {
          state.tokens[integrationType] = token
        }),
      getIntegrationToken: (
        integrationType
      ): Maybe<ConnectedIntegrationToken> =>
        useIntegrationsStore.getState().tokens[integrationType],
      clearIntegrationToken: (integrationType) =>
        set((state) => {
          delete state.tokens[integrationType]
        }),
      setSharepointPickerOpenState: (sharepointPickerOpenState) =>
        set({ sharepointPickerOpenState }),
      setIntegrationFilePickerOpenState: (integrationFilePickerOpenState) =>
        set({ integrationFilePickerOpenState }),
    }))
  )
)

import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Pencil, Trash } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { displayErrorMessage } from 'utils/toast'
import { cn, ToBackendKeys } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { SkeletonBlock } from 'components/ui/skeleton'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'
import { Textarea } from 'components/ui/text-area'
import { useRunReview } from 'components/vault/components/vault-app-header/use-run-review'
import {
  addNewColumn,
  getDisplayDataType,
  getIconForDataType,
} from 'components/vault/query-detail/data-grid-helpers'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import {
  ColumnDataType,
  QueryQuestion,
  ReviewColumn,
} from 'components/vault/utils/vault'
import {
  BuildReviewColumns,
  SuggestedReviewColumn,
} from 'components/vault/utils/vault-fetcher'
import { computeDocumentClassificationAnalyticsData } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

import {
  AdditionalOptionsEditor,
  ColumnDataTypeEditor,
  ColumnHeaderNameEditor,
} from './cells/vault-column-editor'

export const VaultColumnBuilderDialog = () => {
  const { trackEvent } = useAnalytics()

  const [
    isColumnBuilderDialogOpen,
    fileIdToVaultFile,
    setIsColumnBuilderDialogOpen,
  ] = useVaultStore(
    useShallow((state) => [
      state.isColumnBuilderDialogOpen,
      state.fileIdToVaultFile,
      state.setIsColumnBuilderDialogOpen,
    ])
  )

  const [
    historyItem,
    pendingQueryQuestions,
    gridApi,
    pendingQueryFileIds,
    addToPendingQueryQuestions,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.historyItem,
      state.pendingQueryQuestions,
      state.gridApi,
      state.pendingQueryFileIds,
      state.addToPendingQueryQuestions,
    ])
  )
  const pendingQuestions = pendingQueryQuestions ?? []
  const reviewEvent = historyItem as ReviewHistoryItem

  const [question, setQuestion] = useState<string>('')
  const [previouslyAskedQuestion, setPreviouslyAskedQuestion] =
    useState<string>('')
  const [columns, setColumns] = useState<SuggestedReviewColumn[]>([])
  const [isBuildingColumns, setIsBuildingColumns] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const areColumnsEmpty = columns.length === 0

  const { handleRun } = useRunReview()

  const existingColumns: (QueryQuestion | ReviewColumn)[] = [
    ...pendingQuestions,
    ...(reviewEvent?.columns ?? []),
  ]
  const reviewEventFileIds = reviewEvent ? reviewEvent.fileIds : []
  const totalColumnsAfterAdd = existingColumns.length + columns.length
  const questionsLimit = reviewEvent?.questionsLimit

  const documentClassificationAnalyticsData =
    computeDocumentClassificationAnalyticsData(
      reviewEventFileIds,
      fileIdToVaultFile
    )

  const onBuildReviewColumns = async () => {
    setIsBuildingColumns(true)
    trackEvent('Vault Review Column Builder Prompt Submitted', {
      event_id: reviewEvent?.id,
      query_length: question.length,
      num_files: reviewEvent?.fileIds?.length ?? pendingQueryFileIds?.length,
      document_classification: ToBackendKeys(
        documentClassificationAnalyticsData
      ),
    })
    try {
      const generatedColumns = await BuildReviewColumns(question)
      setColumns(generatedColumns)
      setPreviouslyAskedQuestion(question)
    } catch (error) {
      console.error(error)
    } finally {
      setIsBuildingColumns(false)
    }
  }

  const onAddToPendingQueryQuestions = (question: QueryQuestion) => {
    addToPendingQueryQuestions(question, false)
  }

  const isSubmitDisabled = useMemo(() => {
    return (
      totalColumnsAfterAdd > questionsLimit || columns.length === 0 || isEditing
    )
  }, [totalColumnsAfterAdd, questionsLimit, columns, isEditing])

  const submitButtonTooltip = useMemo(() => {
    if (columns.length === 0) {
      return 'No columns to add'
    }
    if (totalColumnsAfterAdd > questionsLimit) {
      return `Adding ${
        columns.length
      } columns would exceed the maximum limit of ${questionsLimit} columns. Please remove ${
        totalColumnsAfterAdd - questionsLimit
      } columns to proceed.`
    }
    if (isEditing) {
      return 'Please save or cancel your changes before proceeding.'
    }
    return undefined
  }, [totalColumnsAfterAdd, questionsLimit, columns, isEditing])

  const onSubmitHandler = async () => {
    setIsSubmitting(true)
    setIsEditing(false)
    trackEvent('Vault Review Column Builder Columns Confirmed', {
      event_id: reviewEvent?.id,
      num_columns: columns.length,
    })
    const newPendingQueryQuestions: QueryQuestion[] = []

    const totalColumnsAfterAdd = existingColumns.length + columns.length
    const questionsLimit = reviewEvent?.questionsLimit

    if (totalColumnsAfterAdd > questionsLimit) {
      displayErrorMessage(
        `Cannot add columns. Maximum limit of ${questionsLimit} columns would be exceeded.`
      )
      setIsSubmitting(false)
      return
    }

    if (gridApi) {
      for (const column of columns) {
        const newQueryQuestion = {
          text: column.fullText,
          header: column.header,
          columnDataType: column.dataType,
          options: column.options?.join(', '),
        }

        const queryQuestion = addNewColumn(
          gridApi,
          [...existingColumns, ...newPendingQueryQuestions],
          onAddToPendingQueryQuestions,
          newQueryQuestion
        )
        newPendingQueryQuestions.push(queryQuestion)
      }
    }
    try {
      await handleRun({
        query: question,
        pendingQuestions: [...pendingQuestions, ...newPendingQueryQuestions],
      })
    } catch (error) {
      console.error(error)
    }
    setIsColumnBuilderDialogOpen(false)
    setIsSubmitting(false)
    setQuestion('')
    setColumns([])
  }

  const isGenerateColumnsDisabled = useMemo(() => {
    // do not allow generating columns if the question is the same as the previously asked question
    const isQuestionUnedited = question === previouslyAskedQuestion
    return question.length === 0 || isEditing || isQuestionUnedited
  }, [question, isEditing, previouslyAskedQuestion])

  const generateColumnsTooltip = useMemo(() => {
    if (question.length === 0) {
      return 'Please enter a prompt to generate columns.'
    }
    if (isEditing) {
      return 'Please save or cancel your changes before generating columns.'
    }
    if (question === previouslyAskedQuestion) {
      return 'Please modify your prompt before generating columns.'
    }
    return undefined
  }, [question, isEditing, previouslyAskedQuestion])

  const onCancelHandler = () => {
    setIsColumnBuilderDialogOpen(false)
    setIsEditing(false)
    setQuestion('')
    setColumns([])
  }

  const onDeleteColumnHandler = (column: SuggestedReviewColumn) => {
    setColumns(columns.filter((c) => c.displayId !== column.displayId))
  }

  return (
    <Dialog
      open={isColumnBuilderDialogOpen}
      onOpenChange={() => {
        setIsColumnBuilderDialogOpen(!isColumnBuilderDialogOpen)
      }}
    >
      <DialogContent
        className="w-4/5 min-w-[600px] max-w-3xl"
        id="column-builder-dialog"
        innerClassName="p-0 py-5 space-y-2"
        hasContainer={false}
      >
        <DialogHeader className="h-9 border-b border-b-primary px-6">
          <DialogTitle>Column builder</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4 px-6">
          <div className="flex w-full flex-col gap-4">
            <p className="pt-2 text-xs text-primary">
              Ask Harvey to generate multiple columns based on your prompt
            </p>
            <Textarea
              placeholder="State the cash consideration, stock consideration, and termination fees from each merger agreement."
              required
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="text-xs *:text-xs placeholder:text-xs"
            />
            <div className="flex items-center justify-between gap-2">
              <p className="text-xs text-muted">
                For best results, include the type of document you‘re reviewing
                and the key information you‘d like to extract.
              </p>
              <Button
                onClick={onBuildReviewColumns}
                size="sm"
                disabled={isGenerateColumnsDisabled || isBuildingColumns}
                className="min-w-28 self-center whitespace-nowrap px-3"
                isLoading={isBuildingColumns}
                tooltip={generateColumnsTooltip}
                tooltipPopoverClassName="w-64"
                tooltipSide="bottom"
              >
                Generate columns
              </Button>
            </div>
          </div>
          <div className="-mx-6 border-b" />
          <div className="flex flex-col gap-2">
            <div className="flex max-h-[40vh] rounded border">
              <ScrollArea className="flex max-h-[40vh] w-full flex-col">
                <Table className="w-full rounded">
                  <TableHeader className="sticky top-0 z-10 bg-accent transition hover:bg-accent [&_tr]:hover:bg-accent">
                    <TableRow className="transition hover:bg-accent">
                      <TableHead className="h-8 w-1/5 px-2 text-xs font-normal text-primary">
                        Type
                      </TableHead>
                      <TableHead className="h-8 w-1/5 px-2 text-xs font-normal text-primary">
                        Label
                      </TableHead>
                      <TableHead className="h-8 w-3/5 px-2 text-xs font-normal text-primary">
                        Query
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {areColumnsEmpty || isBuildingColumns ? (
                      <>
                        <EmptyColumnBuilderRow
                          isBuildingColumns={isBuildingColumns}
                        />
                        <EmptyColumnBuilderRow
                          isBuildingColumns={isBuildingColumns}
                        />
                        <EmptyColumnBuilderRow
                          isBuildingColumns={isBuildingColumns}
                        />
                      </>
                    ) : (
                      columns.map((column, index) => (
                        <ColumnBuilderRow
                          column={column}
                          index={index}
                          isBuildingColumns={isBuildingColumns}
                          columns={columns}
                          setColumns={setColumns}
                          setIsEditing={setIsEditing}
                          onDeleteColumnHandler={onDeleteColumnHandler}
                          key={`column-${index}`}
                        />
                      ))
                    )}
                  </TableBody>
                </Table>
              </ScrollArea>
            </div>
          </div>
          <div className="flex justify-end gap-2">
            <Button variant="outline" size="sm" onClick={onCancelHandler}>
              Cancel
            </Button>
            <Button
              onClick={onSubmitHandler}
              size="sm"
              className="w-fit self-end"
              disabled={isSubmitDisabled}
              isLoading={isSubmitting}
              tooltip={submitButtonTooltip}
              tooltipPopoverClassName="w-64"
              tooltipSide="bottom"
            >
              Add columns
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const EmptyColumnBuilderRow = ({
  isBuildingColumns,
}: {
  isBuildingColumns: boolean
}) => {
  return (
    <TableRow className="h-8 transition hover:bg-transparent">
      <TableCell className="p-2 align-top text-xs">
        {isBuildingColumns && <SkeletonBlock className="h-4 w-full" />}
      </TableCell>
      <TableCell className="p-2 align-top text-xs">
        {isBuildingColumns && <SkeletonBlock className="h-4 w-full" />}
      </TableCell>
      <TableCell className="p-2 align-top text-xs">
        {isBuildingColumns && <SkeletonBlock className="h-4 w-full" />}
      </TableCell>
    </TableRow>
  )
}

const ColumnBuilderRow = ({
  column,
  index,
  isBuildingColumns,
  columns,
  setColumns,
  setIsEditing,
  onDeleteColumnHandler,
}: {
  column: SuggestedReviewColumn
  index: number
  isBuildingColumns: boolean
  columns: SuggestedReviewColumn[]
  setColumns: (columns: SuggestedReviewColumn[]) => void
  setIsEditing: (isEditing: boolean) => void
  onDeleteColumnHandler: (column: SuggestedReviewColumn) => void
}) => {
  const columnDataTypeSelectContentRef = useRef<HTMLDivElement>(null)

  const [columnDataTypeOpen, setColumnDataTypeOpen] = useState<boolean>(false)
  const [hoveredColumnDataType, setHoveredColumnDataType] =
    useState<ColumnDataType | null>(null)
  const [isEditingRow, setIsEditingRow] = useState<boolean>(false)

  const [editedColumnDataType, setEditedColumnDataType] =
    useState<ColumnDataType>(column.dataType)
  const [editedColumnHeader, setEditedColumnHeader] = useState<string>(
    column.header
  )
  const [editedColumnFullText, setEditedColumnFullText] = useState<string>(
    column.fullText
  )
  const [editedColumnOptions, setEditedColumnOptions] = useState<string>(
    column.options?.join(', ') ?? ''
  )

  const isDirty =
    editedColumnDataType !== column.dataType ||
    editedColumnHeader !== column.header ||
    editedColumnFullText !== column.fullText ||
    editedColumnOptions !== column.options?.join(', ')

  // update state values when underlying column changes
  useEffect(() => {
    setEditedColumnDataType(column.dataType)
    setEditedColumnHeader(column.header)
    setEditedColumnFullText(column.fullText)
    setEditedColumnOptions(column.options?.join(', ') ?? '')
  }, [column])

  const onSaveHandler = () => {
    setIsEditingRow(false)
    setIsEditing(false)
    setColumns(
      columns.map((c) =>
        c.header === column.header
          ? {
              ...c,
              dataType: editedColumnDataType,
              header: editedColumnHeader,
              fullText: editedColumnFullText,
              options: editedColumnOptions.split(', '),
            }
          : c
      )
    )
  }

  const onCancelHandler = () => {
    setIsEditingRow(false)
    setIsEditing(false)
    setEditedColumnDataType(column.dataType)
    setEditedColumnHeader(column.header)
    setEditedColumnFullText(column.fullText)
    setEditedColumnOptions(column.options?.join(', ') ?? '')
  }

  return (
    <TableRow
      key={`column-${index}-row`}
      className="transition hover:bg-primary"
    >
      <TableCell
        className={cn('items-center p-2 text-xs', {
          'align-top': isEditingRow,
          'items-center': !isEditingRow,
        })}
      >
        {isEditingRow ? (
          <ColumnDataTypeEditor
            columnDataType={editedColumnDataType}
            isEditingTypeAndQuestionDisabled={false}
            columnDataTypeOpen={columnDataTypeOpen}
            hoveredColumnDataType={hoveredColumnDataType}
            selectContentRef={columnDataTypeSelectContentRef}
            isLoading={isBuildingColumns}
            shouldShowLabel={false}
            setColumnDataTypeOpen={setColumnDataTypeOpen}
            setHoveredColumnDataType={setHoveredColumnDataType}
            onColumnDataTypeChangeHandler={setEditedColumnDataType}
          />
        ) : (
          <div className="flex items-center gap-2">
            <Icon icon={getIconForDataType(column.dataType)} size="small" />
            <p className="text-xs">{getDisplayDataType(column.dataType)}</p>
          </div>
        )}
      </TableCell>
      <TableCell
        className={cn('items-center p-2 text-xs', {
          'align-top': isEditingRow,
          'items-center': !isEditingRow,
        })}
      >
        {isEditingRow ? (
          <ColumnHeaderNameEditor
            headerName={editedColumnHeader}
            isLoading={isBuildingColumns}
            shouldShowLabel={false}
            onHeaderNameChangeHandler={setEditedColumnHeader}
          />
        ) : (
          <p className="text-xs">{editedColumnHeader}</p>
        )}
      </TableCell>
      <TableCell
        className={cn('p-2 text-xs', {
          'align-top': isEditingRow,
          'items-center': !isEditingRow,
        })}
      >
        <div
          className={cn('flex justify-between gap-2', {
            'align-top': isEditingRow,
            'items-center': !isEditingRow,
          })}
        >
          <div className="flex w-full flex-col gap-2">
            {isEditingRow ? (
              <Textarea
                value={editedColumnFullText}
                onChange={(e) => {
                  setEditedColumnFullText(e.target.value)
                }}
                className="min-h-8 text-xs *:text-xs placeholder:text-xs"
              />
            ) : (
              <p className="text-xs">{editedColumnFullText}</p>
            )}
            <AdditionalOptionsEditor
              columnDataType={editedColumnDataType}
              options={editedColumnOptions}
              isLoading={isBuildingColumns}
              onOptionsChangeHandler={setEditedColumnOptions}
              isEditingTypeAndQuestionDisabled={!isEditingRow}
            />
          </div>
          {isEditingRow ? (
            <div className="flex gap-2 align-top">
              <Button
                size="sm"
                className="w-14"
                onClick={onSaveHandler}
                disabled={!isDirty}
              >
                Save
              </Button>
              <Button
                size="sm"
                variant="outline"
                className="w-16"
                onClick={onCancelHandler}
              >
                Cancel
              </Button>
            </div>
          ) : (
            <div className="flex">
              <Button
                size="smIcon"
                variant="ghost"
                onClick={() => {
                  setIsEditing(true)
                  setIsEditingRow(true)
                }}
              >
                <Icon icon={Pencil} size="small" />
              </Button>
              <Button
                size="smIcon"
                variant="ghost"
                onClick={() => onDeleteColumnHandler(column)}
              >
                <Icon icon={Trash} size="small" />
              </Button>
            </div>
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}

import React, { useState } from 'react'

import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'

import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import WorkflowInput from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
  AssistantWorkflowYouComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowTextThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.TEXT
> = ({ blockParams, blockStatus, outputData }) => {
  const { agentText } = blockParams
  const isCompleted = !!outputData

  return (
    <AssistantWorkflowThreadBlock>
      {agentText && (
        <AssistantWorkflowHarveyComponent>
          <AssistantWorkflowThreadText
            blockStatus={blockStatus}
            text={agentText}
          />
        </AssistantWorkflowHarveyComponent>
      )}

      {isCompleted && (
        <AssistantWorkflowYouComponent>
          <AssistantWorkflowThreadText
            blockStatus={blockStatus}
            text={outputData.value.length > 0 ? outputData.value : 'N/A'}
          />
        </AssistantWorkflowYouComponent>
      )}
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowTextInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.TEXT
> = ({ blockParams, onCompleted, outputData }) => {
  const { placeholder, optional } = blockParams
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(outputData?.value ?? '')
  const isCompleted = !!outputData

  const onSubmit = () => {
    if (!value && !optional) return
    setIsLoading(true)
    onCompleted({ value })
    setIsLoading(false)
    setValue('')
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  return (
    <WorkflowInput>
      <div className="relative flex w-full items-center">
        <Input
          type="text"
          className="min-h-16 w-full resize-none pr-20 focus-visible:ring-offset-0"
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          value={value}
          onKeyDown={handleKeyDown}
          disabled={isCompleted || isLoading}
        />
        <Button
          className="absolute right-4"
          onClick={onSubmit}
          disabled={isCompleted || isLoading || (!optional && !value)}
          isLoading={isCompleted || isLoading}
        >
          {optional && !value ? 'Skip' : 'Send'}
        </Button>
      </div>
    </WorkflowInput>
  )
}

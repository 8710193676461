import * as React from 'react'

import AssistantTips from 'components/assistant/components/assistant-tips'
import { Button } from 'components/ui/button'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'components/ui/sheet'

interface AssistantTipsSheetProps {
  onTriggerClick: () => void
}

const AssistantTipsSheet: React.FC<AssistantTipsSheetProps> = ({
  onTriggerClick,
}) => {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          size="sm"
          variant="outline"
          className="shrink-0 text-nowrap"
          onClick={onTriggerClick}
        >
          View tips
        </Button>
      </SheetTrigger>
      <SheetContent className="w-full sm:max-w-3xl">
        <SheetHeader className="border-none bg-primary backdrop-blur-none">
          <SheetTitle className="text-xl">Tips</SheetTitle>
        </SheetHeader>
        <SheetDescription className="p-6">
          <AssistantTips />
        </SheetDescription>
      </SheetContent>
    </Sheet>
  )
}

export default AssistantTipsSheet

import React, { useState } from 'react'

import _ from 'lodash'
import pluralize from 'pluralize'

import {
  BulkGrantPermBundlesToUsers,
  BulkManagePermsUsersResult,
  BulkRevokePermBundlesFromUsers,
} from 'models/perms'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'

import PermBundlesMultiSelectInline from 'components/settings/workspace/permissions/perm-bundles-multi-select-inline'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { Tabs, TabsList, TabsTrigger, TabsContent } from 'components/ui/tabs'

enum Tab {
  add = 'add',
  remove = 'remove',
}

const WorkspacePermissionsDialog = ({
  isInternalAdmin = false,
  addOnly = false,
  workspaceId,
  isOpen,
  onClose,
  onComplete,
  selectedUsers,
  excludedBundles,
}: {
  isInternalAdmin?: boolean
  addOnly?: boolean
  workspaceId: number
  isOpen: boolean
  onClose: () => void
  onComplete: () => void
  selectedUsers: string[]
  excludedBundles?: PermissionBundleId[]
}) => {
  const [selectedPermissions, setSelectedPermissions] = useState<
    PermissionBundleId[]
  >([])
  const [tab, setTab] = useState<Tab>(Tab.add)

  const [result, setResult] = useState<BulkManagePermsUsersResult>()
  const hasResult =
    !_.isEmpty(result?.userManagedPerms) ||
    !_.isEmpty(result?.errorUser) ||
    !_.isEmpty(result?.userNoAction)

  const handleSave = async () => {
    let response: BulkManagePermsUsersResult
    if (tab === Tab.add) {
      response = await BulkGrantPermBundlesToUsers(
        workspaceId,
        selectedUsers,
        selectedPermissions
      )
    } else {
      response = await BulkRevokePermBundlesFromUsers(
        selectedUsers,
        selectedPermissions
      )
    }
    setResult(response)
  }

  const renderPrimaryButton = () => {
    if (hasResult) {
      return <Button onClick={onComplete}>Close</Button>
    }
    if (tab === Tab.add) {
      return <Button onClick={handleSave}>Add permissions</Button>
    }
    return (
      <Button variant="destructive" onClick={handleSave}>
        Remove permissions
      </Button>
    )
  }

  const dropdown = (
    <PermBundlesMultiSelectInline
      filterForWorkspaceId={workspaceId}
      selectedPermissions={selectedPermissions}
      setSelectedPermissions={setSelectedPermissions}
      excludedBundles={excludedBundles}
      disabled={hasResult}
    />
  )

  const resultCard = hasResult && (
    <Card className="mt-4">
      <CardHeader>
        <CardTitle>Results</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        <div className="text-sm">
          Permissions {tab === Tab.add ? 'granted' : 'revoked'}
          <div className="mt-2 flex flex-wrap gap-2">
            {result?.userManagedPerms.map((email) => (
              <Badge key={email}>{email}</Badge>
            ))}
          </div>
        </div>
        <div className="text-sm">
          Permissions {tab === Tab.add ? 'already exist' : 'don’t exist'}
          <div className="mt-2 flex flex-wrap gap-2">
            {result?.userNoAction.map((email) => (
              <Badge variant="secondary" key={email}>
                {email}
              </Badge>
            ))}
          </div>
        </div>
        <div className="text-sm">
          Invalid users
          <div className="mt-2 flex flex-wrap gap-2">
            {result?.errorUser.map((email) => (
              <Badge variant="destructive" key={email}>
                {email}
              </Badge>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  )

  const content = (
    <div className="min-h-32">
      {dropdown}
      {resultCard}
    </div>
  )

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent hasContainer={false}>
        <DialogHeader>
          <DialogTitle>
            {addOnly ? 'Add permissions' : 'Manage permissions'}
          </DialogTitle>
          <DialogDescription>
            {addOnly ? 'Add permissions for' : 'Add or remove permissions for'}{' '}
            {selectedUsers.length === 1
              ? selectedUsers[0]
              : pluralize('selected user', selectedUsers.length, true)}
            {isInternalAdmin &&
              tab === Tab.add &&
              `. Note: Within each bundle, only the permissions enabled for this
                workspace will be granted.`}
          </DialogDescription>
        </DialogHeader>

        {addOnly ? (
          content
        ) : (
          <Tabs value={tab} onValueChange={(value) => setTab(value as Tab)}>
            <TabsList variant="minimal" className="w-full">
              <TabsTrigger
                variant="minimal"
                value={Tab.add}
                disabled={hasResult}
              >
                Add
              </TabsTrigger>
              <TabsTrigger
                variant="minimal"
                value={Tab.remove}
                disabled={hasResult}
              >
                Remove
              </TabsTrigger>
            </TabsList>
            <TabsContent value={Tab.add}>{content}</TabsContent>
            <TabsContent value={Tab.remove}>{content}</TabsContent>
          </Tabs>
        )}

        <DialogFooter>
          <DialogClose asChild>
            {!hasResult && (
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
            )}
          </DialogClose>
          {renderPrimaryButton()}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default WorkspacePermissionsDialog

import { FileUploadSource } from 'openapi/models/FileUploadSource'
import { UploadedFile } from 'openapi/models/UploadedFile'
import Services from 'services'

import { displayErrorMessage } from 'utils/toast'

export interface requestProps {
  redlinesCheck?: boolean
  notRedlinesCheck?: boolean
  languageNotSupportedCheck?: boolean
  eventId?: string
  signal?: AbortSignal
  fileUploadSource?: FileUploadSource
  password?: string // optional password for password protected files
  eagerlyCheckPasswordProtected?: boolean
}

export const uploadFile = async (
  file: File,
  throwOnError = false,
  requestProps?: requestProps
): Promise<UploadedFile> => {
  const formData = new FormData()
  formData.append('file', file)

  const redlinesCheck = requestProps?.redlinesCheck ?? false
  const notRedlinesCheck = requestProps?.notRedlinesCheck ?? false
  const fileUploadSource = requestProps?.fileUploadSource ?? null

  if (redlinesCheck) {
    formData.append('redlines_check', 'true')
  }

  if (notRedlinesCheck) {
    formData.append('not_redlines_check', 'true')
  }

  if (requestProps?.languageNotSupportedCheck) {
    formData.append('language_not_supported_check', 'true')
  }

  if (requestProps?.eventId) {
    formData.append('event_id', requestProps.eventId)
  }

  if (fileUploadSource) {
    formData.append('file_upload_source', fileUploadSource)
  }

  // Add password if provided
  if (requestProps?.password) {
    formData.append('password', requestProps.password)
  }

  // If this boolean is set to true, the backend will check if the file is password protected BEFORE processing the file (adding a step to the process)
  // If the boolean is set to false, the backend will only check the file for password protection after an error is thrown
  if (requestProps?.eagerlyCheckPasswordProtected) {
    formData.append('eagerly_check_password_protected', 'true')
  }

  try {
    const result = await Services.Backend.Post<UploadedFile>(
      'upload_file',
      formData,
      {
        signal: requestProps?.signal,
        throwOnError: true,
      }
    )

    if (Object.keys(result).length === 0 && !requestProps?.signal?.aborted) {
      throw new Error('Empty response from upload file')
    }

    return result
  } catch (error) {
    if (!throwOnError) {
      displayErrorMessage('Document failed to process, please try again.')
    }
    throw error
  }
}

import { QueryCapRuleLevel } from 'openapi/models/QueryCapRuleLevel'
import { QueryCapRuleTimeFrame } from 'openapi/models/QueryCapRuleTimeFrame'
import { QueryCapRuleUnitLevel } from 'openapi/models/QueryCapRuleUnitLevel'
import Services from 'services'

export interface VaultSeats {
  workspaceId: number
  users: VaultUser[]
  managedCount: number
  failedUserEmailWithReasons?: {
    userEmail: string
    reason: string
  }[]
  remainingSeats?: number
  remainingReviewQueryFileLimit?: number
}

export interface VaultUser {
  userId: string
  userEmail: string
  hasSeat: boolean
  reviewLimit: number
  unitLevel: QueryCapRuleUnitLevel
  timeFrame: QueryCapRuleTimeFrame
  level: QueryCapRuleLevel
}

export const getVaultAddOnUsers = async (
  workspaceId: number
): Promise<VaultSeats> => {
  const params = new URLSearchParams()
  params.append('workspace_id', workspaceId.toString())

  return Services.Backend.Get<VaultSeats>(
    `settings/admin/vault/add_on?${params.toString()}`
  )
}

export const addVaultAddOnUsers = async (
  workspaceId: number,
  users: string[]
): Promise<VaultSeats> => {
  return Services.Backend.Post<VaultSeats>(
    `settings/admin/vault/add_on`,
    {
      workspaceId,
      users,
      assignSeat: true,
    },
    { throwOnError: true }
  )
}

export const removeVaultAddOnUsers = async (
  workspaceId: number,
  users: string[]
): Promise<VaultSeats> => {
  return Services.Backend.Post<VaultSeats>(
    `settings/admin/vault/add_on`,
    {
      workspaceId,
      users,
      assignSeat: false,
    },
    { throwOnError: true }
  )
}

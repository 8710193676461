import * as React from 'react'

import { Trash } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'

interface SourceRendererItemProps {
  text: string
  handleClick?: () => void
  handleRemove?: () => void
  isRemoveDisabled?: boolean
}

const SourceRendererItem: React.FC<SourceRendererItemProps> = ({
  text,
  handleClick,
  handleRemove,
  isRemoveDisabled,
}) => {
  const DetailsComp = handleClick ? 'button' : 'span'
  return (
    <div
      className={cn('flex items-center p-2', {
        'hover:bg-secondary': !!handleClick,
      })}
    >
      <DetailsComp
        className="flex grow items-center text-left"
        {...(handleClick ? { onClick: handleClick } : {})}
      >
        <span className="flex grow items-center">
          <span className="truncate text-xs">{text}</span>
        </span>
      </DetailsComp>
      {handleRemove && (
        <Button
          className="shrink-0 transition hover:bg-secondary-hover"
          onClick={handleRemove}
          size="xsIcon"
          variant="ghost"
          aria-label="Remove item"
          disabled={isRemoveDisabled}
        >
          <Icon icon={Trash} size="small" variant="secondary" />
        </Button>
      )}
    </div>
  )
}

export default SourceRendererItem

import React from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import _ from 'lodash'

import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'

interface WorkflowsTableProps {
  workflows: WorkflowDefinitionListItem[]
  onDeleteWorkflow: (workflowId: string) => Promise<void>
}

const WorkflowsTable: React.FC<WorkflowsTableProps> = ({
  workflows,
  onDeleteWorkflow,
}) => {
  const columns = [
    {
      title: 'Workflow name',
      dataIndex: 'name',
      key: 'workflowName',
      width: '20%',
      render: (text: string) => <span className="font-semibold">{text}</span>,
    },
    {
      title: 'Workflow ID',
      dataIndex: 'id',
      key: 'workflowId',
      width: '30%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '45%',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: WorkflowDefinitionListItem) => (
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => {
            void onDeleteWorkflow(record.id)
          }}
        />
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={_.sortBy(workflows, 'name')}
      pagination={false}
      size="small"
      bordered
      footer={() => (
        <div className="flex justify-end">
          <p>{workflows.length} workflows</p>
        </div>
      )}
    />
  )
}

export default WorkflowsTable

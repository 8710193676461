import * as React from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import { PaperclipIcon } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { FileUploadSource } from 'openapi/models/FileUploadSource'
import {
  FileType,
  FileTypeReadableName,
  removeSubsetDuplicates,
} from 'types/file'

import { onDrop } from 'utils/dropzone'
import { mbToBytes } from 'utils/file-utils'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import AssistantDropzone from 'components/assistant/features/composer/assistant-dropzone'
import VaultKnowledgeSourcePicker from 'components/assistant/features/composer/assistant-vault-ks-picker'
import InputSourceButton from 'components/assistant/features/composer/components/input-source-button'
import { useHandleKnowledgeSources } from 'components/assistant/features/composer/hooks'
import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { useAssistantFileUpload } from 'components/assistant/hooks/use-assistant-file-upload'
import { useAssistantKsOptions } from 'components/assistant/hooks/use-assistant-ks-options'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { isResearchKnowledgeSource } from 'components/assistant/utils/assistant-knowledge-sources'
import {
  ACCEPTED_FILE_TYPES,
  MAX_FILE_SIZE,
  MAX_TOTAL_FILE_SIZE,
  MAX_ZIP_FILE_SIZE,
  NUM_MAX_FILES,
} from 'components/assistant/utils/constants'
import { DropdownMenuSeparator } from 'components/ui/dropdown-menu'

const AssistantFilesInput = () => {
  const [
    documents,
    documentsUploading,
    mode,
    knowledgeSource,
    zipFiles,
    pstFiles,
    setZipFiles,
    setPstFiles,
    setIsAskHarveyDisabled,
    showVaultDialog,
    setShowVaultDialog,
  ] = useAssistantStore(
    useShallow((s) => [
      s.documents,
      s.documentsUploading,
      s.mode,
      s.knowledgeSource,
      s.zipFiles,
      s.pstFiles,
      s.setZipFiles,
      s.setPstFiles,
      s.setIsAskHarveyDisabled,
      s.showVaultDialog,
      s.setShowVaultDialog,
    ])
  )
  const { handleFileUpload } = useAssistantFileUpload(mode)
  const trackEvent = useAssistantAnalytics()
  const { handleSetKnowledgeSource, handleRemoveKnowledgeSources } =
    useHandleKnowledgeSources()

  const hasUploadedFiles =
    documents.length > 0 ||
    documentsUploading.length > 0 ||
    zipFiles.length > 0 ||
    pstFiles.length > 0

  const handleFileUploadWithTracking = React.useCallback(
    async (files: File[], fileUploadSource: FileUploadSource) => {
      setZipFiles([])
      setPstFiles([])
      handleRemoveKnowledgeSources()
      await handleFileUpload(files, fileUploadSource)
    },
    [handleFileUpload, setZipFiles, setPstFiles, handleRemoveKnowledgeSources]
  )

  const prevUploadedFiles = React.useRef(!!hasUploadedFiles)
  React.useEffect(() => {
    if (prevUploadedFiles.current && !hasUploadedFiles) {
      handleRemoveKnowledgeSources()
    }
    prevUploadedFiles.current = hasUploadedFiles
  }, [hasUploadedFiles, handleRemoveKnowledgeSources])

  const onAssistantDrop = React.useCallback(
    async (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      fileUploadSource: FileUploadSource
    ) => {
      setIsAskHarveyDisabled(true)
      setZipFiles(acceptedFiles.filter((file) => file.type === FileType.ZIP))
      setPstFiles(
        acceptedFiles.filter(
          (file) =>
            file.type === FileType.OUTLOOK &&
            file.name.toLowerCase().endsWith('.pst')
        )
      )

      return onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: documents.length + documentsUploading.length,
        maxFiles: NUM_MAX_FILES,
        acceptedFileTypes: ACCEPTED_FILE_TYPES,
        maxFileSize: mbToBytes(MAX_FILE_SIZE),
        maxZipFileSize: mbToBytes(MAX_ZIP_FILE_SIZE),
        maxTotalFileSizeProps: {
          maxTotalFileSize: mbToBytes(MAX_TOTAL_FILE_SIZE),
          currentTotalFileSize: documents.reduce(
            (total, file) => total + (file.size ?? 0),
            0
          ),
        },
        handleAcceptedFiles: (files) =>
          handleFileUploadWithTracking(files, fileUploadSource),
      }).finally(() => {
        setIsAskHarveyDisabled(false)
        setZipFiles([])
        setPstFiles([])
      })
    },
    [
      handleFileUploadWithTracking,
      documents,
      documentsUploading,
      setIsAskHarveyDisabled,
      setZipFiles,
      setPstFiles,
    ]
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) =>
      onAssistantDrop(acceptedFiles, fileRejections, FileUploadSource.COMPUTER),
    maxFiles: NUM_MAX_FILES,
    noClick: true,
  })

  const getRootPropsWithTracking = () => {
    const rootProps = getRootProps()
    return {
      ...rootProps,
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        rootProps.onClick?.(e)
        trackUploadFromComputer()
      },
    }
  }

  const trackUploadFromComputer = React.useCallback(() => {
    trackEvent('Upload Files Clicked')
  }, [trackEvent])

  const onUploadFromComputer = React.useCallback(() => {
    open()
    trackUploadFromComputer()
  }, [open, trackUploadFromComputer])

  const { chooseFilesDropdownOptions, descriptionText } = useAssistantKsOptions(
    {
      onUploadFromComputer,
      setShowDialog: setShowVaultDialog,
      onDrop: onAssistantDrop,
      maxFileCount: NUM_MAX_FILES,
      maxFileSize: MAX_FILE_SIZE,
    }
  )

  const btnDisabled =
    (isResearchKnowledgeSource(knowledgeSource) &&
      // research KS are not supported in draft mode
      mode !== AssistantMode.DRAFT) ||
    false

  return (
    <div className="w-full">
      <AssistantDropzone
        rootProps={getRootPropsWithTracking()}
        inputProps={getInputProps()}
      >
        <InputSourceButton
          title="Drag or click to upload files"
          description={descriptionText}
          icon={PaperclipIcon}
          dropdownItems={chooseFilesDropdownOptions}
          disabled={btnDisabled}
          tooltip={
            btnDisabled
              ? 'Cannot upload documents when knowledge sources are selected'
              : undefined
          }
        >
          <DropdownMenuSeparator className="-mx-2 my-2" />
          <p className="line-clamp-2 max-w-64 p-2 text-xs text-muted">
            Supported file types:{' '}
            {removeSubsetDuplicates(
              ACCEPTED_FILE_TYPES.map(
                (fileType) => FileTypeReadableName[fileType]
              )
            )
              .sort((a, b) => a.localeCompare(b))
              .join(', ')}
          </p>
        </InputSourceButton>
      </AssistantDropzone>
      <VaultKnowledgeSourcePicker
        onClose={handleRemoveKnowledgeSources}
        showDialog={showVaultDialog}
        setShowDialog={setShowVaultDialog}
        knowledgeSource={knowledgeSource}
        setKnowledgeSource={handleSetKnowledgeSource}
      />
    </div>
  )
}

export default AssistantFilesInput

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { WorkflowDefinition } from 'openapi/models/WorkflowDefinition'
import { WorkflowEventStatus } from 'openapi/models/WorkflowEventStatus'

import { HarveySocketTask } from 'utils/use-harvey-socket'
import { ToFrontendKeys } from 'utils/utils'

interface AssistantWorkflowState {
  workflowDefinition: WorkflowDefinition | null
  currentEvent: WorkflowEventStatus | null
  blockStores: Record<number, any>
}

interface AssistantWorkflowAction {
  setCurrentWorklow: (
    definition: WorkflowDefinition,
    event: WorkflowEventStatus | null
  ) => void
  workflowSetter: (socketState: Partial<HarveySocketTask>) => void
  reset: () => void
  addBlockStore: (stepIdx: number, blockStore: any) => void
}

const initialState: AssistantWorkflowState = {
  workflowDefinition: null,
  currentEvent: null,
  blockStores: {},
}

export const useAssistantWorkflowStore = create(
  devtools(
    immer<AssistantWorkflowState & AssistantWorkflowAction>((set) => ({
      ...initialState,

      reset: () => {
        // TODO: Is there a better way to purge zudstand stores for blockStores instead
        // of just orphaning them?
        set(() => initialState)
      },

      addBlockStore: (stepIdx, blockStore) => {
        set((state) => {
          state.blockStores[stepIdx] = blockStore
        })
      },

      setCurrentWorklow: (
        definition: WorkflowDefinition,
        event: WorkflowEventStatus | null
      ) => {
        set((state) => {
          state.workflowDefinition = definition

          if (
            !state.currentEvent ||
            (event && state.currentEvent.eventId !== event.eventId)
          ) {
            state.currentEvent = event
          }
        })
      },

      workflowSetter: (socketState: Partial<HarveySocketTask>) => {
        const { workflow } = socketState

        if (!workflow) {
          return
        }

        set((state) => {
          state.currentEvent = ToFrontendKeys(workflow)
        })
      },
    }))
  )
)

import { FileType } from 'types/file'

export const FILE_TYPES = [
  FileType.FLAC,
  FileType.M4A,
  FileType.XM4A,
  FileType.MP4,
  FileType.MPEG,
  FileType.OGG,
  FileType.PDF,
  FileType.TEXT,
  FileType.WAV,
  FileType.WEBM,
]
export const MAX_FILE_SIZE_MB = 200
export const ROUTE = 'diligence-transcripts'

import React from 'react'
import { useMount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'

import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow'
import { useLoadWorkflowsQuery } from 'components/assistant/workflows/workflow-loader'
import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import Link from 'components/ui/link/link'

export const AssistantWorkflowsHomePage: React.FC = () => {
  const { data: workflows } = useLoadWorkflowsQuery()

  const [reset] = useAssistantWorkflowStore(
    useShallow((state) => [state.reset])
  )

  useMount(() => {
    reset()
  })

  const getWorkflowDestination = (workflow: WorkflowDefinitionListItem) => {
    return `/assistant/workflows/${workflow.slug}`
  }

  return (
    <AppMain hasContainer>
      <AppHeader title="Assistant Workflows" />
      <div className="grid grid-cols-1 gap-4 pb-4 md:grid-cols-2 xl:grid-cols-4">
        {workflows?.map((workflow, i) => (
          <Link to={getWorkflowDestination(workflow)} key={i}>
            <Card className="h-32">
              <CardHeader>
                <h2 className="text-lg">{workflow.name}</h2>
              </CardHeader>
              <CardContent>
                <p className="mt-1 text-xs">{workflow.description}</p>
              </CardContent>
            </Card>
          </Link>
        ))}
      </div>
    </AppMain>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import isPast from 'date-fns/isPast'
import {
  IFilePickerOptions,
  IPicker,
  Popup,
  LamdaAuthenticate,
  Picker,
  SPItem,
  IPickData,
} from 'harvey-sdk-pnptimeline'
import _ from 'lodash'
import { Loader2, X } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { IntegrationType } from 'openapi/models/IntegrationType'
import Services from 'services'
import { useIntegrationsStore } from 'stores/integrations-store'
import { Maybe } from 'types'

import { downloadSharepointFiles } from 'utils/file-utils'
import { displayErrorMessage } from 'utils/toast'
import { parseIsoString } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import {
  IN_PRODUCT,
  INTEGRATION_CONNECTED_METRIC,
  INTEGRATION_FILE_PICKER_CLICKED_METRIC,
  INTEGRATION_FILE_PICKER_OPENED_METRIC,
  INTEGRATION_FILES_DOWNLOADED_METRIC,
} from 'components/settings/integrations/constants'
import { useOauthConnect } from 'components/settings/integrations/use-oauth-connect'
import { fetchIntegrationToken } from 'components/settings/integrations/utils'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

import { useAnalytics } from './analytics/analytics-context'

const integrationType = IntegrationType.SHAREPOINT

const SharepointFilePicker = () => {
  const userInfo = useAuthUser()
  const [
    sharepointPickerOpenState,
    setSharepointPickerOpenState,
    getIntegrationToken,
    setIntegrationToken,
  ] = useIntegrationsStore(
    useShallow((state) => [
      state.sharepointPickerOpenState,
      state.setSharepointPickerOpenState,
      state.getIntegrationToken,
      state.setIntegrationToken,
    ])
  )

  const integrationToken = getIntegrationToken(integrationType)

  const resourceUrl: Maybe<string> =
    userInfo.workspace.settings?.resourceUrls?.[integrationType]

  const [loading, setLoading] = useState(false)

  const { trackEvent } = useAnalytics()

  useEffect(() => {
    if (!_.isNil(sharepointPickerOpenState)) {
      Services.HoneyComb.Record({
        metric: INTEGRATION_FILE_PICKER_CLICKED_METRIC,
        user_id: userInfo.id,
        workspace: userInfo.workspace.id,
        integration: integrationType,
      })
      trackEvent('Sharepoint File Picker Clicked')
    }
  }, [
    sharepointPickerOpenState,
    trackEvent,
    userInfo.id,
    userInfo.workspace.id,
  ])

  const fetchSharepointAccessToken = useCallback(
    async (silent: boolean = false) => {
      try {
        const response = await fetchIntegrationToken(IntegrationType.SHAREPOINT)
        if (_.isNil(response)) {
          return null
        }
        setIntegrationToken(IntegrationType.SHAREPOINT, response)
        return response.accessToken
      } catch (error) {
        console.error('Error fetching access token', error)
        if (!silent) {
          displayErrorMessage('Error fetching access token')
        }
        return null
      }
    },
    [setIntegrationToken]
  )

  const getToken = useCallback(async () => {
    const token = getIntegrationToken(integrationType)
    if (_.isNil(token) || isPast(parseIsoString(token.expiresAt))) {
      return (await fetchSharepointAccessToken(true)) ?? ''
    }
    return token.accessToken
  }, [getIntegrationToken, fetchSharepointAccessToken])

  const filePickerParams = useMemo(
    () => ({
      sdk: '8.0',
      entry: {
        oneDrive: {},
      },
      authentication: {},
      messaging: {
        origin: window.location.origin,
        channelId: '27',
      },
      selection: {
        mode: 'multiple',
      },
      typesAndSources: {
        filters: sharepointPickerOpenState?.acceptedFileTypes ?? [],
        mode: 'files',
        pivots: {
          oneDrive: true,
          recent: true,
          search: true,
        },
      },
    }),
    [sharepointPickerOpenState?.acceptedFileTypes]
  )

  const onResults = useCallback(
    async (items: SPItem[]) => {
      const token = getIntegrationToken(integrationType)
      if (_.isNil(token)) {
        return
      }
      const files = await downloadSharepointFiles(items, token.accessToken)
      await sharepointPickerOpenState?.onUploadFromSharepoint(files)

      Services.HoneyComb.Record({
        metric: INTEGRATION_FILES_DOWNLOADED_METRIC,
        user_id: userInfo.id,
        workspace: userInfo.workspace.id,
        integration: integrationType,
        filesCount: files.length,
      })
      trackEvent('Sharepoint Files Downloaded')
    },
    [
      getIntegrationToken,
      sharepointPickerOpenState,
      trackEvent,
      userInfo.id,
      userInfo.workspace.id,
    ]
  )

  const onConnectCallback = useCallback(() => {
    Services.HoneyComb.Record({
      metric: INTEGRATION_CONNECTED_METRIC,
      user_id: userInfo.id,
      workspace: userInfo.workspace.id,
      integration: integrationType,
      product_area: IN_PRODUCT,
    })
    trackEvent(`${integrationType} connected`)
  }, [userInfo, trackEvent])

  const { handleConnect: handleSharepointConnect } = useOauthConnect({
    integrationType,
    onConnectCallback,
  })

  // if sharepoint should be opened, ensure we have a token
  useEffect(() => {
    const ensureToken = async () => {
      if (!_.isNil(sharepointPickerOpenState)) {
        setLoading(true)
        let accessToken = await getToken()
        if (_.isEmpty(accessToken)) {
          await handleSharepointConnect()
          accessToken = await getToken()
          if (_.isEmpty(accessToken)) {
            displayErrorMessage(
              'We encountered an error while connecting to SharePoint. Please try again.'
            )
            setSharepointPickerOpenState(null)
          }
        }
        setLoading(false)
      }
    }
    void ensureToken()
  }, [
    sharepointPickerOpenState,
    getToken,
    handleSharepointConnect,
    setSharepointPickerOpenState,
  ])

  useEffect(() => {
    if (
      !_.isNil(sharepointPickerOpenState) &&
      !_.isNil(integrationToken) &&
      !isPast(parseIsoString(integrationToken.expiresAt))
    ) {
      if (_.isNil(resourceUrl)) {
        displayErrorMessage('SharePoint is not connected to this workspace')
        return
      }

      let pickerInstance: IPicker | null = null

      const initializePicker = (iframe: HTMLIFrameElement) => {
        if (iframe?.contentWindow) {
          pickerInstance = Picker(iframe.contentWindow).using(
            Popup(),
            LamdaAuthenticate(getToken)
          )

          pickerInstance.on.notification(function (this: IPicker, message) {
            this.log('notification: ' + JSON.stringify(message))
          })

          pickerInstance.on.close(function (this: IPicker) {
            setSharepointPickerOpenState(null)
            pickerInstance = null
          })

          if (!_.isNil(sharepointPickerOpenState)) {
            Services.HoneyComb.Record({
              metric: INTEGRATION_FILE_PICKER_OPENED_METRIC,
              user_id: userInfo.id,
              workspace: userInfo.workspace.id,
              integration: integrationType,
            })
            trackEvent('Sharepoint File Picker Opened')
          }

          void (async () => {
            try {
              const results: IPickData | void = await pickerInstance.activate({
                baseUrl: resourceUrl,
                options: filePickerParams as IFilePickerOptions,
              })

              if (!_.isNil(results) && (results as IPickData).items) {
                await onResults((results as IPickData).items)
              }
              setSharepointPickerOpenState(null)
            } catch (error) {
              console.error('Picker activation error:', error)
            }
          })()
        }
      }

      const existingIframe = document.getElementById(
        'picker-iframe'
      ) as HTMLIFrameElement

      if (existingIframe?.contentWindow) {
        initializePicker(existingIframe)
        return
      }

      // If no iframe found, set up observer
      const observer = new MutationObserver(() => {
        const iframe = document.getElementById(
          'picker-iframe'
        ) as HTMLIFrameElement
        if (iframe?.contentWindow) {
          initializePicker(iframe)
        }
      })

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      })

      return () => {
        observer.disconnect()
      }
    }
  }, [
    integrationToken,
    fetchSharepointAccessToken,
    filePickerParams,
    getToken,
    handleSharepointConnect,
    onResults,
    resourceUrl,
    setSharepointPickerOpenState,
    sharepointPickerOpenState,
    trackEvent,
    userInfo.id,
    userInfo.workspace.id,
  ])

  return !_.isNil(sharepointPickerOpenState) ? (
    <>
      <div className="pointer-events-auto fixed inset-0 z-[100] bg-primary/80 backdrop-blur-sm" />
      <div className="pointer-events-auto fixed inset-0 z-[100] flex items-center justify-center px-4">
        {_.isNil(integrationToken) || loading ? (
          <div
            className="pointer-events-auto relative flex items-center justify-center rounded-lg bg-secondary"
            style={{ width: '1200px', height: '700px' }}
          >
            <Button
              onClick={() => setSharepointPickerOpenState(null)}
              className="ring-offset-background absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100"
              aria-label="Close"
              variant="unstyled"
            >
              <Icon icon={X} />
            </Button>
            <Loader2 className="size-8 animate-spin" />
          </div>
        ) : (
          <iframe
            id="picker-iframe"
            title="File Picker"
            width="1200"
            height="700"
            className="pointer-events-auto rounded-lg border border-primary shadow-lg"
          />
        )}
      </div>
    </>
  ) : null
}

export default SharepointFilePicker

import * as React from 'react'

import { LayersIcon } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import { ResearchKnowledgeSourceSelector } from 'components/assistant/features/composer/assistant-research-selector-dialog'
import InputSourceButton from 'components/assistant/features/composer/components/input-source-button'
import { NEW_KNOWLEDGE_SOURCE_CONFIG } from 'components/assistant/features/composer/config'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  DatabaseSource,
  KnowledgeSourceConfig,
  useKnowledgeSources,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { useAuthUser } from 'components/common/auth-context'
import { DropdownItemProps } from 'components/common/ks-input-dropdown/dropdown-item'

const AssistantResearchInput = () => {
  const userInfo = useAuthUser()
  const knowledgeSources = useKnowledgeSources(userInfo)
  const [
    mode,
    isForking,
    documents,
    documentsUploading,
    knowledgeSource,
    showResearchDialog,
    setShowResearchDialog,
    researchDialogArea,
    setResearchDialogArea,
  ] = useAssistantStore(
    useShallow((s) => [
      s.mode,
      s.isForking,
      s.documents,
      s.documentsUploading,
      s.knowledgeSource,
      s.showResearchDialog,
      s.setShowResearchDialog,
      s.researchDialogArea,
      s.setResearchDialogArea,
    ])
  )

  const hasMode = React.useCallback(
    (source: DatabaseSource) =>
      KnowledgeSourceConfig[source].modes.includes(mode),
    [mode]
  )

  const databaseSources = React.useMemo(
    () =>
      Object.values(DatabaseSource).filter((source) =>
        knowledgeSources.has(source)
      ),
    [knowledgeSources]
  )
  const availableDatabaseSources = React.useMemo(
    () => databaseSources.filter(hasMode),
    [databaseSources, hasMode]
  )

  const knowledgeSourceDropdownOptions: (DropdownItemProps | 'separator')[] =
    React.useMemo(() => {
      const tempKSConfig = NEW_KNOWLEDGE_SOURCE_CONFIG

      return availableDatabaseSources.reduce<DropdownItemProps[]>(
        (acc, source) => {
          if (!(source in tempKSConfig)) return acc
          const { icon, title, description } = tempKSConfig[source]!
          acc.push({
            icon,
            title,
            description,
            onClick: () => {
              setResearchDialogArea(source)
              setShowResearchDialog(true)
            },
          })
          return acc
        },
        []
      )
    }, [availableDatabaseSources, setShowResearchDialog, setResearchDialogArea])

  const isDraftMode = mode === AssistantMode.DRAFT
  const hasUploadedFiles =
    isForking ||
    documents.length > 0 ||
    documentsUploading.length > 0 ||
    (knowledgeSource && 'fileIds' in knowledgeSource) ||
    false

  const tooltipText = () => {
    if (isDraftMode) {
      return 'Not available in draft mode'
    }
    if (hasUploadedFiles) {
      return 'Cannot select knowledge sources when documents are added'
    }
    return undefined
  }

  const descriptionText = React.useMemo(() => {
    const availableSources = availableDatabaseSources.map(
      (source) => KnowledgeSourceConfig[source].name
    )

    if (isDraftMode) {
      return 'Not available in draft mode'
    }

    if (hasUploadedFiles) {
      return 'Cannot select knowledge sources when documents are added'
    }

    if (availableSources.length === 0) {
      return 'No knowledge sources available'
    }

    // TODO: extract this to a helper function
    // Can reuse this in descriptionText for files input
    if (availableSources.length === 1) return availableSources[0]

    if (availableSources.length === 2) return availableSources.join(' or ')

    if (availableSources.length === 3) {
      return `${availableSources
        .slice(0, 2)
        .join(', ')} or ${availableSources.slice(-1)}`
    }

    return `${availableSources.slice(0, 3).join(', ')} and more`
  }, [availableDatabaseSources, isDraftMode, hasUploadedFiles])

  return (
    <div className="w-full">
      <InputSourceButton
        title="Choose knowledge source"
        description={descriptionText}
        icon={LayersIcon}
        dropdownItems={knowledgeSourceDropdownOptions}
        disabled={
          isDraftMode || hasUploadedFiles || !availableDatabaseSources.length
        }
        tooltip={tooltipText()}
      />
      <ResearchKnowledgeSourceSelector
        showDialog={showResearchDialog}
        setShowDialog={setShowResearchDialog}
        databaseSource={researchDialogArea}
      />
    </div>
  )
}

export default AssistantResearchInput

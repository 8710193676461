import React from 'react'

import { SocketMessageResponseAntitrustFilingsAllOfMetadataRequirements } from 'openapi/models/SocketMessageResponseAntitrustFilingsAllOfMetadataRequirements'

import { Source } from 'utils/task'

import { Citations } from './citations'
import DataRow from './data-row'

const RequirementComponent: React.FC<{
  requirement: SocketMessageResponseAntitrustFilingsAllOfMetadataRequirements
  idx: number
  handleSourceClick: (source: Source) => void
}> = ({ requirement, idx, handleSourceClick }) => {
  return (
    <div className="space-y-2">
      <div className="mb-1 flex">
        <p className="mr-4 w-24 text-xs font-semibold">Requirement {idx + 1}</p>
        <p className="mr-1 text-xs font-semibold">
          {requirement.requirementConclusion.charAt(0).toUpperCase() +
            requirement.requirementConclusion.slice(1)}
        </p>
        <Citations
          handleSourceClick={handleSourceClick}
          sources={requirement.sources as Source[]}
        />
      </div>

      <DataRow label="Requirement" value={requirement.requirement} />
      <DataRow label="Analysis" value={requirement.requirementAnalysis} />
    </div>
  )
}

export default RequirementComponent

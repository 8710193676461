import React from 'react'

import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'

import AvailableWorkflows from './available-workflows'
import WorkflowsList from './workflows-list'

const WorkflowsPage: React.FC = () => {
  const userInfo = useAuthUser()

  return (
    <AppMain hasContainer data-testid="workflows-container">
      <AppHeader
        title="Workflows"
        subtitle="Use specialized workflows to tackle complex matters"
      />
      {userInfo.IsVaultWorkflowBrowserUser ? (
        <WorkflowsList />
      ) : (
        <AvailableWorkflows />
      )}
    </AppMain>
  )
}

export default WorkflowsPage

import { IRowNode } from 'ag-grid-community'

import {
  ColumnDataType,
  STRICT_COLUMN_DATA_TYPES,
} from 'components/vault/utils/vault'
import { ReviewAnswer } from 'components/vault/utils/vault'
import { isAnswerEmpty } from 'components/vault/utils/vault-helpers'

import { SortOrder } from './cell-comparator-types'
import {
  compareCurrencies,
  CurrencyCellComparator,
} from './currency-cell-comparator'
import { compareDates, DateCellComparator } from './date-cell-comparator'
import {
  compareDurations,
  DurationCellComparator,
} from './duration-cell-comparator'
import {
  compareNumbers,
  NumericCellComparator,
} from './numeric-cell-comparator'
import { TextCellComparator } from './text-cell-comparator'

const textComparator = new TextCellComparator()
const dateComparator = new DateCellComparator()
const durationComparator = new DurationCellComparator()
const numericComparator = new NumericCellComparator()
const currencyComparator = new CurrencyCellComparator()

export const cellComparator = ({
  valueA,
  valueB,
  nodeA,
  nodeB,
  isDescending,
  questionId,
  columnDataType,
}: {
  valueA: string
  valueB: string
  nodeA: IRowNode<any>
  nodeB: IRowNode<any>
  isDescending: boolean
  questionId: string
  columnDataType: ColumnDataType
}): number => {
  const answersA = nodeA.data?.answers ?? []
  const answersB = nodeB.data?.answers ?? []
  const answerA = answersA.find(
    (answer: ReviewAnswer) => answer.columnId === questionId
  )
  const answerB = answersB.find(
    (answer: ReviewAnswer) => answer.columnId === questionId
  )

  // Check for empty answers
  // Check for empty answers
  const isAnswerAEmpty = !answerA || isAnswerEmpty(answerA.text)
  const isAnswerBEmpty = !answerB || isAnswerEmpty(answerB.text)
  if (isAnswerAEmpty && isAnswerBEmpty) {
    return 0
  }
  if (isAnswerAEmpty) {
    // if answerA is empty, then we want to put it to the end of the list
    return isDescending ? -1 : 1
  }
  if (isAnswerBEmpty) {
    // if answerB is empty, then we want to put it to the beginning of the list
    return isDescending ? 1 : -1
  }

  // If the column data types are different, we want to sort by the category of the answer
  if (answerA.columnDataType !== answerB.columnDataType) {
    const answerACategory = getAnswerCategory(answerA)
    const answerBCategory = getAnswerCategory(answerB)
    return answerACategory - answerBCategory
  }

  // If the column data types are the same and NOT compound responses,
  // we want to sort by the the comparator for that data type
  if (
    answerA.columnDataType === answerB.columnDataType &&
    answerA.columnDataType !== ColumnDataType.compoundResponse
  ) {
    const comparator =
      cellComparatorMap[
        answerA.columnDataType as keyof typeof cellComparatorMap
      ]
    return comparator.compareCells({
      valueA,
      valueB,
      answerA,
      answerB,
      isDescending,
    })
  }

  if (
    answerA.columnDataType === ColumnDataType.compoundResponse &&
    answerB.columnDataType === ColumnDataType.compoundResponse
  ) {
    return compareCompoundResponses({
      answerA,
      answerB,
      columnDataType,
    })
  }

  const comparator = cellComparatorMap[ColumnDataType.freeResponse]
  return comparator.compareCells({
    valueA,
    valueB,
    answerA,
    answerB,
    isDescending,
  })
}

const getAnswerCategory = (answer: ReviewAnswer) => {
  if (isAnswerEmpty(answer.text) || !answer.columnDataType) {
    return SortOrder.EMPTY
  }
  if (STRICT_COLUMN_DATA_TYPES.includes(answer.columnDataType)) {
    return SortOrder.STRICT
  }
  if (answer.columnDataType === ColumnDataType.compoundResponse) {
    return SortOrder.COMPOUND_RESPONSE
  }
  return SortOrder.FREE_RESPONSE
}

const compareCompoundResponses = ({
  answerA,
  answerB,
  columnDataType,
}: {
  answerA: ReviewAnswer
  answerB: ReviewAnswer
  columnDataType: ColumnDataType
}) => {
  // find first item in the compound response that matches the columnDataType
  const firstItemA = answerA.rawResponse?.find(
    (item) => item.type === columnDataType
  )
  const firstItemB = answerB.rawResponse?.find(
    (item) => item.type === columnDataType
  )

  if (
    !firstItemA ||
    !firstItemB ||
    columnDataType === ColumnDataType.compoundResponse
  ) {
    return 0
  }

  // Note: we can't directly use the cell comparators here because the
  // we are not using the full answer object
  switch (columnDataType) {
    case ColumnDataType.date:
      return compareDates(firstItemA.value, firstItemB.value)
    case ColumnDataType.duration:
      return compareDurations(firstItemA.value, firstItemB.value)
    case ColumnDataType.numeric:
      return compareNumbers(firstItemA.value, firstItemB.value)
    case ColumnDataType.currency:
      return compareCurrencies({
        currencyCodeA: firstItemA.currencyCode || '',
        currencyCodeB: firstItemB.currencyCode || '',
        currencyAString: firstItemA.value || '',
        currencyBString: firstItemB.value || '',
      })
    default:
      return firstItemA.value > firstItemB.value ? 1 : -1
  }
}

// Update the map to use the singleton instances
export const cellComparatorMap = {
  [ColumnDataType.freeResponse]: textComparator,
  [ColumnDataType.date]: dateComparator,
  [ColumnDataType.duration]: durationComparator,
  [ColumnDataType.numeric]: numericComparator,
  [ColumnDataType.currency]: currencyComparator,

  // Default all others to text for now
  [ColumnDataType.classify]: textComparator,
  [ColumnDataType.extraction]: textComparator,
  [ColumnDataType.list]: textComparator,
  [ColumnDataType.empty]: textComparator,
  [ColumnDataType.noFormat]: textComparator,
  [ColumnDataType.string]: textComparator,
  [ColumnDataType.binary]: textComparator,
}

import * as React from 'react'

import { LucideIcon } from 'lucide-react'

import { cn } from 'utils/utils'

import KsInputDropdown from 'components/common/ks-input-dropdown'
import { DropdownItemProps } from 'components/common/ks-input-dropdown/dropdown-item'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

interface InputSourceButtonProps {
  className?: string
  title: string
  description: string
  icon: LucideIcon
  disabled?: boolean
  tooltip?: string
  dropdownItems: (DropdownItemProps | 'separator')[]
  children?: React.ReactNode
}

const InputSourceButton: React.FC<InputSourceButtonProps> = ({
  className,
  title,
  description,
  icon,
  disabled,
  tooltip,
  dropdownItems,
  children,
}) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  return (
    <KsInputDropdown
      triggerComponent={
        <Button
          variant="unstyled"
          className={cn(
            'group flex h-full w-full items-center justify-center gap-2 border border-transparent bg-primary py-4 pl-4 pr-3 transition hover:border-ring disabled:border-primary disabled:bg-secondary disabled:hover:border-primary',
            className,
            dropdownOpen && 'border-ring'
          )}
          disabled={disabled}
          data-testid="input-source-button"
        >
          <div className="flex-1">
            <p className="text-left text-sm font-medium group-disabled:text-inactive">
              {title}
            </p>
            {description && (
              <p className="text-left text-xs text-muted group-disabled:text-inactive">
                {description}
              </p>
            )}
          </div>
          <div className="flex size-8 items-center justify-center">
            <Icon
              icon={icon}
              className="size-4 text-muted group-disabled:text-inactive"
            />
          </div>
        </Button>
      }
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownItems={dropdownItems}
      tooltip={tooltip}
      disabled={disabled}
    >
      {children}
    </KsInputDropdown>
  )
}

export default InputSourceButton

import React from 'react'

import { ChevronsUpDown, PlusCircle } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button } from './button'
import { Checkbox } from './checkbox'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from './command'
import { Popover, PopoverContent, PopoverTrigger } from './popover'
import { ScrollArea } from './scroll-area'

export type MultiSelectEntry = {
  text: string
  value: string
  values?: string[]
  children?: MultiSelectEntry[]
}

export type MultiSelectGroup = {
  label: string
  entries: MultiSelectEntry[]
}

interface MultiSelectProps {
  placeholder: string
  sortedEntries: MultiSelectEntry[]
  sortedGroups?: MultiSelectGroup[]
  ungroupedTitle?: string
  selectedValues: string[]
  setSelectedValues: (selectedValues: string[]) => void
  disabled?: boolean
  className?: string
  popoverContentClassName?: string
  align?: 'center' | 'start' | 'end'
  createNew?: {
    onCreateNew: (value: string) => void
    createNewLabel: string
  }
  containerRef?: React.RefObject<HTMLDivElement>
  maxHeight?: string
  toggleAll?: {
    // this entry name should be different from the entries in the list
    toggleAllEntry: MultiSelectEntry
    onToggleAll: () => void
  }
  ariaLabelledBy?: string
  ariaLabel?: string
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  ariaLabel,
  placeholder = '',
  sortedEntries = [],
  sortedGroups = [],
  ungroupedTitle = '',
  selectedValues: selectedValuesProp,
  setSelectedValues: setSelectedValuesProp,
  disabled,
  className,
  popoverContentClassName,
  align = 'center',
  createNew = null,
  toggleAll = null,
  containerRef,
  maxHeight,
  ariaLabelledBy,
}) => {
  const [open, setOpen] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const selectedValues = new Set(selectedValuesProp)

  const showCreateNew = createNew?.onCreateNew && search.trim()

  const flattenEntry = (entry: MultiSelectEntry): MultiSelectEntry[] => {
    return entry.children ? [...entry.children] : [entry]
  }

  const flattenEntries = (entries: MultiSelectEntry[]): MultiSelectEntry[] => {
    return entries.flatMap(flattenEntry)
  }

  const getGroupEntries = (groups: MultiSelectGroup[]): MultiSelectEntry[] => {
    return groups.reduce((entries: MultiSelectEntry[], group) => {
      return entries.concat(flattenEntries(group.entries))
    }, [])
  }

  const groupEntries = getGroupEntries(sortedGroups)

  const ungroupedEntries = flattenEntries(sortedEntries)

  const getEntryCount = (entries: MultiSelectEntry[]): number => {
    // we don't want to include parents in the count, because they are not selectable
    const entriesWithoutParents = entries.filter((entry) => !entry.children)
    return entriesWithoutParents.length
  }

  const isEntrySelected = (entry: MultiSelectEntry): boolean =>
    !!(
      selectedValues.has(entry.value) ||
      entry.values?.some((value) => selectedValues.has(value)) ||
      entry.children?.some((item) => selectedValues.has(item.value))
    )

  const allEntriesSelected = (): boolean => {
    const selectableEntriesCount =
      getEntryCount(ungroupedEntries) + getEntryCount(groupEntries)
    return selectedValues.size === selectableEntriesCount
  }

  const isParentIndeterminate = (entry: MultiSelectEntry): boolean => {
    if (!entry.children || !entry.children.length) return false
    const checked = entry.children.filter((child) =>
      selectedValues.has(child.value)
    )
    return !(checked.length === entry.children.length) && checked.length > 0
  }

  const isToggleAllIndeterminate = (): boolean => {
    return !allEntriesSelected() && selectedValues.size > 0
  }

  const textMatchesSearch = (text: string): boolean => {
    return text.toLowerCase().includes(search.toLowerCase())
  }

  const childrenMatchSearch = (children: MultiSelectEntry[] = []): boolean => {
    return children.some((child) => textMatchesSearch(child.text))
  }

  const handleSelect = (
    entry: MultiSelectEntry,
    shouldClosePopover: boolean,
    isIndeterminate?: boolean
  ): void => {
    const value = entry.value
    const isSelected = isEntrySelected(entry)

    // Update all children values if the parent is selected
    if (entry.children) {
      entry.children.forEach((child) => {
        if (isSelected && !isIndeterminate) {
          selectedValues.delete(child.value)
        } else {
          // Select all values if the clicked parent is indeterminate
          selectedValues.add(child.value)
        }
      })
    } else {
      if (isSelected) {
        selectedValues.delete(value)
        entry.values?.forEach((val) => selectedValues.delete(val))
      } else {
        selectedValues.add(value)
      }
    }

    setSelectedValuesProp(Array.from(selectedValues))

    if (shouldClosePopover) {
      setOpen(false)
    }
  }

  const handleCreateNew = (): void => {
    if (!showCreateNew) return
    createNew.onCreateNew(search.trim())
    setSearch('')
  }

  const renderSelectionText = (): string => {
    const numberSelected = selectedValues.size
    if (numberSelected === 0) {
      return placeholder
    }

    if (numberSelected > 1) {
      return `${numberSelected} selected`
    }

    const firstSelectedItem = [...groupEntries, ...ungroupedEntries].find(
      (entry) => selectedValues.has(entry.value)
    )

    return firstSelectedItem?.text || ''
  }

  const renderSelectionItems = (
    entries: MultiSelectEntry[],
    titleMatchesSearch: boolean
  ) =>
    entries
      .map((entry) => {
        const parentMatchesSearch = textMatchesSearch(entry.text)
        const shouldRenderParent =
          parentMatchesSearch ||
          titleMatchesSearch ||
          childrenMatchSearch(entry.children)

        if (!shouldRenderParent) return null
        const isIndeterminate = isParentIndeterminate(entry)

        return (
          <React.Fragment key={entry.value}>
            {/* Render the parent if it, or any of its children match search */}
            <CommandItem
              value={entry.value}
              onSelect={() => handleSelect(entry, false, isIndeterminate)}
            >
              <Checkbox
                checked={isEntrySelected(entry)}
                checkboxClassName="mr-2"
                label={entry.text}
                isIndeterminate={isIndeterminate}
              />
            </CommandItem>

            {/* Render all children if parent matches search. Render any child if it matches search */}
            {entry.children?.map((child) => {
              const childMatchesSearch = textMatchesSearch(child.text)
              const shouldRenderChild =
                childMatchesSearch || titleMatchesSearch || parentMatchesSearch

              return shouldRenderChild ? (
                <CommandItem
                  key={child.value}
                  value={child.value}
                  className="pl-4"
                  onSelect={() => handleSelect(child, false)}
                >
                  <Checkbox
                    checked={isEntrySelected(child)}
                    checkboxClassName="mr-2"
                    label={child.text}
                  />
                </CommandItem>
              ) : null
            })}
          </React.Fragment>
        )
      })
      .filter(Boolean)

  const renderSelectionList = (
    title: string,
    entries: MultiSelectEntry[]
  ): JSX.Element | null => {
    const titleMatchesSearch = textMatchesSearch(title)
    const selectionItems = renderSelectionItems(entries, titleMatchesSearch)

    // Always show the group title if there are any matching items
    const hasMatchingItems = selectionItems.length > 0

    return hasMatchingItems || titleMatchesSearch ? (
      <CommandGroup heading={title}>{selectionItems}</CommandGroup>
    ) : null
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          aria-labelledby={ariaLabelledBy}
          aria-label={ariaLabel}
          disabled={disabled}
          className={cn(
            'justify-between disabled:cursor-not-allowed',
            className
          )}
          data-testid="multiselect-open-popover-button"
        >
          <span className="line-clamp-1 text-left text-sm">
            {renderSelectionText()}
          </span>
          <ChevronsUpDown
            aria-hidden="true"
            className="ml-2 size-4 shrink-0 opacity-50"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          'w-auto min-w-[180px] max-w-96 p-0',
          popoverContentClassName
        )}
        align={align}
        container={containerRef?.current}
      >
        <Command shouldFilter={false} loop>
          <CommandInput
            placeholder="Search"
            value={search}
            onValueChange={setSearch}
            data-testid="multiselect-search-input"
            containerClassName="w-auto"
          />
          <CommandList>
            <ScrollArea maxHeight={maxHeight}>
              <CommandEmpty>No results found.</CommandEmpty>
              {toggleAll && (
                <>
                  <CommandGroup>
                    <CommandItem
                      key={toggleAll.toggleAllEntry.value}
                      onSelect={() => {
                        toggleAll.onToggleAll()
                      }}
                    >
                      <Checkbox
                        checked={selectedValues.size > 0}
                        isIndeterminate={isToggleAllIndeterminate()}
                        checkboxClassName="mr-2"
                        label={toggleAll.toggleAllEntry.text}
                      />
                    </CommandItem>
                  </CommandGroup>
                  <CommandSeparator />
                </>
              )}
              {sortedGroups.map((group, index) => {
                const isLastGroup = index === sortedGroups.length - 1
                return (
                  <React.Fragment key={group.label}>
                    {renderSelectionList(group.label, group.entries)}
                    {!isLastGroup && <CommandSeparator />}
                  </React.Fragment>
                )
              })}
              {sortedGroups.length > 0 && sortedEntries.length > 0 && (
                <CommandSeparator />
              )}
              {sortedEntries.length > 0 &&
                renderSelectionList(ungroupedTitle, sortedEntries)}
              {showCreateNew && (
                <>
                  <CommandSeparator />
                  <CommandGroup>
                    <CommandItem
                      onSelect={handleCreateNew}
                      keywords={[search.trim()]}
                    >
                      <PlusCircle className="mr-2 size-4" />
                      {createNew.createNewLabel}: “{search.trim()}”
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
              {!toggleAll && selectedValues.size > 0 && (
                <>
                  <CommandSeparator />
                  <CommandGroup>
                    <CommandItem
                      onSelect={() => setSelectedValuesProp([])}
                      className="justify-center text-center"
                    >
                      Clear all
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
            </ScrollArea>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export { MultiSelect }

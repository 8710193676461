import * as React from 'react'
import { useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  useFlowControls,
  useFlowStepInput,
} from 'providers/modal-flow-provider/modal-flow-controls'
import { Step } from 'providers/modal-flow-provider/modal-flow-types'
import { z } from 'zod'

import { Profession } from 'openapi/models/Profession'

import { shouldShowTitle, shouldShowYoe } from 'utils/user-profile-helpers'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import Modal from 'components/common/flows/profile-setup/components/modal'
import ModalClose from 'components/common/flows/profile-setup/components/modal-close'
import { useSkipOnboarding } from 'components/common/flows/profile-setup/hooks/use-skip-onboarding'
import { ProfileSetupFlowType } from 'components/common/flows/profile-setup/profile-setup'
import { WelcomeStepType } from 'components/common/flows/profile-setup/steps/welcome-step'
import { prepareEventProperties } from 'components/common/flows/profile-setup/utils/prepare-event-properties'
import ProfileProfessionForm, {
  FORM_DESCRIPTION,
  FORM_HEADING,
} from 'components/common/profile/profile-primary-profession-form'

export type ProfessionStepType = Step<
  WelcomeStepType['stepOutput'],
  {
    primaryProfession: Profession
  }
>

const professionSchema = z.object({
  primaryProfession: z.nativeEnum(Profession),
})

export type ProfileSchema = z.infer<typeof professionSchema>

const ProfessionStep: React.FC = () => {
  const { goBack, navigateToStep, exit } =
    useFlowControls<ProfileSetupFlowType>()
  const stepInput = useFlowStepInput<ProfessionStepType>()
  const userInfo = useAuthUser()
  const form = useForm<ProfileSchema>({
    resolver: zodResolver(professionSchema),
  })
  const { trackEvent } = useAnalytics()
  const handleSkipOnboarding = useSkipOnboarding({ stepInput, exit })

  React.useEffect(() => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent('profile_setup_profession_step_viewed', preparedEventProperties)
  }, [stepInput, trackEvent])

  const handleContinue = form.handleSubmit((data) => {
    if (shouldShowTitle(data.primaryProfession)) {
      navigateToStep('job-title-step', {
        primaryProfession: data.primaryProfession,
        stepCounter: stepInput.stepCounter + 1,
      })
    } else if (shouldShowYoe(data.primaryProfession)) {
      navigateToStep('years-exp-step', {
        primaryProfession: data.primaryProfession,
        stepCounter: stepInput.stepCounter + 1,
      })
    } else {
      throw new Error('Invalid condition')
    }
  })

  return (
    <Modal
      goBack={goBack}
      handleContinue={handleContinue}
      currentStep={stepInput.stepCounter}
      totalSteps={stepInput.totalSteps}
      nextDisabled={!form.formState.isValid}
      heading={FORM_HEADING}
      description={FORM_DESCRIPTION}
    >
      {userInfo.isCreateUserProfilesOptionalUser ? (
        <ModalClose onClick={handleSkipOnboarding} />
      ) : null}
      <ProfileProfessionForm control={form.control} />
    </Modal>
  )
}

export default ProfessionStep

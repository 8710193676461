import * as React from 'react'

import {
  getSortedRowModel,
  getCoreRowModel,
  SortingState,
  ColumnDef,
  useReactTable,
  RowSelectionState,
  createColumnHelper,
} from '@tanstack/react-table'

import { TableSelect as TableSelectType } from 'openapi/models/TableSelect'

import { getHrvyInfoMetadata } from 'utils/source'
import { toTitleCase } from 'utils/string'
import { snakeToCamel } from 'utils/utils'

import Markdown from 'components/common/markdown/markdown'
import { DataTable } from 'components/ui/data-table/data-table'

interface TableSelectProps<T> {
  columns: ColumnDef<T>[]
  data: T[]
  selectedRows?: RowSelectionState
}

export const generateColumns = (
  tableData: TableSelectType,
  getHrvyInfoMetadata: getHrvyInfoMetadata
) => {
  const columnHelper = createColumnHelper<TableSelectType['rows'][number]>()

  if (tableData.rows.length === 0) {
    return []
  }

  if (tableData.orderedColumnNames?.length) {
    return tableData.orderedColumnNames.reduce(
      (acc: ColumnDef<TableSelectType['rows'][number]>[], column) => {
        const { key, name } = column
        if (!key || !name) return acc
        const camelCaseKey = snakeToCamel(key)
        acc.push(
          columnHelper.accessor(`data.${camelCaseKey}`, {
            header: () => <TableSelectHeader>{name}</TableSelectHeader>,
            cell: ({ row }) => (
              <TableSelectCell>
                <Markdown
                  getHrvyInfoMetadata={getHrvyInfoMetadata}
                  content={row.original.data[camelCaseKey]}
                />
              </TableSelectCell>
            ),
          })
        )
        return acc
      },
      []
    )
  } else {
    return Object.keys(tableData.rows[0].data).reduce(
      (acc: ColumnDef<TableSelectType['rows'][number]>[], key) => {
        acc.push(
          columnHelper.accessor(`data.${key}`, {
            header: () => (
              <TableSelectHeader>{toTitleCase(key)}</TableSelectHeader>
            ),
            cell: ({ row }) => (
              <TableSelectCell>{row.original.data[key]}</TableSelectCell>
            ),
          })
        )
        return acc
      },
      []
    )
  }
}

const TableSelectHeader: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="text-xs font-semibold tracking-wide text-primary">
      {children}
    </div>
  )
}

const TableSelectCell: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="text-xs text-primary">{children}</div>
}

const TableSelect = <T,>({
  data,
  columns,
  selectedRows,
}: TableSelectProps<T>) => {
  const [sorting, setSorting] = React.useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      rowSelection: selectedRows || {},
    },
  })

  return (
    <DataTable
      table={table}
      className="w-full"
      tableBodyCellClassName="align-top"
      tableCellClassName="bg-secondary"
    />
  )
}

export { TableSelectHeader, TableSelectCell }
export default TableSelect

import React, { useCallback, useRef } from 'react'

/**
 * This is the hardcoded container classname that the NetDocs extension expects
 * when the file input is not directly clicked.
 *
 * The hidden input component must be a child of this container.
 */
export const NETDOCS_INPUT_CONTAINER_CLASSNAME = 'dropzone'

export const useNetdocsInput = ({
  onFileSelect,
}: {
  onFileSelect?: (files: File[]) => Promise<void>
}) => {
  const prevFilesLengthRef = useRef<number>(0)

  /**
   * The Netdocs extension is designed to work with the native file picker,
   * not react-dropzone. The basic strategy here is to intercept the files
   * that the extension injects into a hidden input element and then call
   * the APIs we actually use for dropzone (i.e. onAssistantDrop).
   */
  const handleChange = useCallback(
    async (event: Event) => {
      if (!onFileSelect) return
      const input = event.target as HTMLInputElement
      const currFilesLength = input.files?.length ?? 0

      // Only process if the number of files has changed
      if (
        input.files &&
        currFilesLength > 0 &&
        currFilesLength !== prevFilesLengthRef.current
      ) {
        const files = Array.from(input.files).slice(prevFilesLengthRef.current)
        prevFilesLengthRef.current = currFilesLength
        await onFileSelect(files)
        // Reset the ref after processing files so we'll process all files next time.
        // Needed because the extension replaces the input's files instead of appending.
        prevFilesLengthRef.current = 0
      }
    },
    [onFileSelect]
  )

  const fileInputRef = useCallback(
    (element: HTMLInputElement | null) => {
      element?.removeEventListener('change', handleChange)
      element?.addEventListener('change', handleChange)
    },
    [handleChange]
  )

  const renderHiddenInput = () => {
    if (!onFileSelect) return null
    return <input type="file" className="hidden" ref={fileInputRef} />
  }

  return {
    fileInputRef,
    renderHiddenInput,
    inputContainerClassName: NETDOCS_INPUT_CONTAINER_CLASSNAME,
  }
}

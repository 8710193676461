import * as React from 'react'

import { FlowRouter } from 'providers/modal-flow-provider/modal-flow-controls'
import {
  Flow,
  FlowComponentMap,
} from 'providers/modal-flow-provider/modal-flow-types'

import JobTitleStep, { JobTitleStepType } from './steps/job-title-step'
import PracticeAreaStep, {
  PracticeAreaStepType,
} from './steps/practice-area-step'
import PrimaryLanguageStep, {
  PrimaryLanguageStepType,
} from './steps/primary-language-step'
import ProfessionStep, { ProfessionStepType } from './steps/profession-step'
import ProfileCompleteStep, {
  ProfileCompleteStepType,
} from './steps/profile-complete-step'
import WelcomeStep, { WelcomeStepType } from './steps/welcome-step'
import YearsExpStep, { YearsExpStepType } from './steps/years-exp-step'

export interface ProfileSetupFlowType extends Flow {
  steps: {
    'welcome-step': WelcomeStepType
    'profession-step': ProfessionStepType
    'practice-area-step': PracticeAreaStepType
    'job-title-step': JobTitleStepType
    'years-exp-step': YearsExpStepType
    'primary-language-step': PrimaryLanguageStepType
    'profile-complete-step': ProfileCompleteStepType
  }
}

const ProfileSetupFlowMap: FlowComponentMap<ProfileSetupFlowType> = {
  'welcome-step': WelcomeStep,
  'profession-step': ProfessionStep,
  'practice-area-step': PracticeAreaStep,
  'job-title-step': JobTitleStep,
  'years-exp-step': YearsExpStep,
  'primary-language-step': PrimaryLanguageStep,
  'profile-complete-step': ProfileCompleteStep,
}

// We subtract 3 because we don't want to count the welcome and profile complete steps.
// Lastly one of the steps is dynamic based on the profession the user selects.
export const NUM_TOTAL_STEPS = Object.keys(ProfileSetupFlowMap).length - 3

export const ProfileSetupFlow: React.FC = () => {
  return <FlowRouter<ProfileSetupFlowType> componentMap={ProfileSetupFlowMap} />
}

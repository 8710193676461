import React, { useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import { BaseAppPath } from 'components/base-app-path'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { Button } from 'components/ui/button'
import { DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import VaultProgress from 'components/vault/components/vault-progress'
import useVaultUploadFiles from 'components/vault/hooks/use-vault-upload-files'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import {
  projectsPath,
  queriesPath,
  QueryQuestion,
  REMOVE_PARAMS,
} from 'components/vault/utils/vault'
import {
  columnToQueryQuestion,
  isFileFinishProcessing,
  runReviewQuery,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

const VaultWorkflowFilesProgress = ({
  onBackButtonClicked,
}: {
  onBackButtonClicked: () => void
}) => {
  useVaultUploadFiles()
  const navigate = useNavigateWithQueryParams()
  const queryClient = useQueryClient()
  const { recordQuerySubmitted } = useQueryAnalytics(EventKind.VAULT_REVIEW)

  const [
    currentProject,
    currentProjectMetadata,
    fileIdToVaultFile,
    folderIdToVaultFolder,
  ] = useVaultStore(
    useShallow((state) => [
      state.currentProject,
      state.currentProjectMetadata,
      state.fileIdToVaultFile,
      state.folderIdToVaultFolder,
    ])
  )

  const [reviewQuestionsPerQueryLimit, reviewFilesPerQueryLimit] =
    useVaultUsageStore(
      useShallow((s) => [
        s.reviewQuestionsPerQueryLimit,
        s.reviewFilesPerQueryLimit,
      ])
    )

  const [selectedWorkflow, setWorkflowModalState] = useVaultWorkflowStore(
    useShallow((state) => [state.selectedWorkflow, state.setWorkflowModalState])
  )

  const [
    gridApi,
    setPendingQueryQuestions,
    setPendingQueryFileIds,
    setWorkflow,
    setQueryId,
    setIsRunButtonLoading,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.gridApi,
      state.setPendingQueryQuestions,
      state.setPendingQueryFileIds,
      state.setWorkflow,
      state.setQueryId,
      state.setIsRunButtonLoading,
    ])
  )

  const [isSubmitting, setIsSubmitting] = useState(false)

  const areAllFilesProcessed = useMemo(() => {
    if (!currentProject) {
      return false
    }
    return Object.values(fileIdToVaultFile)
      .filter((file): file is NonNullable<typeof file> => file !== undefined)
      .every((file) => isFileFinishProcessing(file))
  }, [currentProject, fileIdToVaultFile])

  const handleRunReviewQuery = async () => {
    setIsSubmitting(true)

    if (!selectedWorkflow || !currentProject) {
      return
    }

    const pendingQueryFileIds = Object.values(fileIdToVaultFile)
      .filter(
        (file): file is NonNullable<typeof file> =>
          file !== undefined && !!file.readyToQuery
      )
      .map((file) => file.id)
    const pendingQueryQuestions = [...selectedWorkflow.columns]
      .sort((a, b) => a.displayId - b.displayId)
      .map(columnToQueryQuestion) as QueryQuestion[]
    // add back in if needed
    // const clientMatterId: string | undefined =
    //   userInfo.IsVaultProjectClientMatterUser
    //     ? currentProjectMetadata.clientMatterId
    //     : undefined
    const queryId = await runReviewQuery({
      currentProject,
      currentProjectMetadata,
      folderIdToVaultFolder,
      fileIdToVaultFile,
      gridApi,
      hasSelectedFiles: false,
      hasNewFilesToRun: true,
      hasNewQuestionsToRun: true,
      hasEmptyCells: false,
      selectedRows: [],
      pendingQueryFileIds,
      pendingQueryQuestions,
      workflow: selectedWorkflow,
      queryId: '',
      query: selectedWorkflow.name,
      isNewQuery: true,
      isRetry: false,
      allQueryFileIds: pendingQueryFileIds,
      allQueryQuestions: pendingQueryQuestions,
      questionsLimit: reviewQuestionsPerQueryLimit,
      filesLimit: reviewFilesPerQueryLimit,
      clientMatterId: currentProjectMetadata.clientMatterId,
      setIsRunButtonLoading,
      recordQuerySubmitted,
      setQueryId,
      setPendingQueryFileIds,
      setPendingQueryQuestions,
      setWorkflow,
      clearSelectedRows: () => {},
      queryClient,
      hasToFetchQuery: true,
    })
    setIsSubmitting(false)
    if (queryId) {
      const newPath = `${BaseAppPath.Vault}${projectsPath}${currentProject.id}${queriesPath}${queryId}`
      navigate(newPath, {}, REMOVE_PARAMS)
      setWorkflowModalState(WorkflowModalState.None)
    }
  }

  if (!currentProject) {
    return null
  }
  return (
    <div className="flex h-full flex-col gap-y-4">
      <DialogHeader className="h-9 flex-none border-b border-b-primary px-6">
        <DialogTitle className="flex items-center">
          Create new project
        </DialogTitle>
      </DialogHeader>
      <div className="px-6">
        <VaultProgress />
        <VaultFileExplorer
          selectedRows={[]}
          setSelectedRows={() => {}}
          projectId={currentProject.id}
        />
      </div>
      <DialogFooter className="flex w-full flex-row justify-end gap-2 self-end px-6">
        <Button variant="outline" onClick={onBackButtonClicked}>
          Back
        </Button>
        <Button
          disabled={!areAllFilesProcessed || isSubmitting}
          isLoading={isSubmitting}
          onClick={handleRunReviewQuery}
        >
          Continue
        </Button>
      </DialogFooter>
    </div>
  )
}

export default VaultWorkflowFilesProgress
